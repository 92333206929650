import React, {useCallback, useEffect, useState} from "react";
import {getField} from "../helpers";
import {CategoriesCard} from "../Reports-styled";

const CommunityShare = ({layer, form, setForm, goNext, config, t}) => {
  const [communityShareFields, setCommunityShareFields] = useState([]);
  
  useEffect(()=>{
    if (!layer) return;
    const communityShare = getField(layer,"communityshare");
    setCommunityShareFields(communityShare);
  },[layer])
  
  const onShareSelect = useCallback((value)=>{
    setForm(form=> ({...form, communityshare: value}))
    goNext()
    
  },[setForm, form, goNext])
  
  return (
    communityShareFields.map(elem=>(
      <CategoriesCard
        key={elem.code}
        TypesOfVehicleCard
        selected={form.communityshare === elem.code}
        onClick={() => onShareSelect(elem.code)}
        borderColor={config.opsColor}
        bgColor={form.communityshare === elem.code && config.opsColor ? `${config.opsColor}1a` : ''}
      >
        <span>{t('report.warehouse.' + elem.name.toLowerCase())}</span>
      </CategoriesCard>
    ))
  );
};

export default CommunityShare;