import styled from "styled-components";

export const StyledCard = styled.div`
  	max-height: 120px;
    align-items: center !important;
  	cursor: pointer;
    padding: 5px!important;
	
    :hover {
	    background-color: lightgrey;
	  }
`;

export const CardTitle = styled.div`
	font-size: small;
    text-align: left;
  	color: grey;
`;

export const CardContent = styled.div`
	font-size: xx-small;
  	color: grey;
    text-align: left;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const CardContentLayerTitle = styled.div`
	font-size: xx-small;
    font-weight: bold;
  	color: gray;
    text-align: left;
`;

export const CardImageContainer = styled.span`
  	padding: 2px;
	height: 70px;
  	flex-basis: 35%;
	display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const CardImage = styled.img`
  object-fit: cover;
  border: 1px solid lightgrey;
  height: 100%;
  width: 100%;
`;

export const CardIcon = styled.img`
  height: 75%;
`;

export const CardThumbnailIcon = styled.img`
  position: absolute;
  left: 5px;
  top: 5px; 
  width: 20px;
`;


export const CardComment = styled.div`
  text-align: left;
  font-size: small;
  color: grey;
`;

export const ShowMore = styled.div`
  	font-size: small;
  	cursor: pointer;
    padding: 4px 0px;
`;