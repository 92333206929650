import React, {useEffect, useMemo, useState} from "react";
import {
  StyledReportsModal,
  StyledReportsWrapper
} from "./Reports-styled";
import {useDispatch, useSelector} from "react-redux";
import {getLayerFromId, removePoint, view} from "../../../utils/API";
import {loadModules} from "esri-loader";
import PacReport from "./PacReport/PacReport";
import WarehouseReport from "./WarehouseReport/WarehouseReport";
import {setPanelPath_actionType} from "../../../redux/constants";
import ReportsHeader from "./ReportsHeader/ReportsHeader";
import ReportSelectionFlow from "./ReportSelectionFlow";
import {sendMessageToSW} from "../../../serviceWorker";
import {getConfigISO} from "../../../utils/helper";

const Reports = ({config, openSnackbar, t, activeLanguage}) =>{
  const [layer, setLayer] = useState(null )
  const [feature, setFeature] = useState(null)
  const {activeModule, panelPath} = useSelector(state=>state);
  const [report, setReport] = useState('reportAll');
  const dispatch = useDispatch()

  useEffect(() => {
    if (!view) return
    
    loadModules(["esri/layers/Layer", "esri/core/reactiveUtils"]).then(([Layer, reactiveUtils]) => {
      if (report === 'pac' || report === 'warehouse') {
        const layerId = report === 'pac' ? config.reportingLayerId : config.warehouseSurveyId
        let layerPromise = getLayerFromId(Layer, layerId)
        layerPromise.then((layerPreLoad) => {
          layerPreLoad.load().then((reportLayer) => {
            setLayer(reportLayer)
          })
        })
      }
    })
  }, [report])
  
  useEffect(()=>{
    if (!view) return;
    
    setTimeout(()=>{
      loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
        /** Push report an update button on the popup */
        reactiveUtils.on(() => view.popup, "trigger-action", (event) => {
          if (event.action.id !== "report-update" || !view.popup.selectedFeature)
            return
      
          const selectedFeature = view.popup.selectedFeature
          view.closePopup()
          //Somehow clustered features don't specify layer, but they have a sourceLayer attribute
          if ((!selectedFeature.layer || selectedFeature.layer.declaredClass !== 'esri.layers.FeatureLayer') && selectedFeature.sourceLayer)
            selectedFeature.layer = selectedFeature.sourceLayer
          setFeature(selectedFeature)
          setReport('pac');
          dispatch({type: setPanelPath_actionType, payload: 'reportAll'})
        })
      })
    },100)
  
    if (!panelPath || report === 'pac') return;
    const reports = config.modules[activeModule]?.reports ?? config.opDefaults.modules[activeModule]?.reports ?? [];
    if (reports.length === 1) {
      setReport(reports[0]);
    } else {
      setReport(panelPath);
    }
  },[panelPath, activeLanguage])

  const hideReport = useMemo(()=> !panelPath || panelPath === '' || panelPath !== 'reportAll',[panelPath])
  if (hideReport) return null;
  
  const closeModal = () => {
    dispatch({type: setPanelPath_actionType, payload: ''})
    setFeature(null);
    setLayer(null);
    setReport('reportAll');
    
    const newURL = new URL(window.location.href);
    const newUrlParams = new URLSearchParams(window.location.search);
    newUrlParams.delete('datacollection', 'open')
    newUrlParams.delete('module', activeModule.toLowerCase())
    newUrlParams.delete('lang', activeLanguage.toLowerCase())
    newURL.search = newUrlParams;
    window.history.replaceState({ path: newURL.href }, '', newURL.href);
  }
  
  const handleSubmit = async (contactsData, reportForm) => {
    try {
      const {attachment, geometry ,...reportAttributes} = reportForm
      const reportData = {
        attributes:{
          ...reportAttributes,
          fpname: contactsData.firstName + " " + contactsData.surName,
          fpemail: contactsData.email,
          fpphone: contactsData.contactNumber,
          fporganisation: contactsData.organization,
          showpublic: 2,
          opid: config.id,
          currvalidationfield: 1,
          isdeleted: 2,
          longitude: geometry.longitude,
          latitude: geometry.latitude
        },
        geometry: {
          type: 'point',
          longitude: geometry.longitude,
          latitude: geometry.latitude
        },
      }
      
      /**
       * Temporary patch: use iso code, use the first one when more iso codes are specified
       */
      const iso3 = getConfigISO(config);
      if (iso3){
        reportData.attributes.iso3 =(Array.isArray(iso3)) ? iso3[0] : iso3
      }
      
      // sending form data to service worker for offline
      sendMessageToSW({ report_form_data: { reportData } });

      const editResults = await layer.applyEdits({ addFeatures: [reportData] })

      if (attachment && editResults.addFeatureResults) {
        let attributes = {}
        attributes[layer.objectIdField] = editResults.addFeatureResults[0].objectId
        const feature = { attributes: attributes}
        const attachmentForm = new FormData();
        attachmentForm.set("attachment", attachment);
        //form.append("f","json")
        await layer.addAttachment(feature, attachmentForm)
      }

      openSnackbar("Your report has been sent successfully. Thank you for reporting!", 5000)
      await removePoint();
    } catch (error) {
      console.error(error);
      openSnackbar("Oops, something was wrong! Please try again", 5000)
      await removePoint();
    }
  }
  
  const openReport = (report) => {
    setReport(report)
  }
  
  const renderReport = () => {
    const reportTitlePath = `${config.title} / ${activeModule} / `;
    const reportTitle = reportTitlePath + t('report.' + report + '.title') ?? config.reportDefinition[report]?.titleLabel
    switch (report) {
      case "pac": {
        return <PacReport
          config={config}
          closeModal={closeModal}
          layer={layer}
          feature={feature}
          setFeature={setFeature}
          openSnackbar={openSnackbar}
          onSubmit={handleSubmit}
          openReport={()=>openReport('reportAll')}
          title={reportTitle}
          t={t}
        />
      }
      case "warehouse": {
        return <WarehouseReport
          config={config}
          closeModal={closeModal}
          layer={layer}
          feature={feature}
          openSnackbar={openSnackbar}
          onSubmit={handleSubmit}
          openReport={()=>openReport('reportAll')}
          title={reportTitle}
          t={t}
        />
      }
      case "reportAll": {
        return <ReportSelectionFlow
          closeModal={closeModal}
          openReport={openReport}
          config={config}
          activeModule={activeModule}
          t={t}
          openSnackbar={openSnackbar}
        />
      }
      default: {
        return <>
          <ReportsHeader
            title={reportTitle}
            close={closeModal}
            onArrowClick={() => openReport('reportAll')}
            showArrow={true}
          />
          <iframe width="100%" height="100%" src={config.reportDefinition[report]?.url[activeLanguage]}/>
        </>
      }
    }
  }
  
  return <StyledReportsWrapper>
    <StyledReportsModal show={!hideReport}>
      {renderReport()}
    </StyledReportsModal>
  </StyledReportsWrapper>
};

export default Reports;