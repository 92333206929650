import React from "react";
import PropTypes from "prop-types";

import {CheckBoxContainer} from "../../styles/CheckBox/CheckBox-styled";

const CheckBox = ({ value, onChange }) => {
	return (
		<CheckBoxContainer>
			<input
				data-testid='checkbox-report-input'
				type="checkbox"
				onChange={(ev) => onChange(ev.target.checked)}
				checked={value}
			/>
		</CheckBoxContainer>
	);
};

CheckBox.propTypes = {
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default CheckBox;
