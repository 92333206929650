import React, {useEffect, useState} from "react";
import {StyledSectionDescription, StyledSectionTitle} from "./ScrollSection/ScrollSection-styled";

const ReportFinal = ({closeModal, startCounter, t}) => {
  const [count, setCount] = useState(5);
  
  useEffect(()=>{
    if (!startCounter) return;
    let timer;
    
    if (count < 1) {
      closeModal()
      return;
    }
    
    setTimeout(()=>{
      setCount(prev => prev - 1);
    },1000)
    
    return ()=>{
      if (timer) {
        clearTimeout(timer)
      }
    }
  },[count, startCounter])
  
  return (
    <>
      <StyledSectionTitle>{t("report.final.title")}</StyledSectionTitle>
      <StyledSectionDescription>{t("report.final.description")} {count}</StyledSectionDescription>
    </>
  );
};

export default ReportFinal;