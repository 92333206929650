import React from "react";
import {Cross} from "./helpers"
import {
    PanelHeaderContainer,
    TextContainer,
    PanelSubtitle,
    PanelTitle,
    IconsContainer,
    CloseIconContainer
} from "./PanelHeader-styled";
import PanelPositioning from "../PanelPositioning/PanelPositioning";
import { getScreenId} from "../../helpers";

const PanelHeader = ({panelPath, t, closePanel}) => {
    const openScreenId = getScreenId(panelPath)
    const title = t(`screen.panel.${openScreenId}.title`, openScreenId)
    const subtitle = t(`screen.panel.${openScreenId}.subtitle`, "")

    return (
        <PanelHeaderContainer id={"panel-header"}>
            <TextContainer>
                <PanelTitle> {title}</PanelTitle>
                {subtitle && subtitle.length > 0 && <PanelSubtitle>{subtitle}</PanelSubtitle>}
            </TextContainer>
            <IconsContainer>
                <PanelPositioning/>
                <CloseIconContainer onClick={() => closePanel()}>
                    <Cross/>
                </CloseIconContainer>
            </IconsContainer>
        </PanelHeaderContainer>
    )
}

export default PanelHeader