import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {setPanelPath_actionType} from "../../redux/constants";
import {closeSlideBar} from "../Panel/helpers";

const EditorToggle = ({t}) => {
    const dispatch = useDispatch()
    const defaultPath = "editor"
    const {panelPath} = useSelector((store) => store)

    const title = t('screen.widget.Editor.toggle')

    const setPanelPath = (newPath) => {
        dispatch({type: setPanelPath_actionType, payload: newPath})
    }

    const toggle = () => {
        if (panelPath && panelPath === defaultPath) {
            closeSlideBar(dispatch)
            return
        }
        setPanelPath(defaultPath)
    }

    const expandClassName = panelPath && panelPath === defaultPath ? "esri-icon-collapse" : "esri-icon-edit"
    return (
        <div className="esri-widget esri-widget--button" title={title} onClick= {toggle}>
            <span id="editorToggleIcon" className={expandClassName} />
        </div>
    )
}

export default EditorToggle
