import styled from "styled-components";
import {CalciteTableCell, CalciteTableRow} from "@esri/calcite-components-react"

export const StyledTableCell = styled(CalciteTableCell)`
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	color: #0B1923;
	border: none;
	background-color: #fff;
	width: 100%;
	white-space: pre-line;
	position: relative;
		&:nth-child(n){
			background-color: #fff;
			padding: 0;
		}
`;

export const StyledTableRow = styled(CalciteTableRow)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-bottom: ${({ withUnderline }) => (withUnderline ? `1px solid #E5E4ED` : `none`)};
	padding: 2px 0 4px 0;
	&:nth-child(n){
			background-color: #fff;
			margin: 0 !important;
	}
	&:last-child{
		border: none;
	}
`;

export const StyledContent = styled.span`
	font-weight: 600;
	line-height: ${({ comment }) => ( comment ? "14px" : "20px")};
	color: #232831;
`;