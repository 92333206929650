import styled from "styled-components";

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 6px;
  
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  button {
    display: flex;
    align-items: center;
    border: none;
    padding: 2px;
    background: none;
    cursor: pointer;
  }
`