import React from "react";
import {TextAreaStyled, TextAreaWrapper} from "./TextArea-styled";

const TextArea = ({borderColor, label, ...props}) => {
  return (
    <TextAreaWrapper borderColor={borderColor}>
      <TextAreaStyled
        borderColor={borderColor}
        {...props}
      />
      {label && <span>{label}</span>}
    </TextAreaWrapper>
  );
};

export default TextArea;