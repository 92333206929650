import styled from "styled-components";

export const StyledError = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledH2 = styled.h2`
  color: #000;
`;

export const StyledP = styled.p`
  margin: 0.75rem 0 0.75rem;
`;
