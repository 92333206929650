import React, {useEffect, useMemo, useRef, useState} from "react";
import MapFilter from "./MapFilter/MapFilter";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode} from "swiper/modules";
import {
  ManualWidgetWrapper,
  MapFilterButton,
  StyledToggleButton,
  StyledToggleButtonWrapper,
  StyledToggleTitle, StyledToggleWrapper
} from "./ManualWidget-styled";
import {useSelector} from "react-redux";
import {getLayerTitle} from "../../esri/custom-popup-content";
import {chevronLeft16, chevronRight16} from "@esri/calcite-ui-icons";
import {view} from "../../utils/API";
import {isTouchScreen} from "../../utils/helper";
import Symbology from "./Symbology/Symbology";

const ManualWidget = ({config, t}) => {
  const {filters, printWidget} = useSelector(state=>state);
  const {activeModule, layersLoading} = useSelector(state=>state);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [moduleOptionalLayers, setModuleOptionalLayers] = useState([]);
  
  let [counter, setCounter] = useState([])
  
  const [size, setSize] = useState({
    width:0,
    height:0
  })
  
  useEffect(() => {
    const viewDiv = document.getElementById('viewDiv');
    if (!viewDiv) return;
    const observer = new ResizeObserver(()=>{
      setSize({
        width: viewDiv.offsetWidth,
        height: viewDiv.offsetHeight,
      })
    })
    observer.observe(viewDiv)
    return () => {
      if (observer){
        observer.disconnect()
      }
    }
  },[])
  
  useEffect(()=>{
    addLayers(view?.map?.layers)
    
    view?.map?.layers?.on("change", (event) => {
      addLayers(event.added)
    })
  }, [])
  
  const addLayers = (layers) => {
    layers?.forEach((layer) => {
      layer.load().then((layer) => {
        setCounter(counter++)
        layer.watch("visible", () => setCounter(counter++))
      })
    })
  }
  
  const swiper = useRef();
  const ref = useRef();
  
  const onNext = () =>{
    swiper.current?.slideNext();
    if (!showPrev) {
      setShowPrev(true)
    }
  }
  
  const onPrev = () =>{
    swiper.current?.slidePrev();
    if (!showNext) {
      setShowNext(true)
    }
  }
  
  const handleLayerSelection = (layer) =>{
    const visibility = !layer.visible
    
    layer.visible = visibility;
    layer.labelsVisible = showLabels;
    
    view.map.layers.forEach((l)=>{
      if (l.isLabel && l.layerConfigAlias === layer?.layerConfig.alias && layer?.layerConfig?.id === l.layerConfigId) {
        l.visible = visibility
      }
    })
    
    layer.load().then(()=>{
      setCounter(counter++)
    })
  }
  
  useEffect(()=>{
    if (!view || layersLoading) return;
    
    const optionalLayers = config.modules[activeModule]?.optionalLayers || [];
    const res = [];
    view.map.layers.forEach((layer) =>{
      if (optionalLayers.includes(layer.layerConfig?.alias)) {
        res.push(layer)
      }
    });
  
    setModuleOptionalLayers(res);
  },[activeModule, config, view, layersLoading])
  
  const labelLayers = useMemo(()=>{
    return view.map.layers.filter(layer=>{
      return layer.layerConfig && layer.layerConfig.labelField && layer.visible;
    })
  },[activeModule, config, layersLoading, view, counter])
  
  useEffect(()=>{
    labelLayers.forEach(layer=>{
      layer.labelsVisible = !printWidget
    })
    setShowLabels(!printWidget);
    
  },[printWidget])
  
  const getVisibleFilters = () => {
    let fields = {}
    const mapKeysArray = config.filterFields.filter(item=>item.shownOnMap);
    
    view.map.layers.forEach((layer) => {
      layer.fields && layer.visible && filters[activeModule] && Object.keys(filters[activeModule]).forEach((filterField) => {
        layer.fields && layer.fields.filter((field) => field.name === filterField).forEach((field) => {
          const mapField = mapKeysArray.find(item=>item.name === field.name) || layer.layerConfig?.filterFields?.find(item=>item.name === field.name && item.shownOnMap)
          if (mapField) {
            fields[field.name] = {
              ...mapField,
              field: field,
              layer
            }
          }
        })
      })
    })
    
    if (!window.isSmall && Object.keys(fields).length > 0) {
      fields.all = {
        type: 'all'
      }
    }
    return fields
  }
  
  const visibleFields = getVisibleFilters();
  
  const layersWithSymbology = useMemo(()=>{
    const multipleSymbologyLayer = [];
    view.map.layers.forEach(layer=>{
      if (Array.isArray(layer.layerConfig?.symbology)){
        multipleSymbologyLayer.push(layer);
      }
    })
    
    return multipleSymbologyLayer;
  },[activeModule, config, layersLoading, view, counter])
  
  const isReady = useMemo(()=>{
    return moduleOptionalLayers.length > 0 || Object.keys(visibleFields).length > 0 || labelLayers.length > 0 || layersWithSymbology.length > 0;
  },[moduleOptionalLayers, visibleFields, labelLayers, layersWithSymbology])
  
  useEffect(()=>{
    if (!swiper.current) return;
    if (swiper.current.virtualSize > ref.current?.offsetWidth && !(isTouchScreen() && window.isSmall)) {
      setShowNext(!swiper.current.isEnd)
      setShowPrev(!swiper.current.isBeginning)
    } else {
      setShowNext(false)
      setShowPrev(false)
    }
  },[isReady, size, visibleFields])
  
  const isTouch = useMemo(()=>isTouchScreen(),[])
  
  return (
    <ManualWidgetWrapper
      ref={ref}
      style={{
        width:'auto',
        display:'flex',
        alignItems:'flex-end',
    }}>
      {
        isReady && <>
        {
          showPrev && (
            <MapFilterButton
              onClick={onPrev}
              opsColor={config.opsColor}
              style={{
                marginBottom:4,
                padding:4,
                position: 'absolute',
                left: '-22px',
                zIndex:1
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path d={chevronLeft16} />
              </svg>
            </MapFilterButton>
          )
        }
          <Swiper
            onSwiper={(s)=>{
              swiper.current = s;
            }}
            slidesPerView='auto'
            spaceBetween={5}
            freeMode={true}
            modules={[FreeMode]}
            pagination={false}
            preventInteractionOnTransition={false}
            allowTouchMove={isTouch}
            noSwiping={!isTouch}
            updateOnWindowResize
            onReachEnd={()=>{
              setShowNext(false)
            }}
            onReachBeginning={()=>{
              setShowPrev(false)
            }}
          >
            {
              moduleOptionalLayers.map(layer=>(
                <SwiperSlide
                  key={layer.id}
                >
                  <MapFilterButton
                    key={layer.id}
                    onClick={()=>handleLayerSelection(layer)}
                    selected={layer.visible}
                    opsColor={config.opsColor}
                    radius="4px"
                  >
                    <span>{getLayerTitle(layer, t)}</span>
                  </MapFilterButton>
                </SwiperSlide>
              ))
            }
  
            {layersWithSymbology.map((symbologyLayer)=>(
              <SwiperSlide key={symbologyLayer.id}>
                <Symbology t={t} config={config} layer={symbologyLayer}/>
              </SwiperSlide>
            ))}
            {
              labelLayers.length > 0 && (
                <SwiperSlide>
                  <StyledToggleWrapper>
                    <StyledToggleTitle>
                      <p>{t('screen.widget.Filter.labels')}</p>
                    </StyledToggleTitle>
                    <StyledToggleButtonWrapper>
                      <StyledToggleButton
                        onClick={()=>{
                          setShowLabels(true)
                          labelLayers.forEach(layer=>{
                            layer.labelsVisible = true
                          })
                        }}
                        selected={showLabels}
                        opsColor={config.opsColor}
                      >
                        <span>{t('screen.widget.Filter.show')}</span>
                      </StyledToggleButton>
                      <StyledToggleButton
                        onClick={(e)=>{
                          setShowLabels(false)
                          labelLayers.forEach(layer=>{
                            layer.labelsVisible = false;
                          })
                        }}
                        selected={!showLabels}
                        opsColor={config.opsColor}
                      >
                        <span>{t('screen.widget.Filter.hide')}</span>
                      </StyledToggleButton>
                    </StyledToggleButtonWrapper>
                  </StyledToggleWrapper>
                </SwiperSlide>
              )
            }
            {
              !!Object.keys(visibleFields).length && Object.keys(visibleFields)?.map((field)=>(
                <SwiperSlide
                  key={field}
                >
                  <MapFilter config={config} view={view} t={t} field={visibleFields[field]} />
                </SwiperSlide>
              ))
            }
          </Swiper>
        {
          showNext && (
            <MapFilterButton
              onClick={onNext}
              opsColor={config.opsColor}
              style={{
                marginBottom:4,
                padding:4,
                position: 'absolute',
                right: '-22px',
                zIndex:1
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path d={chevronRight16} />
              </svg>
            </MapFilterButton>
          )
        }
        </>
      }
    </ManualWidgetWrapper>
  );
};

export default ManualWidget;