import React, {useMemo} from "react";
import {view} from "../../../../utils/API";
import { chevronRight24, chevronLeft16 } from "@esri/calcite-ui-icons";
import {renderCell} from "../../../../utils/helper";
import {StyledPopUpTitle, StyledPopupTitleWrapper} from "../../CustomPopupWrapper-styled";
import getAllowedFields from "../../helpers/getAllowedFields";
import getClusterAttributeFields from "../../helpers/getClusterAttributeFields";
import {StyledNavButton} from "../../style/ClusterDetails/ClusterDetails-styled";

const ClusterList = ({layer, feature, clusterFeatures, t, config, goBack, savePopup}) => {
  const cluster = layer.layerConfig.cluster;
  
  const relatedFeatures = useMemo(()=> clusterFeatures
    .filter(feat => feat.attributes[cluster.clusterAttribute1] === feature.attributes[cluster.clusterAttribute1]),
    [clusterFeatures]);
  
  const fields = useMemo(()=> getAllowedFields(layer, feature),[feature, layer]);
  const clusterFields = useMemo(()=>getClusterAttributeFields(cluster, fields, t),[fields, t]);
  
  if (!relatedFeatures.length) return null;
  
  return (
    <div style={{width:'100%'}}>
      <StyledPopupTitleWrapper>
        <StyledPopUpTitle style={{justifyContent:'center'}}>Total: {relatedFeatures.length}</StyledPopUpTitle>
      </StyledPopupTitleWrapper>
      <div style={{display:'flex', alignItems:'center'}}>
        <StyledNavButton
          onClick={()=>{
            goBack()
            view.popup.title = clusterFields.fieldTitle1;
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path d={chevronLeft16} />
          </svg>
          <span style={{fontSize:10}}>{clusterFields.fieldTitle1}</span>
        </StyledNavButton>
        {clusterFields.field2 && <span style={{
          marginLeft: 4,
          fontSize: 10
        }}> / {clusterFields.fieldTitle2}</span>}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap:'4px',
          width:'100%',
          marginTop:12
        }}>
        {
          relatedFeatures.map((feat)=>(
            <StyledNavButton
              key={feat.uid}
              onClick={()=>{
                savePopup();
                view.openPopup({
                  features:[feat],
                  actions: []
                })
              }}
            >
              <span>
                {renderCell(clusterFields.field2, feat.attributes[clusterFields.field2?.name], t, config)}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path d={chevronRight24} />
              </svg>
            </StyledNavButton>
          ))
        }
      </div>
    </div>
  );
};

export default ClusterList;