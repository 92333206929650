import React from "react";
import legend from "./legend.jpg"

const CEPULegend = ({t}) => {
	const title = t('screen.widget.Legend.name')
	return (
		<div className="esri-layer-list esri-widget esri-widget--panel" title={title}>
			<img src={legend} alt={title}/>
		</div>
	)
}

export default CEPULegend;