import React, {useCallback, useEffect, useState} from "react";
import {view} from "../../utils/API";
import {loadModules} from "esri-loader";
import {clickEventOnFeature} from "../../utils/helper";
import FeatureCard from "../ReportManager/FeatureCard";
import {ShowMore} from "../ReportManager/ReportManager-styled";
import {CalciteLoader} from "@esri/calcite-components-react";

/**
 * Latest Updates widget: show recent updates of feature layers
 */
const LatestUpdates = ({expand, config, t}) => {
	const title = t('screen.widget.LatestUpdates.title')

	let [offset, setOffset] = useState(50)
	/**
	 * This is only a trigger to re-render the page. every time we load new data (feature or attachment)
	 * we change the values of this trigger
	 */
	let [layersLoaded, setLayersLoaded] = useState(0)

	useEffect(() => {
		view.map.layers.on("change", (event) => {
			if (event.added)
				event.added.forEach((layer) => {
					processLayer(layer)
					layer.on("edits", () => {processLayer(layer)})
					layer.on("refresh", () => {processLayer(layer)})
					layer.watch("visible", () => {
						setLayersLoaded(layersLoaded++)
					})
				})
		})
		view.map.layers.forEach((layer) => {
			processLayer(layer)

			//Update page when edit or refresh events occur:
			layer.on("edits", () => {processLayer(layer)})
			layer.on("refresh", () => {processLayer(layer)})
			layer.watch("visible", () => {
				setLayersLoaded(layersLoaded++)
			})
		})
	}, [])

	const processLayer = useCallback ((layer) => {
		layer.load().then((layer) => {
			if (layer.type !== "feature" || !layer.layerConfig || !layer.layerConfig.latestUpdates?.isShown || !layer.editFieldsInfo?.editDateField){
				layer.lastEditedFeaturesQueryReady = true
				setLayersLoaded(layersLoaded++)
				return
			}

			const editDateFieldName = layer.editFieldsInfo?.editDateField
			const editDateField = layer.fields.filter((field) => field.name === editDateFieldName)[0]
			if (!editDateField) {
				console.warn("Layer: " + layer.title + " defines a missing editDateField: " + editDateFieldName)
				layer.lastEditedFeaturesQueryReady = true
				setLayersLoaded(layersLoaded++)
				return
			}

			queryLayer(layer, editDateFieldName)
			layer.watch("definitionExpression", () => {
				queryLayer(layer, editDateFieldName)
			})
		})
	})

	/**
	 * Query a layer for the 5 most (or least) recently edited features and their attachments
	 * the results will be saved in the layer.lastEditedFeatures property
	 */
	const queryLayer = (layer, editDateFieldName) => {
		loadModules(["esri/rest/support/Query"]).then(([Query]) => {
			const query = new Query()
			query.outFields = ["*"]
			query.where = editDateFieldName + " is not null"
			if (layer.definitionExpression)
				query.where += " and (" + layer.definitionExpression + ")"

			query.orderByFields = [editDateFieldName + " DESC"]
			//query.num = FEATURES_PER_PAGE
			query.returnGeometry = true

			layer.queryFeatures(query).then((lastEditedFeatures) => {
				const lastEditedFiltered = lastEditedFeatures.features.filter((ftr) =>
					ftr.attributes[editDateFieldName] && ftr.attributes[editDateFieldName] != null
				)

				if (isEqual(layer.lastEditedFeatures, lastEditedFiltered, layer, editDateFieldName)){
					layer.lastEditedFeaturesQueryReady = true
					return
				}

				layer.lastEditedFeatures = lastEditedFiltered
				if (!layer.lastEditedFeatures || layer.lastEditedFeatures.length === 0 || !layer.capabilities.operations.supportsQueryAttachments){
					layer.lastEditedFeaturesQueryReady = true
					setLayersLoaded(layersLoaded++)
					return
				}

				setLayersLoaded(layersLoaded++)
				queryLayerAttachments(layer)
			})
		})
	}

	const isEqual = (resA, resB, layer, editDateFieldName) => {

		const lenA = !resA ? 0 : resA.length
		const lenB = !resB ? 0 : resB.length
		if (lenA !== lenB)
			return false

		const oidField = layer.objectIdField
		resA?.forEach((fa, idx) => {
			const fb = resB[idx]
			if (fa.attributes[editDateFieldName] !== fb.attributes[editDateFieldName] || fa.attributes[oidField] !== fb.attributes[oidField])
				return false
		})

		return true
	}
	/**
	 * Query the layer for attachments of the most (or least) recently edited features.
	 * The features to query for are already stored in the layer.lastEditedFeatures property
	 */
	const queryLayerAttachments = (layer) => {
		const oidField = layer.objectIdField
		const objectIds = layer.lastEditedFeatures.map((feature) => feature.attributes[oidField])

		layer.queryAttachments({objectIds: objectIds}).then((response) => {
			response && Object.keys(response).forEach((oid) => {
				// Each object might have different attachments
				const objectAtts = response[oid]

				layer.lastEditedFeatures.forEach((feature) => {
					// Save attachments into the layer features array, assigned to the correct feature
					if (feature.attributes[oidField].toString() === oid) {
						feature.attachments = objectAtts
					}
				})
			})

			layer.lastEditedFeaturesQueryReady = true
			setLayersLoaded(layersLoaded++)
		})
	}

	const onShowMoreClicked = () => {
		setOffset(offset + 50)
	}

	const getFeatureEditDate = (feature) => {
		const editDateFieldName = feature.layer.editFieldsInfo?.editDateField
		if (!editDateFieldName)
			return

		return feature.attributes[editDateFieldName]
	}

	const compareFeatures = (a, b) => {
		const editDateA = getFeatureEditDate(a)
		if (!editDateA)
			return 1


		const editDateB = getFeatureEditDate(b)
		if (!editDateB)
			return 1

		return (editDateA > editDateB) ? -1 : 1
	}

	const clickEVentOnUpdate = (feature) => {
		expand.collapse()
		clickEventOnFeature(feature)
	}

	let featuresToShow = []
	view.map.layers.forEach((layer) => {
		if (layer.visible && layer.lastEditedFeatures)
			featuresToShow = featuresToShow.concat(layer.lastEditedFeatures)
	})

	featuresToShow = featuresToShow.sort((a,b) => compareFeatures(a, b))
	const queriesInProgress = view.map.layers.filter((layer) => !layer.lastEditedFeaturesQueryReady).length

	let showMoreActive = false
	if (featuresToShow.length > offset){
		featuresToShow = featuresToShow.slice(0, offset)
		if (queriesInProgress === 0)
			showMoreActive = true
	}

	expand.visible = featuresToShow.length !== 0

	const dateLabel = t('screen.widget.LatestUpdates.reportedOn')
	return (
		<div key={layersLoaded} className="esri-layer-list esri-widget esri-widget--panel" title={title}>
			<div className="esri-widget__heading" style={{display: "flex", justifyContent: "center"}}>
				<span>{title}</span>
			</div>

			{queriesInProgress > 0 ?
				<div key={layersLoaded} className="esri-layer-list esri-widget esri-widget--panel" title={title}>
					<CalciteLoader scale="s" color={config.opsColor} />
				</div>
			:
				<ul className="esri-layer-list__list esri-layer-list__list--root esri-layer-list__list--independent">
					{featuresToShow.map((feature, idx) => {
						const layer = feature.layer
						const commentFieldName = feature.layer.layerConfig?.updateCommentsFieldName || '';
						
						return (
							<li key={layer.id + idx} className="esri-layer-list__item" onClick={() => {clickEVentOnUpdate(feature)}}>
								<FeatureCard feature={feature} config={config} t={t} commentFieldName={commentFieldName} dateLabel={dateLabel}/>
							</li>)
					})}
				</ul>
			}

			{showMoreActive && <ShowMore onClick={() => onShowMoreClicked()}>{t('screen.widget.ReportManager.showMore')}</ShowMore>}
		</div>
	)
}

export default LatestUpdates;