import styled from "styled-components";

export const StyledProgressBarContainer = styled.div`
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #a8b1b7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const StyledProgressBar = styled.div`
  width: ${({progress}) => `${progress}%`};
  background-color: ${({bgcolor})=>bgcolor};
  transition: all 0.6s;
  height: inherit;
`