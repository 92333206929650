const hex2rgb = (hex) => {
	return hex.match(/\w\w/g).map((x) => parseInt(x, 16));
}

const markerGreen = hex2rgb("#26A59A")

export const getPolygonSymbol = () => {
	return {
		type: "simple-fill",
		color: [...markerGreen, 0.2],
		style: "solid",
		outline: {
			color: "grey",
			width: 1,
		}
	}
}

export const getLineSymbol = (color, transparency) => {
	return {
		type: "simple-line",
		color: [...hex2rgb(color), transparency],
		style: "solid",
		width: 3,
		join: "round",
	}
}

export const getMarkerSymbol = () => {
	return {
		type: "simple-marker",
		style: "path",
		color: [...markerGreen, 0.05],
		outline: {
			color: [...markerGreen, 0.8],
			width: 1
		}
	}
}