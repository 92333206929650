import * as React from "react";

export const GetPaginationIcons = (position, color) => {
    color = color || '#5A6872'
    switch(position) {
        case 'left':     return <LeftArrowIcon color={color}/>
        case 'right':   return <RightArrowIcon color={color}/>
        default:         return <></>
    }
}

export const RightArrowIcon = (props) => (
    <svg
        width={4}
        height={14}
        viewBox={"0 0 8 14"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7 13 1 7l6-6"
            stroke={props.color ? props.color : "#333945"}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const LeftArrowIcon = (props) => (
    <svg
        width={4}
        height={14}
        viewBox={"0 0 8 14"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m1 13 6-6-6-6"
            stroke={props.color ? props.color : "#333945"}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)