import React, {useState} from "react";

import {DocumentsList} from "../CustomPopupWrapper-styled";
import {DocumentRow} from "./index";
import AttachmentRemovalDialog from "./AttachmentRemovalDialog";
import AttachmentAddDialog from "./AttachmentAddDialog";

/**
 * Documents tab on the popup. Editors can add and remove attachments
 */
const DocumentsTab = ({layer, isEditable, attachments, feature, refreshAttachments}) => {
    const [attachmentIdToRemove, setAttachmentIdToRemove] = useState(null)
    const oid = feature.attributes[layer.objectIdField]

    return (<div>
        {isEditable && <AttachmentAddDialog layer={layer} feature={feature} refreshAttachments={refreshAttachments}/>}

        <div className="esri-feature__attachments esri-feature__content-element">
            <div className="esri-attachments esri-widget">
                <div className="esri-attachments__container esri-attachments__container--preview">
                    <DocumentsList className="esri-attachments__items">
                        {attachments?.map((attachment) => {
                            return (<DocumentRow attachment={attachment} key={oid + "_a" + attachment.id} editable={isEditable} setAttachmentIdToRemove={setAttachmentIdToRemove}/> )
                        })}
                    </DocumentsList>

                    {isEditable && <AttachmentRemovalDialog layer={layer} feature={feature} refreshAttachments={refreshAttachments}
                                                            setAttachmentIdToRemove={setAttachmentIdToRemove}
                                                            attachmentIdToRemove={attachmentIdToRemove}/>}
                </div>
            </div>
        </div>
    </div>)
}

export default DocumentsTab