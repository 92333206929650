export const SingleMarker = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='32'
		height='30'
		viewBox='0 0 32 30'
		fill='none'
	>
		<ellipse
			cx='16.1535'
			cy='27.5376'
			rx='16.1535'
			ry='3.46146'
			fill='#031C2D'
		/>
		<path
			d='M15.5765 1C17.8716 1 20.0727 1.91172 21.6956 3.5346C23.3184 5.15747 24.2302 7.35857 24.2302 9.65366C24.2302 13.7463 17.8842 23.9391 16.0623 26.7879C16.0101 26.8694 15.9382 26.9364 15.8533 26.9828C15.7685 27.0293 15.6733 27.0536 15.5765 27.0536C15.4798 27.0536 15.3846 27.0293 15.2997 26.9828C15.2148 26.9364 15.1429 26.8694 15.0908 26.7879C13.2689 23.9403 6.92285 13.7463 6.92285 9.65366C6.92285 7.35857 7.83457 5.15747 9.45745 3.5346C11.0803 1.91172 13.2814 1 15.5765 1V1Z'
			fill='#BF3C2B'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15.5767 13.1143C17.4884 13.1143 19.0382 11.5646 19.0382 9.65287C19.0382 7.74116 17.4884 6.19141 15.5767 6.19141C13.665 6.19141 12.1152 7.74116 12.1152 9.65287C12.1152 11.5646 13.665 13.1143 15.5767 13.1143Z'
			fill='#F0F3F6'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const DoubleMarkers = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='44'
		height='46'
		viewBox='0 0 44 46'
		fill='none'
	>
		<path
			d='M25.6197 22L24.8499 19.6215L25.6197 22ZM10.8184 15.4934C14.725 15.7779 21.7294 16.8384 25.8794 18.3227C26.9106 18.6915 27.6192 19.0366 28.0309 19.3184C28.5573 19.6787 28.0981 19.573 28.051 18.8758C27.9943 18.0389 28.5966 17.8518 28.0304 18.2465C27.5415 18.5874 26.5672 19.0657 24.8499 19.6215L26.3895 24.3785C28.2772 23.7676 29.8 23.1079 30.8898 22.3482C31.9024 21.6423 33.165 20.3921 33.0395 18.5382C32.9236 16.8241 31.6664 15.7477 30.8551 15.1924C29.9291 14.5585 28.7602 14.0429 27.5633 13.6148C22.8013 11.9116 15.286 10.8055 11.1816 10.5066L10.8184 15.4934ZM24.8499 19.6215C20.3988 21.062 15.3428 22.6255 11.1353 24.2642C9.02659 25.0854 7.05057 25.9556 5.41845 26.8785C3.85266 27.7639 2.31134 28.8558 1.38808 30.2327C0.898389 30.963 0.522654 31.8627 0.500977 32.8989C0.478914 33.9536 0.828852 34.9107 1.39111 35.7251C2.43193 37.2326 4.29319 38.3926 6.55737 39.3839C11.1973 41.4153 19.1889 43.4224 31.5973 45.4667L32.4101 40.5333C20.0345 38.4943 12.5831 36.5638 8.56269 34.8036C6.49668 33.8991 5.72345 33.1997 5.50568 32.8843C5.43864 32.7872 5.50326 32.842 5.49988 33.0035C5.49689 33.1464 5.44385 33.162 5.54088 33.0173C5.79107 32.6442 6.47933 32.0226 7.87949 31.2309C9.21331 30.4767 10.9396 29.7062 12.9498 28.9233C16.9801 27.3537 21.8282 25.8547 26.3895 24.3785L24.8499 19.6215Z'
			fill='#B21B0C'
		/>
		<ellipse cx='33' cy='43.5763' rx='11' ry='2.423' fill='#8C9BA5' />
		<path
			d='M32.6067 25C34.1696 25 35.6685 25.6382 36.7736 26.7742C37.8787 27.9102 38.4996 29.4509 38.4996 31.0575C38.4996 33.9223 34.1782 41.0572 32.9375 43.0513C32.902 43.1083 32.853 43.1553 32.7952 43.1878C32.7374 43.2203 32.6726 43.2373 32.6067 43.2373C32.5408 43.2373 32.476 43.2203 32.4182 43.1878C32.3604 43.1553 32.3115 43.1083 32.2759 43.0513C31.0353 41.058 26.7139 33.9223 26.7139 31.0575C26.7139 29.4509 27.3347 27.9102 28.4398 26.7742C29.545 25.6382 31.0438 25 32.6067 25Z'
			fill='#FFDBDD'
			stroke='#8C9BA5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M32.6067 33.4788C33.9085 33.4788 34.9638 32.394 34.9638 31.0558C34.9638 29.7176 33.9085 28.6328 32.6067 28.6328C31.3048 28.6328 30.2495 29.7176 30.2495 31.0558C30.2495 32.394 31.3048 33.4788 32.6067 33.4788Z'
			fill='white'
			stroke='#8C9BA5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<ellipse cx='10.5' cy='13.3839' rx='7.5' ry='1.6153' fill='#8C9BA5' />
		<path
			d='M10.2317 1C11.2973 1 12.3193 1.42546 13.0728 2.18278C13.8263 2.94009 14.2496 3.96724 14.2496 5.03825C14.2496 6.94807 11.3032 11.7046 10.4573 13.034C10.433 13.072 10.3997 13.1033 10.3603 13.1249C10.3208 13.1466 10.2766 13.158 10.2317 13.158C10.1868 13.158 10.1426 13.1466 10.1032 13.1249C10.0638 13.1033 10.0304 13.072 10.0062 13.034C9.1603 11.7051 6.21387 6.94807 6.21387 5.03825C6.21387 3.96724 6.63718 2.94009 7.39067 2.18278C8.14416 1.42546 9.16612 1 10.2317 1Z'
			fill='#FFDBDD'
			stroke='#8C9BA5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.2317 6.65247C11.1193 6.65247 11.8388 5.92928 11.8388 5.03717C11.8388 4.14507 11.1193 3.42188 10.2317 3.42188C9.34405 3.42188 8.62451 4.14507 8.62451 5.03717C8.62451 5.92928 9.34405 6.65247 10.2317 6.65247Z'
			fill='white'
			stroke='#8C9BA5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const TripleMarkers = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='45'
		height='44'
		viewBox='0 0 45 44'
		fill='none'
	>
		<ellipse
			cx='24.2304'
			cy='17.8075'
			rx='10.2304'
			ry='2.19224'
			fill='#031C2D'
		/>
		<path
			d='M23.8649 1C25.3184 1 26.7124 1.57742 27.7402 2.60523C28.768 3.63304 29.3455 5.02705 29.3455 6.48059C29.3455 9.07254 25.3264 15.5279 24.1725 17.3322C24.1395 17.3837 24.094 17.4262 24.0402 17.4556C23.9864 17.485 23.9261 17.5004 23.8649 17.5004C23.8036 17.5004 23.7433 17.485 23.6895 17.4556C23.6358 17.4262 23.5903 17.3837 23.5572 17.3322C22.4034 15.5287 18.3843 9.07254 18.3843 6.48059C18.3843 5.02705 18.9617 3.63304 19.9895 2.60523C21.0173 1.57742 22.4113 1 23.8649 1V1Z'
			fill='#E66156'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M23.8651 8.67353C25.0758 8.67353 26.0573 7.69204 26.0573 6.4813C26.0573 5.27056 25.0758 4.28906 23.8651 4.28906C22.6543 4.28906 21.6729 5.27056 21.6729 6.4813C21.6729 7.69204 22.6543 8.67353 23.8651 8.67353Z'
			fill='#F0F3F6'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<ellipse
			cx='11.8461'
			cy='30.4613'
			rx='11.8461'
			ry='2.53845'
			fill='#031C2D'
		/>
		<path
			d='M11.4228 11C13.1059 11 14.72 11.6686 15.9102 12.8587C17.1003 14.0489 17.7689 15.663 17.7689 17.3461C17.7689 20.3474 13.1151 27.8223 11.779 29.9114C11.7407 29.9712 11.688 30.0203 11.6258 30.0544C11.5635 30.0884 11.4937 30.1063 11.4228 30.1063C11.3518 30.1063 11.282 30.0884 11.2198 30.0544C11.1575 30.0203 11.1048 29.9712 11.0665 29.9114C9.73048 27.8231 5.07666 20.3474 5.07666 17.3461C5.07666 15.663 5.74527 14.0489 6.93539 12.8587C8.12552 11.6686 9.73968 11 11.4228 11V11Z'
			fill='#E66156'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.4227 19.8835C12.8247 19.8835 13.9612 18.747 13.9612 17.3451C13.9612 15.9431 12.8247 14.8066 11.4227 14.8066C10.0208 14.8066 8.88428 15.9431 8.88428 17.3451C8.88428 18.747 10.0208 19.8835 11.4227 19.8835Z'
			fill='#F0F3F6'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<ellipse
			cx='30.5381'
			cy='40.8839'
			rx='14.5381'
			ry='3.11531'
			fill='#031C2D'
		/>
		<path
			d='M30.0187 17C32.0843 17 34.0653 17.8205 35.5259 19.2811C36.9865 20.7417 37.807 22.7227 37.807 24.7883C37.807 28.4716 32.0956 37.6451 30.4559 40.209C30.4089 40.2824 30.3443 40.3427 30.2679 40.3845C30.1915 40.4263 30.1058 40.4482 30.0187 40.4482C29.9317 40.4482 29.846 40.4263 29.7696 40.3845C29.6932 40.3427 29.6285 40.2824 29.5816 40.209C27.9419 37.6462 22.2305 28.4716 22.2305 24.7883C22.2305 22.7227 23.051 20.7417 24.5116 19.2811C25.9722 17.8205 27.9532 17 30.0187 17V17Z'
			fill='#E66156'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M30.0186 27.9015C31.7392 27.9015 33.1339 26.5067 33.1339 24.7862C33.1339 23.0657 31.7392 21.6709 30.0186 21.6709C28.2981 21.6709 26.9033 23.0657 26.9033 24.7862C26.9033 26.5067 28.2981 27.9015 30.0186 27.9015Z'
			fill='#F0F3F6'
			stroke='#031C2D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const CloseIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
	<path d="M5.25 5.25L18.75 18.75" stroke="#5A6872" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M5.25 18.75L18.75 5.25" stroke="#5A6872" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
);

export const PrevIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
	<path d="M9.2665 3.36661L3.25817 9.37494H19.1665V10.6249H3.25817L9.2665 16.6249L8.38317 17.5166L0.866505 9.99994L8.38317 2.48328L9.2665 3.36661Z" fill="#031C2D"/>
	</svg>
);

export const NextIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
	<path d="M10.7335 3.36661L16.7418 9.37494H0.833496V10.6249H16.7418L10.7335 16.6249L11.6168 17.5166L19.1335 9.99994L11.6168 2.48328L10.7335 3.36661Z" fill="#031C2D"/>
	</svg>
);

export const DragAndDrop = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
	>
		<ellipse cx='24' cy='38.5' rx='24' ry='9.5' fill='#F1F9FF' />
		<path
			d='M33.6307 9H14.3693C13.5359 9 12.7401 9.34665 12.1725 9.95689L5.25 17.4V24H42.75V17.4L35.8275 9.95689C35.2599 9.34665 34.4641 9 33.6307 9Z'
			fill='#c0392b'
			stroke='#ffffff'
		/>
		<path
			d='M42 17.75C43.1046 17.75 44 18.6454 44 19.75V38.25C44 39.3546 43.1046 40.25 42 40.25H6C4.89543 40.25 4 39.3546 4 38.25V19.75C4 18.6454 4.89543 17.75 6 17.75H15.3333C16.4379 17.75 17.3022 18.6682 17.6509 19.7162C18.5124 22.3049 21.0293 24.1786 24 24.1786C26.9707 24.1786 29.4876 22.3049 30.3491 19.7162C30.6978 18.6682 31.5621 17.75 32.6667 17.75H42Z'
			fill='#c0392b'
			stroke='#ffffff'
		/>
	</svg>
);

export const CircleClose = ({color}) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
	>
		<path
			d='M16.0003 2.66699C13.3632 2.66699 10.7854 3.44898 8.59273 4.91406C6.40007 6.37915 4.69111 8.46153 3.68194 10.8979C2.67277 13.3342 2.40872 16.0151 2.92319 18.6015C3.43766 21.1879 4.70754 23.5637 6.57224 25.4284C8.43694 27.2931 10.8127 28.563 13.3991 29.0775C15.9855 29.5919 18.6664 29.3279 21.1028 28.3187C23.5391 27.3096 25.6215 25.6006 27.0866 23.4079C28.5517 21.2153 29.3337 18.6374 29.3337 16.0003C29.3337 14.2494 28.9888 12.5156 28.3187 10.8979C27.6487 9.2802 26.6665 7.81035 25.4284 6.57224C24.1903 5.33412 22.7205 4.35199 21.1028 3.68193C19.4851 3.01187 17.7513 2.66699 16.0003 2.66699ZM19.907 18.587L18.5737 19.9203L16.0003 17.3337L13.4137 19.9203L12.0803 18.587L14.667 16.0003L12.0937 13.4137L13.427 12.0803L16.0003 14.667L18.587 12.0803L19.9203 13.4137L17.3337 16.0003L19.907 18.587Z'
			fill={color ?? "#b21b0c"}
		/>
	</svg>
);

export const SuccessIcon = () => (
	<svg
		width='280'
		height='200'
		viewBox='0 0 280 200'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M52.2575 87.5389C52.2575 87.5389 91.1522 86.1723 99.5573 30.0776C107.021 -19.74 172.608 2.93514 191.567 22.7936C216.126 48.5186 203.034 92.7709 234.804 98.3613C266.575 103.952 245.828 166.106 197.603 160.157C137.612 152.757 152.126 183.514 134.117 196.532C121.192 205.875 68.1966 196.881 66.4303 162.964C64.9439 134.424 51.2226 134.515 41.4968 131.279C27.4699 126.611 18.6315 92.8092 52.2575 87.5389Z'
			fill='white'
		/>
		<path
			d='M131 55C121.111 55 111.444 57.9324 103.222 63.4265C94.9991 68.9206 88.5904 76.7295 84.806 85.8658C81.0217 95.0021 80.0315 105.055 81.9608 114.755C83.89 124.454 88.6521 133.363 95.6447 140.355C102.637 147.348 111.546 152.11 121.246 154.039C130.945 155.969 140.998 154.978 150.134 151.194C159.271 147.41 167.079 141.001 172.573 132.779C178.068 124.556 181 114.889 181 105C181 98.4339 179.707 91.9321 177.194 85.8658C174.681 79.7995 170.998 74.2876 166.355 69.6447C161.712 65.0017 156.2 61.3188 150.134 58.806C144.068 56.2933 137.566 55 131 55ZM128 118.55L111.95 102.5L116.95 97.5L128 109L146.25 90.75L151 95.75L128 118.55Z'
			fill='#E8847E'
		/>
		<rect x='116' y='69' width='59' height='55' fill='white' />
		<path
			d='M141 45C131.111 45 121.444 47.9324 113.222 53.4265C104.999 58.9206 98.5904 66.7295 94.806 75.8658C91.0217 85.0021 90.0315 95.0555 91.9608 104.755C93.89 114.454 98.6521 123.363 105.645 130.355C112.637 137.348 121.546 142.11 131.246 144.039C140.945 145.969 150.998 144.978 160.134 141.194C169.271 137.41 177.079 131.001 182.573 122.779C188.068 114.556 191 104.889 191 95C191 88.4339 189.707 81.9321 187.194 75.8658C184.681 69.7995 180.998 64.2876 176.355 59.6447C171.712 55.0017 166.2 51.3188 160.134 48.806C154.068 46.2933 147.566 45 141 45ZM138 108.55L121.95 92.5L126.95 87.5L138 99L156.25 80.75L161 85.75L138 108.55Z'
			fill='#E66156'
		/>
	</svg>
);
