import styled from "styled-components";

export const Container = styled.button`
    font-family: 'Open Sans', sans-serif !important;
	background-color: #b21b0c;
	border-radius: .2em;
	min-height: 2em;
	text-decoration: none;
	color: #fff;
	border-width: 0px;
	font-size: .675em;
	max-width: 100%;
	opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
	cursor: pointer;
    margin: 0 .5em;
    padding: .5em 1em;
    border: 1px solid #DFE6EB;
`