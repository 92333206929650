import {StyledDropdownMenuItem} from "./Dropdown-styled";

const DropdownMenuItem = ({children, ...props}) => {
  return (
    <StyledDropdownMenuItem {...props}>
      {children}
    </StyledDropdownMenuItem>
  );
};

export default DropdownMenuItem;