import React from "react";
import {CheckMark, PageOrientationWrapper, RadioWrapper} from "./PageOrientation-styled";

const PageOrientation = ({opsColor, onInputChange ,selected, t}) => {
  
  return (
    <PageOrientationWrapper opsColor={opsColor}>
      <RadioWrapper>
        <label>
          <span className="label">
            {t("print.documentSettings.questions.2.options.portrait")}
          </span>
          <input onChange={onInputChange} value="Portrait" type="radio" name="radio" checked={selected === 'Portrait'} />
          <CheckMark orientation="portrait" />
        </label>
      </RadioWrapper>
      <RadioWrapper>
        <label>
          <span className="label">
            {t("print.documentSettings.questions.2.options.landscape")}
          </span>
          <input onChange={onInputChange} value="Landscape" type="radio" name="radio" checked={selected ==='Landscape'} />
          <CheckMark orientation="landscape" />
        </label>
      </RadioWrapper>
    </PageOrientationWrapper>
  );
};

export default PageOrientation;