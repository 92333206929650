export const chartOptions = {
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
            fullSize: true,
            align: 'center',
            textDirection: 'center',
        }
    }
}

export const colorScheme = ['rgba(142, 37, 255, 1)', 'rgba(57, 184, 255, 1)', 'rgba(106, 210, 255, 1)',
	'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)']

export const borderColorScheme = ['rgba(142, 37, 255, 0.5',	'rgba(57, 184, 255, 0.5)', 'rgba(106, 210, 255, 0.5)',
	'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)']

export const hoverColorScheme = ['rgba(142, 37, 255, 0.8', 'rgba(57, 184, 255, 0.8)', 'rgba(106, 210, 255, 0.8)',
	'rgba(75, 192, 192, 0.8)', 'rgba(153, 102, 255, 0.8)', 'rgba(255, 159, 64, 0.8)']