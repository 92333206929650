import styled from "styled-components";
import {mutedColor} from "../../utils/Theme";

export const TextAreaWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:left;
  position: relative;
  margin-bottom: 20px;
  width: inherit;
  
  & span {
    font-family: 'Open Sans', sans-serif !important;
    width: auto;
    padding: 0 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5em;
    color: ${mutedColor};
    text-align: left;
    border-radius: 4px;
    pointer-events: none;
    transition: all 0.5s;
    position: absolute;
    top: 10px;
    left: 8px;
    background: #FFFFFF;
  }

  & textarea::placeholder {
    opacity: 0;
    transition: all 0.7s;
  }

  & textarea:focus {
    border: ${({borderColor})=>`1px solid ${borderColor ?? '#C0392B'}`};;
    ::placeholder {
      opacity: 1;
    }
  }

  textarea:not(:placeholder-shown) + span {
    top: -9px;
    font-size: 10px;
  }

  & textarea:focus + span {
    top: -9px;
    font-size: 10px;
  }
`

export const TextAreaStyled = styled.textarea`
  width: auto;
  height: 160px;
  resize: none;
  border-radius: 10px;
  padding: 8px 14px;
  color: #25282b;
  font-size: 14px;
  outline: none;
  background: #ffffff;
  border: ${({borderColor})=>`1px solid ${borderColor ?? '#C0392B'}4D`};
  transition: all 0.7s;

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #535353;
  }
`