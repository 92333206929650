import {setPanelPath_actionType, resetPanel} from "../constants"

const initialState = null;
const panelPath = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setPanelPath_actionType:
            state = payload
            break
        case resetPanel:
            state = initialState
    }
    return state
}

export default panelPath