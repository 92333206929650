import {useState, useEffect, useContext} from "react";
import {initializeMap, addWidgets, view} from "../utils/API";
import {useTranslation} from "react-i18next";
import {ConfigContext} from "../utils/ConfigContext";
import { useSnackbar } from '../components/SnackBar';
import {getDisclaimerText} from "../utils/helper";
import {setLayersLoading} from "../redux/action/LayersLoading-action";
import {useDispatch} from "react-redux";

const useEsriMap = ({	elementRef,
						setSideBarIFrame,
						activeModule,
						activeLanguage,
						setActiveLanguage,
						setFeatureTableActive,
						setSideBarMobile,
					}) => {
	const [isLoading, setLoading] = useState(true)
	const [errors, setError] = useState([])
	const {t, i18n} = useTranslation('common')
	const {config, setConfig} = useContext(ConfigContext)
	const [openSnackbar] = useSnackbar()
	const dispatch = useDispatch();
	
	useEffect(()=>{
		setLoading(true)
	},[config])

	useEffect(() => {
		if (!isLoading)
			return
		dispatch(setLayersLoading(true))
		initializeMap({
			container: elementRef.current,
			config,
			setLoading,
			setError,
			setSideBarIFrame,
			activeModule,
			setActiveLanguage,
			setFeatureTableActive,
			t,
			i18n,
			openSnackbar,
			setSideBarMobile,
			setConfig,
		}).then(() => {
		});
		setLoading(false);
	}, [elementRef, setSideBarIFrame, activeModule, isLoading, t, setActiveLanguage, i18n, setFeatureTableActive, openSnackbar]);

	useEffect(() => {
		if (isLoading)
			return

		addWidgets(config, activeModule, setActiveLanguage, setSideBarIFrame,
			setFeatureTableActive, i18n, t, openSnackbar)

	}, [activeLanguage]);

	useEffect(() => {
		const disclaimerNode = document.getElementById("disclaimerText")
		if (disclaimerNode)
			disclaimerNode.innerHTML = getDisclaimerText(config, activeModule)
	}, [config, activeModule])

	return {isLoading, errors, setError};
};

export default useEsriMap;
