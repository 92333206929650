import {setAdditionalComment_actionType, removeData} from "../constants";

const initialState = "";
const additionalComment = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setAdditionalComment_actionType:
			return payload;
		case removeData: 
			return state = initialState;
		default:
			return state;
	}
};
export default additionalComment;