import React from "react";
import {Container, SubTitleText, TitleText} from "./Heading-styled";
import {useTranslation} from "react-i18next";

const Heading = ({path}) => {
    const {t} = useTranslation('common')
    const title = t(`screen.panel.${path}`, "")
    const getSubtitlePath = () => {
        const pathArr = path.split(".")
        const lastEntry = pathArr.pop()
        return `${pathArr.join(".")}.sub${lastEntry}`
    }
    const subtitle = t(`screen.panel.${getSubtitlePath()}`, "")
    return (
        <Container>
            {title && title.length > 0 && <TitleText>{title}</TitleText>}
            {subtitle && subtitle.length > 0 && <SubTitleText>{subtitle}</SubTitleText>}
        </Container>
    )
}
export default Heading