import React, {useCallback, useEffect, useState} from "react";
import Input from "../Input/Input";
import {CategoriesCard} from "../Reports-styled";
import {getField} from "../helpers";
import {StyledSectionTitle} from "../ScrollSection/ScrollSection-styled";

const inputs = [
  {
    key:"first",
    name:"ccstoragecapminus15m2"
  },
  {
    key:"second",
    name:"ccstoragecap2to8m2"
  },
  {
    key:"third",
    name:"ccstoragecap8to15m2"
  },
  {
    key:"fourth",
    name:"ccstoragecap15to25m2"
  },
]

const StorageSpace = ({form, setForm, layer, goNext, config, t}) => {
  const [storageClimateFields, setStorageClimateFields] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [storageClimate, setStorageClimate] = useState(null);
  
  useEffect(()=>{
    if (!layer || storageClimateFields.length > 0) return;
    const climateControlValues = getField(layer,"storageclimatecontrol");
    setStorageClimateFields(climateControlValues)
  },[layer])
  
  const onInputChange = useCallback((e)=>{
    const {name, value} = e.target;
    setForm(form=> ({...form, [name]: value === '' ? undefined : value}))
  },[setForm])
  
  const onSelectClimate = useCallback((value)=>{
    if (value === '1') {
      setStorageClimate(value)
      setForm(form=>({...form, storageclimatecontrol: undefined}))
    } else {
      setStorageClimate(value)
      setForm(form=>{
        const names = inputs.map(input=>input.name);
        const {ccstoragecapminus15m2, ccstoragecap2to8m2, ccstoragecap8to15m2, ccstoragecap15to25m2, ...restForm} = form;
        return {...restForm, storageclimatecontrol: storageClimate}
      })
      if (form.storagecapm2) {
        goNext()
      }
    }
  },[setForm, goNext, form])
  
  useEffect(()=>{
    const notFilledInputs = inputs.filter((input)=> inputFields[input.name] === undefined)
    if (notFilledInputs.length === 0) {
      setForm(form=>({...form, storageclimatecontrol: storageClimate, ...inputFields}))
    } else {
      setForm(form=>({...form, storageclimatecontrol: undefined}))
    }
  },[inputFields])
  
  const onClimateInputChange = useCallback((e)=>{
    const {name, value} = e.target;
    setInputFields(fields => ({...fields, [name]: value === "" ? undefined : value}))
  },[setForm, form, storageClimate])

  return (
    <>
      <div style={{
        width:'100%',
        marginTop:'10px'
      }}>
        <StyledSectionTitle>{t("report.warehouse.pages.storageSpace.totalStorage.title")}}*</StyledSectionTitle>
        <Input
          onChange={onInputChange}
          label={`${t("layer.fieldAlias.storagecapm2")}`}
          placeholder={t('screen.component.input.placeholder')}
          type="number"
          name="storagecapm2"
          borderColor={config.opsColor}
        />
      </div>
      <StyledSectionTitle>{t("report.warehouse.pages.storageSpace.climateControl.title")}*</StyledSectionTitle>
        {
          storageClimateFields.map(elem=> (
            <CategoriesCard
              key={elem.code}
              TypesOfVehicleCard
              selected={storageClimate === elem.code}
              onClick={() => onSelectClimate(elem.code)}
              bgColor={storageClimate === elem.code ? `${config.opsColor}1a` : ''}
              borderColor={config.opsColor}
            >
              <span>{t('report.warehouse.' + elem.name.toLowerCase())}</span>
            </CategoriesCard>
          ))
        }
      <div style={{width:'100%', marginTop: 10, opacity: storageClimate === '1' ? 1 : 0, pointerEvents: storageClimate === '1' ? 'auto' : 'none' ,transition: 'all 0.5s'}}>
        {
          inputs.map(input=>(
            <Input
              key={input.name}
              label={`${t("report.warehouse.pages.storageSpace.climateControl.storageClimate.inputs." + input.key)}*`}
              name={input.name}
              placeholder={t('screen.component.input.placeholder')}
              onChange={onClimateInputChange}
              borderColor={config.opsColor}
            />
          ))
        }
      </div>
    </>
  );
};

export default StorageSpace;