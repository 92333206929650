import React, {useRef, useState} from "react";
import {SketchPicker} from "react-color";
import {ColorButton, ColorButtonWrapper, ColorsRowWrapper, CustomizationTitle} from "../SidebarMenu/SidebarMenu-styled";
import useClickOutside from "../../../hooks/useClickOutside";

function rgbaToHex (r,g,b,a) {
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255).toString(16).substring(0, 2)
  ];
  
  // Pad single-digit output values
  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  })
  
  return ('#' + outParts.join(''));
}

const ColorsRow = ({title, colors, selectedColor, onClick}) => {
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef();
  
  useClickOutside(ref, ()=>{
    setShowPicker(false);
  })
  
  const handleChange = (params)=>{
    if (params.rgb) {
      const {r,g,b,a} = params.rgb;
      const hex = rgbaToHex(r,g,b,a)
      onClick(hex)
    }
  }

  return (
    <ColorsRowWrapper>
      <CustomizationTitle>{title}</CustomizationTitle>
      <div style={{display:'flex', gap:4, justifyContent:'space-between'}}>
        <ColorButtonWrapper
          ref={ref}
        >
          <ColorButton
            width={42}
            height={42}
            onClick={()=>setShowPicker(prev=>!prev)}
            color={selectedColor}
          >
            {
              selectedColor === 'transparent' ? <img src={process.env.PUBLIC_URL + '/assets/transparent.png'} alt="transparent" /> : null
            }
          </ColorButton>
          {showPicker && <div style={{
            position:'absolute',
            zIndex:2,
            top:50,
            left:-5
          }}>
            <SketchPicker onChange={handleChange} color={selectedColor} />
          </div>}
        </ColorButtonWrapper>
      
        <div style={{display:'flex', gap:4}}>
          {
            colors.map(color=>(
              <ColorButton key={color} selected={color === selectedColor} onClick={()=>onClick(color)} color={color}>
                {
                  color === 'transparent' ? <img src={process.env.PUBLIC_URL + '/assets/transparent.png'} alt="transparent" /> : null
                }
              </ColorButton>
            ))
          }
        </div>
      </div>
    </ColorsRowWrapper>
  );
};

export default ColorsRow;