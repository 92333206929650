import React, {useContext, useEffect, useRef, useState} from "react";
import FormInput from "./FormInput";
import {useFormContext} from "react-hook-form";
import CryptoJS from "crypto-js";
import {MessageContainer} from "../../styles/Message/Message-styled";
import {StyledLoader} from "../../../App/App-styled";
import {ConfigContext} from "../../../../utils/ConfigContext";

// TODO: move to the .env file
const SECRET_KEY = "LogIeSecretKey";

const ReportContacts = ({t}) => {
  const { register, getValues ,setValue, formState: { isValid, errors, isSubmitting }, trigger } = useFormContext();
  const [saveContacts, setSaveContacts] = useState(false)
  const mountedRef = useRef(true)
  const {config} = useContext(ConfigContext);
  
  useEffect(() => {
    const fetchSavedContacts = () => {
      try {
        const savedContacts = localStorage.getItem("contacts")
        if (savedContacts && savedContacts?.length) {
          const parsedSavedContacts = JSON.parse(
            CryptoJS.AES.decrypt(savedContacts, SECRET_KEY).toString(CryptoJS.enc.Utf8)
          )
          
          Object.entries(parsedSavedContacts).forEach(([key, value]) => {
            setValue(key, value)
            trigger(key)
          })
          setSaveContacts(true)
          return parsedSavedContacts
        }
      } catch (err) {
        console.log(err)
      }
    }
    
    fetchSavedContacts()
    
  }, [setValue])
  
  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [mountedRef])
  
  useEffect(()=>{
    if (isSubmitting) {
      if (saveContacts) {
        
        const encryptedString = CryptoJS.AES.encrypt(
          JSON.stringify({
            ...getValues(),
            report: undefined,
            sendEmail: undefined,
          }),
          SECRET_KEY
        ).toString();
        localStorage.setItem("contacts", encryptedString)
      }
      if (!mountedRef.current) return null
    }
  },[isSubmitting])
  
  if (isSubmitting) {
    return (
      <MessageContainer>
        <StyledLoader style={{margin:0}} text="Loading..."/>
      </MessageContainer>
    )
  }
  
  return (
    <FormInput
      register={register}
      errors={errors}
      setSaveContacts={setSaveContacts}
      saveContacts={saveContacts}
      isValid={isValid}
      currentScreen={'contacts'}
      config={config}
      t={t}
    />
  );
};

export default ReportContacts;