import styled from "styled-components";


export const StyledPrintButton = styled.button`
  font-size: 14px;
  background-color: #fff;
  color: #6e6e6e;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: background-color 125ms ease-in-out;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px !important;
  outline: none !important;
  border:none;

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    width: 32px !important;
    height: 32px !important;
    span {
      font-size: 14px;
    }
  }
`