import React from "react";
import {useTranslation} from "react-i18next";

const List = ({ path }) => {
    const {t} = useTranslation('common')
    const getList = () => {
        const content = t(`screen.panel.${path}`, "")
        const contentArr = content.split(",")
        const listArr = []
        contentArr.forEach((item, index) => {
            listArr.push(<li key={`list_${index}`}>{item}</li>)
        })
        return listArr
    }
    return (
        <ul>
            {getList()}
        </ul>
    )
}
export default List