import React from "react";
import Comment from "../Comment/Comment";
import UploadFile from "../UploadFile/UploadFile";

const translationPath = 'report.pac.pages.describe.'

const Describe = ({form, setForm, config, t}) => {
  return (
    <>
      <div style={{width:'100%'}}>
        <Comment
          value={form.reportedremarks}
          name="reportedremarks"
          onChange={(e)=>{
            const {name, value} = e.target;
            setForm({...form, [name]: value === '' ? undefined : value })
          }}
          borderColor={config.opsColor}
          title={t(translationPath + "comment.title")}
          placeholder={t("screen.component.input.placeholder")}
        />
      </div>
      <UploadFile
        title={t(translationPath + "imageUpload.title")}
        description={t(translationPath + "imageUpload.description")}
        buttonTitle={t(translationPath + "imageUpload.button")}
        fileDescription={t(translationPath + "imageUpload.fileDescription")}
        config={config}
        form={form}
        setForm={(name, value)=>setForm({...form, [name]:value})}
        name="attachment"
      />
    </>
  );
};

export default Describe;