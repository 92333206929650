import styled from 'styled-components';

const FilterFieldComboContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #6a6a6a;
    padding: 0.5em 0;
    flex-grow: 1;

    & label {
        font-size: 12px !important;
        font-weight: 500;
        padding-bottom: 0.2em;
    }

    & div {
        text-align: left !important;
    }

    & div[role='combobox'],
    & input[type='text'] {
        width: 100%;
        border-radius: 4px;
        height: 38px;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        box-shadow: none;
    }
`;

const StyledContainer = styled.div`
    min-height: ${(props) => (props.featureTableStyling ? 'none' : '300px')};
    
`;
const StyledLi = styled.li`
    ${(props) => (props.featureTableStyling ? 'flex-grow: 1' : '')};
    width: ${(props) => (props.featureTableStyling ? '30.33%' : '100%')};
    
    ${({ featureTableStyling }) => featureTableStyling && `
    textarea {
      height: 38px;
      resize: none;
      border-radius: 4px;
      border-color: #ccc;
      padding: 8px;
    }
    
    textarea:focus-visible {
      outline-color: #2684ff;
    }
    
    & div[role='combobox'],
    & input[type='text'] {
        height: auto;
    }
  `}
    
    @media screen and (max-width: 850px) {
        flex-direction: column;
        width: 100%;
    }
`;
const StyledListContainer = styled.div``;
const StyledTitleDiv = styled.div``;
const StyledUl = styled.ul`
    display: ${(props) => (props.featureTableStyling ? 'flex' : 'block')};
    flex-wrap: ${(props) => (props.featureTableStyling ? 'wrap' : 'no-wrap')};
    gap:${(props) => (props.featureTableStyling ? '5px' : '0')};
    list-style-type: none;
    padding-inline-start: 0;
    @media screen and (max-width: 850px) {
        flex-direction: column;
    }
`;

export { StyledContainer, StyledLi, StyledUl, StyledListContainer, StyledTitleDiv, FilterFieldComboContainer };
