import styled from "styled-components";
import DatePicker from "react-datepicker";

export const InputLabel = styled.label`
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	color: #000000;
	cursor: pointer;
	text-align: left;
	margin-bottom: 32px;
	padding: 0;
	&:before {
		content: url("${process.env.PUBLIC_URL + "/assets/calendar.svg"}");
		display: block;
		position: relative;
		top: 25px;
		left: calc(100% - 30px);
		height: 16px;
		width: 16px;
		z-index: 5;
	}
`;

export const DatePickerWrapper = styled(DatePicker)`
	width: 100%;
	height: 40px;
	display: block;
	border-radius: 2px;
	padding: 8px 14px;
	cursor: pointer;
	color: #8C9BA5;
	border: 1px solid #C5CDD2;
	&:active, 
	&:focus {
		color: #000000
	}
`;

export const Calendar = styled.div`
	border-radius: 10px;
	box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
	overflow: hidden;
`;

export const Popper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
`;
