import React from "react";
import {LegendContent} from "../index"
import {LegendWrapper, LegendColor, LegendItem} from "../../styles/LegendBox/LegendBox-styled";

const LegendBox = ({labels, colors}) => {
  
    return (
        <LegendWrapper>
            <LegendContent>
                {
                labels?.map((label, index) => {
                        return (
                            <LegendItem key={index}>
                                <LegendColor color={colors?.[index]}/>
                                <span>{label}</span>
                            </LegendItem>   
                        )
                    })
                }
            </LegendContent>
        </LegendWrapper>
    )
}

export default LegendBox;
