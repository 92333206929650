import styled from "styled-components";

export const AnalyticsPanel = styled.div`
    background-color: #fff !important;
    border-radius: 8px;
    padding: 20px 5px 32px 10px !important;
    height: 550px !important;
    min-width: max-content;
    position: absolute;
        .esri-widget--panel {
            max-height: 550px !important;
        }
        @media (max-width: 769px) {
            max-height: 360px !important;
        }
        @media (min-width: 1400px) {
            max-height: 900px !important;
        }
`;

export const AnalyticsTitle = styled.h2`
    margin: 0;
    padding: 0 0 20px 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #031C2D;
`
export const TitleOfChart = styled.h4`
    padding: 0 !important;
    margin: 0 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #5A6872;
`;

export const ListOfChart = styled.ul`
    margin: 0 !important;
    position: static;
    max-height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
`;

export const ItemOfChartList = styled.li`
    box-sizing: border-box;
    position: static;
    padding: 18px;
    width: 293px;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: #fff;
    border-radius: 8px;
    gap: 22px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.1);
        & > canvas {
            max-width: 147px;
            max-height: 147px;
            margin: 0 auto;
        }
`;
 
export const ListContainer = styled.div`
    width: max-content;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    padding: 0 7px 2px 0;
    margin: 0 !important;
`;