import * as React from "react";

export const GetPositionIcon = (position, color) => {
    color = color || '#5A6872'
    switch(position) {
        case 'left':     return <LeftIcon color={color}/>
        case 'bottom':   return <BottomIcon color={color}/>
        case 'modal':    return <ModalIcon color={color}/>
        default:         return <RightIcon color={color}/>
    }
}

export const RightIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox={"0 0 24 24"}
        fill="none"
        {...props}
    >
        <g fill={props.color ? props.color : '#5A6872'} clipPath="url(#a)">
            <path
                d="M23 19V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2Zm-2 .02H3V4.97h18v14.05Z"/>
            <path d="M14 6h6v12h-6z"/>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>
)

export const LeftIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox={"0 0 24 24"}
        fill="none"
        {...props}
    >
        <g fill={props.color ?props.color : '#5A6872'} clipPath="url(#a)">
            <path d="M23 19V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2Zm-2 .02H3V4.97h18v14.05Z" />
            <path d="M4 6h6v12H4z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export const BottomIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox={"0 0 24 24"}
        fill="none"
        {...props}
    >
        <g fill={props.color ?props.color : '#5A6872'} clipPath="url(#a)">
            <path d="M23 19V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2Zm-2 .02H3V4.97h18v14.05Z" />
            <path d="M4 12h16v6H4z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export const ModalIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox={"0 0 24 24"}
        fill="none"
        {...props}
    >
        <path fill="#fff" d="M0 0h25v24H0z" />
        <path fill={props.color ?props.color : '#5A6872'} d="M18 20v-2H3v2z" />
        <path fill={props.color ?props.color : '#5A6872'} d="M3 8h2v12H3z" />
        <path fill={props.color ?props.color : '#5A6872'} strokeWidth={2} d="M8 5h13v10H8z" />
    </svg>
)