import styled from "styled-components";

export const ReportHeaderStyled = styled.div`
	width: 100%;
	height: 56px;
	margin: 0;
	border-radius: 8px 8px 0 0;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 21px 0;
	position: absolute;
	top: -51px;
		&::after {
			position: absolute;
			content: "";
			width: 100%;
			height: 1px;
			background-color: #DCDBE4;
			bottom: 0;
			left: 0;
			z-index: 5;
		}
`;

export const HeaderTitle = styled.span`
	margin: 0;
	padding: 0;
	font-weight: 600;
	font-size: 20px;
	line-height: 120%;
	color: #031C2D;
	text-align: center;
	margin: 0 auto;
	overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
`;

export const HeaderCloseBtn = styled.div`
	cursor: pointer;
	height: 14px;
	width: 14px;
	margin-bottom: 10px;
`;
