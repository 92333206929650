import React from "react";
import {GetPositionIcon} from "./helpers";
import {ItemDropdown, PositioningContainer, PositioningDropdown, PositioningTitle} from "./PanelPositioning-styled";
import {inactiveColor, mutedColor} from "../../../../utils/Theme";
import {setPanelPosition_actionType} from "../../../../redux/constants";
import {useDispatch, useSelector} from "react-redux";

const PanelPositioning = () => {
    const dispatch = useDispatch()
    const {panelPosition} = useSelector((store) => store)
    const panelPositions = ["right", "left", "bottom", "modal"]

    const setPanelPosition = (position) => {
        dispatch({type: setPanelPosition_actionType, payload: position})
    }

    return (
        <PositioningDropdown id={'positioning-dropdown'}>
            <PositioningTitle>{GetPositionIcon(panelPosition, mutedColor)}</PositioningTitle>
            <PositioningContainer>
                {panelPositions.map((position) => {
                    return (
                        <ItemDropdown key={"icon_" +position} onClick={() => setPanelPosition(position)}>{GetPositionIcon(position,
                            panelPosition === position ? mutedColor : inactiveColor)}</ItemDropdown>
                    )
                })}
            </PositioningContainer>
        </PositioningDropdown>
    );
};

export default PanelPositioning;