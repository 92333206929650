import React, {useState} from "react"
import ReactSelect from "react-select"
import {SelectContainer} from "./Select-styled";
import {InputLabel} from "../Input/Input-styled";
import {useTranslation} from "react-i18next";
import {
    borderColor, dangerColor,
    mutedColor,
    placeholderColor,
    whiteColor
} from "../../../../utils/Theme";
import {convertColorToRGBA, getFormDataVal} from "../../helpers";
import {useSelector} from "react-redux";

const Select = ({specs, path, formProps}) => {
    const {onChange} = formProps
    //const {type, size, label, placeholder, content} = specs
    const {t} = useTranslation('common')
    const label = t(`screen.panel.${path}.label`, specs.label ?? "")
    const placeholder = t(`screen.panel.${path}.placeholder`, specs.placeholder ?? `${t("screen.component.select.placeholder")}`)
    let options = specs.content ?? t(`screen.panel.${path}.content`,  {})
    options = typeof options === "string" ? JSON.parse(options) : options
    const { panelFormData } = useSelector((store) => store)
    const newValue = (getFormDataVal(panelFormData, path)).value ?? ""
    const [selectValue, setSelectValue] = useState((options.find((o) => o.value == newValue)) ?? null)

    const handleValueSelection = (newValue) => {
        onChange(newValue.value, path)
        setSelectValue(newValue)
    }

    const styles = {
        container: (baseStyles) => ({
            ...baseStyles,
            height: "1.2em",
            fontFamily: "\"Open Sans\", sans-serif",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            minHeight: 0,
            fontSize: ".575em",
            fontWeight: "400",
            alignItems: "flex-end",
            color: state.isFocused ? mutedColor : placeholderColor,
            borderRadius: 0,
            borderBottom: state.isFocused ? `2px solid ${specs.opsColor ?? dangerColor}` : `1px solid ${borderColor}`,
            boxShadow: "0 0 0 0",
            outline: 0,
            fontFamily: "\"Open Sans\", sans-serif",
            '&:hover': {
                borderColor: state.isFocused ? specs.opsColor ?? dangerColor : borderColor,
            }
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
            lineHeight: "110%"
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: placeholderColor,
            fontSize: ".85em",
            alignSelf: "centre",
            height: "auto",
            maxWidth: "100%",
            textOverflow: "ellipsis",
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? whiteColor :mutedColor,
            fontSize: ".575em",
            fontWeight: "400",
            padding: "0 .5em",
            margin: 0,
            backgroundColor: state.isSelected ? specs.opsColor ?? dangerColor :
                state.isFocused ? convertColorToRGBA(specs.opsColor ?? dangerColor, .2)
                : undefined,
            cursor: state.isFocused ? "pointer" : "auto"
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            whiteSpace: "normal",
        }),
        indicatorSeparator: () => ({ display: "none"}),
        indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            alignSelf: "flex-end",
            padding: 0,
            margin: 0
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
            width: "1em",
        }),
    }

    return (
        <SelectContainer id={"select-container"}>
            {(label && label.length > 0) && <InputLabel>{label}</InputLabel>}
            <ReactSelect
                defaultValue={selectValue}
                styles={styles}
                options={options}
                onChange={handleValueSelection}
                placeholder={placeholder}
            >
            </ReactSelect>
        </SelectContainer>
    )
}

export default Select