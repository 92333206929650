import {AiIcon, ApkIcon, CssIcon, DocIcon,
        SheetIcon, PdfIcon, ZipIcon, TxtIcon,
        UnknownIcon} from "../../Icons/File-TypeIcons";
 
const filetypes = [
    {
        type: "ai",
        icon: <AiIcon/>
    },
    {
        type: "apk",
        icon: <ApkIcon/>,
    },
    {
        type: "css",
        icon: <CssIcon/>
    },
    {
        type: "doc",
        icon: <DocIcon/>
    },
    {
        type: "xlsx",
        icon: <SheetIcon/>
    },
    {
        type: "pdf",
        icon: <PdfIcon/>
    },
    {
        type: "zip",
        icon: <ZipIcon/>
    },
    {
        type: "txt",
        icon: <TxtIcon/>
    },
    {
        type: "unknown",
        icon: <UnknownIcon/>
    }
]

const getIcon = (type) => {
    const icon = filetypes.find(el => el.type === type)

    if (!icon) {
        return filetypes?.[filetypes.length -1].icon
    }
    return icon.icon;
}

export default getIcon;