import { setActiveModule_actionType } from '../constants';

const initialState = ''
const activeModule = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setActiveModule_actionType:
            return (state = payload)
        default:
            return state
    }
}

export default activeModule
