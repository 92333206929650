import styled from "styled-components";

export const InformationCard = styled.div`
  padding: 10px 10px ${({showFooter})=> showFooter ? '6px' : '10px'} 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 8px;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: fit-content;
  //cursor: pointer;
  transition: all 0.3s;
  text-align: left;
  
  .image-holder {
    display: flex;
    align-items: center;
    gap: 4px;
    
    .image {
      display: flex;
    }
    
    img {
      width: 24px;
    }
  }
  
  :hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  
  img {
    width: 22px;
    height: 22px;
  }
`
const tagColors = {
  10:'#999999',
  6:'#999999',
  5:'#34495e',
  2:'#2fa69a',
  1:'#c03a2a',
  
}
export const Tag = styled.span`
  background:${({bg, status})=> bg ?? (tagColors[status] ? tagColors[status] : tagColors[10])};
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-wrap: nowrap;
  
`

export const CardBody = styled.div`
  //margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 2px;
  width: 100%;
  
  .comment {
    span {
      color:#6a6a6a;
    }
  }
`

export const StyledField = styled.div`

  ${({expand})=> !expand ? `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  ` : '' }
  
  transition: all 0.3s;
  span:first-child {
    font-size: 12px;
    line-height: normal;
    color:#393738;
    span {
      font-weight: 600;
    }
  }
`