import {setPanelIsSurvey_actionType, resetPanel} from "../constants"

const initialState = false
const panelIsSurvey = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setPanelIsSurvey_actionType:
            return state = payload
        case resetPanel:
            return state = initialState
        default:
            return state
    }
}

export default panelIsSurvey 