import React from "react";
import {Chart as ChartJS, ArcElement} from 'chart.js';
import {Doughnut} from "react-chartjs-2";

ChartJS.register(
    ArcElement
)

const DoughnutChart = ({data, options}) => {
    return <Doughnut data={data} options={options}/>
}

export default DoughnutChart;