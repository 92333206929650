import styled from "styled-components";

export const PageOrientationWrapper = styled.div`
  display:flex;
  flex-direction:row;
  min-height: 90px;
  gap: 20px;
  
  label {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    min-width: 50px;
    
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    &:hover input ~ span {
      background-color: #eee;
    }

    & input:checked ~ span {
      background-color: ${({opsColor})=> opsColor ? opsColor : '#2196F3'};
    }
  }
`

export const CheckMark = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #eee;
  transition: all 0.2s;
  height: ${({orientation})=> orientation === 'landscape' ? '50px' : '70px'};
  width: ${({orientation})=> orientation === 'landscape' ? '70px' : '50px'};;
`

export const RadioWrapper = styled.div`
  position: relative;
`