import styled from "styled-components";

export const TitleBlack = styled.p`
	color: #000;
	width: 100%;
	font-weight: 500;
	font-size: 24px;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 0;
`;

export const TitleGray = styled.p`
	color: #333945;
	width: 100%;
	font-weight: 500;
	font-size: 22px;
	text-align: left;
	margin-bottom: 16px;
`;

export const TypesOfVehicleCardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 8px;
	padding: 2px;
`;

export const TypesOfVehicleCard = styled.div`
	max-width: 100%;
	height: 60px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	cursor: pointer;
	background: ${({selected}) => (selected ? "#b21b0c" : "#fff")};
	color: ${({selected}) => (selected ? "#fff" : "#323232")};
	box-shadow: 0px 2px 8px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.06)" : "rgba(0, 0, 0, 0.06)")}, 0px 0px 4px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.1)" : "rgba(0, 0, 0, 0.1)")};
		&:active,
		&:focus {
			box-shadow: 0px 0px 2px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.06)" : "rgba(0, 0, 0, 0.06)")}, 0px 0px 3px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.1)" : "rgba(0, 0, 0, 0.1)")};
		}
`;
