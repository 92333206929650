import React, {useEffect, useMemo} from "react";
import {
  CloseIconWrapper,
  OnBoardingFooter,
  OnBoardingWrapper,
  StyledDescription,
  StyledTitle
} from "./OnBoarding-styled";
import ProgressBar from "../Report/new/ProgressBar/ProgressBar";
import {StyledSubmitButton} from "../Report/new/Footer/Footer-styled";
import {RightArrowIcon} from "../Panel/components/Pagination/helpers";
import {useSelector} from "react-redux";
import {CustomLoader} from "../App/App-styled";
import {useTranslation} from "react-i18next";

const OnBoarding = ({
  title,
  description,
  total,
  config,
  tour,
  t
}) => {
  const {layersLoading} = useSelector(store=>store);
  const currentPosition = useMemo(()=>{
    return tour.steps.indexOf(tour.getCurrentStep())
  },[tour])
  
  return (
    <div>
      <div style={{height:8, position:'relative'}}>
        <ProgressBar currentPosition={currentPosition+1} total={total} color={config.opsColor} />
      </div>
      <OnBoardingWrapper>
        <CloseIconWrapper onClick={()=>tour.complete()}>
          <svg stroke="#626060" fill="#626060" strokeWidth="0" viewBox="0 0 512 512" height="22px" width="22px" xmlns="http://www.w3.org/2000/svg">
            <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368 144 144m224 0L144 368"></path>
          </svg>
        </CloseIconWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </OnBoardingWrapper>
      <OnBoardingFooter>
        <StyledSubmitButton
          bg="#cfcdcd"
          style={{
            borderRadius:6,
            overflow:'hidden',
            padding: 6,
            alignItems:'center',
            display:'flex',
            justifyContent:'center',
            minWidth:'auto',
            opacity: tour.steps.indexOf(tour.getCurrentStep()) === 0 ? 0 : 1,
            pointerEvents: tour.steps.indexOf(tour.getCurrentStep()) === 0 ? 'none' : 'auto'
          }}
          onClick={()=>{
            const prevSteps = tour.steps.slice(0, currentPosition).reverse();
            const nextStep = prevSteps.find(step=>{
              const element = document.querySelector(step.options.attachTo.element);
              return !!element;
            })
            
            if (!nextStep) {
              tour.complete();
            } else {
              tour.show(nextStep.id, false);
            }
          }}
        >
          <RightArrowIcon
            width={18}
            color='#FFFFFF'
          />
        </StyledSubmitButton>
        <StyledSubmitButton
          bg={config.opsColor}
          style={{position:'relative'}}
          disabled={layersLoading}
          id={`onboarding-next-${tour.getCurrentStep().id}`}
          onClick={()=>{
            const nextSteps = tour.steps.slice(currentPosition + 1, tour.steps.length);
            
            const nextStep = nextSteps.find(step=>{
              const element = document.querySelector(step.options.attachTo.element);
              return !!element;
            })

            if (!nextStep) {
              tour.complete();
            } else {
              tour.show(nextStep.id, true);
            }
          }}>
          {layersLoading ? <CustomLoader fontSize="1rem" opsColor="#fff"/> : currentPosition === tour.steps.length - 1 ? t('onboarding.buttons.finish') : t('onboarding.buttons.next')}
        </StyledSubmitButton>
      </OnBoardingFooter>
    </div>
  );
};

export default OnBoarding;