import styled from "styled-components";

export const Input = styled.input`
	width: 100%;
	border: 1px solid #dbdedf;
	height: 40px;
	border-radius: 2px;
	${({hasError}) => (hasError ?
    "border: 1px solid #b21b0c;"
    : "")}
	${({hasError}) => (hasError ? 
    "box-shadow: 0px 2px 8px rgba(178, 27, 12, 0.06), 0px 0px 4px rgba(178, 27, 12, 0.1);" 
    : "")}
	margin-top: 5px;
	padding: 8px 14px;
	font-size: 16px;
	color: #25282b;
`;

export const CheckboxLabel = styled.label`
	width: 100%;
	font-weight: 400;
	font-size: 12px;
	margin: 16px 0;
	text-align: left;
	display: flex;
	align-items: flex-start;
	color: "#000000";
	
	> div {
		margin-top: 1px;
	}
	
	&:last-child {
		margin: 0;
	}
	
	span {
		line-height: 1rem;
		font-size: 10px;
	}
`;

export const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 16px;
`;

export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	//height: 100%;
`;
