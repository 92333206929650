export const primaryColor = "rgb(0,125,188)"
export const secondaryColor = "rgb(234, 239, 244)"
export const dangerColor = "rgb(192, 58, 43)"
export const successColor = "rgb(38, 166, 154)"
export const warningColor = "rgb(255,152,0)"
export const darkColor = "rgb(15,23,42)"
export const whiteColor = "rgb(255, 255, 255)"
export const mutedColor = "rgb(76, 76, 76)"
export const inactiveColor = "rgb(150, 150, 150)"
export const borderColor = "rgb(197, 205, 210)"
export const placeholderColor = "rgb(180, 180, 180)"