import styled from "styled-components";

export const StyledMapDisclaimer = styled.div`
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  width: 100%;

  #disclaimerText {
    font-size: xx-small;
    text-align: center;
    line-height: 1.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5em;
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    #disclaimerText {
      font-size: 8px;
    }
  }

  @media screen and (max-width: 769px) {
    box-shadow: 0 -0.01rem 0 #a9a9a9, 0 -1px 2px #ccc;
    z-index: 1;
  }
  
  .expandButton {
    font-size: ${({isEmbed})=>isEmbed ? '11px' : ''};
  }
`