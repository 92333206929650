import {UpdateDate, UpdateHeader, UpdateHeaderTitle} from "./LatestUpdates-styled";
import {StyledProgress} from "../../App/App-styled";
import React, {useEffect, useState} from "react";
import {timeAgo} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const LatestUpdateHeader = ({title, updateDate, queriesInProgress, config, icon, children}) => {
  const [time, setTime] = useState('');
  const {t, i18n} = useTranslation('common');
  
  let timeout;
  useEffect(()=>{
    const handleTimeChange = (refreshedTime)=>{
      if (refreshedTime === 'now') {
        setTime(t('screen.widget.common.refreshedNow'));
      } else {
        setTime(t('screen.widget.common.refreshed') + ' ' + refreshedTime);
      }
    }
    
    const refreshedTime = timeAgo(updateDate, i18n.language);
    handleTimeChange(refreshedTime);
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
      const refreshedTime = timeAgo(updateDate, i18n.language);
      handleTimeChange(refreshedTime);
    },1000 * 60)
    return ()=>{
      clearTimeout(timeout)
    }
  },[updateDate])
  
  return (
    <UpdateHeader showBorder={children || title}>
      {title && <UpdateHeaderTitle>
        <p>{title} </p>
        {icon}
      </UpdateHeaderTitle>}
      <UpdateDate
        style={{
          opacity: !!time ? 1 : 0,
        }}
      >
        {time ? `${time}` : ''}
      </UpdateDate>
      {children}
      {
        queriesInProgress > 0 && (
          <div
            style={{
              position:'absolute',
              bottom:0,
              left:0,
              width:'100%'
            }}>
            <StyledProgress opsColor={config.opsColor} type="indeterminate" />
          </div>
        )
      }
    </UpdateHeader>
  );
};

export default LatestUpdateHeader;