import React, {useState} from "react";

const AttachmentAddDialog = ({layer, feature, refreshAttachments}) => {

    const [successContainerVisible, setSuccessContainerVisible] = useState(false)
    const [selectedFileName, setSelectedFileName] = useState()
    const [attachmentMessage, setAttachmentMessage] = useState()
    const [isDisabled, setIsDisabled] = useState(true)

    const addAttachment = () => {
        const attachment = document.getElementById("newAttachment")
        layer.addAttachment(feature, attachment).then((res) => {
            if (res.error){
                setAttachmentMessage("Couldn't add attachment, details: " + res.error)
            } else {
                setSuccessContainerVisible(true)
                setAttachmentMessage(null)
                refreshAttachments()
            }
        })
    }

    const fileInputChange = (event) => {
        if (!event.target.value){
            setSelectedFileName(null)
            setIsDisabled(true)
            return
        }

        const fileNameArr = event.target.value.split("\\")
        setSelectedFileName(fileNameArr[fileNameArr.length-1])
        setIsDisabled(false)
    }

    return (
        <div className="esri-attachments__form-node">
            <form id="newAttachment" data-node-ref="_addAttachmentForm" >
                <fieldset className="esri-attachments__file-fieldset">
                    <span id="attachmentFileName" className="esri-attachments__file-name">{selectedFileName}</span>
                    <label className="esri-attachments__file-label esri-button esri-button--secondary">Select file
                        <input type="file" className="esri-attachments__file-input" name="attachment" id="attachmentFileInput" onChange={fileInputChange}/>
                    </label>
                </fieldset>
            </form>

            { isDisabled &&
                <calcite-button id="addAttachment" className="esri-editor__control-button" type="button" slot="footer-actions" width="full"
                    alignment="center" appearance="solid" color="blue" scale="m" disabled={isDisabled}
                    onClick={()=> {addAttachment()}}>Add</calcite-button>}

            { !isDisabled &&
                <calcite-button id="addAttachment" className="esri-editor__control-button" type="button" slot="footer-actions" width="full"
                                alignment="center" appearance="solid" color="blue" scale="m"
                                onClick={()=> {addAttachment()}}>Add</calcite-button>}

            {attachmentMessage &&
                <div id="attachmentErrorContainer" className="esri-editor__prompt--warning" >
                    <div className="esri-editor__prompt__header">
                        <calcite-icon icon="exclamation-mark-triangle" aria-hidden="true" scale="m"/>
                        <h4 className="esri-widget__heading esri-editor__prompt__header__heading" role="heading"
                            aria-level="4">Something went wrong</h4></div>
                    <div id="attachmentErrorMessage" className="esri-editor__prompt__message">{attachmentMessage}</div>
                    <div className="esri-editor__prompt__divider"/>
                    <div className="esri-editor__prompt__actions">
                        <calcite-button id="attachmentErrorButton" appearance="solid" color="blue" width="full" alignment="center" scale="m"
                            onClick={() => {setAttachmentMessage(null)}}>OK</calcite-button>
                    </div>
                </div>
            }

            {successContainerVisible &&
                <div id="attachmentSuccessContainer" className="esri-editor__prompt--warning">
                    <div className="esri-editor__prompt__message">Attachment successfully added</div>
                    <div className="esri-editor__prompt__divider"/>
                    <div className="esri-editor__prompt__actions">
                        <calcite-button id="attachmentSuccessButton" appearance="solid" color="blue" width="full" alignment="center" scale="m"
                            onClick={() => {setSuccessContainerVisible(false)}}>OK</calcite-button>
                    </div>
                </div>
            }
        </div>)
}

export default AttachmentAddDialog