import React from "react";

import {appendParamToURL} from "../../../utils/helper";
import {OverviewImage, StyledPopupSwiper} from "../CustomPopupWrapper-styled";
import {Navigation} from "swiper/modules";
import {SwiperSlide} from "swiper/react";
import DetailsTab from "./DetailsTab";

/**
 * Overview tab
 */
const OverViewTab = ({layer, overviewTable1Fields, overviewTable2Fields, images, goToImageTab, feature, t, i18n, config}) => {
    const oid = feature.attributes[layer.objectIdField]
    return (
        <div>
            <DetailsTab fields={overviewTable1Fields} row={feature} t={t} i18n={i18n} config={config}/>
            <DetailsTab fields={overviewTable2Fields} row={feature} t={t} i18n={i18n} config={config}/>

            {(images.length > 0) &&
                <StyledPopupSwiper slidesPerView={1} slidesPerGroup={1}	centeredSlides={true} spaceBetween={0} navigation
                                   modules={[Navigation]} centerInsufficientSlides={false}	loop length={images.length}>
                    {images?.map((image) => (
                        <SwiperSlide key={oid + "_a" + image.id} onClick={goToImageTab}>
                            <OverviewImage alt={image.name} src={appendParamToURL(image.url, "w", 400)} />
                        </SwiperSlide>
                    ))}
                </StyledPopupSwiper>
            }
        </div>
    )
}

export default OverViewTab