import styled from "styled-components";

export const ContextMenuContainer = styled.div`
	top: ${props => props.yPos};
	left: ${props => props.xPos};
	z-index: 1;
	position: fixed;
`;

export const ContextMenuContent = styled.div`
	border-radius: 4px;
	padding: 8px 0;
	background: #fff;
	height: 32px;
	display: flex;
	align-items: center;
`;

export const ContextMenuList = styled.ul`
	list-style: none;
	padding: 0;
`;


export const ContextMenuListItem = styled.li`
	padding: 0 16px;
	font-size: 12px;
	cursor: pointer;
`;


