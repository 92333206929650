import React from "react";
import { StyledError, StyledH2, StyledP } from "./Error-styled";
import {CalciteButton, CalciteModal, CalciteAction} from "@esri/calcite-components-react"
import PropTypes from "prop-types";

const Error = ({ messages }) => {
  const errors = messages.map(message => {
    return <StyledP key={message}>{message}</StyledP>;
  });
  return (
    <StyledError>
      <CalciteModal open={true} appElement={document.body}>
        <StyledH2>Error</StyledH2>
        {errors}
        <CalciteAction>
          <CalciteButton>Okay</CalciteButton>
        </CalciteAction>
      </CalciteModal>
    </StyledError>
  );
};

Error.propTypes = {
  messages: PropTypes.array
};

export default Error;
