import styled from "styled-components";

export const StyledMapContainer = styled.div`
  position: relative;
  background-color: #efefef;
  font-size: 0;
  margin: 0;
  padding: 0;
  //width: 100%;
  height: 100%;
  //flex-shrink: 1;
  flex:1;
  @media screen and (max-width: 768px) {
    height: calc(100%-55px);
  }
`;