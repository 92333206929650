import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	width: 100%;
`;

export const ButtonYes = styled.button`
	background-color: #b21b0c;
	border-radius: 2px;
	height: 40px;
	text-decoration: none;
	color: #fff;
	border-width: 0px;
	font-size: 16px;
	cursor: pointer;
	width: 100%;
	margin-top: 16px;
`;

export const ButtonCancel = styled.button`
	background-color: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	height: 40px;
	text-decoration: none;
	color: #000000;
	border-width: 0px;
	font-size: 16px;
	cursor: pointer;
	width: 100%;
	margin-top: 16px;
	margin-right: 15px;
`;
