import React from "react";
import {useTranslation} from "react-i18next";
import {ButtonContainer, PaginationContainer, ArrowButtonsContainer} from "./Pagination-styled";
import Button from "../Button/Button";
import {routeLink} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {setPanelPageIndex_actionType} from "../../../../redux/constants";
import {GetPaginationIcons} from "./helpers";
import {darkColor, inactiveColor} from "../../../../utils/Theme";

const Pagination = ({onSubmit}) => {
    const {panelSurveyIsValid} = useSelector((store) => store)
    const {t} = useTranslation("common")
    const dispatch = useDispatch()
    const {panelPageIndex, panelPath} = useSelector((store) => store)
    const data = routeLink(panelPath)
    const isLastPage = panelPageIndex === ((Object.keys(data.content ?? (data ?? {}))).length - 1)
    const isFirstPage = panelPageIndex === 0
    //console.log(isLastPage, !panelSurveyIsValid)

    const setCurrPageIndex = (newIndex) => {
        dispatch({type: setPanelPageIndex_actionType, payload: newIndex})
    }

    const onCompleteClick = () => {
        if (isLastPage){
            onSubmit()
            return
        }
        setCurrPageIndex(isLastPage ? 0 : panelPageIndex + 1)
    }
    const color = isLastPage || !panelSurveyIsValid ? inactiveColor : darkColor
    const rightArrowSpecs = {
        variant: "filled",
        color: "secondary",
        content: GetPaginationIcons("right", color),
        disabled: isFirstPage
    }
    const leftArrowSpecs = {
        variant: "filled",
        color: "secondary",
        content: GetPaginationIcons("left", color),
        disabled: isLastPage || !panelSurveyIsValid
    }
    const btnContent = (isLastPage) ? t('screen.popup.actions.submit') : t('screen.popup.actions.next')
    const btnSpecs = {variant: "filled", color: "secondary", content: btnContent, disabled: !panelSurveyIsValid}
    return (
        <PaginationContainer id={"pagination-container"}>
            <ArrowButtonsContainer>
                <Button specs={rightArrowSpecs} onClick={() => setCurrPageIndex(panelPageIndex - 1)}/>
                <Button specs={leftArrowSpecs} onClick={() => setCurrPageIndex(panelPageIndex + 1)}/>
            </ArrowButtonsContainer>
            <ButtonContainer>
                <Button specs={btnSpecs} onClick={onCompleteClick}/>
            </ButtonContainer>
        </PaginationContainer>
    )
}
export default Pagination