import {removeData, setSubCategory_actionType} from "../constants";
const initialState = null;
 
const subCategory = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setSubCategory_actionType:
			return state = payload;
		case removeData: 
			return state = initialState;
		default:
			return state;
	}
};

export default subCategory;