import {useEffect, useRef} from "react";
import {isTouchScreen} from "../utils/helper";

const MAX = 100;
const k = 0.4;

function appr(x) {
  return MAX * (1 - Math.exp((-k * x) / MAX));
}

function usePullToRefresh(
  ref,
  onTrigger,
  loaderRef,
) {
  const toucheEnd = useRef();
  const wheel = useRef();
  const wheelStart = useRef(0);
  const scroll = useRef(false);
  
  useEffect(()=>{
    const el = ref.current;
    
    if (!el) return;
    const onScroll = ()=>{
      scroll.current = true
    }
    
    const onScrollEnd = ()=>{
      scroll.current = false;
    }
    
    
    el.addEventListener('scroll', onScroll);
    el.addEventListener('scrollend', onScrollEnd);
    
    return ()=>{
      el.removeEventListener('scroll', scroll);
      el.removeEventListener('scrollend', onScrollEnd);
      
    }
  },[])
  
  useEffect(() => {
    const el = ref.current;
    
    if (!el) return;
    
    function addPullIndicator() {
      const refresh = loaderRef.current
      if (!refresh) return;
      refresh.style.opacity = 1;
    }
    
    function removePullIndicator() {
      const refresh = loaderRef.current
      if (!refresh) return;
      refresh.style.opacity = 0;
    }
    
    // attach the event listener
    // el.addEventListener("mousedown", handleTouchStart);
    el.addEventListener("touchstart", handleTouchStart);
    el.addEventListener('wheel', onWheel)
    // let wheelStart = 0
    function onWheel (event) {
      if (isTouchScreen()) return;
      
      const el = ref.current;
      if (!el) return;
      
      if (!!wheel.current || el.scrollTop > 0 || event.deltaY > 0 || scroll.current) return;
      
      wheelStart.current += Math.abs(event.deltaY);
      const parentEl = el.parentNode;
      const dy = Math.abs(wheelStart.current);
      el.style.transition = "transform 0.2s";
      
      if (dy > 10) {
        onTrigger();
        addPullIndicator(parentEl);
        el.removeEventListener('wheel', onWheel);
        el.style.transform = "translateY(30px)";
        wheelStart.current = 0;
        
        wheel.current = setTimeout(()=>{
          removePullIndicator(el);
          el.style.transform = "translateY(0)";
          clearTimeout(wheel.current);
          wheel.current = null;
          el.addEventListener('wheel', onWheel);
        },1000)
        return;
      }
      
      // now we are using the `appr` function
      el.style.transform = `translateY(${appr(Math.abs(wheelStart.current))}px)`;
    }
    
    function handleTouchStart(startEvent) {
      const el = ref.current;
      if (!el || el.scrollTop > 0 || scroll.current) return;
      // get the initial Y position
      const initialY = startEvent.type.includes('mouse') ? startEvent.clientY : startEvent.touches[0].clientY;
      // el.addEventListener("mousemove", handleTouchMove);
      // el.addEventListener("mouseup", handleTouchEnd);
      // el.addEventListener("mouseleave", handleTouchEnd);
      
      el.addEventListener("touchmove", handleTouchMove);
      el.addEventListener("touchend", handleTouchEnd);
      
      function handleTouchMove(moveEvent) {
        const el = ref.current;
        if (!el) return;
        
        const currentY = moveEvent.type.includes('mouse') ? moveEvent.clientY : moveEvent.touches[0].clientY;
        // get the current Y position
        
        // get the difference
        const dy = currentY - initialY;
        
        if (dy < 0) return;
        
        const parentEl = el.parentNode;
        if (dy > 100) {
          addPullIndicator(parentEl);
        }
        
        // now we are using the `appr` function
        el.style.transform = `translateY(${appr(dy)}px)`;
      }
      
      
      function handleTouchEnd(endEvent) {
        const el = ref.current;
        if (!el) return;
        
        
        // run the callback
        const y = endEvent.type.includes('mouse') ? endEvent.clientY : endEvent.changedTouches[0].clientY;
        const dy = y - initialY;
        const parentEl = el.parentNode;
        if (dy < 0) return;
        
        if (dy < 99) {
          el.style.transition = "transform 0.2s";
          el.style.transform = "translateY(0)";
          removePullIndicator(parentEl)
          return;
        }
        
        el.style.transform = "translateY(30px)";
        
        if (toucheEnd.current) return;
        toucheEnd.current = setTimeout(()=>{
          // return the element to its initial position
          el.style.transition = "transform 0.2s";
          el.style.transform = "translateY(0)";
          
          // add transition
          
          if (dy > 100) {
            onTrigger();
          }
          removePullIndicator(parentEl);
          clearTimeout(toucheEnd.current)
          toucheEnd.current = null;
        },500)
        
        // listen for transition end event
        el.addEventListener("transitionend", onTransitionEnd);
        //
        // el.removeEventListener("mousemove", handleTouchMove);
        // el.removeEventListener("mouseup", handleTouchEnd);
        // el.removeEventListener("mouseleave", handleTouchEnd);
        // cleanup
        el.removeEventListener("touchmove", handleTouchMove);
        el.removeEventListener("touchend", handleTouchEnd);
      }
      
      function onTransitionEnd() {
        const el = ref.current;
        if (!el) return;
        
        setTimeout(()=>{
          // remove transition
          el.style.transition = "";
        }, 100)
        
        // cleanup
        el.removeEventListener("transitionend", onTransitionEnd);
      }
    }
    
    return () => {
      // let's not forget to cleanup
      el.removeEventListener("touchstart", handleTouchStart);
      // el.removeEventListener("mousedown", handleTouchStart);
    };
  }, [ref.current]);
}

export default usePullToRefresh;