import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setPrintWidget} from "../../../redux/action/PrintWidget-action";
import {StyledPrintButton} from "./PrintButton-styled";

const PrintButton = () => {
  const {layersLoading} = useSelector(state=>state);
  const dispatch = useDispatch();
  useEffect(()=>{
    if (layersLoading) return;
    document.querySelector('#Print').style.opacity = 1;
    document.querySelector('#Print').style.pointerEvents = 'auto';
  },[layersLoading])
  
  return <StyledPrintButton
    className="onboarding-print"
    style={{
      opacity: layersLoading ? 0 : 1,
      pointerEvents: layersLoading ? 'none' : 'auto'
    }}
    onClick={()=>{
      const mapDiv = document.querySelector('#mapContainer');
      if (mapDiv) {
        mapDiv.classList.add('print-enabled')
      }
      dispatch(setPrintWidget(true))
  }}>
    <span className="esri-collapse__icon esri-icon-printer"></span>
  </StyledPrintButton>
}

export default PrintButton;