import styled from "styled-components";

export const DisclaimerWrapper = styled.div`
  background:#fff;
  height: 48px;
  width:100%;
  bottom: 0;
  position:absolute;
  font-size:12px;
  display:flex;
  pointer-events:all;
  gap: 4px;
  padding: 0 4px;
  box-sizing: border-box;
  align-items: center;

  span {
    font-size: ${({fontSize})=> fontSize ?? '5px'};
    margin:0;
    line-height: 1.5em;
  }
`

export const DisclaimerSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px 0;
  
  svg {
    width: 40px;
  }
`