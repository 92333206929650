import React, {useEffect, useState} from "react";
import {
  StyledToggleButton,
  StyledToggleButtonWrapper,
  StyledToggleTitle,
  StyledToggleWrapper
} from "../ManualWidget-styled";
import {removeWidget} from "../../../esri/widgets/expandUtils";
import {addSymbology} from "../../../utils/symbologies";
import {loadModules} from "esri-loader";
import {createSymbologyLegend} from "../../../esri/widgets";
import {view} from "../../../utils/API";
import {useDispatch, useSelector} from "react-redux";
import {setLegendWidgetUpdate} from "../../../redux/action/LegendWidget-action";

const Symbology = ({t, config, layer}) => {
  const [symbology, setSymbology] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const {printWidget} = useSelector(state=>state);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    const symbologies = [];
    layer.layerConfig?.symbology.forEach(symbology=>{
      const sy = {
        ...config.layerSymbologies[symbology],
        colorMap:{
          ...config.layerSymbologies[symbology].colorMap,
          field: Array.isArray(config.layerSymbologies[symbology].colorMap) ? config.layerSymbologies[symbology].colorMap.field[0] : config.layerSymbologies[symbology].colorMap.field
        }
      }
  
      symbologies.push(sy);
    })
    setSelectedField(symbologies[0].colorMap.field)
    setSymbology(symbologies);
  },[config, layer])
  
  return (
    <StyledToggleWrapper>
      <StyledToggleButtonWrapper>
        {
          symbology.map((symbology, index)=>(
            <StyledToggleButton
              key={index}
              onClick={()=>{
                dispatch(setLegendWidgetUpdate());
                setSelectedField(symbology.colorMap.field)
                addSymbology(layer, config, t, symbology);
                
                loadModules(["esri/widgets/Expand", 'esri/core/reactiveUtils']).then(async ([Expand, reactiveUtils])=>{
                  createSymbologyLegend(Expand, reactiveUtils, view, t, config, 1)
                })
              }}
              selected={selectedField === symbology.colorMap.field}
              opsColor={config.opsColor}
            >
              <span>{t("layer.fieldAlias."+symbology.colorMap.field)}</span>
            </StyledToggleButton>
          ))
        }
      </StyledToggleButtonWrapper>
    </StyledToggleWrapper>
  );
};

export default Symbology;