import {setPrintWidget_actionType} from "../constants"

const initialState = false
const printWidget = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case setPrintWidget_actionType:
      state = payload
      break
  }
  return state
}

export default printWidget