import React, {useState} from "react";

import {appendParamToURL} from "../../../utils/helper";
import AttachmentRemovalDialog from "./AttachmentRemovalDialog";

/**
 * Image tab on the popup. Editors can remove images
 */
const ImagesTab = ({layer, isEditable, attachments, feature, refreshAttachments}) => {
    const [attachmentIdToRemove, setAttachmentIdToRemove] = useState(null)

    return (
        <div className="esri-feature__attachments esri-feature__content-element">
            <div className="esri-attachments esri-widget">
                <div className="esri-attachments__container esri-attachments__container--preview">
                    <ul className="esri-attachments__items">
                        {attachments?.map((image) => (
                            <li key={feature.attributes[layer.objectIdField] + "_a" + image.id} className="esri-attachments__item">
                                <a className="esri-attachments__item-button" href={image.url} target="_blank" rel="noreferrer">
                                    <div className="esri-attachments__item-mask">
                                        <img alt={image.name} src={appendParamToURL(image.url, "w", 400)}
                                             className="esri-attachments__image--resizable esri-attachments__image" />

                                        {isEditable &&
                                            <span className="esri-popup__icon esri-icon-trash" title="Remove me"
                                                  onClick={(event) => {
                                                      event.preventDefault()
                                                      setAttachmentIdToRemove(image.id) }}/>
                                        }
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                {isEditable && <AttachmentRemovalDialog layer={layer} feature={feature} refreshAttachments={refreshAttachments}
                                                        setAttachmentIdToRemove={setAttachmentIdToRemove}
                                                        attachmentIdToRemove={attachmentIdToRemove}/>}
            </div>
        </div>
    )
}

export default ImagesTab