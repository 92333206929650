import styled from "styled-components";

export const BannerContainer = styled.div`
  border-radius: 8px;
  padding: 8px;
  background-color: ${props => `${props.color}`};
`

export const BannerInner = styled.div`
  overflow: hidden;
`

export const AnimatedLine = styled.div`
  text-align: left;
  gap: 4em;
`
export const BannerText = styled.a`
  text-align: left;
  color: white;
  font-size: 0.8em; 
  font-weight: 700;
  margin-right: 0.5em;
  
`