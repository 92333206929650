import React, {useCallback, useEffect, useMemo, useState} from "react";
import {loadModules} from "esri-loader";
import {
  StyledLoaderWrapper,
  StyledToggleButton,
  StyledToggleButtonWrapper,
  StyledToggleTitle,
  StyledToggleWrapper
} from "../ManualWidget-styled";
import {getMapFilterOptions} from "../../Filter/helpers";
import {CalciteLoader} from "@esri/calcite-components-react"
import {ColorPolygon, SymbolLine} from "../../SymbologyLegend/SymbologyLegend-styled";
import {createSymbol, getLayerSymbology} from "../../../utils/symbologies";

const getMapFilterSymbols = (layer, config) => {
  if (!layer?.layerConfig) return;
  
  const symbology = getLayerSymbology(layer, config);
  if (!symbology) return;
  const colorMap = symbology.colorMap || {};
  const colors = colorMap?.default || {};
  if (colorMap) {
    // const colorSymbologyValue = feature.attributes[colorMap.fields[0]] === null ? 'NULL' : feature.attributes[colorMap.fields[0]];
    // const color = colors[colorSymbologyValue] ?? colors.defaultColor;
    const symbols = [];
    Object.keys(colors).forEach(key => {
      const symbol = createSymbol(symbology, layer, symbology.defaultIcon, colors[key], symbology)
      symbols.push({
        value: key,
        symbol
      })
    })
    return symbols;
  }
  return []
}

const FilterToggle = ({field, options, selectedValue, onFilterChange, t, config, view, layer, showSymbol}) => {
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    if (!loading) return;
    let handler;
    let timer;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      handler = reactiveUtils.watch(
        () => view.updating,
        () => {
          timer = setTimeout(()=>{
            setLoading(false);
          },800)
        })
    })
    return () => {
      handler?.remove();
      clearTimeout(timer);
      if (loading) {
        setLoading(false);
      }
    }
  },[loading])
  
  const handleSelection = useCallback((e, value)=>{
    setLoading(true);
    if (selectedValue === value) {
      onFilterChange(field.name, []);
    } else {
      onFilterChange(field.name, [value]);
    }
  },[onFilterChange, selectedValue])
  
  const toggleOptions = useMemo(() => getMapFilterOptions({options, field, t}),[field, options, t])
  const symbolOptions = useMemo(()=>{
    if (showSymbol) {
      return getMapFilterSymbols(layer, config)
    }
    
    return [];
  }, [config, layer, showSymbol]);
  
  return (
    <StyledToggleWrapper>
      <StyledToggleTitle>
        <p>{t('layer.fieldAlias.' + field.name, field.alias)}</p>
      </StyledToggleTitle>
      <StyledToggleButtonWrapper>
        {
          loading && (
            <StyledLoaderWrapper opsColor={config.opsColor}>
              <CalciteLoader scale="s" />
            </StyledLoaderWrapper>
          )
        }
  
        {
          showSymbol ? symbolOptions.map(option=>(
            <StyledToggleButton
              key={option.value}
              onClick={(e)=>handleSelection(e, option.value)}
              selected={selectedValue === option.value}
              opsColor={config.opsColor}
            >
              <SymbolLine key={"syml"}>
                <ColorPolygon color={option.symbol.color}/>
                {/*<SymbolTitle >{Array.from(cd.labels).join(', ')}</SymbolTitle>*/}
              </SymbolLine>
            </StyledToggleButton>
          )) : toggleOptions.map((option)=>(
            <StyledToggleButton
              key={option.value}
              onClick={(e)=>handleSelection(e, option.value)}
              selected={selectedValue === option.value}
              opsColor={config.opsColor}
            >
              <span>{option.label}</span>
            </StyledToggleButton>
          ))
        }
      </StyledToggleButtonWrapper>
    </StyledToggleWrapper>
  );
};

export default FilterToggle;