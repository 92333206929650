import styled from "styled-components";

export const StyledDragHandle = styled.div`
  position:absolute;
  background: ${({opsColor})=> opsColor};
  font-size: 10px;
  width: ${({ratio})=> `${20 * ratio}px`};
  height:${({ratio})=> `${20 * ratio}px`};
  right:${({right = 0, ratio = 1})=> `${right * ratio}px`};
  top: 0;
  z-index: 1;
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 2px;
  //pointer-events: all;
`

export const StyledPDFPreview = styled.div`
  position:absolute;
  margin-bottom: 48px;
  width:100%;
`

export const StyledZoomWrapper = styled.div`
  width: ${({width}) => `${width}`};
  height: ${({height})=> `${height}`};
  border: ${({opsColor})=>`1px solid ${opsColor}`};
  pointer-events: auto;
  
  button {
    opacity: 0;
    pointer-events: none;
  }

  :hover button {
    opacity: 1;
    pointer-events: all;
  }

  calcite-loader {
    stroke: ${({opsColor})=> opsColor ? `${opsColor} !important` : '#b21b0c !important'};
  }
`

export const StyledZoomMap = styled.div`
  width:100%;
  height:100%;
  opacity:0;
  background: #efefef;

  .esri-zoom  .esri-widget--button {
    width: 20px !important;
    height: 20px !important;
    border-radius: 6px !important;
  }
  
  .esri-zoom  .esri-widget--button span,
  .esri-zoom  .esri-widget--button span:before {
    font-size: 9px;
  }

  .esri-ui-top-left {
    top: 93%;
    left: 97%;
    transform: translate(-50%, -50%);
  }
`

export const StyledLegendPlaceholder = styled.div`
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: 180px;
  height: 139px;
  display: flex;
  align-items: center;
  justify-content: center;

  calcite-loader {
    stroke: ${({opsColor})=> opsColor ? `${opsColor} !important` : ''};
  }
`

export const StyledZoomOutArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 150px;
  max-width: 80%;
  max-height: 80%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
export const StyledLegendWrapper = styled.div`
  
  #legendBox {
      min-width: 150px;
     padding: 6px !important;
     border: 1px solid ${({opsColor})=>opsColor};
  }
  
  .legendBox {
    &__grid {
      gap: 2px;
    }
    
    &__title {
       font-size: 11px;
       line-height: 0.5rem;
    }

    &__layer-title {
      font-size: 6px !important;
      line-height: 0.5rem;
    }

    &__layer-feature,
    &__symbol-title {
      font-size: 5px !important;
      line-height: 0.5rem;
    }

    &__layer-symbol {
      width: 10px;
      height: 10px;
      min-width: 10px;
      line-height: 0.5rem;
    }

    &__color-cube {
      width: 4px;
      height: 4px;
      border-radius: 1px;
    }
    
  }
`