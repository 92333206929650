import React, {useEffect, useState} from "react";
import {view} from "../../../../utils/API";
import {GeometryContainer} from "./Geometry-styled";
import {getFormDataVal} from "../../helpers";
import {useSelector} from "react-redux";
import Input from "../Input/Input";
import {svgToBase} from "../../../Icons/layerSvg/clusterAssets";
import {loadModules} from "esri-loader";

const Geometry = ({specs, path, formProps}) => {
    const {isSurvey, onChange} = formProps
    const { panelFormData, panelId } = useSelector((store) => store)
    const value = getFormDataVal(panelFormData, path).value

    const [lat, setLat] = useState((value?.latitude || "").toString() || "")
    const [lng, setLng] = useState((value?.longitude || "").toString() || "")
    const [gLayer, setGLayer] = useState()

    useEffect(() => {
        const setPanelLayer = async () => {
            return panelId.content ?? await getLayer(layerId, view)
        }
        setPanelLayer().then((l) => {
            loadModules(["esri/layers/GraphicsLayer"] ).then(([GraphicsLayer]) => {
                const gl = new GraphicsLayer({title: "Geometry Sketch", opacity: 1})
                gl.layerModules = l.layerModules
                view.map.layers.add(gl)
                setGLayer(gl)
            })
        })
    },[])

    useEffect(() => {
        const svg = svgToBase(specs.symbol, specs.opsColor)
        let handler = view?.on("click", (event) => {
            let icon = {
                type: "picture-marker",
                url: svg,
                width: "24px",
                height: "24px"
            }
            //setMapPoint(event.mapPoint)
            gLayer.removeAll()
            gLayer.add({geometry: event.mapPoint, symbol: icon})
            setLat(event.mapPoint.latitude.toString())
            setLng(event.mapPoint.longitude.toString())
        })
        return () => handler.remove()
    }, [gLayer])

    useEffect( () => {
        if (!isSurvey)
            return
        onChange({latitude: lat, longitude: lng}, path)
    }, [lat, lng])

    //Input = ({type, specs, path, formProps })
    //{isSurvey, value, onChange} = formProps
    return <GeometryContainer>
        <Input
            specs={{opsColor: specs.opsColor, label: "Latitude"}}
            path={"latitude"}
            formProps={{isSurvey, value: lat, onChange: (inputVal) => setLat(inputVal)}}
            disabled={true}
        />
        <Input
            specs={{opsColor: specs.opsColor, label: "Longitude"}}
            path={"longitude"}
            formProps={{isSurvey, value: lng, onChange: (inputVal) => setLng(inputVal)}}
            disabled={true}
        />
    </GeometryContainer>
}
export default Geometry