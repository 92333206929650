import Dropdown, {DropdownBody} from "../../../Dropdown";
import {information16} from "@esri/calcite-ui-icons";
import {StyledCardDescription, StyledCardHeader} from "../Insights-styled";
import React, {useState} from "react";

const CardHeader = ({
  title,
  description,
  ...props
}) => {
  const [show, setShow] = useState(false);
  return (
    <StyledCardHeader {...props}>
      <p>{title}</p>
      {description && <Dropdown
        setShow={setShow}
        show={show}
        leftOffset={0.3}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            viewBox="0 0 16 16"
          >
            <path d={information16}/>
          </svg>
        </div>
        <DropdownBody style={{display:'flex'}}>
          <StyledCardDescription>
            {description}
          </StyledCardDescription>
        </DropdownBody>
      </Dropdown>}
    </StyledCardHeader>
  );
};

export default CardHeader;