import {Bar, BarChart, CartesianGrid, Cell, LabelList, Tooltip, XAxis, YAxis} from "recharts";
import {useMemo} from "react";
import {StyledChartTooltip} from "../Insights-styled";
import {COLORS} from "../Insights";

const CustomTooltip = ({ active, payload, label, ...rest }) => {
  if (active && payload && payload.length) {
    if (payload[0].dataKey === 'value') {
      return (
        <StyledChartTooltip>
          <span style={{fontSize:12, fontWeight:500}}>{payload[0].payload.name}</span>
          <span style={{fontSize:12}}>
          Storage capacity (m2):
          <span style={{fontSize:18, fontWeight:500}}>{payload[0].value}</span></span>
        </StyledChartTooltip>
      );
    } else {
      const hscode = payload[0].payload?.hscode || ''
      
      return (
        <StyledChartTooltip>
          <span style={{fontSize:12, fontWeight:500, marginBottom:4}}>{payload[0].payload.name}
            {hscode && (<span style={{fontSize: 10, color: "grey"}}> ({hscode})</span>)}
          </span>
          {
            payload.map(item=> {
              const title = Array.isArray(item.payload[`${item.dataKey}-title`]) ? item.payload[`${item.dataKey}-title`].join(', ') : item.payload[`${item.dataKey}-title`];
              const description = Array.isArray(item.payload[`${item.dataKey}-description`]) ? item.payload[`${item.dataKey}-description`].join(', ') : item.payload[`${item.dataKey}-description`];
              
              return <span key={item.dataKey} style={{fontSize:12, color: item.color === '#E8E8E8' ? '#393738' : item.color}}>
                {title}:
                <span style={{fontSize:18, fontWeight:500}}> {item.value} </span>
                {description}
              </span>
            })
          }
        </StyledChartTooltip>
      );
    }
  }
  
  return null;
};

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 6;
  
  return (
    <g>
      <circle cx={x + width + 5} cy={y + 5} r={radius} fill="#8884d8" />
      {/*<text x={x + width} y={y} fill="#fff" textAnchor="middle" dominantBaseline="middle">*/}
      {/*  {value.split(' ')[1]}*/}
      {/*</text>*/}
    </g>
  );
};

const CustomBarChart = ({config, t, data, mapFilters, onClick , barValues = [], stackIds = {}, colors={}, layout, children ,...props}) => {
  const selectedIndexes = useMemo(()=>{
    if (!data.length) return []
    const selectedKeyArray = data.filter((item)=>mapFilters[item.key]?.length > 0);
    let indexes = [];
    if (selectedKeyArray.length === 1) {
      indexes = selectedKeyArray.map(item=>data.findIndex(i=>i.key === item.key));
    } else {
      indexes = selectedKeyArray.filter(item=>mapFilters[item.key].includes(item.codedValue)).map(item=>data.findIndex(i=>i.codedValue === item.codedValue));
    }
    return indexes;
  },[mapFilters, data])
  
  return (
    <>
      <BarChart
        data={data}
        layout={layout}
        onClick={(params)=>{
          if (onClick) {
            onClick(params, data)
          }
        }}
        {...props}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {
          layout === 'vertical' ? (
            <>
              <XAxis
                fontSize={10}
                type="number"
              />
              <YAxis
                interval={0}
                width={140}
                fontSize={11}
                type="category"
                dataKey="name"
                tick={{
                  fill:'#393738',
                }}
              />
            </>
          )  : (
            <>
              <XAxis interval={0} fontSize={10} dataKey="name" />
              <YAxis width={30} fontSize={10} />
            </>
          )
        }
        <Tooltip
          content={CustomTooltip}
          cursor={{fill: COLORS.green + '1a'}}
          wrapperStyle={{cursor:'pointer'}}
        />
        
        {
          barValues.map((barValue)=>(
            <Bar
              key={barValue}
              dataKey={barValue}
              fill={colors.fill[barValue]}
              stackId={stackIds[barValue]}
            >
              {
                data.map((item, index)=>(
                  <Cell
                    key={item.name}
                    fill={(selectedIndexes.length > 0 && !selectedIndexes.includes(index)) ? COLORS.grey : colors.fill[barValue]}
                  />
                ))
              }
            </Bar>
          ))
        },
      </BarChart>
      </>
  );
};

export default CustomBarChart;