import React from "react";
import 'chart.js/auto';

import {PieChart, DoughnutChart} from "../index"; 

const CanvasChart = ({type, chart, onClickCallBack}) => {

	let options = {
		plugins: {
			legend: {
				display: false,
				position: 'bottom',
				fullSize: true,
				align: 'center',
				textDirection: 'center',
			}
		},
		layout:	{ padding: 5},
		onClick: (e, element) => {
			onClickCallBack(e, element)
		}
	}

	if (type === "pie")
		return <PieChart data={chart} options={options}/>

	return <DoughnutChart data={chart} options={options}/>
}

export default CanvasChart;