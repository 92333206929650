import React, {useMemo} from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import {StyledReportsBody} from "../Reports-styled";
import ScrollSection from "../ScrollSection/ScrollSection";
import Footer from "../Footer/Footer";
import useReport from "../../../../hooks/useReport";
import {FormProvider} from "react-hook-form";
import ReportFinal from "../ReportFinal";
import ReportsHeader from "../ReportsHeader/ReportsHeader";

const containerId = "warehouse-body";
const WarehouseReport = ({config, closeModal, layer, onSubmit, openReport, title, t}) => {
  const pages = useMemo(()=>{
    return [
      {
        id:1,
        title: t('report.warehouse.pages.intro.title'),
        description: t('report.warehouse.pages.intro.description')
      },
      {
        id:2,
        component: 'location',
        title: t('report.warehouse.pages.location.title'),
        description: t('report.warehouse.pages.location.description'),
        required: ['geometry']
      },
      {
        id:3,
        component: 'storagespace',
        required: ['storagecapm2', 'storageclimatecontrol']
      },
      {
        id:4,
        title: t('report.warehouse.pages.communityShare.title'),
        component: 'communityshare',
        required:['communityshare']
      },
      {
        id:5,
        title:t("report.contacts.title"),
        description: t("report.contacts.description"),
        component: 'contacts',
      },
    ]
  },[t])
  
  
  const {
    contactsMethods,
    scrollNextDelay,
    goNext,
    goBack,
    page,
    resetReport,
    form,
    setForm
  } = useReport({pages, containerId, t});
  
  const canGoNext = useMemo(() => {
    if (page === pages.length) return;
    const currentPage = pages[page]
    const requiredFields = {};
    
    currentPage.required?.map(requiredField=>{
      requiredFields[requiredField] = form[requiredField]
    })
    return !(Object.values(requiredFields).filter(value => value === undefined).length > 0)
  },[pages, form, page])
  const handleFormSubmit = async (contactsData) => {
    try {
      await onSubmit(contactsData, form)
      goNext();
    } catch (err){
      console.log(err)
    }
  }
  
  const currentPage = useMemo(()=>{
    return pages[page]
  },[page, pages])
  
  const isFinalPage = useMemo(()=>{
    return page === pages.length
  },[page, pages])
  
  return (
    <>
      <ReportsHeader
        showArrow={true}
        isCustom={true}
        title={title}
        onArrowClick={openReport}
        close={closeModal}
      >
        <ProgressBar total={pages.length - 1} currentPosition={page} color={config.opsColor} />
      </ReportsHeader>
      <FormProvider {...contactsMethods}>
        <StyledReportsBody
          isFinal={isFinalPage}
          id={containerId}
        >
          {
            !isFinalPage && pages.map((item, index)=>(
                <ScrollSection
                  key={item.id}
                  index={index}
                  item={item}
                  goNext={scrollNextDelay}
                  form={form}
                  setForm={setForm}
                  resetReport={resetReport}
                  layer={layer}
                  config={config}
                  t={t}
                />
            ))
          }
          {
            isFinalPage && <ScrollSection index={pages.length}>
              <ReportFinal closeModal={closeModal} resetReport={resetReport} startCounter={isFinalPage} t={t}/>
            </ScrollSection>
          }
        </StyledReportsBody>
        <Footer
          isFinal={isFinalPage}
          isNextActive={(canGoNext || isFinalPage) && currentPage?.component !== 'contacts'}
          nextTitle={page === pages.length ? t("report.final.button") : t("screen.popup.actions.next")}
          showSubmit={currentPage?.component === 'contacts'}
          showNext={currentPage?.component !== 'contacts'}
          isValid={contactsMethods.formState.isValid}
          onSubmit={contactsMethods.handleSubmit(handleFormSubmit)}
          onNextClick={()=>{
            if (isFinalPage) {
              resetReport();
            } else {
              goNext();
            }
          }}
          onPrevClick={page > 0 && goBack}
          t={t}
        />
      </FormProvider>
    </>
  );
};

export default WarehouseReport;