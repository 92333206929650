import React, {useEffect, useState} from "react";
import {CheckboxContainer, CheckboxInput, CheckboxLabel} from "./Checkbox-styled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getFormDataVal} from "../../helpers";

const Checkbox = ({ path, formProps }) => {
    const {t} = useTranslation('common')
    let {isSurvey, value, onChange} = formProps
    const { panelFormData } = useSelector((store) => store)
    const newValue = value ?? ((getFormDataVal(panelFormData, path)).value) ?? ""
    const [inputVal, setInputVal] = useState(newValue)
    const currentNode = (path.split(".")).pop()
    const label = t(`screen.panel.${path}.label`, t(`screen.component.checkbox.${currentNode}.label`) ?? "")

    useEffect( () => {
        if (!isSurvey)
            return
        onChange(inputVal, path)
    }, [inputVal])

    useEffect(() => {
        if (!formProps && !formProps.value)
            return
        setInputVal(formProps.value)
    }, [formProps])

    return (
        <CheckboxContainer>
            <CheckboxInput
                type="checkbox"
                value={inputVal}
                defaultChecked={newValue === currentNode}
                onChange={(e) => setInputVal(e.target.checked ? currentNode : "")}
            />
            {(label && label.length > 0) && <CheckboxLabel>{label}</CheckboxLabel>}
        </CheckboxContainer>
    )
}

export default Checkbox