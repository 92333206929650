import styled, {css} from "styled-components";
import {CalciteListItem} from "@esri/calcite-components-react";

const Container = styled.div`
    display: flex;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    flex-direction: column;
    box-shadow: 0 1px 2px rgb(0 0 0 / 30%) !important;

    & header {
        font-size: 16px;
        font-weight: 600;
        padding: 0 0 2px;
        text-align: center;
        width: 100%;
    }
`

const Step = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: ${(props) => props.borderless ? "none" : "1px solid rgb(221, 221, 221)"};
    padding-bottom: ${(props) => (props.borderless ? "0px" : "10px")};
    text-align: left;
`

const KeyValComboContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;

    & div[role="combobox"],
    & input[type="text"] {
        flex-grow: 1;
        max-width: calc(50% - 5px);
        margin-right: 6px;
    }
`

const ListItemStyled = styled(CalciteListItem)`
  ${props => props.active && css`
    background: #1d5d8c !important;
    color: white !important;
	outline: unset !important;
  `
  }
`

const StepHead = styled.div`
	display: flex;
  	flex-direction: row;
  	justify-content: space-between;
  	align-items: center;
`

const StepField = styled.div`
	display: flex;
  	flex-direction: column;
`

export { Container, Step, KeyValComboContainer, StepHead, StepField, ListItemStyled}
