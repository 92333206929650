import styled from "styled-components";

export const StyledArrowButton = styled.button`
  min-height: 34px;
  text-decoration: none;
  border-width: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${({disable, show})=> show ? 0 : disable ? 0.5 : 1};
  pointer-events: ${({disable, show})=> (show || disable )? 'none' : 'auto'};
  display: flex;
  align-items: center;
  border-radius: ${({ radius })=> radius ? `${radius}px` : '' };
  background-color:${({ bg })=> bg ? `${bg}e6` : 'transparent'};
  color: #fff;
  
  &:hover {
    background-color:${({ bg })=> bg };
  }

  :disabled {
    background-color:${({ bg })=> bg ? `${bg}7f` : '#ffffff7f' };
  }
`

export const StyledSubmitButton = styled.button`
  line-height: normal;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-weight: 500;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-transform: none;
  border-style: solid;
  border-width: 0;
  border-color: transparent;
  background-color: ${({bg})=> bg ? `${bg}e6` : '#b21b0ce6'};
  min-height: 34px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  opacity: ${({disable})=> disable ? 0.7 : 1};
  pointer-events: ${({disable})=> disable ? 'none' : 'auto'};
  transition: all 0.5s;
  border-radius: 9999px;
  padding: 0 16px 0 16px;
  min-width: 78px;

  &:hover {
    background-color: ${({bg})=> bg ? bg : '#b21b0c'};
  }
`

export const StyledFooter = styled.div`
  position: sticky;
  width: 100%;
  flex: 0 0 2;
  margin-top: auto;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const StyledFooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 7px;
  height: 48px;
  border-top: 1px solid #DFE6EB;
`
