import styled from "styled-components";
import {mutedColor} from "../../../../utils/Theme";
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;

  & input {
    display: block;
    padding: 6px 8px;
    cursor: auto;
    color: #000000;
    border: ${({borderColor})=>`1px solid ${borderColor ?? '#C0392B'}4D`};
    outline: 0;
    font-size: 14px;
    line-height: 2em;
    text-overflow: ellipsis;
    transition: all 0.5s;
    border-radius: 6px;
  }
  
  & input::placeholder {
    opacity: 0;
    transition: all 0.7s;
  }
  
  & input:focus {
    border: ${({borderColor})=>`1px solid ${borderColor ?? '#C0392B'}`};;
    ::placeholder {
      opacity: 1;
    }
  }
  
  input:not(:placeholder-shown) + label {
    top: -9px;
    font-size: 10px;
  }
  
  & input:focus + label {
    top: -9px;
    font-size: 10px;
  }
    
`
export const InputLabel = styled.label`
  font-family: 'Open Sans', sans-serif !important;
  width: auto;
  padding: 0px 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.5em;
  color: ${mutedColor};
	text-align: left;
  border-radius: 4px;
  pointer-events: none;
  transition: all 0.5s;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #FFFFFF;
`

export const WarningMessage = styled.span`
  position: absolute;
  bottom: -22px;
  font-size: 10px !important;
  color: #b21b0c;
  opacity: ${({isError})=> isError ? 1 : 0};
  transform: ${({isError})=> isError ? 'translateY(0px)' : 'translateY(5px)'};
  transition: all 0.5s;
  width: 100%;
  text-align: end;
`