import styled from "styled-components";

export const HeaderContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${props => props.isEditorMode ? "#000" : "#fff"};
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  ${({isEmbed})=> isEmbed ? `
    a {
      pointer-events:none !important;
    }
  ` : ''}

  & svg {
    path {
      fill: ${props => props.isEditorMode && "#fff"};
    }
  }
  
  @media screen and (max-width: 769px) {
    max-width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const HeaderLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: ${({isEmbed}) => isEmbed ? 31 : 70}px;
  
  @media screen and (min-width: 769px) {
    height: 100%;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  padding: 0.4em 0.8em;
  align-items: center;
  
  img {
    height: 20px;
  }
  
  &a{
	display: flex;
  }

  @media screen and (min-width: 769px) {
    svg#logisticsClusterSmallIcon {
      display: none;
    }
  }
  
  @media screen and (max-width: 768px) {
    padding: 1.1em 0.8em;
	svg {
		height: 20px;
	  	&#logisticsClusterIcon {
			display: none;
	  	}
    }
  }
  &.hiddenOnSmall {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    svg {
      width: 42px;
      height: 42px;
    }
  }
`;

export const HeaderCenter = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  height: 100%;
  gap: 0.5em;
  margin-right: ${({hasModules})=>hasModules ? '0.8em' : ''};
  
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;


export const ModuleWrapper = styled.div`
  flex-grow: 4;
  display: flex;
  justify-content: flex-start;
  gap: 0.8em;
  padding-top: 0.2em;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-grow: 1 ;
  flex-direction: row;
  justify-content: ${({center})=>center ? 'center' : 'flex-end'};
  align-items: center;
  gap: 4px;
  
  @media screen and (max-width: 768px) {
    padding: 0;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    gap: 0.2em;
    justify-content: flex-end;
    
	& > h2 {
      display: ${({isEmbed})=> isEmbed ? 'none' : 'flex'};
      height: 100%;
      align-items: center;
      font-size: ${({isLong})=>isLong ? '18px' : '21px'};
      font-weight: 500;
    }

    & > h3, .subtitle{
      font-size: ${({isEmbed, isSubtitleLong})=>isEmbed || isSubtitleLong ? '12px' : '14px'};
      margin-bottom: 2px;
  	}
    
    & > h2, & > h3, .subtitle {
      text-align: center;
    }
  }
`;

export const Title = styled.h2`
  color: ${props => props.isEditorMode ? "#FFF" : "#393738"};
  line-height: 1;
  font-weight: 400;
  font-size: 24px;
  max-width: max-content;
  text-align: right;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 768px) and (max-width: 1210px){
    font-size: 20px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 1.2em;
  }
  @media screen and (max-width: 450px) {
    font-size: 1em;
  }
`;

export const Subtitle = styled.h3`
	max-width: max-content;
	font-size: 12px;
	line-height: 1;
	color: #8c9ba5;
	margin: 0;
	font-weight: 400;
`;

export const IconVisibleWrapper = styled.div`
  padding: 0.4em 0.8em;
  display: flex;
  @media screen and (max-width: 768px) {
	& svg{
	  height: ${({isEmbed})=>isEmbed ? '10px' : '12px'};
    path {
      fill: ${({isEmbed, opsColor, isEditorMode})=> isEditorMode ? '#fff' : isEmbed && opsColor ? opsColor : ''};
    }
	}
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const GetStartedButton = styled.button`
  padding: 4px 16px;
  gap: 8px;
  background: #CFE8FF;
  border: 1px solid #3B94D3;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #3B94D3;
 
  @media screen and (max-width: 1120px) {
    margin: 4px 0;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Separator = styled.div`
  height: 31px;
  left: 144px;
  top: 31.06px;
  margin: 0 24px 0;

  border: 1px solid #C5CDD2;
  
  @media screen and (max-width: 1120px) {
    margin: 0 8px 0;
  }
    @media screen and (max-width: 1070px) {
    margin-right: 8px;
  }
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeaderSlideContent = styled.div`
  &.subtitle {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  color: ${({ active }) => (active ? (props) => props.opsColor : "#6F7072")};
  font-weight: ${({ isEmbed }) => (isEmbed ? "500": "700")};
  font-size: 0.8em;
  line-height: normal;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: ${({isEmbed})=> isEmbed ? '7' : '4'}px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    display: ${({ active, isEmbed }) => (active && !isEmbed ? "block" : "none")};
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.opsColor};
    top: calc(100% + 2px);
    left: 0;
  }

  @media screen and (min-width: 769px) and (max-width:1210px) {
    font-size: 0.7em;
  }

`;

export const SectionItem = styled.div`
  color: ${({selected = true})=> selected ? '#525252' : '#8a8a8a'};
  display: flex;
  height: 40px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  padding: 0 10px;
  gap:12px;
  transition: all 0.2s;
  background: ${({selected, opsColor = '#8080801a'})=>selected ? `${opsColor}` : ''};
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
 
  ${({selected})=> !selected ? `
    :hover {
     background: #8080801a;
    }
  ` : ''};
  
   svg {
     border-radius: 2px;
     height: 24px;
     width: 24px;
     
     path {
       fill: ${({fill = true, selected = true, opsColor = '#525252'})=> fill ? selected ? `${opsColor}` : '#8a8a8a' : ''};
     }
   }
  
  p {
    flex:1;
    margin-top: 0;
  }
  
  p {
    font-weight: 500;
    font-size: 12px;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: ${({expand = true})=> expand ? 'block' : 'none'};
    transition: all 0.3s;
  }
`

export const HelpDropdownListItemIcon = styled.div`
  width: 14px;
  height: 14px;
  svg {
    path {
      //fill: #525252;
    }
  }
`

export const HelpDropdownListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`