import { loadModules, setDefaultOptions } from "esri-loader";
import { setAsyncError } from "../utils/helper";
setDefaultOptions({ css: true });

export const createOAuth = async setError => {
	try {
		loadModules(["esri/identity/OAuthInfo", "esri/identity/IdentityManager"]).then(([OAuthInfo, esriId]) => {
			const info = new OAuthInfo({
				appId: "NUbgskpZPx5um9Wc",
				popup: false
			})

			esriId.registerOAuthInfos([info])
			return info
		})
	} catch (err) {
    	setAsyncError(err, setError)
	}
}
