import React, { useState, useCallback, useEffect } from "react";
import { view } from "../../utils/API";
import { ContextMenuContainer, ContextMenuContent, ContextMenuList, ContextMenuListItem } from './ContextMenu-styled';
import { useTranslation } from "react-i18next";
import { useSnackbar } from '../SnackBar'

const useContextMenu = () => {
	const [xPos, setXPos] = useState("0px");
	const [yPos, setYPos] = useState("0px");
	const [coordinates, setCoordinates] = useState();
	const [showMenu, setShowMenu] = useState(false);
	const [, closeSnackbar] = useSnackbar();

	const handleMapClick = (event) => {
		if (event.button === 2) {
			const lat = parseFloat(event.mapPoint.latitude).toFixed(5);
			const lon = parseFloat(event.mapPoint.longitude).toFixed(5);
			setCoordinates(`${lat}, ${lon}`)
		}
	}

	const _closeAndClear = useCallback(() => {
		setShowMenu(false);
		setCoordinates('');
		closeSnackbar();
	}, [closeSnackbar])

	const handleContextMenu = useCallback(
		(event) => {
			_closeAndClear();
			// check if right click was done inside map
			if (event.target.tagName.toLowerCase() !== 'canvas'
				&&
				(!Array.from(event.target.children).find(i => i.tagName.toLowerCase() === 'canvas'))) {
				return;
			}
			event.preventDefault();
			setXPos(`${event.pageX}px`);
			setYPos(`${event.pageY}px`);
			setShowMenu(true);
		},
		[_closeAndClear]
	);

	const validateCondition = useCallback((cond = true) => {
		if (showMenu && cond) {
			_closeAndClear();

		}
	}, [_closeAndClear, showMenu]);

	const handleClick = useCallback((event) => {
		validateCondition(event.target.localName !== 'li')
	}, [validateCondition]);

	const handleZoom = useCallback(() => {
		validateCondition()
	}, [validateCondition])

	useEffect(() => {
		document.addEventListener("click", handleClick);
		if (view) {
			view.on('click', handleMapClick)
			view.watch("scale", handleZoom)
		}
		document.addEventListener("contextmenu", handleContextMenu);
		return () => {
			document.addEventListener("click", handleClick);
			document.removeEventListener("contextmenu", handleContextMenu);
		};
	}, [handleClick, handleContextMenu, handleZoom]);

	return { xPos, yPos, showMenu, coordinates, closeMenu: _closeAndClear };
};

const ContextMenu = () => {
	const { xPos, yPos, showMenu, coordinates, closeMenu } = useContextMenu();
	const { t } = useTranslation('common');
	const [openSnackbar] = useSnackbar();

	const handleCoordinatesClick = async () => {
		try {
			await navigator.clipboard.writeText(coordinates);
			closeMenu();
			openSnackbar(t("screen.message.copied"), 15000);
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<ContextMenuContainer yPos={yPos} xPos={xPos}>
			{showMenu && coordinates ? (
				<ContextMenuContent>
					<ContextMenuList>
						<ContextMenuListItem onClick={handleCoordinatesClick}>
							{coordinates}
						</ContextMenuListItem>
					</ContextMenuList>
				</ContextMenuContent>
			) : (
				<></>
			)
			}
		</ContextMenuContainer>
	);
};

export default ContextMenu;
