import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {chevronRight24} from "@esri/calcite-ui-icons";
import {Swiper, SwiperSlide} from "swiper/react";
import {useDispatch} from "react-redux";
import {view} from "../../../../utils/API";
import ClusterList from "./ClusterList";
import {renderCell} from "../../../../utils/helper";
import {
  StyledCustomPopupWrapperContainer,
  StyledPopUpTitle,
  StyledPopupTitleWrapper,
} from "../../CustomPopupWrapper-styled";
import {setClusterFeature} from "../../../../redux/action/ClusterFeature-action";
import getAllowedFields from "../../helpers/getAllowedFields";
import {StyledNavButton} from "../../style/ClusterDetails/ClusterDetails-styled";
import getClusterAttributeFields from "../../helpers/getClusterAttributeFields";
import {EffectCreative} from "swiper/modules";

const Cluster = ({graphic, t, config, layer, initial = 0, clusterFeatures = []}) => {
  const [features, setFeatures] = useState([]);
  const [clusterField, setClusterField] = useState();
  const [clusterFieldTitle, setClusterFieldTitle] = useState();
  const cluster = layer.layerConfig.cluster;
  const ref = useRef();
  const dispatch = useDispatch();
  const handlePopupSetup = (features) => {
    let fieldFeature;
  
    if (features.length > 0 && graphic.isAggregate) {
      fieldFeature = features.find(feat => feat.attributes[cluster.clusterAttribute1]) ?? features[0]
    } else {
      fieldFeature = graphic;
    }
  
    setFeatures(features);
  
    const fields = getAllowedFields(layer, fieldFeature);
    if (fields.length > 0) {
      let clusterFields = getClusterAttributeFields(cluster, fields, t)
      setClusterField(clusterFields.field1)
      setClusterFieldTitle(clusterFields.fieldTitle1)
      if (!view.popup.title) {
        view.popup.title = clusterFields.fieldTitle1;
      }
    }
  }
  
  useEffect(()=>{
    // When returning from ClusterDetails popup we receive cluster features,
    // this is to fix issue after zooming in layerView.queryFeatures returns empty value
    if (clusterFeatures.length > 0) {
      handlePopupSetup(clusterFeatures)
      return;
    }
    
    view.whenLayerView(layer).then(async (layerView) => {
      try {
        const query = layerView.createQuery();
        query.aggregateIds = [graphic.getObjectId()];
      
        layerView.queryFeatures(query).then((result) => {
          handlePopupSetup(result.features.length > 0 && graphic.isAggregate ? result.features : [graphic])
        })
      } catch (err) {
        console.log(err)
      }
    })
  },[layer])
  
  const filteredFeatures = useMemo(() => {
    
    if (!cluster.clusterAttribute2) {
      return features;
    }
    
    if (!graphic.isAggregate) return [graphic]
    
    const set = new Set();
    return features.filter(feat => {
      const hasValue = set.has(feat.attributes[cluster.clusterAttribute1]);
    
      set.add(feat.attributes[cluster.clusterAttribute1])
      return !hasValue;
    })
  },[features, graphic])
  
  const saveClusterFeature = useCallback((feat, tabIndex = 0)=>{
    
    const title = cluster.clusterAttribute2
      ? renderCell(clusterField, feat.attributes[clusterField?.name], t, config)
      : clusterFieldTitle
    
    dispatch(setClusterFeature({
      graphic,
      title,
      tabIndex,
      features
    }))
  },[graphic, clusterField, t, config, clusterFieldTitle, features])
  
  const handleNavButtonClick = useCallback((feat, tabIndex)=>{
    if (cluster.clusterAttribute2) {
      view.popup.title = renderCell(clusterField, feat.attributes[clusterField?.name], t, config);
      ref.current?.slideTo(tabIndex);
    } else {
      saveClusterFeature(feat);
      view.openPopup({
        location: graphic.geometry,
        features:[feat],
        actions: []
      })
    }
  },[clusterField, t, config, ref, saveClusterFeature, view, graphic])
  if (!filteredFeatures.length) return null;
  
  return (
    <StyledCustomPopupWrapperContainer>
      <div style={{
        width:'100%',
        height:'100%',
        marginTop:12,
      }}>
        <Swiper
          style={{height:'100%'}}
          onSwiper={(s)=>{
            ref.current = s;
          }}
          allowTouchMove="false"
          preventInteractionOnTransition="true"
          noSwiping="true"
          effect={'creative'}
          creativeEffect={{
            prev: {
              translate: ['100%', 0, 0],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          modules={[EffectCreative]}
          initialSlide={initial}
        >
            <SwiperSlide
              style={{
                overflow:'auto',
                paddingBottom: 8,
                maxHeight: 183
              }}
              tabIndex={0}
            >
              {({ isActive }) => isActive && <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap:'4px'
                }}
              >
                <StyledPopupTitleWrapper style={{marginTop:6}}>
                  <StyledPopUpTitle style={{justifyContent:'center'}}>Total: {filteredFeatures.length}</StyledPopUpTitle>
                </StyledPopupTitleWrapper>
                {
                  filteredFeatures.map((feat, index)=>(
                    <StyledNavButton
                      key={feat.uid}
                      style={{width:'100%'}}
                      onClick={()=>handleNavButtonClick(feat, index + 1)}
                    >
                      <span>
                        {renderCell(clusterField, feat.attributes[clusterField?.name], t, config)}
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path d={chevronRight24} />
                      </svg>
                    </StyledNavButton>
                  ))
                }
              </div>
              }
            </SwiperSlide>
            {
              cluster.clusterAttribute2 && filteredFeatures.map((feat, index)=>(
                <SwiperSlide
                  key={feat.uid}
                  tabIndex={index + 1}
                  style={{
                    overflow:'auto',
                    paddingBottom: 8,
                    maxHeight: 173
                  }}
                >
                  {({ isActive }) => isActive && (
                      <ClusterList
                        t={t}
                        clusterFeatures={features}
                        config={config}
                        layer={layer}
                        feature={feat}
                        goBack={()=>{
                          ref.current?.slideTo(0)
                        }}
                        savePopup={() => saveClusterFeature(feat, index + 1)}
                      />
                  )}
                </SwiperSlide>
              ))
            }
        </Swiper>
      </div>
    </StyledCustomPopupWrapperContainer>
  );
};

export default Cluster;