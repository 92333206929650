import {setLatestUpdates_actionType} from "../constants";
const initialState = false;

const showLatestUpdates = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case setLatestUpdates_actionType: {
      return state = payload;
    }
    default:
      return state;
  }
};

export default showLatestUpdates;