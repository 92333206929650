export const countryCodes = [{
	"name": "Afghanistan",
	"ISO2": "AF",
	"ISO3": "AFG",
	"country-code": "004",
	"iso_3166-2": "ISO 3166-2:AF",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Åland Islands",
	"ISO2": "AX",
	"ISO3": "ALA",
	"country-code": "248",
	"iso_3166-2": "ISO 3166-2:AX",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Albania",
	"ISO2": "AL",
	"ISO3": "ALB",
	"country-code": "008",
	"iso_3166-2": "ISO 3166-2:AL",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Algeria",
	"ISO2": "DZ",
	"ISO3": "DZA",
	"country-code": "012",
	"iso_3166-2": "ISO 3166-2:DZ",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "American Samoa",
	"ISO2": "AS",
	"ISO3": "ASM",
	"country-code": "016",
	"iso_3166-2": "ISO 3166-2:AS",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Andorra",
	"ISO2": "AD",
	"ISO3": "AND",
	"country-code": "020",
	"iso_3166-2": "ISO 3166-2:AD",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Angola",
	"ISO2": "AO",
	"ISO3": "AGO",
	"country-code": "024",
	"iso_3166-2": "ISO 3166-2:AO",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Anguilla",
	"ISO2": "AI",
	"ISO3": "AIA",
	"country-code": "660",
	"iso_3166-2": "ISO 3166-2:AI",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Antarctica",
	"ISO2": "AQ",
	"ISO3": "ATA",
	"country-code": "010",
	"iso_3166-2": "ISO 3166-2:AQ",
	"region": "",
	"sub-region": "",
	"intermediate-region": "",
	"region-code": "",
	"sub-region-code": "",
	"intermediate-region-code": ""
}, {
	"name": "Antigua and Barbuda",
	"ISO2": "AG",
	"ISO3": "ATG",
	"country-code": "028",
	"iso_3166-2": "ISO 3166-2:AG",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Argentina",
	"ISO2": "AR",
	"ISO3": "ARG",
	"country-code": "032",
	"iso_3166-2": "ISO 3166-2:AR",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Armenia",
	"ISO2": "AM",
	"ISO3": "ARM",
	"country-code": "051",
	"iso_3166-2": "ISO 3166-2:AM",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Aruba",
	"ISO2": "AW",
	"ISO3": "ABW",
	"country-code": "533",
	"iso_3166-2": "ISO 3166-2:AW",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Australia",
	"ISO2": "AU",
	"ISO3": "AUS",
	"country-code": "036",
	"iso_3166-2": "ISO 3166-2:AU",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "Austria",
	"ISO2": "AT",
	"ISO3": "AUT",
	"country-code": "040",
	"iso_3166-2": "ISO 3166-2:AT",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Azerbaijan",
	"ISO2": "AZ",
	"ISO3": "AZE",
	"country-code": "031",
	"iso_3166-2": "ISO 3166-2:AZ",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Bahamas",
	"ISO2": "BS",
	"ISO3": "BHS",
	"country-code": "044",
	"iso_3166-2": "ISO 3166-2:BS",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Bahrain",
	"ISO2": "BH",
	"ISO3": "BHR",
	"country-code": "048",
	"iso_3166-2": "ISO 3166-2:BH",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Bangladesh",
	"ISO2": "BD",
	"ISO3": "BGD",
	"country-code": "050",
	"iso_3166-2": "ISO 3166-2:BD",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Barbados",
	"ISO2": "BB",
	"ISO3": "BRB",
	"country-code": "052",
	"iso_3166-2": "ISO 3166-2:BB",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Belarus",
	"ISO2": "BY",
	"ISO3": "BLR",
	"country-code": "112",
	"iso_3166-2": "ISO 3166-2:BY",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Belgium",
	"ISO2": "BE",
	"ISO3": "BEL",
	"country-code": "056",
	"iso_3166-2": "ISO 3166-2:BE",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Belize",
	"ISO2": "BZ",
	"ISO3": "BLZ",
	"country-code": "084",
	"iso_3166-2": "ISO 3166-2:BZ",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Benin",
	"ISO2": "BJ",
	"ISO3": "BEN",
	"country-code": "204",
	"iso_3166-2": "ISO 3166-2:BJ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Bermuda",
	"ISO2": "BM",
	"ISO3": "BMU",
	"country-code": "060",
	"iso_3166-2": "ISO 3166-2:BM",
	"region": "Americas",
	"sub-region": "Northern America",
	"intermediate-region": "",
	"region-code": "019",
	"sub-region-code": "021",
	"intermediate-region-code": ""
}, {
	"name": "Bhutan",
	"ISO2": "BT",
	"ISO3": "BTN",
	"country-code": "064",
	"iso_3166-2": "ISO 3166-2:BT",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Bolivia (Plurinational State of)",
	"ISO2": "BO",
	"ISO3": "BOL",
	"country-code": "068",
	"iso_3166-2": "ISO 3166-2:BO",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Bonaire, Sint Eustatius and Saba",
	"ISO2": "BQ",
	"ISO3": "BES",
	"country-code": "535",
	"iso_3166-2": "ISO 3166-2:BQ",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Bosnia and Herzegovina",
	"ISO2": "BA",
	"ISO3": "BIH",
	"country-code": "070",
	"iso_3166-2": "ISO 3166-2:BA",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Botswana",
	"ISO2": "BW",
	"ISO3": "BWA",
	"country-code": "072",
	"iso_3166-2": "ISO 3166-2:BW",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Southern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "018"
}, {
	"name": "Bouvet Island",
	"ISO2": "BV",
	"ISO3": "BVT",
	"country-code": "074",
	"iso_3166-2": "ISO 3166-2:BV",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Brazil",
	"ISO2": "BR",
	"ISO3": "BRA",
	"country-code": "076",
	"iso_3166-2": "ISO 3166-2:BR",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "British Indian Ocean Territory",
	"ISO2": "IO",
	"ISO3": "IOT",
	"country-code": "086",
	"iso_3166-2": "ISO 3166-2:IO",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Brunei Darussalam",
	"ISO2": "BN",
	"ISO3": "BRN",
	"country-code": "096",
	"iso_3166-2": "ISO 3166-2:BN",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Bulgaria",
	"ISO2": "BG",
	"ISO3": "BGR",
	"country-code": "100",
	"iso_3166-2": "ISO 3166-2:BG",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Burkina Faso",
	"ISO2": "BF",
	"ISO3": "BFA",
	"country-code": "854",
	"iso_3166-2": "ISO 3166-2:BF",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Burundi",
	"ISO2": "BI",
	"ISO3": "BDI",
	"country-code": "108",
	"iso_3166-2": "ISO 3166-2:BI",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Cabo Verde",
	"ISO2": "CV",
	"ISO3": "CPV",
	"country-code": "132",
	"iso_3166-2": "ISO 3166-2:CV",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Cambodia",
	"ISO2": "KH",
	"ISO3": "KHM",
	"country-code": "116",
	"iso_3166-2": "ISO 3166-2:KH",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Cameroon",
	"ISO2": "CM",
	"ISO3": "CMR",
	"country-code": "120",
	"iso_3166-2": "ISO 3166-2:CM",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Canada",
	"ISO2": "CA",
	"ISO3": "CAN",
	"country-code": "124",
	"iso_3166-2": "ISO 3166-2:CA",
	"region": "Americas",
	"sub-region": "Northern America",
	"intermediate-region": "",
	"region-code": "019",
	"sub-region-code": "021",
	"intermediate-region-code": ""
}, {
	"name": "Cayman Islands",
	"ISO2": "KY",
	"ISO3": "CYM",
	"country-code": "136",
	"iso_3166-2": "ISO 3166-2:KY",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Central African Republic",
	"ISO2": "CF",
	"ISO3": "CAF",
	"country-code": "140",
	"iso_3166-2": "ISO 3166-2:CF",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Chad",
	"ISO2": "TD",
	"ISO3": "TCD",
	"country-code": "148",
	"iso_3166-2": "ISO 3166-2:TD",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Chile",
	"ISO2": "CL",
	"ISO3": "CHL",
	"country-code": "152",
	"iso_3166-2": "ISO 3166-2:CL",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "China",
	"ISO2": "CN",
	"ISO3": "CHN",
	"country-code": "156",
	"iso_3166-2": "ISO 3166-2:CN",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Christmas Island",
	"ISO2": "CX",
	"ISO3": "CXR",
	"country-code": "162",
	"iso_3166-2": "ISO 3166-2:CX",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "Cocos (Keeling) Islands",
	"ISO2": "CC",
	"ISO3": "CCK",
	"country-code": "166",
	"iso_3166-2": "ISO 3166-2:CC",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "Colombia",
	"ISO2": "CO",
	"ISO3": "COL",
	"country-code": "170",
	"iso_3166-2": "ISO 3166-2:CO",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Comoros",
	"ISO2": "KM",
	"ISO3": "COM",
	"country-code": "174",
	"iso_3166-2": "ISO 3166-2:KM",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Congo",
	"ISO2": "CG",
	"ISO3": "COG",
	"country-code": "178",
	"iso_3166-2": "ISO 3166-2:CG",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Congo, Democratic Republic of the",
	"ISO2": "CD",
	"ISO3": "COD",
	"country-code": "180",
	"iso_3166-2": "ISO 3166-2:CD",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Cook Islands",
	"ISO2": "CK",
	"ISO3": "COK",
	"country-code": "184",
	"iso_3166-2": "ISO 3166-2:CK",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Costa Rica",
	"ISO2": "CR",
	"ISO3": "CRI",
	"country-code": "188",
	"iso_3166-2": "ISO 3166-2:CR",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Côte d'Ivoire",
	"ISO2": "CI",
	"ISO3": "CIV",
	"country-code": "384",
	"iso_3166-2": "ISO 3166-2:CI",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Croatia",
	"ISO2": "HR",
	"ISO3": "HRV",
	"country-code": "191",
	"iso_3166-2": "ISO 3166-2:HR",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Cuba",
	"ISO2": "CU",
	"ISO3": "CUB",
	"country-code": "192",
	"iso_3166-2": "ISO 3166-2:CU",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Curaçao",
	"ISO2": "CW",
	"ISO3": "CUW",
	"country-code": "531",
	"iso_3166-2": "ISO 3166-2:CW",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Cyprus",
	"ISO2": "CY",
	"ISO3": "CYP",
	"country-code": "196",
	"iso_3166-2": "ISO 3166-2:CY",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Czechia",
	"ISO2": "CZ",
	"ISO3": "CZE",
	"country-code": "203",
	"iso_3166-2": "ISO 3166-2:CZ",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Denmark",
	"ISO2": "DK",
	"ISO3": "DNK",
	"country-code": "208",
	"iso_3166-2": "ISO 3166-2:DK",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Djibouti",
	"ISO2": "DJ",
	"ISO3": "DJI",
	"country-code": "262",
	"iso_3166-2": "ISO 3166-2:DJ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Dominica",
	"ISO2": "DM",
	"ISO3": "DMA",
	"country-code": "212",
	"iso_3166-2": "ISO 3166-2:DM",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Dominican Republic",
	"ISO2": "DO",
	"ISO3": "DOM",
	"country-code": "214",
	"iso_3166-2": "ISO 3166-2:DO",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Ecuador",
	"ISO2": "EC",
	"ISO3": "ECU",
	"country-code": "218",
	"iso_3166-2": "ISO 3166-2:EC",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Egypt",
	"ISO2": "EG",
	"ISO3": "EGY",
	"country-code": "818",
	"iso_3166-2": "ISO 3166-2:EG",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "El Salvador",
	"ISO2": "SV",
	"ISO3": "SLV",
	"country-code": "222",
	"iso_3166-2": "ISO 3166-2:SV",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Equatorial Guinea",
	"ISO2": "GQ",
	"ISO3": "GNQ",
	"country-code": "226",
	"iso_3166-2": "ISO 3166-2:GQ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Eritrea",
	"ISO2": "ER",
	"ISO3": "ERI",
	"country-code": "232",
	"iso_3166-2": "ISO 3166-2:ER",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Estonia",
	"ISO2": "EE",
	"ISO3": "EST",
	"country-code": "233",
	"iso_3166-2": "ISO 3166-2:EE",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Eswatini",
	"ISO2": "SZ",
	"ISO3": "SWZ",
	"country-code": "748",
	"iso_3166-2": "ISO 3166-2:SZ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Southern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "018"
}, {
	"name": "Ethiopia",
	"ISO2": "ET",
	"ISO3": "ETH",
	"country-code": "231",
	"iso_3166-2": "ISO 3166-2:ET",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Falkland Islands (Malvinas)",
	"ISO2": "FK",
	"ISO3": "FLK",
	"country-code": "238",
	"iso_3166-2": "ISO 3166-2:FK",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Faroe Islands",
	"ISO2": "FO",
	"ISO3": "FRO",
	"country-code": "234",
	"iso_3166-2": "ISO 3166-2:FO",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Fiji",
	"ISO2": "FJ",
	"ISO3": "FJI",
	"country-code": "242",
	"iso_3166-2": "ISO 3166-2:FJ",
	"region": "Oceania",
	"sub-region": "Melanesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "054",
	"intermediate-region-code": ""
}, {
	"name": "Finland",
	"ISO2": "FI",
	"ISO3": "FIN",
	"country-code": "246",
	"iso_3166-2": "ISO 3166-2:FI",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "France",
	"ISO2": "FR",
	"ISO3": "FRA",
	"country-code": "250",
	"iso_3166-2": "ISO 3166-2:FR",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "French Guiana",
	"ISO2": "GF",
	"ISO3": "GUF",
	"country-code": "254",
	"iso_3166-2": "ISO 3166-2:GF",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "French Polynesia",
	"ISO2": "PF",
	"ISO3": "PYF",
	"country-code": "258",
	"iso_3166-2": "ISO 3166-2:PF",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "French Southern Territories",
	"ISO2": "TF",
	"ISO3": "ATF",
	"country-code": "260",
	"iso_3166-2": "ISO 3166-2:TF",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Gabon",
	"ISO2": "GA",
	"ISO3": "GAB",
	"country-code": "266",
	"iso_3166-2": "ISO 3166-2:GA",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Gambia",
	"ISO2": "GM",
	"ISO3": "GMB",
	"country-code": "270",
	"iso_3166-2": "ISO 3166-2:GM",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Georgia",
	"ISO2": "GE",
	"ISO3": "GEO",
	"country-code": "268",
	"iso_3166-2": "ISO 3166-2:GE",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Germany",
	"ISO2": "DE",
	"ISO3": "DEU",
	"country-code": "276",
	"iso_3166-2": "ISO 3166-2:DE",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Ghana",
	"ISO2": "GH",
	"ISO3": "GHA",
	"country-code": "288",
	"iso_3166-2": "ISO 3166-2:GH",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Gibraltar",
	"ISO2": "GI",
	"ISO3": "GIB",
	"country-code": "292",
	"iso_3166-2": "ISO 3166-2:GI",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Greece",
	"ISO2": "GR",
	"ISO3": "GRC",
	"country-code": "300",
	"iso_3166-2": "ISO 3166-2:GR",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Greenland",
	"ISO2": "GL",
	"ISO3": "GRL",
	"country-code": "304",
	"iso_3166-2": "ISO 3166-2:GL",
	"region": "Americas",
	"sub-region": "Northern America",
	"intermediate-region": "",
	"region-code": "019",
	"sub-region-code": "021",
	"intermediate-region-code": ""
}, {
	"name": "Grenada",
	"ISO2": "GD",
	"ISO3": "GRD",
	"country-code": "308",
	"iso_3166-2": "ISO 3166-2:GD",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Guadeloupe",
	"ISO2": "GP",
	"ISO3": "GLP",
	"country-code": "312",
	"iso_3166-2": "ISO 3166-2:GP",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Guam",
	"ISO2": "GU",
	"ISO3": "GUM",
	"country-code": "316",
	"iso_3166-2": "ISO 3166-2:GU",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Guatemala",
	"ISO2": "GT",
	"ISO3": "GTM",
	"country-code": "320",
	"iso_3166-2": "ISO 3166-2:GT",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Guernsey",
	"ISO2": "GG",
	"ISO3": "GGY",
	"country-code": "831",
	"iso_3166-2": "ISO 3166-2:GG",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "Channel Islands",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": "830"
}, {
	"name": "Guinea",
	"ISO2": "GN",
	"ISO3": "GIN",
	"country-code": "324",
	"iso_3166-2": "ISO 3166-2:GN",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Guinea-Bissau",
	"ISO2": "GW",
	"ISO3": "GNB",
	"country-code": "624",
	"iso_3166-2": "ISO 3166-2:GW",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Guyana",
	"ISO2": "GY",
	"ISO3": "GUY",
	"country-code": "328",
	"iso_3166-2": "ISO 3166-2:GY",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Haiti",
	"ISO2": "HT",
	"ISO3": "HTI",
	"country-code": "332",
	"iso_3166-2": "ISO 3166-2:HT",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Heard Island and McDonald Islands",
	"ISO2": "HM",
	"ISO3": "HMD",
	"country-code": "334",
	"iso_3166-2": "ISO 3166-2:HM",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "Holy See",
	"ISO2": "VA",
	"ISO3": "VAT",
	"country-code": "336",
	"iso_3166-2": "ISO 3166-2:VA",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Honduras",
	"ISO2": "HN",
	"ISO3": "HND",
	"country-code": "340",
	"iso_3166-2": "ISO 3166-2:HN",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Hong Kong",
	"ISO2": "HK",
	"ISO3": "HKG",
	"country-code": "344",
	"iso_3166-2": "ISO 3166-2:HK",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Hungary",
	"ISO2": "HU",
	"ISO3": "HUN",
	"country-code": "348",
	"iso_3166-2": "ISO 3166-2:HU",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Iceland",
	"ISO2": "IS",
	"ISO3": "ISL",
	"country-code": "352",
	"iso_3166-2": "ISO 3166-2:IS",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "India",
	"ISO2": "IN",
	"ISO3": "IND",
	"country-code": "356",
	"iso_3166-2": "ISO 3166-2:IN",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Indonesia",
	"ISO2": "ID",
	"ISO3": "IDN",
	"country-code": "360",
	"iso_3166-2": "ISO 3166-2:ID",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Iran (Islamic Republic of)",
	"ISO2": "IR",
	"ISO3": "IRN",
	"country-code": "364",
	"iso_3166-2": "ISO 3166-2:IR",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Iraq",
	"ISO2": "IQ",
	"ISO3": "IRQ",
	"country-code": "368",
	"iso_3166-2": "ISO 3166-2:IQ",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Ireland",
	"ISO2": "IE",
	"ISO3": "IRL",
	"country-code": "372",
	"iso_3166-2": "ISO 3166-2:IE",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Isle of Man",
	"ISO2": "IM",
	"ISO3": "IMN",
	"country-code": "833",
	"iso_3166-2": "ISO 3166-2:IM",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Israel",
	"ISO2": "IL",
	"ISO3": "ISR",
	"country-code": "376",
	"iso_3166-2": "ISO 3166-2:IL",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Italy",
	"ISO2": "IT",
	"ISO3": "ITA",
	"country-code": "380",
	"iso_3166-2": "ISO 3166-2:IT",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Jamaica",
	"ISO2": "JM",
	"ISO3": "JAM",
	"country-code": "388",
	"iso_3166-2": "ISO 3166-2:JM",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Japan",
	"ISO2": "JP",
	"ISO3": "JPN",
	"country-code": "392",
	"iso_3166-2": "ISO 3166-2:JP",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Jersey",
	"ISO2": "JE",
	"ISO3": "JEY",
	"country-code": "832",
	"iso_3166-2": "ISO 3166-2:JE",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "Channel Islands",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": "830"
}, {
	"name": "Jordan",
	"ISO2": "JO",
	"ISO3": "JOR",
	"country-code": "400",
	"iso_3166-2": "ISO 3166-2:JO",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Kazakhstan",
	"ISO2": "KZ",
	"ISO3": "KAZ",
	"country-code": "398",
	"iso_3166-2": "ISO 3166-2:KZ",
	"region": "Asia",
	"sub-region": "Central Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "143",
	"intermediate-region-code": ""
}, {
	"name": "Kenya",
	"ISO2": "KE",
	"ISO3": "KEN",
	"country-code": "404",
	"iso_3166-2": "ISO 3166-2:KE",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Kiribati",
	"ISO2": "KI",
	"ISO3": "KIR",
	"country-code": "296",
	"iso_3166-2": "ISO 3166-2:KI",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Korea (Democratic People's Republic of)",
	"ISO2": "KP",
	"ISO3": "PRK",
	"country-code": "408",
	"iso_3166-2": "ISO 3166-2:KP",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Korea, Republic of",
	"ISO2": "KR",
	"ISO3": "KOR",
	"country-code": "410",
	"iso_3166-2": "ISO 3166-2:KR",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Kuwait",
	"ISO2": "KW",
	"ISO3": "KWT",
	"country-code": "414",
	"iso_3166-2": "ISO 3166-2:KW",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Kyrgyzstan",
	"ISO2": "KG",
	"ISO3": "KGZ",
	"country-code": "417",
	"iso_3166-2": "ISO 3166-2:KG",
	"region": "Asia",
	"sub-region": "Central Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "143",
	"intermediate-region-code": ""
}, {
	"name": "Lao People's Democratic Republic",
	"ISO2": "LA",
	"ISO3": "LAO",
	"country-code": "418",
	"iso_3166-2": "ISO 3166-2:LA",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Latvia",
	"ISO2": "LV",
	"ISO3": "LVA",
	"country-code": "428",
	"iso_3166-2": "ISO 3166-2:LV",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Lebanon",
	"ISO2": "LB",
	"ISO3": "LBN",
	"country-code": "422",
	"iso_3166-2": "ISO 3166-2:LB",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Lesotho",
	"ISO2": "LS",
	"ISO3": "LSO",
	"country-code": "426",
	"iso_3166-2": "ISO 3166-2:LS",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Southern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "018"
}, {
	"name": "Liberia",
	"ISO2": "LR",
	"ISO3": "LBR",
	"country-code": "430",
	"iso_3166-2": "ISO 3166-2:LR",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Libya",
	"ISO2": "LY",
	"ISO3": "LBY",
	"country-code": "434",
	"iso_3166-2": "ISO 3166-2:LY",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "Liechtenstein",
	"ISO2": "LI",
	"ISO3": "LIE",
	"country-code": "438",
	"iso_3166-2": "ISO 3166-2:LI",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Lithuania",
	"ISO2": "LT",
	"ISO3": "LTU",
	"country-code": "440",
	"iso_3166-2": "ISO 3166-2:LT",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Luxembourg",
	"ISO2": "LU",
	"ISO3": "LUX",
	"country-code": "442",
	"iso_3166-2": "ISO 3166-2:LU",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Macao",
	"ISO2": "MO",
	"ISO3": "MAC",
	"country-code": "446",
	"iso_3166-2": "ISO 3166-2:MO",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Madagascar",
	"ISO2": "MG",
	"ISO3": "MDG",
	"country-code": "450",
	"iso_3166-2": "ISO 3166-2:MG",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Malawi",
	"ISO2": "MW",
	"ISO3": "MWI",
	"country-code": "454",
	"iso_3166-2": "ISO 3166-2:MW",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Malaysia",
	"ISO2": "MY",
	"ISO3": "MYS",
	"country-code": "458",
	"iso_3166-2": "ISO 3166-2:MY",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Maldives",
	"ISO2": "MV",
	"ISO3": "MDV",
	"country-code": "462",
	"iso_3166-2": "ISO 3166-2:MV",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Mali",
	"ISO2": "ML",
	"ISO3": "MLI",
	"country-code": "466",
	"iso_3166-2": "ISO 3166-2:ML",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Malta",
	"ISO2": "MT",
	"ISO3": "MLT",
	"country-code": "470",
	"iso_3166-2": "ISO 3166-2:MT",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Marshall Islands",
	"ISO2": "MH",
	"ISO3": "MHL",
	"country-code": "584",
	"iso_3166-2": "ISO 3166-2:MH",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Martinique",
	"ISO2": "MQ",
	"ISO3": "MTQ",
	"country-code": "474",
	"iso_3166-2": "ISO 3166-2:MQ",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Mauritania",
	"ISO2": "MR",
	"ISO3": "MRT",
	"country-code": "478",
	"iso_3166-2": "ISO 3166-2:MR",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Mauritius",
	"ISO2": "MU",
	"ISO3": "MUS",
	"country-code": "480",
	"iso_3166-2": "ISO 3166-2:MU",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Mayotte",
	"ISO2": "YT",
	"ISO3": "MYT",
	"country-code": "175",
	"iso_3166-2": "ISO 3166-2:YT",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Mexico",
	"ISO2": "MX",
	"ISO3": "MEX",
	"country-code": "484",
	"iso_3166-2": "ISO 3166-2:MX",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Micronesia (Federated States of)",
	"ISO2": "FM",
	"ISO3": "FSM",
	"country-code": "583",
	"iso_3166-2": "ISO 3166-2:FM",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Moldova, Republic of",
	"ISO2": "MD",
	"ISO3": "MDA",
	"country-code": "498",
	"iso_3166-2": "ISO 3166-2:MD",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Monaco",
	"ISO2": "MC",
	"ISO3": "MCO",
	"country-code": "492",
	"iso_3166-2": "ISO 3166-2:MC",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Mongolia",
	"ISO2": "MN",
	"ISO3": "MNG",
	"country-code": "496",
	"iso_3166-2": "ISO 3166-2:MN",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Montenegro",
	"ISO2": "ME",
	"ISO3": "MNE",
	"country-code": "499",
	"iso_3166-2": "ISO 3166-2:ME",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Montserrat",
	"ISO2": "MS",
	"ISO3": "MSR",
	"country-code": "500",
	"iso_3166-2": "ISO 3166-2:MS",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Morocco",
	"ISO2": "MA",
	"ISO3": "MAR",
	"country-code": "504",
	"iso_3166-2": "ISO 3166-2:MA",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "Mozambique",
	"ISO2": "MZ",
	"ISO3": "MOZ",
	"country-code": "508",
	"iso_3166-2": "ISO 3166-2:MZ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Myanmar",
	"ISO2": "MM",
	"ISO3": "MMR",
	"country-code": "104",
	"iso_3166-2": "ISO 3166-2:MM",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Namibia",
	"ISO2": "NA",
	"ISO3": "NAM",
	"country-code": "516",
	"iso_3166-2": "ISO 3166-2:NA",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Southern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "018"
}, {
	"name": "Nauru",
	"ISO2": "NR",
	"ISO3": "NRU",
	"country-code": "520",
	"iso_3166-2": "ISO 3166-2:NR",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Nepal",
	"ISO2": "NP",
	"ISO3": "NPL",
	"country-code": "524",
	"iso_3166-2": "ISO 3166-2:NP",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Netherlands",
	"ISO2": "NL",
	"ISO3": "NLD",
	"country-code": "528",
	"iso_3166-2": "ISO 3166-2:NL",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "New Caledonia",
	"ISO2": "NC",
	"ISO3": "NCL",
	"country-code": "540",
	"iso_3166-2": "ISO 3166-2:NC",
	"region": "Oceania",
	"sub-region": "Melanesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "054",
	"intermediate-region-code": ""
}, {
	"name": "New Zealand",
	"ISO2": "NZ",
	"ISO3": "NZL",
	"country-code": "554",
	"iso_3166-2": "ISO 3166-2:NZ",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "Nicaragua",
	"ISO2": "NI",
	"ISO3": "NIC",
	"country-code": "558",
	"iso_3166-2": "ISO 3166-2:NI",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Niger",
	"ISO2": "NE",
	"ISO3": "NER",
	"country-code": "562",
	"iso_3166-2": "ISO 3166-2:NE",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Nigeria",
	"ISO2": "NG",
	"ISO3": "NGA",
	"country-code": "566",
	"iso_3166-2": "ISO 3166-2:NG",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Niue",
	"ISO2": "NU",
	"ISO3": "NIU",
	"country-code": "570",
	"iso_3166-2": "ISO 3166-2:NU",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Norfolk Island",
	"ISO2": "NF",
	"ISO3": "NFK",
	"country-code": "574",
	"iso_3166-2": "ISO 3166-2:NF",
	"region": "Oceania",
	"sub-region": "Australia and New Zealand",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "053",
	"intermediate-region-code": ""
}, {
	"name": "North Macedonia",
	"ISO2": "MK",
	"ISO3": "MKD",
	"country-code": "807",
	"iso_3166-2": "ISO 3166-2:MK",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Northern Mariana Islands",
	"ISO2": "MP",
	"ISO3": "MNP",
	"country-code": "580",
	"iso_3166-2": "ISO 3166-2:MP",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Norway",
	"ISO2": "NO",
	"ISO3": "NOR",
	"country-code": "578",
	"iso_3166-2": "ISO 3166-2:NO",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Oman",
	"ISO2": "OM",
	"ISO3": "OMN",
	"country-code": "512",
	"iso_3166-2": "ISO 3166-2:OM",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Pakistan",
	"ISO2": "PK",
	"ISO3": "PAK",
	"country-code": "586",
	"iso_3166-2": "ISO 3166-2:PK",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Palau",
	"ISO2": "PW",
	"ISO3": "PLW",
	"country-code": "585",
	"iso_3166-2": "ISO 3166-2:PW",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Palestine, State of",
	"ISO2": "PS",
	"ISO3": "PSE",
	"country-code": "275",
	"iso_3166-2": "ISO 3166-2:PS",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Panama",
	"ISO2": "PA",
	"ISO3": "PAN",
	"country-code": "591",
	"iso_3166-2": "ISO 3166-2:PA",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Central America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "013"
}, {
	"name": "Papua New Guinea",
	"ISO2": "PG",
	"ISO3": "PNG",
	"country-code": "598",
	"iso_3166-2": "ISO 3166-2:PG",
	"region": "Oceania",
	"sub-region": "Melanesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "054",
	"intermediate-region-code": ""
}, {
	"name": "Paraguay",
	"ISO2": "PY",
	"ISO3": "PRY",
	"country-code": "600",
	"iso_3166-2": "ISO 3166-2:PY",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Peru",
	"ISO2": "PE",
	"ISO3": "PER",
	"country-code": "604",
	"iso_3166-2": "ISO 3166-2:PE",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Philippines",
	"ISO2": "PH",
	"ISO3": "PHL",
	"country-code": "608",
	"iso_3166-2": "ISO 3166-2:PH",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Pitcairn",
	"ISO2": "PN",
	"ISO3": "PCN",
	"country-code": "612",
	"iso_3166-2": "ISO 3166-2:PN",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Poland",
	"ISO2": "PL",
	"ISO3": "POL",
	"country-code": "616",
	"iso_3166-2": "ISO 3166-2:PL",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Portugal",
	"ISO2": "PT",
	"ISO3": "PRT",
	"country-code": "620",
	"iso_3166-2": "ISO 3166-2:PT",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Puerto Rico",
	"ISO2": "PR",
	"ISO3": "PRI",
	"country-code": "630",
	"iso_3166-2": "ISO 3166-2:PR",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Qatar",
	"ISO2": "QA",
	"ISO3": "QAT",
	"country-code": "634",
	"iso_3166-2": "ISO 3166-2:QA",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Réunion",
	"ISO2": "RE",
	"ISO3": "REU",
	"country-code": "638",
	"iso_3166-2": "ISO 3166-2:RE",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Romania",
	"ISO2": "RO",
	"ISO3": "ROU",
	"country-code": "642",
	"iso_3166-2": "ISO 3166-2:RO",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Russian Federation",
	"ISO2": "RU",
	"ISO3": "RUS",
	"country-code": "643",
	"iso_3166-2": "ISO 3166-2:RU",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Rwanda",
	"ISO2": "RW",
	"ISO3": "RWA",
	"country-code": "646",
	"iso_3166-2": "ISO 3166-2:RW",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Saint Barthélemy",
	"ISO2": "BL",
	"ISO3": "BLM",
	"country-code": "652",
	"iso_3166-2": "ISO 3166-2:BL",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Saint Helena, Ascension and Tristan da Cunha",
	"ISO2": "SH",
	"ISO3": "SHN",
	"country-code": "654",
	"iso_3166-2": "ISO 3166-2:SH",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Saint Kitts and Nevis",
	"ISO2": "KN",
	"ISO3": "KNA",
	"country-code": "659",
	"iso_3166-2": "ISO 3166-2:KN",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Saint Lucia",
	"ISO2": "LC",
	"ISO3": "LCA",
	"country-code": "662",
	"iso_3166-2": "ISO 3166-2:LC",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Saint Martin (French part)",
	"ISO2": "MF",
	"ISO3": "MAF",
	"country-code": "663",
	"iso_3166-2": "ISO 3166-2:MF",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Saint Pierre and Miquelon",
	"ISO2": "PM",
	"ISO3": "SPM",
	"country-code": "666",
	"iso_3166-2": "ISO 3166-2:PM",
	"region": "Americas",
	"sub-region": "Northern America",
	"intermediate-region": "",
	"region-code": "019",
	"sub-region-code": "021",
	"intermediate-region-code": ""
}, {
	"name": "Saint Vincent and the Grenadines",
	"ISO2": "VC",
	"ISO3": "VCT",
	"country-code": "670",
	"iso_3166-2": "ISO 3166-2:VC",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Samoa",
	"ISO2": "WS",
	"ISO3": "WSM",
	"country-code": "882",
	"iso_3166-2": "ISO 3166-2:WS",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "San Marino",
	"ISO2": "SM",
	"ISO3": "SMR",
	"country-code": "674",
	"iso_3166-2": "ISO 3166-2:SM",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Sao Tome and Principe",
	"ISO2": "ST",
	"ISO3": "STP",
	"country-code": "678",
	"iso_3166-2": "ISO 3166-2:ST",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Middle Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "017"
}, {
	"name": "Saudi Arabia",
	"ISO2": "SA",
	"ISO3": "SAU",
	"country-code": "682",
	"iso_3166-2": "ISO 3166-2:SA",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Senegal",
	"ISO2": "SN",
	"ISO3": "SEN",
	"country-code": "686",
	"iso_3166-2": "ISO 3166-2:SN",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Serbia",
	"ISO2": "RS",
	"ISO3": "SRB",
	"country-code": "688",
	"iso_3166-2": "ISO 3166-2:RS",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Seychelles",
	"ISO2": "SC",
	"ISO3": "SYC",
	"country-code": "690",
	"iso_3166-2": "ISO 3166-2:SC",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Sierra Leone",
	"ISO2": "SL",
	"ISO3": "SLE",
	"country-code": "694",
	"iso_3166-2": "ISO 3166-2:SL",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Singapore",
	"ISO2": "SG",
	"ISO3": "SGP",
	"country-code": "702",
	"iso_3166-2": "ISO 3166-2:SG",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Sint Maarten (Dutch part)",
	"ISO2": "SX",
	"ISO3": "SXM",
	"country-code": "534",
	"iso_3166-2": "ISO 3166-2:SX",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Slovakia",
	"ISO2": "SK",
	"ISO3": "SVK",
	"country-code": "703",
	"iso_3166-2": "ISO 3166-2:SK",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "Slovenia",
	"ISO2": "SI",
	"ISO3": "SVN",
	"country-code": "705",
	"iso_3166-2": "ISO 3166-2:SI",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Solomon Islands",
	"ISO2": "SB",
	"ISO3": "SLB",
	"country-code": "090",
	"iso_3166-2": "ISO 3166-2:SB",
	"region": "Oceania",
	"sub-region": "Melanesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "054",
	"intermediate-region-code": ""
}, {
	"name": "Somalia",
	"ISO2": "SO",
	"ISO3": "SOM",
	"country-code": "706",
	"iso_3166-2": "ISO 3166-2:SO",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "South Africa",
	"ISO2": "ZA",
	"ISO3": "ZAF",
	"country-code": "710",
	"iso_3166-2": "ISO 3166-2:ZA",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Southern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "018"
}, {
	"name": "South Georgia and the South Sandwich Islands",
	"ISO2": "GS",
	"ISO3": "SGS",
	"country-code": "239",
	"iso_3166-2": "ISO 3166-2:GS",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "South Sudan",
	"ISO2": "SS",
	"ISO3": "SSD",
	"country-code": "728",
	"iso_3166-2": "ISO 3166-2:SS",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Spain",
	"ISO2": "ES",
	"ISO3": "ESP",
	"country-code": "724",
	"iso_3166-2": "ISO 3166-2:ES",
	"region": "Europe",
	"sub-region": "Southern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "039",
	"intermediate-region-code": ""
}, {
	"name": "Sri Lanka",
	"ISO2": "LK",
	"ISO3": "LKA",
	"country-code": "144",
	"iso_3166-2": "ISO 3166-2:LK",
	"region": "Asia",
	"sub-region": "Southern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "034",
	"intermediate-region-code": ""
}, {
	"name": "Sudan",
	"ISO2": "SD",
	"ISO3": "SDN",
	"country-code": "729",
	"iso_3166-2": "ISO 3166-2:SD",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "Suriname",
	"ISO2": "SR",
	"ISO3": "SUR",
	"country-code": "740",
	"iso_3166-2": "ISO 3166-2:SR",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Svalbard and Jan Mayen",
	"ISO2": "SJ",
	"ISO3": "SJM",
	"country-code": "744",
	"iso_3166-2": "ISO 3166-2:SJ",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Sweden",
	"ISO2": "SE",
	"ISO3": "SWE",
	"country-code": "752",
	"iso_3166-2": "ISO 3166-2:SE",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "Switzerland",
	"ISO2": "CH",
	"ISO3": "CHE",
	"country-code": "756",
	"iso_3166-2": "ISO 3166-2:CH",
	"region": "Europe",
	"sub-region": "Western Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "155",
	"intermediate-region-code": ""
}, {
	"name": "Syrian Arab Republic",
	"ISO2": "SY",
	"ISO3": "SYR",
	"country-code": "760",
	"iso_3166-2": "ISO 3166-2:SY",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Taiwan, Province of China",
	"ISO2": "TW",
	"ISO3": "TWN",
	"country-code": "158",
	"iso_3166-2": "ISO 3166-2:TW",
	"region": "Asia",
	"sub-region": "Eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "030",
	"intermediate-region-code": ""
}, {
	"name": "Tajikistan",
	"ISO2": "TJ",
	"ISO3": "TJK",
	"country-code": "762",
	"iso_3166-2": "ISO 3166-2:TJ",
	"region": "Asia",
	"sub-region": "Central Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "143",
	"intermediate-region-code": ""
}, {
	"name": "Tanzania, United Republic of",
	"ISO2": "TZ",
	"ISO3": "TZA",
	"country-code": "834",
	"iso_3166-2": "ISO 3166-2:TZ",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Thailand",
	"ISO2": "TH",
	"ISO3": "THA",
	"country-code": "764",
	"iso_3166-2": "ISO 3166-2:TH",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Timor-Leste",
	"ISO2": "TL",
	"ISO3": "TLS",
	"country-code": "626",
	"iso_3166-2": "ISO 3166-2:TL",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Togo",
	"ISO2": "TG",
	"ISO3": "TGO",
	"country-code": "768",
	"iso_3166-2": "ISO 3166-2:TG",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Western Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "011"
}, {
	"name": "Tokelau",
	"ISO2": "TK",
	"ISO3": "TKL",
	"country-code": "772",
	"iso_3166-2": "ISO 3166-2:TK",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Tonga",
	"ISO2": "TO",
	"ISO3": "TON",
	"country-code": "776",
	"iso_3166-2": "ISO 3166-2:TO",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Trinidad and Tobago",
	"ISO2": "TT",
	"ISO3": "TTO",
	"country-code": "780",
	"iso_3166-2": "ISO 3166-2:TT",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Tunisia",
	"ISO2": "TN",
	"ISO3": "TUN",
	"country-code": "788",
	"iso_3166-2": "ISO 3166-2:TN",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "Türkiye",
	"ISO2": "TR",
	"ISO3": "TUR",
	"country-code": "792",
	"iso_3166-2": "ISO 3166-2:TR",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Turkmenistan",
	"ISO2": "TM",
	"ISO3": "TKM",
	"country-code": "795",
	"iso_3166-2": "ISO 3166-2:TM",
	"region": "Asia",
	"sub-region": "Central Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "143",
	"intermediate-region-code": ""
}, {
	"name": "Turks and Caicos Islands",
	"ISO2": "TC",
	"ISO3": "TCA",
	"country-code": "796",
	"iso_3166-2": "ISO 3166-2:TC",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Tuvalu",
	"ISO2": "TV",
	"ISO3": "TUV",
	"country-code": "798",
	"iso_3166-2": "ISO 3166-2:TV",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Uganda",
	"ISO2": "UG",
	"ISO3": "UGA",
	"country-code": "800",
	"iso_3166-2": "ISO 3166-2:UG",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Ukraine",
	"ISO2": "UA",
	"ISO3": "UKR",
	"country-code": "804",
	"iso_3166-2": "ISO 3166-2:UA",
	"region": "Europe",
	"sub-region": "Eastern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "151",
	"intermediate-region-code": ""
}, {
	"name": "United Arab Emirates",
	"ISO2": "AE",
	"ISO3": "ARE",
	"country-code": "784",
	"iso_3166-2": "ISO 3166-2:AE",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "United Kingdom of Great Britain and Northern Ireland",
	"ISO2": "GB",
	"ISO3": "GBR",
	"country-code": "826",
	"iso_3166-2": "ISO 3166-2:GB",
	"region": "Europe",
	"sub-region": "Northern Europe",
	"intermediate-region": "",
	"region-code": "150",
	"sub-region-code": "154",
	"intermediate-region-code": ""
}, {
	"name": "United States of America",
	"ISO2": "US",
	"ISO3": "USA",
	"country-code": "840",
	"iso_3166-2": "ISO 3166-2:US",
	"region": "Americas",
	"sub-region": "Northern America",
	"intermediate-region": "",
	"region-code": "019",
	"sub-region-code": "021",
	"intermediate-region-code": ""
}, {
	"name": "United States Minor Outlying Islands",
	"ISO2": "UM",
	"ISO3": "UMI",
	"country-code": "581",
	"iso_3166-2": "ISO 3166-2:UM",
	"region": "Oceania",
	"sub-region": "Micronesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "057",
	"intermediate-region-code": ""
}, {
	"name": "Uruguay",
	"ISO2": "UY",
	"ISO3": "URY",
	"country-code": "858",
	"iso_3166-2": "ISO 3166-2:UY",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Uzbekistan",
	"ISO2": "UZ",
	"ISO3": "UZB",
	"country-code": "860",
	"iso_3166-2": "ISO 3166-2:UZ",
	"region": "Asia",
	"sub-region": "Central Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "143",
	"intermediate-region-code": ""
}, {
	"name": "Vanuatu",
	"ISO2": "VU",
	"ISO3": "VUT",
	"country-code": "548",
	"iso_3166-2": "ISO 3166-2:VU",
	"region": "Oceania",
	"sub-region": "Melanesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "054",
	"intermediate-region-code": ""
}, {
	"name": "Venezuela (Bolivarian Republic of)",
	"ISO2": "VE",
	"ISO3": "VEN",
	"country-code": "862",
	"iso_3166-2": "ISO 3166-2:VE",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "South America",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "005"
}, {
	"name": "Viet Nam",
	"ISO2": "VN",
	"ISO3": "VNM",
	"country-code": "704",
	"iso_3166-2": "ISO 3166-2:VN",
	"region": "Asia",
	"sub-region": "South-eastern Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "035",
	"intermediate-region-code": ""
}, {
	"name": "Virgin Islands (British)",
	"ISO2": "VG",
	"ISO3": "VGB",
	"country-code": "092",
	"iso_3166-2": "ISO 3166-2:VG",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Virgin Islands (U.S.)",
	"ISO2": "VI",
	"ISO3": "VIR",
	"country-code": "850",
	"iso_3166-2": "ISO 3166-2:VI",
	"region": "Americas",
	"sub-region": "Latin America and the Caribbean",
	"intermediate-region": "Caribbean",
	"region-code": "019",
	"sub-region-code": "419",
	"intermediate-region-code": "029"
}, {
	"name": "Wallis and Futuna",
	"ISO2": "WF",
	"ISO3": "WLF",
	"country-code": "876",
	"iso_3166-2": "ISO 3166-2:WF",
	"region": "Oceania",
	"sub-region": "Polynesia",
	"intermediate-region": "",
	"region-code": "009",
	"sub-region-code": "061",
	"intermediate-region-code": ""
}, {
	"name": "Western Sahara",
	"ISO2": "EH",
	"ISO3": "ESH",
	"country-code": "732",
	"iso_3166-2": "ISO 3166-2:EH",
	"region": "Africa",
	"sub-region": "Northern Africa",
	"intermediate-region": "",
	"region-code": "002",
	"sub-region-code": "015",
	"intermediate-region-code": ""
}, {
	"name": "Yemen",
	"ISO2": "YE",
	"ISO3": "YEM",
	"country-code": "887",
	"iso_3166-2": "ISO 3166-2:YE",
	"region": "Asia",
	"sub-region": "Western Asia",
	"intermediate-region": "",
	"region-code": "142",
	"sub-region-code": "145",
	"intermediate-region-code": ""
}, {
	"name": "Zambia",
	"ISO2": "ZM",
	"ISO3": "ZMB",
	"country-code": "894",
	"iso_3166-2": "ISO 3166-2:ZM",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}, {
	"name": "Zimbabwe",
	"ISO2": "ZW",
	"ISO3": "ZWE",
	"country-code": "716",
	"iso_3166-2": "ISO 3166-2:ZW",
	"region": "Africa",
	"sub-region": "Sub-Saharan Africa",
	"intermediate-region": "Eastern Africa",
	"region-code": "002",
	"sub-region-code": "202",
	"intermediate-region-code": "014"
}]