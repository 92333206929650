import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {loadModules} from "esri-loader";
import {web24} from "@esri/calcite-ui-icons";
import Dropdown, {DropdownMenu, DropdownMenuItem} from "../../../Dropdown";
import {isWidgetDisplayed} from "../../../../esri/widgets/expandUtils";
import {SectionItem} from "../../Header-styled";

const languages = {
  "ar": "عربى",
  "zh-cn": "中国人",
  "en": "English",
  "fr": "Français",
  "es": "Español",
  "ru": "Pусский",
  "tr": "Türkçe"
}

const LanguageDropdown = ({setActiveLanguage, config}) => {
  const {i18n} = useTranslation('common')
  const [show, setShow] = useState(false);
  
  const hideLanguageSelection = useMemo(()=>{
    return !isWidgetDisplayed(config, 'LanguageSwitcher') || !(config.supportedLngs && config.supportedLngs.length > 1)
  },[config])
  
  if (hideLanguageSelection) return null;
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
      leftOffset={0.5}
      styles={{zIndex:5}}
    >
      <SectionItem>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4e4e4e">*/}
          <path d={web24} />
        </svg>
      </SectionItem>
      <DropdownMenu>
        {
          config.supportedLngs.map(lang=>(
            <DropdownMenuItem
              key={lang}
              selected={i18n.language === lang}
              opsColor={config.opsColor}
              onClick={()=>{
                // setActiveLanguage(lang);
                i18n.changeLanguage(lang, () => {
                  loadModules(["esri/intl"]).then(([intl]) => {
                    intl.setLocale(lang);
                  })
                }).then(()=>window.location.reload())
                setShow(false);
              }}
            >
              <span
                style={{
                  fontWeight:600,
                  color: config.opsColor
                }}>{lang.toUpperCase()}</span>
              <span>{languages[lang]}</span>
            </DropdownMenuItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;