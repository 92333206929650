import styled from "styled-components";
import {Element} from 'react-scroll'

export const StyledSectionContainer = styled(Element)`
  height:100%;
  display: flex;
  overflow: hidden;
  //scroll-snap-align: center;
  //scroll-snap-stop: always;
`

export const StyledSectionTitle = styled.p`
  text-align: left;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #031C2D;
  width: 100%;
  margin: 0 0 8px 0;
`

export const StyledSectionDescription = styled.p`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #333945;
  //margin: 8px 0 8px 0;
  margin: 0;
`
export const StyledSectionBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 5px 2px;
`