import React, {useContext} from "react";
import {
  StyledSectionBody,
  StyledSectionContainer,
  StyledSectionDescription,
  StyledSectionTitle
} from "./ScrollSection-styled";
import PacType from "../PacReport/PacType";
import Location from "../Location/Location";
import VehiclePracticability from "../PacReport/VehiclePracticability";
import Describe from "../PacReport/Describe";
import ReportContacts from "../ReportContacts/ReportContacts";
import StorageSpace from "../WarehouseReport/StorageSpace";
import CommunityShare from "../WarehouseReport/CommunityShare";
import ReportFinal from "../ReportFinal";
import {ConfigContext} from "../../../../utils/ConfigContext";

const ScrollSection = ({index, item, children, goNext, form, setForm, resetReport, layer,t}) => {
  const {config} = useContext(ConfigContext);
  const render = () =>{
    switch (item?.component) {
      case 'pactype':{
        return <PacType form={form} setForm={setForm} goNext={goNext} config={config} t={t} />
      }
      case 'location':{
        return <Location form={form} setForm={setForm} config={config} t={t} />
      }
      case 'vehiclePracticability':{
        return <VehiclePracticability form={form} setForm={setForm} goNext={goNext} layer={layer} config={config} t={t} />
      }
      case 'describe': {
        return <Describe config={config} form={form} setForm={setForm} t={t} />
      }
      case 'contacts':{
        return <ReportContacts t={t} />;
      }
      case 'storagespace':{
        return <StorageSpace layer={layer} form={form} setForm={setForm} goNext={goNext} config={config} t={t}/>
      }
      case 'communityshare':{
        return <CommunityShare layer={layer} form={form} setForm={setForm} goNext={goNext} config={config} t={t} />
      }
      case 'more': {
        return <ReportFinal resetReport={resetReport} t={t}/>
      }
      default:{
        return  <div />
      }
    }
  }
  
  return (
    <StyledSectionContainer
      id={`scrollSection-${index}`}
      name={`scrollSection-${index}`}
    >
      <div
        style={{
          margin:'auto',
          width:'100%',
          overflow:'hidden',
          height:'100%',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center'
        }}>
        {
          item?.title && <div style={{padding:'0px 24px'}}>
            <StyledSectionTitle>{item.title}{item?.required?.length > 0 && "*"}</StyledSectionTitle>
          </div>
        }
        <StyledSectionBody style={{overflow:'auto', padding:'5px 24px'}}>
          {item?.description && <StyledSectionDescription>{item.description}</StyledSectionDescription>}
          {children ?? render()}
        </StyledSectionBody>
      </div>
    </StyledSectionContainer>
  );
};

export default ScrollSection;