import {setPanelPageIndex_actionType, resetPanel} from "../constants"

const initialState = 0
const panelPageIndex = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setPanelPageIndex_actionType:
            return state = payload
        case resetPanel:
            return state = initialState
        default:
            return state
    }
}

export default panelPageIndex