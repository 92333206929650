import {Excalidraw} from "@excalidraw/excalidraw";
import React, {useTransition} from "react";

const ExcalidrawWrapper = ({setDrawings, drawings}) => {
  const [isPending, startTransition] = useTransition();
  return (
    <div
      onWheelCapture={(e)=>e.stopPropagation()}
      style={{position:"absolute", width:'100%', height:`100%`,pointerEvents:'auto'}}
      className={drawings.viewModeEnabled ? 'drawings__view-mode' : ''}
    >
      <Excalidraw
        isCollaborating={false}
        zenModeEnabled={false}
        excalidrawAPI={(api)=> {
          setTimeout(()=>{
            api.updateScene({
              elements: drawings.elements,
              appState: {
                viewBackgroundColor: "#FFFFFF00",
                currentItemEndArrowhead: "triangle",
                currentItemFillStyle: "solid",
                currentItemFontFamily: 2,
                currentItemRoundness: "sharp",
                currentItemRoughness:0
              },
            })
  
            startTransition(()=>{
              setDrawings(prev=>({
                ...prev,
                api: api
              }))
            })
          },0)
        }}
        viewModeEnabled={drawings.viewModeEnabled}
      />
    </div>
  );
};

export default ExcalidrawWrapper;