import {createAutoCollapseExpand} from "./expandUtils";
import {isWidgetDisplayed} from "./expandUtils";

export const createLegend = (Legend, reactiveUtils, Expand, view, t, config, pos) => {
	const widgetId = "Legend"
	if (!isWidgetDisplayed(config, widgetId))
		return

	let legend = new Legend({ view: view})
	legend.layerInfos = view.map.layers.map((lyr) => {
		return {title: lyr.getLayerTitle ? lyr.getLayerTitle(t) : lyr.title, layer: lyr}
	})

	/**
	 * Create unique hash from symbol. its id cannot be used, but all the rest should be fine, especially the URL
	 */
	const createSymbolHash = (symbol) => {
		return JSON.stringify(symbol).replace(/"id":.*,/g,"")
	}

	/**
	 * Query features visible on the map, and collect their symbols into a set
	 */
	const getActiveSymbols = (layerView) => {
		if (layerView.layer.type !== "feature")
			return new Promise((resolve) => {
				resolve(new Set());
			})

		return layerView.queryFeatures({geometry: view.extent,	outFields: "*"}).then((result) => {
			return result.features.map((feature) => {
				return layerView.layer.renderer.getSymbolAsync(feature)
			})
		})
	}

	const onWidgetExpanded = () => {
		if (!expand.viewModel.expanded)
			return

		legend.viewModel.activeLayerInfos.items.forEach((activeLayerItem) => {
			activeLayerItem.title = activeLayerItem.layer.getLayerTitle(t)
			activeLayerItem.children?.items.forEach((child) =>{
				child.title = child.layer.getLayerTitle(t)
			})

			view.whenLayerView(activeLayerItem.layer).then((layerView) => {
				reactiveUtils.when(
					() => !layerView.updating,
					() => {
						activeLayerItem.children.items.forEach((chItem) =>{
							updateLayerview(chItem.layerView, chItem.legendElements)
						})

						updateLayerview(layerView, activeLayerItem.legendElements)
					})
			})
		})
	}

	/**
	 * Remove invisible legend elements
	 * @param layerView
	 * @param legendElements
	 */
	const updateLayerview = (layerView, legendElements) => {
		getActiveSymbols(layerView).then(activeSymbolPromises => {
			Promise.all(activeSymbolPromises).then((activeSymbols) => {
				let symbolSet = new Set()
				activeSymbols.forEach((symbol) => {
					if (symbol)
						symbolSet.add(createSymbolHash(symbol))
				})

				updateLegend(legendElements, symbolSet)
			})
		})
	}

	/**
	 * Hide not visible elements from the legend
	 */
	const updateLegend = (legendElements, activeSymbols) => {
		let legendRow
		legendElements.forEach((legendElement) => {
			legendElement.infos.forEach((legendInfo) => {
				if (!legendInfo.preview)
					return

				legendRow = legendInfo.preview.closest('.esri-legend__layer-row')
				if (!legendRow){
					return
				}

				const symbol = legendInfo.symbol
				const isVisible = activeSymbols && activeSymbols.has(createSymbolHash(symbol))
				legendRow.style.display = isVisible ? "block" : "none"

				//Translate label, at the moment only labels with url can be translated
				if (isVisible && symbol.source && symbol.source.url){
					const urlSplit = symbol.source.url.split("/")
					const imageHash = urlSplit[urlSplit.length - 1]

					const legendCaption = legendRow.querySelector(".esri-legend__layer-cell--info")
					legendCaption.innerHTML = t('layer.symbology.' + imageHash, legendCaption.innerHTML)
				}
			})
		})

		//Hide the layer related block when no legend found in the layer
		if (legendRow){
			legendRow.closest(".esri-legend__service").style.display = activeSymbols.size > 0 ? "block" : "none"
		}
	}

	
	const expand = createAutoCollapseExpand(Expand, view, config, t, widgetId, legend)
	view.ui.add({component: expand, position: "bottom-left", index: pos})

	if (expand.expanded)
		onWidgetExpanded()

	reactiveUtils.when(
		() => expand.expanded,
		() => {onWidgetExpanded()}
	)
}