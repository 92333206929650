import {StyledButton} from "./Button-styled";


const Button = ({children, ...rest}) => {
  return (
    <StyledButton {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;