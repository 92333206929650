import {setKnowledgeOfRehabilitation_actionType, removeData} from "../constants";
const initialState = null;

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setKnowledgeOfRehabilitation_actionType:
			return state = payload;
		case removeData:
			return state = initialState;
		default:
			return state;
	}
};
