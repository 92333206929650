import {StyledProgressBarContainer, StyledProgressBar} from "./ProgressBar-styled";
import React from "react";

const ProgressBar = ({total, currentPosition, color}) => {
  return (
    <StyledProgressBarContainer>
      <StyledProgressBar
        bgcolor={color}
        progress={100 / total * currentPosition}
      />
    </StyledProgressBarContainer>
  );
};

export default ProgressBar;