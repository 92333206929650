import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {xCircle16} from "@esri/calcite-ui-icons";
import {AttachmentWrapper} from "./UploadMediaFiles-styled";

const UploadMediaFiles = ({attachments, setAttachments, t}) => {
  
  const onDrop = useCallback((acceptedFiles)=>{
    setAttachments(prev=>prev.concat(...acceptedFiles))
  },[setAttachments])
  
  const handleRemove = useCallback((fileIndex)=>{
    setAttachments(prev=>prev.filter((_file, index)=>index !== fileIndex))
  },[attachments])
  
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg,image/jpg,image/png,application/pdf"
  });
  
  return (
    <calcite-accordion-item heading={t('screen.widget.Editor.mediaFiles.label')}>
      <fieldset className="esri-attachments__file-fieldset">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" onClick={open}>
            {t('screen.component.input.upload')}
          </button>
        </div>
      </fieldset>
      <AttachmentWrapper>
        {
          attachments.map((file, index)=>(
            <span key={`${file.name}-${index}}`}>
                {file.name}
              <button type="button" onClick={()=>handleRemove(index)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d={xCircle16} />
                  </svg>
                </button>
              </span>
          ))
        }
      </AttachmentWrapper>
    </calcite-accordion-item>
  );
};

export default UploadMediaFiles;