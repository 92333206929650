import styled from "styled-components";

export const FooterContainer = styled.div`
    position: sticky;
    width: 100%;
    flex: 0 0 2;
    margin-top: auto;
    bottom: 0;
    background-color: #fafdff;
`
export const Content = styled.div`
    padding: 0 .2em .8em .2em;
    border-top: 1px solid #DFE6EB;
`