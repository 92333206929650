import {getField} from "../../../Report/new/helpers";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {StyledCard, StyledCardLegendText, StyledCardTitle} from "../Insights-styled";
import CardHeader from "../InsightsCard/CardHeader";
import {ResponsiveContainer} from "recharts";
import CustomBarChart from "../Charts/CustomBarChart";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {COLORS} from "../Insights";

const DOMESTIC_CHART_DESCRIPTION = {
  'PRP': {
    overall: (<span>
      This graph shows the national existing
      <span> per item quantities </span>
      (in green) compared to an ideal national stock level (in red) should the country decide to reach a stock level capable of supporting 70% of the disasters impacting the country (on the basis of the disasters passed in the last 20 years) with no additional assistance (suppliers or regional hubs).
    </span>),
    area:(
      <span>
        This graph shows the national existing
        <span> per province quantities </span>
        (in green) compared to an ideal national stock level (in red) should the country decide to reach a stock level capable of supporting 70% of the disasters impacting the country (on the basis of the disasters passed in the last 20 years) with no additional assistance (suppliers or regional hubs).
      </span>
    )
  },
  'ANT':{
      overall: (
        <span>
          This graph shows the national existing
            <span> per item quantities </span>
          (in green) compared to the forecasted needs (in red) on the basis of the disaster alert received.
        </span>
    ),
    area:(
      <span>
        This graph shows the existing stocks (in green) compared to forecasted needs (in red)
        <span> per province </span>
        on the basis of the disaster alert received.
      </span>
    )
  },
  'RES':{
    overall:(
      <span>
        This graph shows the national existing
        <span> per item quantities </span>
        (in green) compared to the needs assessment conducted in response to the disaster.
      </span>
    ),
    area:(
      <span>
        This graph shows the existing stocks (in green) compared to the needs assessment (in red)
        <span> per province </span>
        conducted in response to the disaster.
      </span>
    )
  }
}

const Legend = ({quantity, expected, children})=>{
  return <div style={{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    gap:8
  }}>
    <div style={{
      display:'flex',
      gap:6
    }}>
      {quantity && <StyledCardLegendText bg={COLORS.green}>
        {quantity}
      </StyledCardLegendText>}
      {expected && <StyledCardLegendText bg={COLORS.red}>
        {expected}
      </StyledCardLegendText>}
    </div>
    {children}
  </div>
}

const InsightsStockPrepositioning = ({expand, onFilterChange, statsData, layer}) => {
  const [barItemgroup, setBarItemgroup] = useState([]);
  const [barLocation, setBarLocation] = useState([]);
  
  const [domestic, setDomestic] = useState({
    itemgroup: [],
    location: [],
  })
  
  const [international, setInternational] = useState({
    itemgroup: [],
    location: []
  })
  
  const [legend, setLegend] = useState({
    quantity: '',
    expected: ''
  })
  
  const {t} = useTranslation('common');
  const selectedFilters = useRef([]);
  
  const {activeModule, filters:mapFilters} = useSelector(state=>state);
  const {config} = useContext(ConfigContext);
  
  const handleCloseSidebar = useCallback(()=>{
    setBarItemgroup([]);
    
    setLegend({
      quantity: '',
      expected: ''
    })
    
    setInternational({
      itemgroup: [],
      location: [],
    })
    
    setDomestic({
      itemgroup: [],
      location: [],
    })
    
    const filter = {}
    selectedFilters.current.forEach((key)=>{
      const filterField = config.filterFields.find(item=>item.name === key);
      filter[key] = filterField?.defaultValue;
    })
    
    onFilterChange(filter);
    
    selectedFilters.current = [];
  },[onFilterChange])
  
  useEffect(()=>{
    if (!expand){
      handleCloseSidebar();
    }
    
    return ()=>{
      handleCloseSidebar();
    }
  },[expand])
  
  //original
  // const handleBarSelect = useCallback((params, data)=>{
  //   const index = params.activeTooltipIndex;
  //   if (index === undefined) return;
  //
  //   const filter = {};
  //   selectedFilters.current = selectedFilters.current.filter(key=>!data.find(item=>item.key === key));
  //   const payload = params.activePayload[0].payload;
  //
  //   const filterField = config.filterFields.find(item=>item.name === payload.key);
  //   const codedValue = payload.key === 'itemgroup' ? Number(payload.codedValue) : payload.codedValue
  //   const filteredValue = mapFilters[activeModule][payload.key]
  //
  //   if (!filteredValue || (filteredValue && !filteredValue.includes(codedValue)) || (filteredValue.includes(codedValue) && filteredValue.length > 1)) {
  //     selectedFilters.current.push(payload.key)
  //     filter[payload.key] = [codedValue];
  //   } else if (filteredValue && filteredValue.includes(codedValue)) {
  //     filter[payload.key] = filterField?.defaultValue ?? [];
  //   }
  //
  //   onFilterChange(filter)
  // },[mapFilters, activeModule, onFilterChange])
  
  //improved with filtering by domestic and international values
  const handleBarSelect = useCallback((params, data)=>{
    const index = params.activeTooltipIndex;
    if (index === undefined) return;
    
    const filter = {};
    const payload = params.activePayload[0].payload;
    
    const filterField = config.filterFields.find(item=>item.name === payload.key);
    const codedValue = payload.key === 'itemgroup' ? Number(payload.codedValue) : payload.codedValue;
    let filteredValue = mapFilters[activeModule][payload.key] || [];
    
    const internationalSelected = selectedFilters.current.includes('international-locationname');
    const domesticSelected = selectedFilters.current.includes('domestic-locationname');
    const currentPayloadLocation = payload.locationType;
    const domesticLocations = domestic.location.map(loc=>loc.codedValue);
    const internationalLocations = international.location.map(loc=>loc.codedValue);
  
    if (payload.key === 'locationname'){
      //removing locations based on active filters
      if (currentPayloadLocation === 'domestic' && (!internationalSelected && filteredValue.length > 0)) {
        filteredValue = filteredValue.filter(item=>!internationalLocations.includes(item))
      } else if (currentPayloadLocation === 'international' && (!domesticSelected && filteredValue.length > 0)) {
        filteredValue = filteredValue.filter(item=>!domesticLocations.includes(item))
      } else if (currentPayloadLocation === 'international' && (domesticSelected && !internationalSelected && filteredValue.length > 0)) {
        filteredValue = filteredValue.filter(item=>!internationalLocations.includes(item))
      } else if (currentPayloadLocation === 'domestic' && (!domesticSelected && internationalSelected && filteredValue.length > 0)) {
        filteredValue = filteredValue.filter(item=>!domesticLocations.includes(item))
      }
      
      selectedFilters.current = selectedFilters.current.filter(key=>key!==`${payload.locationType}-${payload.key}`);
    } else {
      selectedFilters.current = selectedFilters.current.filter(key=>key!==payload.key);
    }
    
    if (!filteredValue || (filteredValue && !filteredValue.includes(codedValue)) || (payload.key === 'itemgroup' && filteredValue.includes(codedValue) && filteredValue.length > 1)) {
      if (payload.key === 'locationname') {
        selectedFilters.current.push(`${payload.locationType}-${payload.key}`);
        if ((internationalSelected && currentPayloadLocation === 'domestic') || (domesticSelected && currentPayloadLocation === 'international')) {
          const currLocations = currentPayloadLocation === 'domestic' ? domesticLocations : internationalLocations;
          
          filter[payload.key] = [...filteredValue.filter(item=>!currLocations.includes(item)), codedValue];
        } else {
          filter[payload.key] = [codedValue];
        }
      } else {
        selectedFilters.current.push(payload.key)
        filter[payload.key] = [codedValue];
      }
    } else if (filteredValue && filteredValue.includes(codedValue)) {
      if (payload.key === 'locationname') {
        filter[payload.key] = filteredValue.filter(item=>item !== codedValue);
      } else {
        filter[payload.key] = filterField?.defaultValue ?? [];
      }
    }
    
    //adding locations based on active filters
    if (payload.key === 'locationname' && filter[payload.key].length > 0) {
      const internationalSelectedAfter = selectedFilters.current.includes('international-locationname');
      const domesticSelectedAfter = selectedFilters.current.includes('domestic-locationname');
      if (currentPayloadLocation === 'domestic' && !internationalSelectedAfter) {
        filter[payload.key].push(...internationalLocations);
      } else if (currentPayloadLocation === 'international' && !domesticSelectedAfter) {
        filter[payload.key].push(...domesticLocations);
      } else if (currentPayloadLocation === 'domestic' && internationalSelectedAfter && !domesticSelectedAfter) {
        filter[payload.key].push(...domesticLocations);
      } else if (currentPayloadLocation === 'international' && domesticSelectedAfter && !internationalSelectedAfter) {
        filter[payload.key].push(...internationalLocations);
      }
    }
    
    onFilterChange(filter)
  },[mapFilters, activeModule, onFilterChange, international, domestic])
  
  useEffect(()=>{
    const groupData = (statsData, expectedKey)=>{
      const data = {
        itemgroup:{},
        location:{},
        hscode: ''
      };
  
      if (statsData.length === 0) {
        setBarItemgroup([])
        setBarLocation([])
        return;
      }
  
      statsData.forEach(feautre=>{
        const {itemgroup, locationname, unit, quantity_sum} = feautre.attributes;
        data.itemgroup[itemgroup] = {
          ...data.itemgroup[itemgroup] || {},
          [unit]: {
            quantity_sum: 0,
            expected_sum: 0,
            hscode: ''
          }
        };
    
        data.location[locationname] = {
          ...data.location[locationname] || {},
          [unit]: {
            quantity_sum: 0,
            expected_sum: 0
          }
        };
      })
  
      statsData.forEach(feautre=>{
        const {itemgroup, locationname, unit, quantity_sum, [`${expectedKey}_sum`]:expected, hscode_min = ''} = feautre.attributes;
        const itemgroupKey = itemgroup;
        const locationKey = locationname;
    
        data.itemgroup[itemgroupKey][unit].quantity_sum += (quantity_sum || 0)
        data.itemgroup[itemgroupKey][unit].expected_sum += (expected || 0)
        data.itemgroup[itemgroupKey][unit].locationname = locationKey
        data.itemgroup[itemgroupKey][unit].hscode = hscode_min;
    
        data.location[locationKey][unit].quantity_sum += (quantity_sum || 0)
        data.location[locationKey][unit].expected_sum += (expected || 0)
        data.location[locationKey][unit].itemgroup = itemgroup
      })
      
      return data;
    }
  
    const quantityTitle = getTranslatedText('layer.fieldAlias.quantity', 'Quantity');
    const expectedTitle = getTranslatedText(`layer.fieldAlias.${statsData.expectedKey}`, '');
    
    if (expectedTitle){
      setLegend({
        quantity: quantityTitle,
        expected: expectedTitle
      })
    }
    
    if (statsData.domestic.length > 0) {
      const domesticData = groupData(statsData.domestic, statsData.expectedKey)
      handleStockData(domesticData, 'domestic', statsData.expectedKey)
    }
  
    if (statsData.international.length > 0) {
      const internationalData = groupData(statsData.international)
      handleStockData(internationalData, 'international')
    }
  },[statsData])
  
  const getTranslatedText = (path, defaultValue) => {
    return t(path) === path ? defaultValue : t(path)
  }
  const handleStockData = (data, type, expectedKey)=>{
    const itemgroupValuesMap = new Map();
    const unitValues = new Map();
    
    getField(layer, 'itemgroup').forEach(value=>{
      itemgroupValuesMap.set(`${value.code}`, value.name)
    });
    
    getField(layer, 'unit').forEach(value=>{
      unitValues.set(`${value.code}`, value.name)
    });
    
    const {itemgroup, location} = data;
    
    const itemGroupBarArray = [];
    const locationBarArray = [];
    
    const quantityTitle = getTranslatedText('layer.fieldAlias.quantity', 'Quantity');
    const expectedTitle = getTranslatedText(`layer.fieldAlias.${expectedKey}`, '');
    const hsCodeTitle = getTranslatedText(`layer.fieldAlias.hscode`, 'HS Code')
    
    Object.keys(itemgroup).forEach(key=> {
      const data = {
        name: getTranslatedText('layer.domain.itemgroup.' + key, itemgroupValuesMap[key]),
        key: 'itemgroup',
        codedValue: Number(key),
        quantity:0,
        'quantity-title': quantityTitle,
        'quantity-description':'',
        expected:0,
        'expected-title':expectedTitle,
        'expected-description':'',
        hscode: ''
      }
      
      let maxQuantity = -1;
      Object.keys(itemgroup[key]).forEach((unit, index)=>{
        if (itemgroup[key][unit].quantity_sum > maxQuantity) {
          maxQuantity = itemgroup[key][unit].quantity_sum;
          const description = getTranslatedText('layer.domain.unit.' + unit, unitValues.get(unit));
          
          data['quantity'] = itemgroup[key][unit].quantity_sum;
          data[`quantity-description`] = description;
          
          data.expected = itemgroup[key][unit].expected_sum;
          data['expected-description'] = description;
          data.hscode = hsCodeTitle + ' ' + itemgroup[key][unit].hscode
        }
      })
      
      itemGroupBarArray.push({
        ...data
      })
    })
    
    Object.keys(location).forEach(key=> {
      const data = {
        name: getTranslatedText('layer.domain.locationname.' + key, key),
        key: 'locationname',
        codedValue: key,
        quantity:0,
        'quantity-title':quantityTitle,
        'quantity-description':[],
        expected:0,
        'expected-title':expectedTitle,
        'expected-description':[],
        locationType: type
      }
      
      Object.keys(location[key]).forEach((unit, index)=>{
        const description = getTranslatedText('layer.domain.unit.' + unit, unitValues.get(unit));
        
        data['quantity'] += location[key][unit].quantity_sum;
        data['quantity-description'].push(description);
  
        data.expected += location[key][unit].expected_sum
        data['expected-description'].push(description);
      })
      
      locationBarArray.push({
        ...data
      })
    })
  
    if (!selectedFilters.current.includes('itemgroup')) {
      if (type === 'domestic') {
        setDomestic(prev=>({
          ...prev,
          itemgroup: itemGroupBarArray,
        }))
      } else {
        setInternational(prev=>({
          ...prev,
          itemgroup: itemGroupBarArray
        }))
      }
      // setBarItemgroup(itemGroupBarArray);
    }
  
    if (!selectedFilters.current.includes(type+'-locationname')) {
      if (type === 'domestic') {
        setDomestic(prev=>({
          ...prev,
          location: locationBarArray
        }))
      } else {
        setInternational(prev=>({
          ...prev,
          location: locationBarArray
        }))
      }
      // setBarLocation(locationBarArray);
    }
  }
  
  const chartDescription = useMemo(()=>{
    return DOMESTIC_CHART_DESCRIPTION[activeModule]
  },[activeModule])
  
  return (
    <div style={{
      display:'flex',
      flexDirection:"column",
      flex:1,
      gap:10,
    }}>
      <div style={{display:'flex', flexDirection:'column', flex:1, gap:10,
        minHeight:window.innerHeight - 44 - 57 - 24 - 8
      }}>
       <StyledCard style={{flex:1, display:'flex', flexDirection:"column", width:'100%', marginTop:10}}>
         <StyledCardTitle style={{textAlign:'center', marginBottom:8, fontSize:18}}>
             National Prepositioned Stock
           </StyledCardTitle>
            <div style={{
              display:'flex',
              height:'100%'
            }}>
              <div style={{width: "100%", height:'100%',boxSizing: "border-box", display:'flex', flexDirection:'column', minHeight:170}}>
                <CardHeader
                  title="Overall Stock Prepositioned"
                  description={chartDescription.overall}
                  style={{
                    justifyContent:'center',
                    alignItems:'center',
                    marginBottom: 8,
                  }}
                />
                <div
                  onScroll={(e) => {
                    let ele = e.target.getElementsByClassName("recharts-xAxis")[0];
                    const scroll = -((e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop)
                    if (ele) {
                      ele.style = "transform: translateY("+ (scroll === 0 ? 0 : scroll + 20) +"px);";
                    }
                  }}
                  className={domestic.itemgroup.length > 10 ? "custom__xaxis" : ''}
                  style={{
                    position: "relative",
                    flex:1,
                    overflow:domestic.itemgroup.length > 10 ? 'auto' : "unset"
                  }}>
                  <ResponsiveContainer style={{position:'absolute'}}  height={domestic.itemgroup.length > 10 ? '400%' : '100%'}>
                    <CustomBarChart
                      t={t}
                      config={config}
                      data={domestic.itemgroup}
                      onClick={handleBarSelect}
                      mapFilters={mapFilters[activeModule]}
                      barValues={['quantity', 'expected']}
                      margin={{left:-40}}
                      colors={{
                        fill:{
                          'quantity':COLORS.green,
                          'expected':COLORS.red
                        },
                      }}
                      layout="vertical"
                    />
                  </ResponsiveContainer>
                </div>
              </div>
              <div style={{width: "100%", height:'100%',boxSizing: "border-box", display:'flex', flexDirection:'column', minHeight:170}}>
                <CardHeader
                  title="Stock Prepositioned by Area"
                  description={chartDescription.area}
                  style={{
                    justifyContent:'center',
                    alignItems:'center',
                    marginBottom: 8,
                  }}
                />
                <div style={{position: "relative", flex:1}}>
                  <ResponsiveContainer style={{position:'absolute'}}>
                    <CustomBarChart
                      t={t}
                      config={config}
                      data={domestic.location}
                      onClick={handleBarSelect}
                      mapFilters={mapFilters[activeModule]}
                      barValues={['quantity', 'expected']}
                      margin={{left:-40}}
                      colors={{
                        fill:{
                          'quantity':COLORS.green,
                          'expected':COLORS.red
                        },
                      }}
                      layout="vertical"
                    />
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
         <Legend quantity={legend.quantity} expected={legend.expected}>
           <StyledCardTitle style={{fontSize:'11px', lineHeight:1, display:'flex', alignItems:'center', color:'#6F7072'}}>
             Data Sources:
             <a style={{color:'inherit', display:'flex', margin:'0px 4px', alignItems:'center', gap:2}} href="https://www.esups-stockholm.org/#/private/signin" target="_blank"
             >
               Stockholm
             </a>
             {activeModule === 'RES' && 'and'}
             {
               activeModule === 'RES' && (
                 <a style={{color:'inherit', display:'flex', marginLeft:4, alignItems:'center', gap:2}} href="https://www.asyrec.org/" target="_blank">
                  Asyrec
                 </a>
               )
             }
           </StyledCardTitle>
         </Legend>
       </StyledCard>
        <StyledCard style={{flex:1, display:'flex', flexDirection:"column", width:'100%', marginTop:10}}>
          <StyledCardTitle style={{textAlign:'center', marginBottom:8, fontSize:18}}>
            International Prepositioned Stock
          </StyledCardTitle>
          <div style={{display:'flex', height:'100%'}}>
            <div style={{width: "100%", height:'100%',boxSizing: "border-box", display:'flex', flexDirection:'column', minHeight:170}}>
              <CardHeader
                title="Overall Stock Prepositioned"
                description={
                  <span>
                    This graph shows the existing
                    <span> per item quantities </span>
                    stored at the international level.
                  </span>
                }
                style={{
                  justifyContent:'center',
                  alignItems:'center',
                  marginBottom: 8,
                }}
              />
              <div
                onScroll={(e) => {
                  let ele = e.target.getElementsByClassName("recharts-xAxis")[0];
                  const scroll = -((e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop)
                  if (ele) {
                    ele.style = "transform: translateY("+ (scroll === 0 ? 0 : scroll + 20) +"px);";
                  }
                }}
                className={international.itemgroup.length > 10 ? "custom__xaxis" : ''}
                style={{
                  position: "relative",
                  flex:1,
                  overflow:international.itemgroup.length > 10 ? 'auto' : "unset"
                }}>
                <ResponsiveContainer style={{position:'absolute'}}  height={international.itemgroup.length > 10 ? '400%' : '100%'}>
                  <CustomBarChart
                    t={t}
                    config={config}
                    data={international.itemgroup}
                    onClick={handleBarSelect}
                    mapFilters={mapFilters[activeModule]}
                    barValues={['quantity']}
                    margin={{left:-40}}
                    colors={{
                      fill:{
                        'quantity':COLORS.green,
                      },
                    }}
                    layout="vertical"
                  />
                </ResponsiveContainer>
              </div>
            </div>
            <div style={{width: "100%", height:'100%',boxSizing: "border-box", display:'flex', flexDirection:'column', minHeight:170}}>
              <CardHeader
                title="Stock Prepositioned by Area"
                description={
                  <span>
                    This graph shows the existing stocks stored at the different international hubs.
                  </span>
                }
                style={{
                  justifyContent:'center',
                  alignItems:'center',
                  marginBottom: 8,
                }}
              />
              <div style={{position: "relative", flex:1}}>
                <ResponsiveContainer style={{position:'absolute'}}>
                  <CustomBarChart
                    t={t}
                    config={config}
                    data={international.location}
                    onClick={handleBarSelect}
                    mapFilters={mapFilters[activeModule]}
                    barValues={['quantity']}
                    margin={{left:-40}}
                    colors={{
                      fill:{
                        'quantity':COLORS.green,
                      },
                    }}
                    layout="vertical"
                  />
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <Legend quantity={legend.quantity}>
            <StyledCardTitle style={{fontSize:'11px', lineHeight:1, display:'flex', alignItems:'center', color:'#6F7072'}}>
              Data Sources:
              <a style={{color:'inherit', display:'flex', margin:'0px 4px', alignItems:'center', gap:2}}
                 href="https://www.ihc.ae/databank/" target="_blank"
              >
                Dubai Humanitarian
              </a>
            </StyledCardTitle>
          </Legend>
        </StyledCard>
      </div>
    </div>
  );
};

export default InsightsStockPrepositioning;