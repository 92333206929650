import FeatureTable, {geometryTypes} from "./FeatureTable";
import {useEffect, useMemo, useRef, useState} from "react";
import {view} from "../../utils/API";
import {useSelector} from "react-redux";

const FeatureTableWrapper = ({openSnackbar}) => {
  const {activeModule, layersLoading, printWidget} = useSelector(state=>state);
  const handles = useRef({
    change: undefined,
    watchLayers: []
  });
  const tableLayers = useRef({});
  const [_count, setCount] = useState(0);
  const count = useRef(0);
  
  const isShownInTable = (layer, visible) => {
    const isShown = (layer.layerConfig && layer.layerConfig.editable && layer.layerConfig.featureTable?.isShownInFeatureTable && layer.geometryType && geometryTypes.has(layer.geometryType))
    tableLayers.current[layer.id] = (visible && !!isShown);
    count.current += 1;
    setCount(count.current);
  }
  
  useEffect(()=>{
    view.map.layers.forEach((layer) => {
      isShownInTable(layer, layer.visible)
  
      const handler = layer.watch("visible", (visible) => {
        isShownInTable(layer, visible)
      })
  
      handles.current.watchLayers.push(handler)
    })
    
    view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {
          layer.load().then((l)=>{
            isShownInTable(l, l.visible)
          })
  
          const handler = layer.watch("visible", (visible) => {
            isShownInTable(layer, visible)
          })
  
          handles.current.watchLayers.push(handler)
        })
    })
    
    return () => {
      if (handles.current.change){
        handles.current.change.remove();
      }
      handles.current.watchLayers.forEach(item=>{
        item.remove();
      })
      handles.current.watchLayers = [];
      handles.current.change = undefined;
    }
  },[])
  
  const showTable = useMemo(()=>{
    return Object.values(tableLayers.current).filter(visible=>visible).length > 0;
  },[_count])
  
  if (printWidget) return null;

  return (
    showTable && !layersLoading ? <FeatureTable openSnackbar={openSnackbar} /> : null
  );
};

export default FeatureTableWrapper;