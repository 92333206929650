import React, {useEffect, useMemo, useState} from "react";
import { useSelector} from "react-redux";
import {loadModules} from "esri-loader";
import {view} from "../../utils/API";
import useWindowSize from "../../hooks/useWindowSize";
import {layoutDimensions} from "./helpers";
import PrintScreen from "./PrintScreen/PrintScreen";
import {useTranslation} from "react-i18next";

const PrintMask = ({parent, config}) => {
  const windowSize = useWindowSize()
  const {activeModule} = useSelector(state=>state);
  const {layersLoading} = useSelector(state=>state)
  const {t} = useTranslation('common')

  const [page, setPage] = useState({
    size:'A4',
    orientation: 'Landscape'
  })
  
  const [dpi, setDpi] = useState('300');
  
  const addMask = () => {
    const value = `LogIE_LogisticsCluster_${page.size}_${page.orientation}`;
    
    const printOutDimensions = layoutDimensions[value]
    if (printOutDimensions) {
      const offsetWidth = windowSize.width
      const printoutRatio = printOutDimensions[0] / printOutDimensions[1]
      const offsetHeight = parent.offsetHeight - 68;
      const screenRatio = offsetWidth / offsetHeight
    
      // Vertically cut? if true, we cut from the top and button, otherwise from the left and right
      const verticalCut = (printoutRatio > screenRatio)
  
      let calculatedWidth = offsetHeight * printoutRatio
      
      let calculatedMaskWidth =  (offsetWidth - calculatedWidth) / 2
      let printPreviewHeight;
      if (calculatedMaskWidth < 260) {
        const reduceLeftMaskByPx = (260 - calculatedMaskWidth)
        calculatedMaskWidth = calculatedMaskWidth - reduceLeftMaskByPx;
        
        const offset = page.orientation === 'Landscape' ? 114 : 37;
        calculatedMaskWidth = calculatedMaskWidth > offset ? calculatedMaskWidth : offset
        
        calculatedWidth = offsetWidth - calculatedMaskWidth - 260;

        if (calculatedMaskWidth === offset) {
          printPreviewHeight = calculatedWidth / printoutRatio;
        }
      }
      
      return {
        type:'horizontal',
        style:{
          width: calculatedMaskWidth
        },
        width: calculatedWidth,
        height: printPreviewHeight
      }
    }
  }
  
  const masks = useMemo(()=> addMask(),[page, windowSize])
  
  //intercept request for main map and add extent
  useEffect(() => {
    loadModules(["esri/config"]).then(([esriConfig]) => {
      esriConfig.request.interceptors.push({
        urls: config.printServiceUrl + "/submitJob",
        before: function (params) {
          if (params.requestOptions.query.Layout_Template.includes('Zoom')) return;
          
          const printPreview = document.querySelector('#printPreview');
          const dataPage = printPreview.getAttribute('data-page');
          const dpi = printPreview.getAttribute('data-dpi');
          if (!printPreview){
            return;
          }

          const value = `LogIE_LogisticsCluster_${dataPage}`;

          const printOutDimensions = layoutDimensions[value]
          
          if (!printOutDimensions)
            return
          
          const parent = document.getElementsByClassName("esri-view-root")
          
          if (!parent[0]?.parentNode?.id === 'viewDiv') return;
          
          const screen = parent[0];
          const printoutRatio = printOutDimensions[0] / printOutDimensions[1]
          const screenRatio = screen.offsetWidth / screen.offsetHeight

          // Vertically cut? if true, we cut from the top and button, otherwise from the left and right
          const verticalCut = (printoutRatio > screenRatio)

          let jsonToEdit = JSON.parse(params.requestOptions.query["Web_Map_as_JSON"])
          
          const mapExtent = view.extent
          const map = document.querySelector('#pdfMap');
          let topLeft, bottomRight

          const mapPos = map.getBoundingClientRect();
          if (mapPos.x < 260 && dataPage.includes('Landscape')) {
            topLeft = view.toMap({
              x: mapPos.x === 114 ? 114 : mapPos.x,
              y: dataPage.includes('Landscape') ? 10 : 65
            })
            bottomRight = view.toMap({
              x: (mapPos.x === 114 ? 114 : mapPos.x) + map.offsetWidth,
              y: map.offsetHeight + (dataPage.includes('Landscape') ? 10 : 65)
            })

          } else {
            const leftMaskWidth = (screen.offsetWidth - map.offsetWidth) / 2
            //We cut the sides
            const printWidthPx = (screen.offsetHeight - 62) * printoutRatio

            topLeft = view.toMap({x: leftMaskWidth, y: dataPage.includes('Landscape') ? 10 : 65})
            bottomRight = view.toMap({x: leftMaskWidth + map.offsetWidth, y: screen.offsetHeight - 58})
          }

          mapExtent.xmin = topLeft.x
          mapExtent.xmax = bottomRight.x
          mapExtent.ymin = topLeft.y
          mapExtent.ymax = bottomRight.y
          
          jsonToEdit.exportOptions['dpi'] = dpi;
          
          jsonToEdit.mapOptions.extent = mapExtent.toJSON();
          jsonToEdit.mapOptions.scale = undefined;
          
          params.requestOptions.query["Web_Map_as_JSON"] = JSON.stringify(jsonToEdit);
          params.requestOptions.query["Layout_Template"] = value;
        }
      })
    })
  },[])
  
  return (
    <div className="printWidgetMask">
      <div className={masks.type === 'horizontal' ? 'mask horizontalMask leftMask' : 'mask verticalMask topMask'} style={masks.style} />
      <div className={masks.type === 'horizontal' ? 'mask horizontalMask rightMask' : 'mask verticalMask bottomMask'} style={masks.style} />
      <div style={{width: masks.width ?? '100%', left:masks.style.width ,top:0, height:10, background:"#32323280", position:'absolute'}} />
      <div style={{width: masks.width ?? '100%', left:masks.style.width ,bottom:0, height:masks.height ? parent.offsetHeight - (masks.height + 10) : 10, background:"#32323280", position:'absolute'}} />
      <PrintScreen
        config={config}
        parent={parent}
        setPage={setPage}
        page={page}
        setDpi={setDpi}
        dpi={dpi}
        masks={masks}
        t={t}
      />
    </div>
  );
};

export default PrintMask;