import {useRef, useState} from "react";
import useClickOutside from "../../hooks/useClickOutside";
import {createPortal} from "react-dom";

const Dropdown = ({ children, setShow, show, leftOffset= 0, styles= {} }) => {
  const [style, setStyle] = useState({});
  const ref = useRef(null);
  const tooltipRef = useRef(null);
  
  useClickOutside(tooltipRef, (e) => {
    if (e.target.id === 'overlay') return;
    setShow(false);
  });
  
  const top = 6;
  const left = 0;
  const showTooltip = (e) => {
    if (!ref.current) return;
    const width = tooltipRef.current.clientWidth ?? 200;
    const targetWidth = e.currentTarget.offsetWidth;
    
    // some maths to align the tooltip with whatever you just hovered over (the 'target')
    const style = { minWidth:width }; // this style object will be passed as the tooltip's 'style' prop
    const dimensions = ref.current.getBoundingClientRect(); // where on the screen is the target
    
    // center align the tooltip by taking both the target and tooltip widths into account
    style.left = dimensions.left - left - (width * leftOffset - targetWidth * leftOffset);
    style.left = Math.max(left, style.left); // make sure it doesn't poke off the left side of the page
    style.left = Math.min(style.left, document.body.clientWidth - width - left); // or off the right
    if (style.left === document.body.clientWidth - width - left) {
      style.left = style.left - 6;
    }
    if (dimensions.top + top + (tooltipRef.current?.clientHeight ?? 0) < window.innerHeight) { // the top half of the page
      // when on the top half of the page, position the top of the tooltip just below the target (it will stretch downwards)
      style.top = dimensions.top + dimensions.height + top;
    } else {
      // when on the bottom half, set the bottom of the tooltip just above the top of the target (it will stretch upwards)
      style.bottom = (window.innerHeight - dimensions.top + top);
    }
    
    setStyle(style);
    setShow(prev=>!prev);
  };
  
  return (
    <>
      <div
        ref={ref}
        onClick={showTooltip}
        style={{position:'relative'}}
      >
        {
          show && <div
            id="overlay"
            style={{
              position:'absolute',
              width:'100%',
              height:'100%',
              top:0,
              left:0,
              zIndex:2,
              cursor: 'pointer'
            }}
          />
        }
        {children[0]}
      </div>
      {
        createPortal(
          <div
            ref={tooltipRef}
            style={{
              position:'fixed',
              opacity: show ? 1 : 0,
              pointerEvents: show ? 'all' : 'none',
              transition:'opacity 0.3s',
              zIndex:1,
              cursor:'pointer',
              ...style,
              ...styles
            }}
          >
            {children[1]}
          </div>,
          document.querySelector('#portal'),
        )
      }
    </>
  );
};

export default Dropdown;