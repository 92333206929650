import styled from "styled-components";

export const Container = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
export const TitleText = styled.h1`
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: .675em;
    line-height: 1.2em;
    margin-bottom: 0;
    margin-top: .5em;
    max-width: 100%;
    @media screen and (max-width: 1120px) {
        max-width: 65%;
    }
`
export const SubTitleText = styled.h2`
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: .675em;
    line-height: 1.5em;
    margin-top: 0;
    width: 100%;
`