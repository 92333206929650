import React from "react";
import {getExtension} from "mime";

import {StyledDocumentLink, StyledDocumentsListItem, DocumentLinkContent, DocumentLinkDocumentType} from "../../style/DocumentRow/DocumentRow-styled";
import getIcon from "../../helpers/getIcon";

const DocumentRow = ({attachment, editable, setAttachmentIdToRemove}) => {
    const type = getExtension(attachment?.contentType);
    const icon = getIcon(type);
    const typeUppercase = type.toUpperCase();
    return (
        <StyledDocumentsListItem className="esri-attachments__item">
            <StyledDocumentLink href={attachment?.url} target="_blank" rel="noreferrer">
                {icon}
                <DocumentLinkContent>
                    {attachment.name}
                    <DocumentLinkDocumentType>{typeUppercase}</DocumentLinkDocumentType>
                </DocumentLinkContent>

                {editable &&
                    <span className="esri-popup__icon esri-icon-trash" title="Remove me" onClick={(event) => {
                        event.preventDefault()
                        setAttachmentIdToRemove(attachment.id)
                    }} />
                }
            </StyledDocumentLink>

        </StyledDocumentsListItem>
    )
}

export default DocumentRow;