import styled from "styled-components";

export const BurgerMenuWrapper = styled.div`
  display: ${({showMenu}) => (showMenu ? "block" : 'none')};
  padding: 0 ${({isEmbed})=>isEmbed ? '12px' : '23px'} 0 0;
  position: absolute;
  z-index: 5;
  width: ${({isEmbed})=>isEmbed ? '50vw' : '65vw'};
  height: 100vh;
  background-color: ${props => props.isEditorMode ? "#000" : "#fff"};
  top: 0;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: fixed;
    width: ${({isEmbed})=>isEmbed ? '50vw' : '35vw'};
    height: 100vh;
    top: 0;
    right: -${({isEmbed})=>isEmbed ? '12' : '23'}px;
    background-color: rgb(0, 0, 0, .2);
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  svg {
    
    width: ${({isEmbed})=>isEmbed ? '18px' : '30px'};
    height: ${({isEmbed})=>isEmbed ? '18px' : '30px'};
  }
  & svg {
    path {
      stroke: ${props => props.isEditorMode && "#fff"};
    }
  }
`;

export const BurgerMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({isEmbed})=>isEmbed ? '10px 0px 14px 10px' : '1em'};
  align-items: center;

  ${({isEmbed})=>isEmbed ? `
    a > svg {
      width: 38px;
      height: 24px;
    }
  ` : ''}
`;

export const BurgerMenuList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1em;
  font-weight: 400;
  font-size: ${({isEmbed})=> isEmbed ? '12px' : '14px'};
  line-height: 20px;
  color: ${props => props.isEditorMode ? "#6F7072" : "#333945"};
  padding-left: ${({isEmbed})=> isEmbed ? '22px' : ''};
  

  & > :first-child {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.isEditorMode ? "#fff" : "#5A6872"};
    font-weight: 600;
    font-size: ${({isEmbed})=> isEmbed ? '10px' : '12px'};
    line-height: 16px;
  }
`;

export const BurgerMenuListItem = styled.li`
  color: ${({active}) => (active ? (props) => props.opsColor : "inherit")};
  font-weight: ${({active, isEmbed}) => (active ? isEmbed ? 600 : 700 : "inherit")};
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 40px;
    right: calc(100% + ${({isEmbed})=> isEmbed ? '10px' : '20px'});
    top: calc(50% - 20px);
    display: ${({active}) => (active ? "block" : "none")};
    background-color: ${(props) => props.opsColor};
  }
`;

export const GetStartedButton = styled.button`
  padding: 4px 16px;
  gap: 8px;
  background: #CFE8FF;
  border: 1px solid #3B94D3;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #3B94D3;
`;