import {loadModules} from "esri-loader";
const symbol = {
	type: "simple-marker",
	size: 8,
	color: "#B74F6F",
	outline: {
		width: 1,
		color: "#FFF"
	}
};

export const getSurvey123PointData = ({event, config, activeLanguage, icon }) => {
	if (!event || !event.mapPoint)
		return;

	const pnt = event.mapPoint
	const sym = icon ?? symbol
	try {
		return loadModules(["esri/geometry/support/webMercatorUtils", "esri/Graphic"]).then(([webMercatorUtils, Graphic]) => {
			const surveyPoint = new Graphic({geometry: pnt, sym})
			const point = webMercatorUtils.xyToLngLat(pnt.x, pnt.y)
			// const iframe = `${config?.surveyUrl}?center=${point[1]},${point[0]}` +
			// 	`&field:${config?.surveyISOField}=${config?.iso3}` +
			// 	`&hide=${config?.surveyHideDetails},field:${config?.surveyISOField}` +
			// 	`&locale=${activeLanguage}`
			const iframe = '';

			return {marker: surveyPoint, iframe};
		})
	} catch (err) {
		console.log("Error occurred while getting Survey123PointData", err)
	}
}
