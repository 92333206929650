import React, {useState} from "react";

import {LegendContentWrapper, ShowMore} from "../../styles/LegendContent-styled/LegendContent-Styled"; 

const LegendContent = ({children = 100}) => {
    const [showAll, setShowAll] = useState(false);
    const content = children;
    const legendInClosedBox = 2;
    const showLegend = () => {
        setShowAll(!showAll)
    }
    const result = !showAll ? content.slice(0, legendInClosedBox) : content;

    return (
        <LegendContentWrapper show={showAll}>
            {result}
            {content.length > legendInClosedBox ?
                (
                    <ShowMore onClick={showLegend}>{showAll ? "Show less" : "Show more"}</ShowMore>
                ) : (
                    null
                )
            }
        </LegendContentWrapper>
    )
}

export default LegendContent;