import CustomPopupWrapper from "../components/CustomPopupWrapper";
import React from "react";
import {EMBED_VARIANTS, renderCell, ROLE_EDITOR} from "../utils/helper";
import {Provider} from "react-redux";
import store from "../redux/store";
import {createRoot} from "react-dom/client";
import {setPanelPath_actionType} from "../redux/constants";
import {isWidgetDisplayed} from "./widgets/expandUtils";

export const createCustomPopup = (config, layer, PopupTemplate, CustomContent, t, i18n) => {
	let actions = []

	if (config.role === ROLE_EDITOR && layer.layerConfig && layer.layerConfig.editable){
		actions.push({
			title: t("screen.popup.actions.edit-action"),
			id: "edit-action",
			className: "esri-icon-edit"
		})
	}
/*
actions.push({
    title: "LCA",
    id: "show-lca-mobile",
    className: "esri-icon-public-mobile",
    display: false
})

actions.push({
    title: "LCA",
    id: "show-lca",
    className: "esri-icon-public",
    visible: false
})
*/
	if (layer.layerConfig?.hasReportingUpdate && config.embed !== EMBED_VARIANTS.APP && config.role !== ROLE_EDITOR){
		if (store.getState().panelPath === null && !isWidgetDisplayed(config, 'Report')) {
			store.dispatch({type: setPanelPath_actionType, payload: ''});
		}
		
		actions.push({
			title: t("screen.popup.actions.report-update"),
			id: "report-update",
			visible: false
		})
	}

	actions.push({
		title: t("screen.popup.actions.share"),
		id: "share",
		className: "esri-icon-share",
		visible: true
	})

	let root
	return new PopupTemplate({
		title: (event) => {
			return getFeatureTitle(event.graphic, t)
		},
		actions: actions,
		includeDefaultActions: true,
		content: [
			new CustomContent({
				outFields: ["*"],
				creator: (event) => {
					const graphic = event.graphic
					if (!graphic)
						return ""

					const grLayer = graphic.sourceLayer ? graphic.sourceLayer : graphic.layer
					if (!grLayer)
						return ""

					const element = document.createElement("div");
					element.id = `popupdiv_${grLayer.id}_${event.graphic.attributes.objectid}`

					root = createRoot(element)
					root.render(<Provider store={store}>
						<CustomPopupWrapper actions={actions} row={graphic} config={config} t={t} i18n={i18n}/>
					</Provider>)

					return element
				},
				destroyer: () => {
					if (root)
						root.unmount()
				}
			})
		]
	})
}

export const getFeatureTitle = (feature, t) => {
	const grLayer = feature.sourceLayer ? feature.sourceLayer : feature.layer
	if (!grLayer || !grLayer.layerConfig)
		return ""

	if (grLayer.layerConfig.titleTemplate)
		return getTitleFromTitleTemplate(feature, t)

	return getPopupOrTooltipTitle(feature, t)
}

/**
 * Get a feature title based on the titleTemplate customization
 * Template supports the following elements:
 * {layer.title} and {feature.attribute} notations
 *
 * example: "{layer.title}: {feature.ownerorg}"
 */
export const getTitleFromTitleTemplate = (graphic, t) => {
	let grLayer = graphic.sourceLayer ? graphic.sourceLayer : graphic.layer
	if (!grLayer)
		return ""

	let template = grLayer.layerConfig.titleTemplate
	
	return generateTitle(template, graphic, grLayer, t)
}

export const generateTitle = (template, graphic, grLayer, t) =>{
	if (!template) return ""
	
	let bracketStart
	while ((bracketStart = template.indexOf("{")) >= 0){
		let begin = template.substring(0, bracketStart)
		let end = template.substring(bracketStart + 1)
		
		let bracketEnd = end.indexOf("}")
		if (bracketEnd >= 0){
			let replacement = end.substring(0, bracketEnd)
			let tail = end.substring(bracketEnd + 1)
			template = begin + replaceTemplate(replacement, graphic, grLayer, t) + tail
		} else {
			template = begin
		}
	}
	
	return template
}

const LAYER_PREFIX = "layer."
const FEATURE_PREFIX = "feature."

const replaceTemplate = (template, graphic, grLayer, t) => {
	if (template.startsWith(LAYER_PREFIX)){
		return grLayer.getLayerTitle(t)
	} else if (template.startsWith(FEATURE_PREFIX)){
		const attribute = template.substring(FEATURE_PREFIX.length)

		const fields = Object.values(grLayer.fields).filter(field => field.name === attribute )
		if (!fields || fields.length === 0)
			return ""

		if (graphic.attributes[attribute])
			return renderCell(fields[0], graphic.attributes[attribute], t)
		else if (graphic.attributes[attribute] === undefined) {
			//Attribute needs to be loaded
			let q = grLayer.createQuery()
			q.objectIds = [graphic.attributes[grLayer.objectIdField]]
			q.outFields = [attribute]
			return grLayer.queryFeatures(q).then((results) => {
				if (results.features.length > 0) {
					const feature = results.features[0]
					return renderCell(fields[0], feature.attributes[attribute], t)
				} else
					return ""
			})
		} else {
			//Attribute is null
			return ""
		}
	}
	return template
}

export const getTooltipTitle = (graphic, t) => {
	let grLayer = graphic.layer ? graphic.layer : graphic.sourceLayer
	if (!grLayer)
		return ""

	if (grLayer.layerConfig.titleTemplate)
		return getTitleFromTitleTemplate(graphic, t)

	return getPopupOrTooltipTitle(graphic, t)
}

const getPopupOrTooltipTitle = (graphic, t) => {
	let grLayer = graphic.sourceLayer ? graphic.sourceLayer : graphic.layer
	if (!grLayer)
		return ""

	return getLayerTitle(grLayer, t)
}

export const getLayerTitle = (layer, t) => {
	return layer.getLayerTitle ? layer.getLayerTitle(t) : layer.title
}