import React from "react";
import {
	CheckboxLabel,
	DetailsContainer,
	FormContainer
} from "./Contacts-styled";
import {CheckBox} from "../../components";
import Input from "../Input/Input";

const translationPath = 'report.contacts.'

const FormInput = ({
	register,
	errors,
	setSaveContacts,
	saveContacts,
	config,
	t
}) => {
	return (
		<FormContainer>
			<DetailsContainer>
				<Input
					label={`${t(translationPath + 'firstName')}*`}
					placeholder={`${t("screen.component.input.placeholder")}`}
					errorMessage={errors?.firstName?.message}
					name="firstName"
					register={{...register("firstName", { required: true })}}
					borderColor={config.opsColor}
					required
				/>
				<Input
					label={t(translationPath + 'lastName')}
					placeholder={t("screen.component.input.placeholder")}
					register={{...register("surName")}}
					borderColor={config.opsColor}
				/>
				<Input
					label={t(translationPath + 'mobile')}
					register={{...register("contactNumber")}}
					placeholder={t("screen.component.input.placeholder")}
					borderColor={config.opsColor}
				/>
				<Input
					label={`${t(translationPath + 'email')}*`}
					register={{...register("email", { required: true })}}
					placeholder={t("screen.component.input.placeholder")}
					errorMessage={errors?.email && t('screen.component.input.required')}
					borderColor={config.opsColor}
				/>
				<Input
					label={`${t(translationPath + 'organization')}*`}
					register={{...register("organization", { required: true })}}
					placeholder={t("screen.component.input.placeholder")}
					errorMessage={errors?.organization?.message}
					borderColor={config.opsColor}
				/>
				<CheckboxLabel>
					<CheckBox onChange={setSaveContacts} value={saveContacts} />
					<span>
						{t(translationPath + 'checkbox')}
					</span>
				</CheckboxLabel>
			</DetailsContainer>
		</FormContainer>
	);
};

export default FormInput;
