import styled from "styled-components";

export const SortButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  
  span {
    font-size: 12px;
    color:#6a6a6a;
    margin-right: 4px;
  }
  
  div {
    position: relative;
    width: 8px;
    height: 16px;
  }
  
  svg {
    top: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s;
  }
`