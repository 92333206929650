import {getExcludeFields} from "../../../utils/helper";

const getAllowedFields = (layer, row)=>{
  const fields = layer.fields
  const layerConfig = layer.layerConfig
  const layerSettings = layerConfig.customPopupOps
  
  const excludeFields = getExcludeFields(layer);
  
  const allowedFieldsPre = fields.filter(field => row.attributes[field.name] !== undefined
    && row.attributes[field.name] !== null	&& row.attributes[field.name] !== ''
    && !excludeFields.includes(field.name));
  const allowedFieldNames = allowedFieldsPre.map((field) => field.name)
  const allowedFields = allowedFieldsPre.sort((a, b) => {
    const getFieldIdx = (fieldName) => {
      const aIdx1 = layerSettings.overviewTable1Fields.indexOf(fieldName)
      if (aIdx1 >= 0)
        return aIdx1
      
      const aIdx2 = layerSettings.overviewTable2Fields.indexOf(fieldName)
      if (aIdx2 >= 0)
        return 1000 + aIdx2
      
      return 1000000 + allowedFieldNames.indexOf(fieldName)
    }
    
    return getFieldIdx(a.name) - getFieldIdx(b.name)
  })
  return allowedFields
}
export default getAllowedFields;