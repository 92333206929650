import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {CategoriesCard} from "./Reports-styled";
import {
  StyledSectionBody,
  StyledSectionContainer, StyledSectionTitle,
} from "./ScrollSection/ScrollSection-styled";
import ReportsHeader from "./ReportsHeader/ReportsHeader";
import {getConfig} from "../../../utils/helper";
import {ConfigContext} from "../../../utils/ConfigContext";
import {getModulesWithReports, getOperationsWithReports, getReports, replaceURLParams} from "./helpers";
import {setActiveModule_actionType} from "../../../redux/constants";
import {useDispatch, useSelector} from "react-redux";
import {CalciteLoader} from "@esri/calcite-components-react"
import {Swiper, SwiperSlide} from "swiper/react";

const ReportSelectionFlow = ({openReport, closeModal, config, activeModule, t, openSnackbar}) => {
  const [selectionType, setSelectionType] = useState('');
  
  const [operations, setOperations] = useState([]);
  const [modules, setModules] = useState([]);
  const [reports, setReports] = useState([]);
  
  const {layersLoading} = useSelector(state=>state)
  const [initial, setInitial] = useState(2);
  const [selectedValue, setSelectedValue] = useState();
  const {setConfig} = useContext(ConfigContext)
  const dispatch = useDispatch();
  const ref = useRef();
  
  let nextTimer;
  const goNext = useCallback((fn, value) => {
    if (nextTimer) return;
    setSelectedValue(value)
    nextTimer = setTimeout(()=> {
      fn(value)
      setSelectedValue(undefined);
      nextTimer = null;
      clearTimeout(nextTimer);
    },800)
  },[])
  
  const handleSelection = (report) => {
    openReport(report)
  }
  
  const handleOperationSelection = (opId) => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('op')) {
      const newParams = [{
        key: 'op',
        value: opId
      }];
  
      urlParams.forEach((value,key)=>{
        newParams.push({value, key})
      })
      replaceURLParams(newParams)
      setSelectionType('moduleReports')
      const config = getConfig(t, openSnackbar);
      setConfig(config)
      ref.current?.slideTo(1);
    }
  }

  const handleModuleSelection = (module) => {
    ref.current?.slideTo(2)
    dispatch({type: setActiveModule_actionType, payload:module});
    setSelectionType('reports');
  }
  
  useEffect(()=> {
    const urlParams = new URLSearchParams(window.location.search);
    const urlModule = urlParams.get('module');
    switch (selectionType) {
      case 'reports':
        const reports = getReports(config, activeModule);

        if (!urlModule && reports.length === 1) {
          openReport(reports[0]);
        } else if (reports.length === 1) {
          goBack();
        }
        
        if (!urlModule) {
          const newParams = [
            {
              key: 'module',
              value: activeModule.toLowerCase()
            }
          ];
  
          urlParams.forEach((value,key)=>{
            newParams.push({value, key})
          })
          replaceURLParams(newParams)
        }
        
        setReports(reports)
        break;
      case 'operations':
        const operations = getOperationsWithReports(config);
        setOperations(operations)
        break;
      case 'moduleReports':
        const modules = getModulesWithReports(config);
        setModules(modules)
        break;
    }
  },[selectionType, config])
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlModule = urlParams.get('module');
    const urlDataCollection = urlParams.get('datacollection');
    const urlOp = urlParams.get('op');
  
    if (urlDataCollection) {
      if (urlModule) {
        setInitial(2)
        setSelectionType('reports');
      } else if (urlOp) {
        setInitial(1)
        setSelectionType('moduleReports')
        dispatch({type: setActiveModule_actionType, payload:config.defaultModule});
      } else {
        setInitial(0)
        setSelectionType('operations')
      }
    } else if (urlOp) {
      setInitial(2)
      setSelectionType('reports')
    }
  },[])
  
  const goBack = () => {
      if (selectionType === 'reports') {
        const urlParams = new URLSearchParams(window.location.search);
        const newURL = new URL(window.location.href);
        urlParams.delete('module');
        newURL.search = urlParams;
        window.history.replaceState({ path: newURL.href }, '', newURL.href);
        dispatch({type: setActiveModule_actionType, payload:config.defaultModule});
        setSelectionType('moduleReports');
        ref.current?.slideTo(1);
      } else {
        ref.current?.slideTo(0)
        replaceURLParams([{key: 'datacollection', value: 'open'}])
        setSelectionType('operations');
        const newConfig = getConfig(t, openSnackbar);
        setConfig(newConfig)
      }
  }
  
  const renderCategoryCard = (item, array, tabIndex)=> {
    switch (tabIndex){
      case 2:{
        return <CategoriesCard
          key={item}
          width={array.length === 1 ? '100%' : '48%'}
          disable={!!selectedValue}
          selected={selectedValue === item}
          className={selectedValue === item ? "blink_me" : ""}
          onClick={()=>goNext(handleSelection, item)}
          borderColor={config.opsColor}
          bgColor={selectedValue === item && (config.opsColor ? `${config.opsColor}1a` : '')}
        >
          {t("report." + item + ".title")}
        </CategoriesCard>
      }
      case 1:{
        return <CategoriesCard
          key={item}
          width={array.length === 1 ? '100%' : '48%'}
          disable={!!selectedValue}
          selected={selectedValue === item}
          className={selectedValue === item ? "blink_me" : ""}
          onClick={()=>goNext(handleModuleSelection, item)}
          borderColor={config.opsColor}
          bgColor={selectedValue === item && (config.opsColor ? `${config.opsColor}1a` : '')}
        >
          {t("screen.widget.ModuleSwitcher." + item + ".name") + " (" + item + ")"}
        </CategoriesCard>
      }
      case 0:{
        const translationPath = "ops." + item.id + "-viewer.title";
        const translation = t(translationPath);
        return <CategoriesCard
          key={item.id}
          style={{
            width:'48%'
          }}
          disable={!!selectedValue}
          selected={selectedValue === item.id}
          className={selectedValue === item.id ? "blink_me" : ""}
          onClick={()=>goNext(handleOperationSelection, item.id)}
          borderColor={item.opsColor}
          bgColor={item.opsColor}
          color={item.opsColor ? '#fff' : ''}
        >
          {translation !== translationPath ? translation : item.title}
        </CategoriesCard>
      }
    }
  }
  
  const renderHeaderTitle = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlOp = urlParams.get('op') ?? '';
    const module = urlParams.get('module') ?? '';
  
    const titleNav = []
  
    if (urlOp) {
      const translationPath = "ops." + config.id + "-viewer.title";
      const translation = t(translationPath);
      const title = translation !== translationPath ? translation : config.title;
      titleNav.push(title)
    }
  
    if (module) {
      titleNav.push(`${activeModule}`)
    }
  
    return titleNav.length > 0 ? titleNav.join(' / ') : t('report.operations.header');
  }
  
  return (
    <>
      <ReportsHeader
        onArrowClick={!layersLoading && goBack}
        showArrow={selectionType !== 'operations'}
        title={renderHeaderTitle()}
        close={closeModal}
      />
      <StyledSectionContainer style={{padding: '0px 24px'}}>
        {
          selectionType !== '' && <Swiper
            onInit={(s)=>{
              ref.current = s;
              s.update();
            }}
            initialSlide={initial}
            allowTouchMove={false}
            preventInteractionOnTransition
            noSwiping
          >
            <SwiperSlide style={{display:'flex', overflow:'hidden', height:'100%'}} tabIndex={0}>
              {({ isActive }) => <div
                style={{
                  margin:'auto',
                  width:'100%',
                  overflow:'auto',
                  height:'100%',
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center'
              }}>
                  {
                    layersLoading && isActive ? <CalciteLoader color={config.opsColor ?? "#b21b0c"} /> : (
                      <>
                        <StyledSectionTitle>{t('report.operations.title')}</StyledSectionTitle>
                        <StyledSectionBody
                          style={{overflow:'auto'}}
                        >
                          {operations.map((item, index, array)=>(
                            renderCategoryCard(item, array, 0)
                          ))}
                        </StyledSectionBody>
                      </>
                    )
                  }
              </div>}
            </SwiperSlide>
            <SwiperSlide style={{display:'flex', overflow:'auto', height:'100%'}} tabIndex={1}>
              {({ isActive}) => <div
                style={{
                  margin:'auto',
                  width:'100%',
                  overflow:'auto',
                  height:'100%',
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center'
                }}>
                {
                  layersLoading && isActive ? <CalciteLoader color={config.opsColor ?? "#b21b0c"} /> : (
                    <>
                      <StyledSectionTitle>{t('report.modules.title')}</StyledSectionTitle>
                      <StyledSectionBody
                        style={{overflow:'auto'}}
                      >
                        {modules.map((item, index, array)=>(
                          renderCategoryCard(item, array, 1)
                        ))}
                      </StyledSectionBody>
                    </>
                  )
                }
              </div>}
            </SwiperSlide>
            <SwiperSlide style={{display:'flex', overflow:'auto', height:'100%'}} tabIndex={2}>
              {({ isActive }) => <div style={{margin:'auto', width:'100%'}}>
                {
                  layersLoading && isActive ? <CalciteLoader color={config.opsColor ?? "#b21b0c"} /> : (
                    <>
                      <StyledSectionTitle>{t('report.reportSelection.title')}</StyledSectionTitle>
                      <StyledSectionBody>
                        {reports.map((item, index, array)=>(
                          renderCategoryCard(item, array, 2)
                        ))}
                      </StyledSectionBody>
                    </>
                  )
                }
              </div>}
            </SwiperSlide>
          </Swiper>
        }
      </StyledSectionContainer>
    </>
  );
};

export default ReportSelectionFlow;