import React, {useContext, useEffect, useState} from "react";
import Shepherd from "shepherd.js";
import { offset } from '@floating-ui/dom';
import {createRoot} from "react-dom/client";
import {ConfigContext} from "../utils/ConfigContext";
import OnBoarding from "../components/OnBoarding/OnBoarding";
import {Provider} from "react-redux";
import store from "../redux/store";
import {isWidgetDisplayed} from "../esri/widgets/expandUtils";
import {useTranslation} from "react-i18next";
import {ROLE_EDITOR} from "../utils/helper";

const useOnboarding = () => {
  const [onBoardingTour, setOnBoardingTour] = useState();
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  
  useEffect(()=>{
    if (config.role === ROLE_EDITOR || !!config.embed) return;
    
    const replaceContent = ({
      title,
      description,
      tour
    })=>{
      const currentStep = Shepherd.activeTour?.getCurrentStep();
      const currentStepElement = currentStep?.getElement();
      const content = currentStepElement?.querySelector('.shepherd-content');
      const progress = document.createElement('div');
      const root = createRoot(progress)
      root.render(<Provider store={store}>
        <OnBoarding
          total={Shepherd.activeTour?.steps.length}
          config={config}
          title={title}
          description={description}
          tour={tour}
          t={t}
        />
      </Provider>)
      content.innerHTML = '';
      content.append(progress);
    }
  
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md',
        modalOverlayOpeningRadius: 8
      },
    });
  
    const steps = [
      {
        id: '1',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.modules.title"),
              description: t("onboarding.modules.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-modules',
          on: 'bottom'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 12 })]
        }
      },{
        id: '2',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.map.title"),
              description: t("onboarding.map.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-map',
          on: 'top'
        },
      },
    ]
    
    if (isWidgetDisplayed(config, 'Print')){
      steps.push({
        id: '3',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.print.title"),
              description: t("onboarding.print.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-print',
          on: 'left'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    if (isWidgetDisplayed(config, 'Report')){
      steps.push({
        id: '4',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.report.title"),
              description: t("onboarding.report.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-report',
          on: window.isSmall ? 'top' : 'left'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
    
    if (isWidgetDisplayed(config, 'LatestUpdatesNew')) {
      steps.push({
        id: '5',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.latestUpdates.title"),
              description: t("onboarding.latestUpdates.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-latest-updates',
          on: !window.isSmall ? 'left' : 'top'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      },)
    }
  
    if (isWidgetDisplayed(config, 'FeatureTable')) {
      steps.push({
        id: '6',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.featureTable.title"),
              description: t("onboarding.featureTable.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-feature-table',
          on: 'top'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    if (isWidgetDisplayed(config, 'Search')) {
      steps.push({
        id: '7',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.search.title"),
              description: t("onboarding.search.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-search',
          on: 'bottom'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    if (isWidgetDisplayed(config, 'Filter') && !window.isSmall) {
      steps.push({
        id: '8',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.filter.title"),
              description: t("onboarding.filter.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-filter',
          on: 'right'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    if (isWidgetDisplayed(config, 'LayerList')) {
      steps.push({
        id: '9',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.layerList.title"),
              description: t("onboarding.layerList.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-layer-list',
          on: 'right'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    if (isWidgetDisplayed(config, 'BasemapGallery')) {
      steps.push({
        id: '10',
        when:{
          show() {
            replaceContent({
              title: t("onboarding.basemap.title"),
              description: t("onboarding.basemap.description"),
              tour: tour
            })
          }
        },
        attachTo: {
          element: '.onboarding-basemap',
          on: 'top'
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 10, crossAxis: 0 })]
        }
      })
    }
  
    tour.addSteps(steps);
    
    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        const nextBtn = document.querySelector('#onboarding-next-' + tour.getCurrentStep().id);
        if (nextBtn){
          nextBtn.click();
        }
      }
    }
    
    tour.on('start', ()=>{
      document.addEventListener('keypress', handleEnter);
    })
  
    tour.on('complete', ()=>{
      document.removeEventListener('keypress', handleEnter);
    })
    
    setOnBoardingTour(tour);
    
  },[])
  
  return {
    onBoardingTour,
    setOnBoardingTour
  }
};

export default useOnboarding;