import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {
	HeaderSlideContent,
	HeaderContainer,
	Title,
	Subtitle,
	IconVisibleWrapper,
	TitleWrapper,
	HeaderLine, ModuleWrapper, HeaderIcon, HeaderCenter
} from "./Header-styled";

import {view} from "../../utils/API";
import {ConfigContext} from "../../utils/ConfigContext";
import {ROLE_EDITOR} from "../../utils/helper";
import useViewLayers from "../../hooks/useViewLayers";
import {LogIEIcon, LogisticsClusterIcon, BurgerIcon, WFPIcon, LogisticsClusterSmallIcon} from "../Icons/Header-Icons";
import {BurgerMenu, LanguageDropdown} from "./components";
import bngrc from "../Icons/img/bngrc.jpg";
import cdema_logo from "../Icons/img/cdema_logo.png"
import {useDispatch} from "react-redux";
import {setActiveModule_actionType} from "../../redux/constants";
import {applyCurrentFilters} from "../Filter/ApplyFilters";
import useOnboarding from "../../hooks/useOnboarding";
import HelpDropdown from "./components/HelpDropdown/HelpDropdown";
import {isWidgetDisplayed} from "../../esri/widgets/expandUtils";

const Header = ({module, setActiveLanguage}) => {
	const {onBoardingTour} = useOnboarding();
	const [showMenu, setMenuVisible] = useState(false);
	const [items, setItems] = useState(null);

	const { t } = useTranslation("common");
	const {config} = useContext(ConfigContext);
	const dispatch = useDispatch();
	
	useEffect(()=>{
		const modules = config.activeModules?.map((module) => {
			const currModule = config.modules[module];
			//Check for configuration error, empty layers
			if (!currModule || !currModule.layers || currModule.layers.length === 0) {return null}
			return {
				moduleName: t("screen.widget.ModuleSwitcher." + module + ".name") + " (" + module + ")",
				module: module,
			};
		}).filter((module) => module) || [];
		setItems(modules);
	},[module, config])
	
	useEffect(()=>{
		if (!isWidgetDisplayed(config, 'Onboarding')) return;
		
		
		if (Array.isArray(items) && onBoardingTour && !onBoardingTour.isActive()){
			if (items.length === 0) {
				onBoardingTour.removeStep('1');
			}
			
			const urlParams = new URLSearchParams(window.location.search);
			const widgetOpen = urlParams.get('widget-open');
			if (widgetOpen === 'onboarding') {
				onBoardingTour.start();
			}
		}
	},[items, onBoardingTour])

	const currentItem = items?.filter(item => item?.module === module);
	const currentItemName = currentItem?.[0]?.moduleName || '';
	const {localModule, setLocalModule} = useViewLayers(view, config);
	
	useEffect(()=>{
		if (localModule !== module) {
			setLocalModule(module);
		}
	},[module])

	useEffect(() => {
		if (localModule){
			applyCurrentFilters(config.defaultFilters[localModule], config)
			if (localModule !== module) {
				dispatch({type: setActiveModule_actionType, payload: localModule})
			}
		}
  }, [localModule])

	const title = t("ops." + config.id + ".title", config.title) || '';
	const subtitle = t("ops." + config.id + ".subtitle", config.subtitle)
	const isEditorMode = useMemo(()=> config.role === ROLE_EDITOR, [config]);
	const isEmbedMode = useMemo(()=> !!config.embed, [config]);
	
	const defaultOpsColor = isEditorMode ? "#fff" : "#a0a0a0"
	const opsColor = config && config.opsColor && !isEditorMode ? config.opsColor : defaultOpsColor
	
	const handleModuleChange = useCallback((module) =>{
		setLocalModule(module);
		view?.closePopup();
	},[setLocalModule]);
	
	//temporarily for demo
	const isVanuatu = useMemo(()=> config.id === 'vanuatu',[config]);
	
	const hasOnboardingWidget = useMemo(()=>{
		return isWidgetDisplayed(config, 'Onboarding');
	},[config])
	
	return (
		<HeaderContainer
			id="header"
			isEditorMode={isEditorMode}
			isEmbed={isEmbedMode}
		>
			<HeaderLine
				isEmbed={isEmbedMode}
			>
				<BurgerMenu
					key="burgerMenu"
					isEditorMode={isEditorMode}
					isEmbedMode={isEmbedMode}
					showMenu={showMenu}
					setMenuVisible={setMenuVisible}
					items={items}
					setLocalModule={handleModuleChange}
					opsColor={opsColor}
					module={module}
				>
					{hasOnboardingWidget && <>
						{config.role !== ROLE_EDITOR && !config.embed && <HelpDropdown tour={onBoardingTour} config={config} setMenuVisible={setMenuVisible}/>}
						<LanguageDropdown setActiveLanguage={setActiveLanguage} config={config} />
					</>}
				</BurgerMenu>

				<IconVisibleWrapper className={window.isSmall ? "onboarding-modules" : ''} isEditorMode={isEditorMode} opsColor={config.opsColor} isEmbed={isEmbedMode} key="smallBurgerMenu" onClick={() => setMenuVisible(!showMenu)}>
					<BurgerIcon />
				</IconVisibleWrapper>

				<HeaderIcon className="hiddenOnSmall">
					<a  href="/" style={{height: "44px"}} >
						<LogIEIcon />
					</a>
				</HeaderIcon>

				<HeaderCenter hasModules={!!items}>
					{items &&
						<ModuleWrapper className={!window.isSmall ? "onboarding-modules" : ''} id="modules">
							{items?.map((item, index) => (
								<HeaderSlideContent
									key={`mod_${index}`}
									onClick={() => {
										handleModuleChange(item.module)
									}}
									active={item.module === module}
									opsColor={opsColor}>
									{item.moduleName}
								</HeaderSlideContent>
							))}
						</ModuleWrapper>
					}
					{
						!isVanuatu && <TitleWrapper isLong={title.length > 24} isSubtitleLong={currentItemName.length > 29} isEmbed={isEmbedMode} center={!items}>
							<Title isEditorMode={isEditorMode}>{isEditorMode ? "EDITOR - " : ""} {title}</Title>
							{subtitle && items && <Subtitle>{subtitle}</Subtitle>}
							
							{items &&
								<HeaderSlideContent isEmbed={isEmbedMode}	className="subtitle" active={true} opsColor={opsColor}>
									{currentItemName}
								</HeaderSlideContent>
							}
						</TitleWrapper>
					}
				</HeaderCenter>
				{isVanuatu && <TitleWrapper
					isLong={title.length > 24}
					isSubtitleLong={currentItemName.length > 29}
					style={{justifyContent: "flex-start"}}
					isEmbed={isEmbedMode} center={!items}>
					<Title isEditorMode={isEditorMode}>{isEditorMode ? "EDITOR - " : ""} {title}</Title>
					{subtitle && items && <Subtitle>{subtitle}</Subtitle>}
					
					{items &&
						<HeaderSlideContent isEmbed={isEmbedMode} className="subtitle" active={true} opsColor={opsColor}>
							{currentItemName}
						</HeaderSlideContent>
					}
				</TitleWrapper>}
				{
					(!window.isSmall && hasOnboardingWidget) && <>
						{config.role !== ROLE_EDITOR && !config.embed && <HelpDropdown tour={onBoardingTour} config={config} setMenuVisible={setMenuVisible}/>}
						<LanguageDropdown setActiveLanguage={setActiveLanguage} config={config} />
					</>
				}
				
				{!hasOnboardingWidget && <LanguageDropdown setActiveLanguage={setActiveLanguage} config={config}/>}
				{isVanuatu && <>
					<HeaderIcon>
						<a href="https://www.ihc.ae/databank/" target="_blank" style={{display: "flex"}}>
							<img style={{width: "100%"}} src={process.env.PUBLIC_URL + "/assets/Dubai.svg"}
									 alt="disclaimer_arrow_scale"/>
						</a>
					</HeaderIcon>
					<HeaderIcon>
						<a href="https://www.esups-stockholm.org/#/private/signin" target="_blank" style={{display:"flex"}}>
							<img style={{width:"100%"}} src={process.env.PUBLIC_URL + "/assets/STOCKHOLM_logo.svg"} alt="disclaimer_arrow_scale" />
						</a>
					</HeaderIcon>
					<HeaderIcon>
						<a href="https://www.asyrec.org/" target="_blank" style={{display:"flex"}}>
							<img style={{width:"100%"}} src={process.env.PUBLIC_URL + "/assets/asyrec_logo_traced.svg"} alt="disclaimer_arrow_scale" />
						</a>
					</HeaderIcon>
				</>}
				<HeaderIcon>
					{getHeaderIcon(config)}
				</HeaderIcon>
			</HeaderLine>
		</HeaderContainer>
  	)
}

const getHeaderIcon = (config) => {
	switch (config.header.icon) {
		case "wfp":
			return <WFPIcon />
		case "cdema":
			return <div style={{
				display:'flex',
				alignItems:'center',
				gap:2
			}}>
				<WFPIcon />
				<img src={cdema_logo} alt={"cdema_logo"} width={72} height={72} />
			</div>
		case "bngrc":
			return <img src={bngrc} alt={"Bngrc"} height={50} width={50}/>
		default:
			return <a href="https://logcluster.org/en" target="_blank" style={{display:'flex'}}>
				<LogisticsClusterIcon key="lci"/>
				<LogisticsClusterSmallIcon key="lcsi"/>
			</a>
	}
}

Header.propTypes = {
	setSideBarIFrame: PropTypes.func,
	module: PropTypes.string
}

export default Header