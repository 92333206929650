import {degrees, PDFDocument, rgb} from "pdf-lib";
import openSans from "./OpenSans-Regular.ttf";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import {formatIntlDateTime} from "../../utils/helper";

//zoom dimensions [width, height]
export const ZOOM_DIMENSIONS = {
  "XSmall": [150, 100],
  "Small": [225, 150],
  "Medium": [300, 200],
  "Large": [375, 250]
}


export const ZOOM_IN = {
  width: ZOOM_DIMENSIONS.Medium[0],
  height: ZOOM_DIMENSIONS.Medium[1]
}

export const ZOOM_OUT = {
  width: 200,
  height: 150
}

export const layoutDimensions = {
  "a4-landscape": [276, 191], //[233.821, 193.2304],
  "LogIE_LogisticsCluster_A4_Landscape": [276, 191], //[233.821, 193.2304],
  "a4-portrait": [206, 258],
  "LogIE_LogisticsCluster_A4_Portrait": [206, 258],
  "a3-landscape": [399, 278],
  "LogIE_LogisticsCluster_A3_Landscape": [399, 278],
  "a3-portrait": [293, 381],
  "LogIE_LogisticsCluster_A3_Portrait": [293, 381],
  "A2 Landscape": [573, 401],
  "LogIE_LogisticsCluster_A2_Landscape": [573, 401],
  "A2 Portrait": [416, 555],
  "LogIE_LogisticsCluster_A2_Portrait": [416, 555],
}

export const convertMMtoPDF = (value) => {
  return (value / 25.400) * 72;
}

export const convertPDFToMM = (value) => {
  return (value / 72) * 25.400;
}


export const drawLegend = ({pagePdf, x, y, width, height, color, image}) =>{
  const [r,g,b] = convertHexToRGB(color);
  pagePdf.drawRectangle({
    x,
    y,
    width,
    height,
    borderColor: rgb(r,g,b),
    borderWidth: 1
  })
  
  pagePdf.drawImage(image, {
    x,
    y,
    width,
    height,
  });
}

export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth*ratio, height: srcHeight*ratio };
}

export const drawBar = async ({pagePdf, page, customFont, mapData, pdfDoc, addPublicCustomizations}) => {
  const [r, g, b] = convertHexToRGB(mapData.backgroundColor);
  const [textR, textG, textB] = convertHexToRGB(mapData.textColor);
  const textColor = rgb(textR, textG, textB);
  
  const isLandscape = page.orientation === 'Landscape'
  
  pagePdf.drawRectangle({
    x: 0,
    y: isLandscape ? 0 : pagePdf.getHeight() - 55,
    width: isLandscape ? convertMMtoPDF(19) : pagePdf.getWidth(),
    height: isLandscape ? pagePdf.getHeight() : 55,
    color: rgb(r, g, b),
  })
  
  const textWidth = customFont.widthOfTextAtSize(mapData.title, 18)
  if (mapData.title){
    pagePdf.drawText(mapData.title, {
      x: isLandscape ? 23 : pagePdf.getWidth() - textWidth - 12,
      y: isLandscape ? pagePdf.getHeight() - textWidth - 12 : pagePdf.getHeight() - 23,
      lineHeight: 1,
      size: 18,
      color: textColor,
      rotate: isLandscape ? degrees(90) : degrees(0),
      font: customFont
    })
  }
  
  if (mapData.subtitle || mapData.date) {
    const subtitle = `${mapData.subtitle ? `${mapData.subtitle}` : ''}` + `${!addPublicCustomizations ? ` ${mapData.subtitle ? '- ' : ''}${mapData.date}` : ''}`
    const dateWidth = customFont.widthOfTextAtSize(subtitle, 10)
  
    pagePdf.drawText(subtitle, {
      x: isLandscape ? 43 : pagePdf.getWidth() - dateWidth - 12,
      y: isLandscape ? pagePdf.getHeight() - dateWidth - 12 : pagePdf.getHeight() - 43,
      lineHeight: 1,
      size: 10,
      color: textColor,
      rotate: isLandscape ? degrees(90) : degrees(0),
      font: customFont
    })
  }
  
  // if (false) {
  //   //Logo on bar
  //   pagePdf.drawSvgPath('M 225 78l-89.5 51.9-135-77.9 89.4-52 Z', {
  //     x: 7,
  //     y: isLandscape ? 12 : pagePdf.getHeight() - 7,
  //     color: rgb(1, 1, 1),
  //     scale: 0.1,
  //     rotate: isLandscape ? degrees(90) : degrees(0)
  //   })
  //
  //   pagePdf.drawSvgPath('m94.9 315.5l174.7-101.6 90 52-264.7 153.9z', {
  //     x: 7,
  //     y: isLandscape ? 12 : pagePdf.getHeight() - 7,
  //     color: rgb(1, 1, 1),
  //     scale: 0.1,
  //     rotate: isLandscape ? degrees(90) : degrees(0)
  //   })
  //
  //   pagePdf.drawSvgPath('m0.5 367.8v-310.4l90 52v310.4z', {
  //     x: 7,
  //     y: isLandscape ? 12 : pagePdf.getHeight() - 7,
  //     color: rgb(1, 1, 1),
  //     scale: 0.1,
  //     rotate: isLandscape ? degrees(90) : degrees(0)
  //   })
  // } else
  
  if ((!addPublicCustomizations || (addPublicCustomizations && mapData.attachment))){
    const logoImage = await pdfDoc.embedPng(mapData.attachment);
    const maxHeight = isLandscape ? 82 : 48
    const maxWidth = isLandscape ? 48 :  82
    
    const {width, height} = calculateAspectRatioFit(logoImage.width, logoImage.height, maxWidth, maxHeight);
    pagePdf.drawImage(logoImage, {
      x: !addPublicCustomizations ? isLandscape ? 27.5 + (height / 2) : 12 : isLandscape ? 27.5 - (width / 2) : 12,
      y: isLandscape ? 12 : pagePdf.getHeight() - 27.5 - (height / 2),
      width: width,
      height: height,
      rotate: addPublicCustomizations ? degrees(0) : isLandscape ? degrees(90) : degrees(0),
    });
  }
}

function fillParagraph(text, font, fontSize, maxWidth) {
  let paragraphs = text.split('\n');
  let numOfParagraphs = paragraphs.length;
  
  for (let index = 0; index < paragraphs.length; index++) {
    let paragraph = paragraphs[index];
    if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
      let words = paragraph.split(' ');
      let newParagraph = [];
      let i = 0;
      
      newParagraph[i] = [];
      for (let k = 0; k < words.length; k++) {
        let word = words[k];
        newParagraph[i].push(word);
        if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
          numOfParagraphs++;
          newParagraph[i].splice(-1);
          i = i + 1;
          newParagraph[i] = [];
          newParagraph[i].push(word);
        }
      }
      paragraphs[index] = newParagraph.map(p => p.join(' ')).join('\n');
    }
  }
  
  return {
    text: paragraphs.join('\n'),
    height: paragraphs.join('\n').split('\n').length * font.heightAtSize(fontSize)
  };
}

const calcBottomTextCenter = (height) =>{
  return 48 - height;
}

const convertHexToRGB = (hex) => {
  hex = hex.replace("#", "")
  const r = parseInt(hex.substring(0, 2), 16) / 255
  const g = parseInt(hex.substring(2, 4), 16) / 255
  const b = parseInt(hex.substring(4, 6), 16) / 255
  return [r,g,b]
}

export const drawDisclaimer = async ({pagePdf, page, customFont, mapData, diffPxToPDF, pdfDoc, config}) =>{
  const isLandscape = page.orientation === 'Landscape';
  
  const disclaimerWidth = (pagePdf.getWidth() - (61 * diffPxToPDF) - (isLandscape ? 120 : 110)) * (isLandscape ? 0.5 : 0.4);
  const restWidth = (pagePdf.getWidth() - (61 * diffPxToPDF) - 94.17) * (isLandscape ? 0.25 : 0.35);
  const date = mapData.date ? `Date created: ${mapData.date}` : '';
  const contact = mapData.mapEmail ? `Contact: ${mapData.mapEmail}` : ''
  
  const first = `${date ? `${date} - ` : ''}${contact}`;
  const second = mapData.mapUrl ? `Website url: ${mapData.mapUrl}` : '';
  const third = mapData.mapAuthor ? `Prepared by: ${mapData.mapAuthor}` : '';
  
  const {text, height} = fillParagraph(first + '\n' + second  + '\n' + third, customFont, 4, restWidth);
  
  if (text.trim().length > 0) {
    pagePdf.drawText(text, {
      x: (isLandscape ? 61 : 42) * diffPxToPDF,
      y: 41, //calcBottomTextCenter(height),
      lineHeight: 5,
      size: 4,
      color: rgb(0,0,0) ,
      font: customFont,
      maxWidth: restWidth
    })
  }
  
  if (mapData.dataSources) {
    const dataSources = fillParagraph('Data Sources: ' + mapData.dataSources, customFont, 4, restWidth);
    pagePdf.drawText(dataSources.text, {
      x: isLandscape ? restWidth  + ((61 + 2) * diffPxToPDF) : restWidth + ((42 + 2) * diffPxToPDF),
      y: 41,//calcBottomTextCenter(dataSources.height),
      lineHeight: 5,
      size: 4,
      color: rgb(0,0,0) ,
      font: customFont,
      maxWidth: isLandscape ? restWidth : 40 * 2.85
    })
  }
  
  if (mapData.disclaimer) {
    const disclaimer = fillParagraph(mapData.disclaimer, customFont, 4, disclaimerWidth);
    pagePdf.drawText(disclaimer.text, {
      x: isLandscape ? (restWidth * 2) + ((61 + 2 + 2) * diffPxToPDF) : restWidth * 2 + ((42 + 2) * diffPxToPDF),
      y: 41,//calcBottomTextCenter(disclaimer.height),
      lineHeight: 5,
      // maxWidth: isLandscape ? disclaimerWidth : 48 * diffPxToPDF,
      size: 4,
      color: rgb(0,0,0) ,
      font: customFont,
    })
  }
  
  const logoImage = await pdfDoc.embedPng(mapData.disclaimerLogo);
  const {width:logoWidth, height:logoHeight} = calculateAspectRatioFit(logoImage.width, logoImage.height, 82, 31);
  
  const scale = 0.4;
  const logoY = calcBottomTextCenter(logoHeight) - 5;
  const logoX = pagePdf.getWidth() - convertMMtoPDF(2) - logoWidth - 1;
  
  const [r,g,b] = convertHexToRGB(config.opsColor);
  pagePdf.drawRectangle({
    width: logoWidth + 2,
    height: logoHeight + 2,
    x: logoX - 1,
    y: logoY - 1,
    borderWidth: 1,
    borderColor: rgb(r,g,b),
    borderDashArray:[3]
  })
  
  pagePdf.drawImage(logoImage, {
    x: logoX,
    y: logoY,
    width: logoWidth,
    height: logoHeight,
  });
  
  const logoText = `@ World Food Programme ${mapData.date.slice(-4)}`;
  
  pagePdf.drawText(logoText, {
    x: logoX - 1,
    y: logoY - 8,
    lineHeight: 5,
    size: 4,
    color: rgb(0,0,0) ,
    font: customFont,
  })
}

const saveDrawings = async ({images, pdfDoc})=> {
  const promises = images.map(img=>pdfDoc.embedPng(img.url))
  
  const res = await Promise.all(promises);
  return res.map((image,index)=>{
    const el = images[index];
    const imageDims = image.scale(0.1)
    return {
      image,
      x:el.x,
      y:el.y,
      width: imageDims.width,
      height: imageDims.height,
    }
  })
}

export const addElementsToPDF = async (link, preview, printableData) => {
  const {
    legend, zoom, page, mapData, config, zoomOut, activeModule, drawings, addPublicCustomizations, borderColor
  } = printableData;
  
  try {
    const url = link;
    const existingPdfBytes = await fetch(url).then((res) =>
      res.arrayBuffer()
    );
    
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    
    const pages = pdfDoc.getPages();
    const pagePdf = pages[0];
    
    const layout = `LogIE_LogisticsCluster_${page.size}_${page.orientation}`;
    const isLandscape = page.orientation === 'Landscape';
    const [layoutWidth, layoutHeight] = layoutDimensions[layout];
    
    const previewWidth = preview.width;
    const previewHeight = preview.height;
    const diffPxToPDF = convertMMtoPDF(layoutWidth) / layoutWidth;
    const diff = (previewWidth / (layoutWidth - (0.8)));
    const diffH = (previewHeight) / (layoutHeight - 0.4);
    const leftOffset = isLandscape ? convertMMtoPDF(19) : convertMMtoPDF(2); //19 is bar with title 2 is map offset
    const topOffset = isLandscape ? convertMMtoPDF(17) : convertMMtoPDF(19);
    
    if (legend.uri.length > 0) {
      // Embed the JPG image bytes and PNG image bytes
      const pngImage = await pdfDoc.embedPng(legend.uri);
      const imageWidth = convertMMtoPDF(legend.width / diff);
      const imageHeight = convertMMtoPDF(legend.height / diffH);
      const imageX = convertMMtoPDF(legend.x / diff) + leftOffset;
      const imageY = convertMMtoPDF(legend.y / diffH) + topOffset;
  
      drawLegend({
        pagePdf,
        x:imageX,
        y:imageY,
        width: imageWidth,
        height: imageHeight,
        color: borderColor,
        image: pngImage
      })
    }
    
    if (zoom.url) {
      const zoomMapPdfBytes = await fetch(zoom.url).then((res) =>
        res.arrayBuffer()
      );
      const zoomPages = await PDFDocument.load(zoomMapPdfBytes);
      
      const [zoomMapPdf] = await pdfDoc.embedPdf(zoomMapPdfBytes);
      
      const diff = (previewWidth / (layoutWidth));
      const diffH = (previewHeight) / (layoutHeight);
      const [r,g,b] = convertHexToRGB(borderColor);
      const zoomWidth = zoom.width + 2;
      const zoomHeight = zoom.height + 2;
      
      if (zoom.coordinates) {
        const zoomPreviewWidth = zoom.coordinates.width;
        const zoomPreviewHeight = zoom.coordinates.height;
        
        pagePdf.drawRectangle({
          width: convertMMtoPDF(zoomPreviewWidth / diff),
          height: convertMMtoPDF(zoomPreviewHeight / diffH),
          x: convertMMtoPDF(zoom.coordinates.x / diff) + leftOffset,
          y: convertMMtoPDF((previewHeight - zoom.coordinates.y - zoom.coordinates.height) / diffH) + topOffset,
          borderWidth: 1,
          borderColor: rgb(r,g,b),
        })
      }
      
      pagePdf.drawPage(zoomMapPdf, {
        width: convertMMtoPDF(zoomWidth / diff),
        height: convertMMtoPDF(zoomHeight / diffH),
        x: convertMMtoPDF(zoom.x / diff) + leftOffset,
        y: convertMMtoPDF(zoom.y / diffH) + topOffset,
      });
      
      pagePdf.drawRectangle({
        width: convertMMtoPDF(zoomWidth / diff),
        height: convertMMtoPDF(zoomHeight / diffH),
        x: convertMMtoPDF(zoom.x / diff) + leftOffset,
        y: convertMMtoPDF(zoom.y / diffH) + topOffset,
        borderWidth: 1,
        borderColor: rgb(r,g,b),
      })
    }
    
    if (zoomOut.url) {
      const zoomOutMapPdfBytes = await fetch(zoomOut.url).then((res) =>
        res.arrayBuffer()
      );
      const [zoomMapPdf] = await pdfDoc.embedPdf(zoomOutMapPdfBytes);
      
      const diff = (previewWidth / (layoutWidth));
      const diffH = (previewHeight) / (layoutHeight);
      const zoomOutWidth = convertMMtoPDF(zoomOut.width / diff);
      const zoomOutHeight = convertMMtoPDF(zoomOut.height / diffH);
      const zoomOutX = convertMMtoPDF(zoomOut.x / diff) + leftOffset;
      const zoomOutY = convertMMtoPDF(zoomOut.y / diffH) + topOffset;
  
      pagePdf.drawPage(zoomMapPdf, {
        width: zoomOutWidth,
        height: zoomOutHeight,
        x: zoomOutX,
        y: zoomOutY,
      });
  
      const [r,g,b] = convertHexToRGB(borderColor);
      pagePdf.drawRectangle({
        width: zoomOutWidth,
        height: zoomOutHeight,
        x: zoomOutX,
        y: zoomOutY,
        borderWidth: 1,
        borderColor: rgb(r,g,b),
      })

      const zoomOutAreaWidth = convertMMtoPDF(zoomOut.area.width / diff);
      const zoomOutAreaHeight = convertMMtoPDF(zoomOut.area.height / diffH);
      
      pagePdf.drawRectangle({
        width: zoomOutAreaWidth,
        height: zoomOutAreaHeight,
        x: (zoomOutX + (zoomOutWidth / 2)) - (zoomOutAreaWidth / 2),
        y: (zoomOutY + (zoomOutHeight / 2)) - (zoomOutAreaHeight / 2),
        borderWidth: 1,
        borderColor: rgb(r,g,b),
      })
    }
  
    if (drawings.active){
      const drawElements = await saveDrawings({
        images: drawings.images,
        pdfDoc,
      })
    
      drawElements.forEach(drawing=>{
        const diff = (previewWidth / (layoutWidth));
        const diffH = (previewHeight) / (layoutHeight);
        const imageX = convertMMtoPDF(drawing.x / diff) + leftOffset;
        const imageY = convertMMtoPDF((previewHeight - drawing.y - drawing.height) / diffH) + topOffset;
        const imageWidth = convertMMtoPDF(drawing.width / diff);
        const imageHeight = convertMMtoPDF(drawing.height / diffH);
      
        pagePdf.drawImage(drawing.image, {
          x: imageX,
          y: imageY,
          width: imageWidth,
          height: imageHeight,
        });
      })
    }
    
    const fontBytes = await fetch(openSans).then(res => res.arrayBuffer())
    pdfDoc.registerFontkit(fontkit)
    const customFont = await pdfDoc.embedFont(fontBytes)
    
    await drawBar({
      pagePdf,
      page,
      customFont,
      mapData,
      pdfDoc,
      addPublicCustomizations
    })
    
    await drawDisclaimer({
      pagePdf,
      page,
      customFont,
      mapData,
      diffPxToPDF,
      addPublicCustomizations,
      pdfDoc,
      config
    })
    
    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
  
    const date = formatIntlDateTime({
      date: new Date(),
      separator: ''
    })
    const title = !addPublicCustomizations ? `${config.title ?? ''}${activeModule ? `_${activeModule}` : ''}` : `${mapData.title ?? ''}${mapData.subtitle ? `_${mapData.subtitle}` : ''}`;
    await download(
      pdfBytes,
      `${title}${date ? `_${date}` : ''}`,
      "application/pdf"
    );
  } catch (error) {
    console.error("oops, something went wrong!", error);
  }
};