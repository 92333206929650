import styled from "styled-components";
import {secondaryColor} from "../../../../utils/Theme";

export const PositioningContainer = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 999;
  flex-direction: row;
`

export const PositioningDropdown = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  z-index: 100000;

  &:hover {
    background: lightgray;
  }

  &:hover ${PositioningContainer} {
    display: block;
  }
  @media (max-width: 450px) or (max-height: 450px) {
    display: none;
  }
`

export const PositioningTitle = styled.div`
  font-size: .775em;
  font-weight: bold;
  padding-top: .1em;
  &:hover {
    cursor: pointer;
  }
`

export const ItemDropdown = styled.div`
  &:hover {
    cursor: pointer;
    background: ${secondaryColor};
    z-index: 100000;
  }
`