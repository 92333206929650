import {resetPanel, setPanelFormData_actionType} from "../constants"
const initialState = {}

const panelFormData = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setPanelFormData_actionType:
            return state = payload
        case resetPanel:
            return state = initialState
        default:
            return state
    }
}

export default panelFormData