import {Container, ListItemStyled, Step} from "../BatchEditor/BatchEditor-styled";
import {CalciteButton, CalciteList} from "@esri/calcite-components-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {graphicsLayer, isdeletedFieldName, opidFieldName, showpublicFieldName, view} from "../../utils/API";
import {getConfigISO, getFieldsByPattern, renderCellInEditor} from "../../utils/helper";
import {getFeatureTitle} from "../../esri/custom-popup-content";
import {dangerColor} from "../../utils/Theme";
import {StyledLoader} from "../App/App-styled";
import UploadMediaFiles from "./UploadMediaFiles/UploadMediaFiles";

/**
 * Replacement of the standard editor widget
 *
 * steps for the different workflows:
 *
 * 0: Select layer
 * 1: Select operation: Create, Modify, Delete
 * 2: Click on the map / Select existing feature
 * 3: Edit feature attributes & save
 * 4: Delete confirmation
 *
 */

const supportedGeometryTypes = [
    "point",
    "polyline",
    "polygon"
]

const pointSymbol = {
    type: "simple-marker",
    size: 8,
    color: dangerColor,
    outline: {
        width: 1,
        color: "#FFF"
    }
}

const lineSymbol = {
    type: "simple-line",
    color: "red",
    width: 1
}

const fillSymbol = {
    type: "simple-fill",
    color: dangerColor,
    style: "solid",
    outline: { color: "black", width: 1}
}

const EditType = {
    edit : "edit",
    create : "create",
    delete : "delete"
}

const Editor = ({ config, expand, t, openSnackbar, reactiveUtils, Polyline, Polygon}) => {
    /**
     * Current step
     */
    const [step, setStep] = useState(0)

    /**
     * All editable layers
     */
    const [editableLayers, setEditableLayers] = useState([])

    const [_, setVisibleEditableLayers] = useState([])

    /**
     * The layer selected for edition
     */
    const [editableLayer, setEditableLayer] = useState()

    /**
     * Create, edit, delete
     */
    const [editType, setEditType] = useState()

    /**
     * Remember the point of the last click
     */
    const [clickEvent, setClickEvent] = useState()

    /**
     * New feature points (only one for pointFeature, more for polyline and polygon)
     */
    const [addedPoints, setAddedPoints] = useState([])
    /**
     * The highlighed feature and the handle to be able to remove the highlight
     */
    const [highlightHandle, setHighlightHandle] = useState()
    const [highlightFeature, setHighlightFeature] = useState()
    const [highlightSymbol, setHighlightSymbol] = useState()
    const [layerLabel, setLayerLabel] = useState();
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    
    /**
     * Handle to remove the registered drag & drop event
     */
    const [dragHandle, setDragHandle] = useState()

    useEffect( () => {
        if (step <= 2) {
            graphicsLayer.removeAll()
            setAddedPoints([])

            highlightHandle?.remove()
            setHighlightFeature(null)
            setHighlightSymbol(null)
        }

        if (step === 3 && editType === EditType.edit) {
            highlightFeature.layer?.renderer?.getSymbolAsync(highlightFeature).then((symbol) => {
                setHighlightSymbol(symbol)
            })
        }

        dragHandle?.remove()
    }, [step])

    /**
     * Click on the map...at the moment there are 2 use cases
     *  - adding points to a new feature (step 2)
     *  - selecting feature to edit
     */
    useEffect( () => {
        if (step !== 2 || !clickEvent)
            return

        if (editType === EditType.create) {
            addPointToNewFeature(clickEvent.mapPoint)
            // view.hitTest(clickEvent).then((res) => {
            //
            //     // console.log(editableLayer.groupedIds, res?.results.map(res=>res.layer?.id))
            //
            //     const firstRes = res?.results.filter((res) => editableLayer.groupedIds.includes(res.graphic.layer.id) || editableLayer.groupedIds.includes(res.graphic.sourceLayer.id)).at(0)
            //     console.log(firstRes, res);
            //
            //     if (!firstRes)
            //         return
            // })

        } else if (editType === EditType.edit) {
            view.hitTest(clickEvent).then((res) => {
                const firstRes = res?.results.filter((res) => res.graphic.layer === editableLayer || res.graphic.sourceLayer === editableLayer).at(0)
                // const firstRes = res?.results.filter((res) => editableLayer.groupedIds.includes(res.graphic.layer.id) || editableLayer.groupedIds.includes(res.graphic.sourceLayer.id)).at(0)
                
                if (!firstRes)
                    return

                highlightHandle?.remove()
                view.whenLayerView(editableLayer).then((layerView) => {
                    const query = editableLayer.createQuery();
                    const objectIdField = editableLayer.objectIdField;
                    query.objectIds = [firstRes.graphic.attributes[objectIdField]];
                    query.outFields = ['*'];
                    let editingGraphic = firstRes.graphic
                    
                    setHighlightHandle(layerView.highlight(editingGraphic))
                    editingGraphic.layer?.renderer?.getSymbolAsync(editingGraphic).then((symbol) => {
                        setHighlightSymbol(symbol)
                    })
                    
                    editableLayer.queryFeatures(query).then((result) => {
                        const graphic = result.features.find(feat=>feat[objectIdField] === editingGraphic[objectIdField])
                        
                        if (!!graphic) {
                            editingGraphic = graphic;
                        }

                        setHighlightFeature(editingGraphic)
                    }).catch(err=>{
                        setHighlightFeature(editingGraphic)
                    })
                    
                    //reassigning editable layer for save
                    // firstRes.layer.groupedIds = editableLayer.groupedIds;
                    // setEditableLayer(firstRes.layer);
                })
            })
        }
    }, [clickEvent])

    const addPointToNewFeature = (newPoint) => {
        view.graphics.removeAll()
        const geomType = editableLayer.geometryType
        if (geomType === "point")
            addedPoints.pop()

        addedPoints.push(newPoint)
        if (geomType === "polyline") {
            const line = new Polyline({spatialReference: newPoint.spatialReference})
            line.addPath(addedPoints)
            graphicsLayer.add({geometry: line, symbol: lineSymbol})

        } else if (geomType === "polygon") {
            const polygon = new Polygon({spatialReference: addedPoints[0].spatialReference})
            polygon.addRing(addedPoints)
            graphicsLayer.add({geometry: polygon, symbol: fillSymbol})
        }

        graphicsLayer.add({geometry: newPoint, symbol: pointSymbol})
        setAddedPoints(addedPoints)
    }

    useEffect(() => {
        if (!highlightFeature || !expand.expanded || step !== 3)
            return

        dragHandle?.remove()
        setDragHandle(view.on("drag", async (event) => {
            if (!highlightFeature || !expand.expanded)
                return

            if (event.action === "start"){
                //Drag starting, search for graphics around the mouse
            } else if (event.action === "update" || event.action === "end") {
                //Dragging: adjust position
                highlightFeature.geometry = view.toMap({x: event.x, y: event.y})

                const nGraphic = highlightFeature.clone()
                nGraphic.symbol = highlightSymbol ? highlightSymbol : pointSymbol
                view.graphics.removeAll()
                view.graphics.add(nGraphic)
                event.stopPropagation()
            }
        }))
    }, [highlightFeature, highlightSymbol, step])


    const clickEventInPopup = useCallback ((feature) => {
        const layer = feature.sourceLayer ? feature.sourceLayer : feature.layer
        feature.geometry = feature.originalGeometry? feature.originalGeometry : feature.geometry

        view.whenLayerView(layer).then((layerView) => {
            setEditableLayer(layer)
            setHighlightHandle(layerView.highlight(feature))
            setHighlightFeature(feature)
            setEditType(EditType.edit)
            setStep(3)
        })
    }, [step, highlightFeature, editableLayer, editType])

    useEffect(() => {
        view.on("click", (event) => { setClickEvent(event)})
        let layerViewFilters = {};
        reactiveUtils.watch(() => expand.viewModel.expanded, () => {
            if (expand.viewModel.expanded){
                view.layerViews.forEach(lv=>{
                    if (lv.filter?.where) {
                        layerViewFilters[lv.layer.id] = lv.filter.where;
                        lv.filter.where = '';
                    }
                })
            } else {
                setStep(0)
                setAttachments([]);
                view.layerViews.forEach(lv=>{
                    if (layerViewFilters[lv.layer.id]) {
                        lv.filter.where = layerViewFilters[lv.layer.id];
                    }
                })
                layerViewFilters = {};
            }
        })

        updateLayers(view.map.layers)
        view.map.layers.on("change", (event) => {
            if (event.added)
                updateLayers(event.added)
        })

        /** Push edit button on the popup */
        reactiveUtils.on(() => view.popup, "trigger-action", (event) => {
            if (event.action.id !== "edit-action")
                return

            const feature = view.popup.selectedFeature
            view.closePopup()

            expand.expanded = true
            clickEventInPopup(feature)
        })
    }, [])

    const groupLayers = (layers) => {
        const layersByLabel = {};

        layers.forEach(l=>{
            const label = l.layerConfig?.titleLabel;
            if (label && l.visible) {
                if (layersByLabel[label]) {
                    layersByLabel[label].groupedIds.push(l.id)
                } else {
                    layersByLabel[label] = l;
                    layersByLabel[label].groupedIds = [l.id];
                }
            }
        })
        return layersByLabel;
    }
    
    const updateLayers = (newLayers) => {
        const newEditableLayers = newLayers.filter((l) => isEditable(l))
        newEditableLayers.forEach((el) => {
            el.watch("visible", () => {
                setVisibleEditableLayers(editableLayers.filter((l) => l.visible))
                setStep(0)
            })
        })

        if (newEditableLayers.length) {
            setEditableLayers(view.map.layers.filter((l) => isEditable(l)))
        }

    }

    const isEditable = (l) => {
        return l.type === "feature" && supportedGeometryTypes.includes(l.geometryType) && l.editingEnabled && l.layerConfig?.editable
    }

    const selectEditableLayer = (layerId) => {
        // setEditableLayer(layer);
        const selectedLayer = editableLayers.filter((layer) => {return layer.id === layerId})
        if (selectedLayer)
            setEditableLayer(selectedLayer.getItemAt(0))
    }

    const selectEditType = (editType) => {
        setEditType(editType)
        setStep(step+1)
    }

    const getCalciteButton = (key, label, icon, onClick, disabled, appearance = "solid") => {
        return disabled?
            <CalciteButton scale="s" key={key} iconStart={icon} disabled appearance={appearance}>{label}</CalciteButton> :
            <CalciteButton scale="s" key={key} iconStart={icon} onClick={onClick} appearance={appearance}>{label}</CalciteButton>
    }

    const getField = (f) => {
        const label = t('layer.fieldAlias.' + f.name, f.alias)
        //@todo: this should be null
        let value = ""
        if (editType === EditType.create){
            value = getFieldDefaultValue(f)
        } else if (editType === EditType.edit && highlightFeature.attributes[f.name] != null) {
            if (f.type === "date" )
                value = new Date(highlightFeature.attributes[f.name]).toISOString().substring(0, 10)
            else
                value = highlightFeature.attributes[f.name]
        }

        return <label key={"l" + f.name} className="esri-feature-form__label">
            {label}
            {renderCellInEditor(f, value)}
        </label>
    }

    const getFieldDefaultValue = (f) => {
        if (f.name === 'iso3' || f.name === 'iso3a'){
            const iso3 = getConfigISO(config);
            return iso3 ? Array.isArray(iso3) ? iso3[0] : iso3 : ""
        } else if (f.name === opidFieldName){
            return config.id
        } else if (f.name === isdeletedFieldName || f.name === showpublicFieldName){
            return 2
       } else if (f.defaultValue)
            return f.defaultValue
    }

    const getAccordionItem = (fieldSet, title) => {
        if (fieldSet.length === 0)
            return

        return <calcite-accordion-item heading={title}>
            {fieldSet.map((f) => getField(f))}
        </calcite-accordion-item>
    }

    const getFields = () => {
        const el = editableLayer
        const lc = el.layerConfig

        const visibilityFields = getFieldsByPattern(el, lc.visibilityFields).filter((f) => f.editable)
        const situationalFields = getFieldsByPattern(el, lc.situationalFields).filter((f) => f.editable)
        const baselineFields = getFieldsByPattern(el, lc.baselineFields).filter((f) => f.editable)
        return <calcite-accordion>
            {getAccordionItem(visibilityFields, t('screen.widget.Editor.visibilityFields.label'))}
            {getAccordionItem(situationalFields, t('screen.widget.Editor.situationalFields.label'))}
            {getAccordionItem(baselineFields, t('screen.widget.Editor.baselineFields.label'))}
            {editableLayer.capabilities.operations.supportsQueryAttachments &&
              <UploadMediaFiles layer={editableLayer} feature={highlightFeature} t={t} attachments={attachments}
                                setAttachments={setAttachments}/>}
        </calcite-accordion>
    }

    const doEdit = async () => {
        const inputs = document.querySelectorAll("#inputFields [data-field-name]")
        const atts = {}
        editableLayer.fields.forEach((f) =>{
            const defaultValue = getFieldDefaultValue(f)
            if (defaultValue)
               atts[f.name] = defaultValue
        })

        Array.from(inputs).forEach( (el) => {
            const fieldName = el.getAttribute("data-field-name")
            let val = el.value
            if (el.value && el.getAttribute("type") === "date"){
                val = el.valueAsDate.getTime()
            }

            if (el.value === '')
                val = null

            if (editType === EditType.edit){
                highlightFeature.attributes[fieldName] = val
            } else {
                atts[el.getAttribute("data-field-name")] = val
            }
        })

        const geometry = getGeometry()
        if (editType === EditType.create && !geometry)
            return

        if (editType === EditType.edit && highlightFeature.originalGeometry)
            highlightFeature.geometry = highlightFeature.originalGeometry
        
        setLoading(true);
        
        try {
            await editableLayer.applyEdits({
                addFeatures: (editType === EditType.create) ? [{geometry: geometry, attributes: atts}] : [],
                updateFeatures: (editType === EditType.edit) ? [highlightFeature] : []
            });
    
            if (attachments.length > 0) {
                const promises = [];
                attachments.forEach((file)=>{
                    const formData = new FormData();
                    formData.set('attachment', file)
                    promises.push(editableLayer.addAttachment(highlightFeature, formData))
                })
                
                await Promise.all(promises);
            }

            view.graphics.removeAll();
            onEditComplete();
        } catch (err) {
            console.log(err)
            openSnackbar(t("screen.message.error"), 15000);
            setLoading(false);
        }
    }

    /**
     * Returns the geometry that will be added as a new feature to the map
     * @returns {*}
     */
    const getGeometry = () => {
        if (addedPoints.length === 0)
            return

        if (editableLayer.geometryType === "point"){
            return addedPoints[0]

        } else if (editableLayer.geometryType === "polyline"){
            const polyline = new Polyline({spatialReference: addedPoints[0].spatialReference})
            polyline.addPath(addedPoints)
            return polyline

        } else if (editableLayer.geometryType === "polygon") {
            const polygon = new Polygon({spatialReference: addedPoints[0].spatialReference})
            polygon.addRing(addedPoints)
            return polygon
        }
    }
    const doDelete = (doDelete) => {
        if (!getIsDeletedField()){
            setStep(1)
            return
        }

        highlightFeature.attributes[isdeletedFieldName] = doDelete ? 1 : 2
        setLoading(true);
        editableLayer.applyEdits({
            updateFeatures: [highlightFeature]
        }).then(() => {
            view.graphics.removeAll()
            onEditComplete()
        }).catch(err=>{
            openSnackbar(t("screen.message.error"), 15000);
            setLoading(false);
        })
    }

    const onEditComplete = () => {
        view.whenLayerView(editableLayer).then((layerView) => {
            reactiveUtils.whenOnce(() => !layerView.updating).then(() => {
                setStep(0);
                setLoading(false);
                setAttachments([]);
                expand.collapse();
                openSnackbar(t("screen.message.batchEditorReady"), 15000)
            })
        })
    }

    const getIsDeletedField = () => {
        if (!highlightFeature)
            return

        const layer = highlightFeature.sourceLayer ? highlightFeature.sourceLayer : highlightFeature.layer
        return layer.fields.filter((f) => f.name === isdeletedFieldName)[0]
    }
    
    
    const groupedLayers = useMemo(()=>{
        return groupLayers(editableLayers)
    },[editableLayers])
    
    //if (!expand.expanded)
    //    return null

    return (
        <Container className="esri-widget esri-widget--panel">
            <header>{t('screen.widget.Editor.name')}</header>
            
            {loading && <StyledLoader scale="s" style={{color: config.opsColor}}/> || (step === 0 &&
                <Step>
                    <h3>{t('screen.widget.BatchEditor.selectLayer')}</h3>
                    <CalciteList >
                        { editableLayers.filter((l) => l.visible).map((layer) =>
                            <ListItemStyled key={"li_" + layer.id} label={layer.getLayerTitle(t)}
                                            onClick={() => {
                                                selectEditableLayer(layer.id)
                                                setStep(step+1)
                                            } }
                                            selected={(editableLayer && layer === editableLayer)}/>
                        )}
                    </CalciteList>
                    {/*Left for future improvements when we will work on editor widget soon*/}
                    {/*<CalciteList>*/}
                    {/*    {*/}
                    {/*        Object.values(groupedLayers).map(l=>(*/}
                    {/*            <ListItemStyled*/}
                    {/*              key={"li_" + l.id} label={l.getLayerTitle(t)}*/}
                    {/*              onClick={() => {*/}
                    {/*                  selectEditableLayer(l)*/}
                    {/*                  setStep(step+1)*/}
                    {/*              } }*/}
                    {/*              selected={(editableLayer && l === editableLayer)}*/}
                    {/*            />*/}
                    {/*        ))*/}
                    {/*    }*/}
                    {/*</CalciteList>*/}
                </Step>
            ) || (step === 1 &&
                <Step>
                    <h3>{t('screen.widget.BatchEditor.selectedLayer')}: {editableLayer.getLayerTitle(t)}</h3>
                    <CalciteList >

                        <ListItemStyled key={"li_e"} label="Edit" onClick={() => selectEditType(EditType.edit)}/>
                        {editableLayer?.layerConfig?.featureCreationEnabled !== false &&
                            <ListItemStyled key={"li_c"} label="Create" onClick={() => {selectEditType(EditType.create)}}/>}
                    </CalciteList>

                    {getCalciteButton("prev", t('screen.widget.BatchEditor.back'), "arrow-bold-left", () => setStep(step-1), false)}
                </Step>
            ) || (step === 2 &&
                <Step >
                    <h3>{t('screen.widget.BatchEditor.selectedLayer')}: {editableLayer.getLayerTitle(t)}</h3>
                    {editType === EditType.edit  && !highlightFeature && <h4>Please select a feature on the map</h4>}
                    {editType === EditType.edit  && highlightFeature && <h4>Edit feature: {getFeatureTitle(highlightFeature, t)}</h4>}
                    {editType === EditType.create  && <h4>Draw on the map to add a new feature</h4>}
                    {editType === EditType.delete  && <h4>Please select a feature on the map</h4>}

                    <div style={{display:"flex", justifyContent: "center", gap: 5, padding: "1em 0"}}>
                        {getCalciteButton("prev", t('screen.widget.BatchEditor.back'), "arrow-bold-left", () => setStep(step-1), false)}
                        {getCalciteButton("next", t('screen.widget.BatchEditor.next'), "arrow-bold-right", () => setStep(step+1),
                            (editType === EditType.create && clickEvent === null) || (editType === EditType.edit && highlightFeature === null))}
                    </div>
                </Step>
            ) || (step === 3 && [
                <h3 key="st2h">{t('screen.widget.BatchEditor.selectedLayer')}: {editableLayer.getLayerTitle(t)}</h3>,
                <div key="st2i" id="inputFields">{getFields()}</div>,
                <div key="st2b" style={{display:"flex", justifyContent: "center", gap: 5, padding: "1em 0"}}>
                    {getCalciteButton("prev", t('screen.widget.BatchEditor.back'), "arrow-bold-left", () => setStep(1), false)}
                    {getCalciteButton("save", t('screen.widget.BatchEditor.save'), "save", () => doEdit(), false)}
                    {getIsDeletedField() && highlightFeature.attributes[isdeletedFieldName] !== 1
                        && getCalciteButton("del", t('screen.widget.Editor.delete'), "trash", () => setStep(4), false)}
                    {getIsDeletedField() && highlightFeature.attributes[isdeletedFieldName] === 1
                        && getCalciteButton("del", t('screen.widget.Editor.undelete'), "trash", () => setStep(4), false)}
                </div>
            ]) || (step === 4 && [
                <h3 key="st4h">{t('screen.widget.BatchEditor.selectedLayer')}: {editableLayer.getLayerTitle(t)}</h3>,
                <h4 key="st4q">
                    {highlightFeature.attributes[isdeletedFieldName] !== 1 ? t('screen.widget.Editor.deleteConfirmation')
                        : t('screen.widget.Editor.undeleteConfirmation')}
                </h4>,

                <div key="st2b" style={{display:"flex", justifyContent: "center", gap: 5, padding: "1em 0"}}>
                    {getCalciteButton("prev", t('screen.widget.BatchEditor.back'), "arrow-bold-left", () => setStep(1), false)}
                    {highlightFeature.attributes[isdeletedFieldName] !== 1
                        && getCalciteButton("del", t('screen.widget.Editor.delete'), "trash", () => doDelete(true), false)}
                    {highlightFeature.attributes[isdeletedFieldName] === 1
                        && getCalciteButton("del", t('screen.widget.Editor.undelete'), "trash", () => doDelete(false), false)}
                </div>
            ])}
        </Container>
    )
}

export default Editor