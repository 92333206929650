import {view} from "../../utils/API";
import {EMBED_VARIANTS, ROLE_EDITOR} from "../../utils/helper";

const autoCollapseGroup = "autoCollapseGroup"

export const createAutoCollapseExpand = (Expand, view, config, t, widgetId, content, icon) => {
	let expand = createExpand(Expand, view, config, t, widgetId, content, icon)
	//expand.autoCollapse = true
	expand.group = autoCollapseGroup

	return expand
}

export const createExpand = (Expand, view, config, t, widgetId, content, icon) => {
	removeWidget(widgetId)
	return new Expand({
		expandIconClass: icon,
		autoCollapse: false,
		view: view,
		content: content,
		expanded: isWidgetExpanded(config, widgetId),
		expandTooltip: t('screen.widget.' + widgetId + '.name'),
		id: widgetId
	})
}

export const isWidgetExpanded = (config, widgetName) =>{
	return (!window.isSmall && config.openWidgets && config.openWidgets.includes(widgetName))
}

export const removeWidget = (widgetId) => {
	if (!view || !view.ui)
		return

	const existingWidget = view.ui.find(widgetId)
	if (existingWidget)
		view.ui.remove(existingWidget)
}

export const collapseWidget = (widgetId) => {
	const existingWidget = view.ui.find(widgetId);
	if (existingWidget)
		existingWidget.viewModel.expanded = false;
}

export const expandWidget = (widgetId) => {
	const existingWidget = view.ui.find(widgetId);
	if (existingWidget)
		existingWidget.viewModel.expanded = true;
}

export const isWidgetDisplayed = (config, widgetName) =>{
	if (config.role === ROLE_EDITOR)
		return inArray(config.widgetsForEditors, widgetName)

	if (config.embed === EMBED_VARIANTS.WEBSITE)
		return inArray(config.widgetsForEmbed, widgetName)
	
	if (config.embed === EMBED_VARIANTS.APP)
		return inArray(config.widgetsForAppEmbed, widgetName)

	if (window.isSmall)
		return inArray(config.widgetsForSmallScreen, widgetName)

	return inArray(config.widgets, widgetName)
}

const inArray = (array, value) => {
	return (array && array.includes(value)) ?? false
}