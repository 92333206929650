import React from "react";
import {Container} from "./Button-styled";
import {getColorValue} from "../../helpers";
import {darkColor} from "../../../../utils/Theme";
const Button = ({specs, onClick}) => {
    //variant={"filled"} {variant, color, content, disabled=false}
    let {variant, color, content, disabled} = specs
    variant = variant ?? "filled"
    const getStyleValue = () => {
        const colors = getColorValue(color, color ? "#FFFFFF" : darkColor, "#FFFFFF")
        switch (variant) {
            case "tonal":
                return {
                background: colors.tonal ?? colors.background,
                color: colors.color
            }
            case "outlined":
                return {
                    background: "#FFFFFF",
                    border: `1px solid ${colors.background}`,
                    color: colors.background
                }
            case "filled":
            default:
                return {
                background: colors.background,
                color: colors.color
            }
        }
    }
    const handleClick = () => {
        if (typeof onClick === 'function')
            onClick()
    }
    const styleValue = {...getStyleValue(), cursor: disabled ? "not-allowed" : "pointer"}
    return (
        <Container disabled={disabled ?? false} style={styleValue} onClick={handleClick}>
            {content ?? "Submit"}
        </Container>
    )
}
export default Button