import styled from "styled-components";

export const ReportContent = styled.div`
	margin: 16px 0 16px 0;
	padding: 0;
	width: 100%;
`;

export const MainQuestion = styled.h2`
	text-align: left;
	padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #031C2D;
    width: 100%;
    margin: 0;
`;

export const MainHint = styled.h4`
	width: 100%;
	text-align: left;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	color: #333945;
	margin: 8px 0 0 0;
`;
