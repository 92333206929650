import {StyledDropdownBody} from "./Dropdown-styled";

const DropdownBody = ({children, ...props}) => {
  return (
    <StyledDropdownBody {...props}>
      {children}
    </StyledDropdownBody>
  );
};

export default DropdownBody;