import React, {useEffect, useState} from "react";
import {CategoriesCard} from "../Reports-styled";
import {StyledSectionDescription} from "../ScrollSection/ScrollSection-styled";

const RoadConditions = [
  {
    title: 'Passable',
    key: "passable",
    id: 10,
    bgColor: '#15803da8',
    selectedColor: '#15803d',
    color: "#fff"
  },
  {
    title: 'Passable with difficulties / damaged',
    key: "passableWithDifficulties",
    id: 10,
    bgColor: '#fdba74',
    selectedColor: '#f97316',
    color: "#fff"
  },
  {
    title: 'Not passable',
    key: "notPassable",
    id: 1,
    bgColor: '#fca5a5',
    selectedColor: '#b91c1c',
    color: "#fff"
  },
];

const optionalVehicleTypes = [
  {
    id:2,
    title: 'By foot only',
    key: "byFoot"
  },
  {
    id:3,
    title: 'Motorcycle',
    key: "motorcycles"
  },
  {
    id:4,
    title: 'Car/minivans',
    key:"minivans",
  },
  {
    id:5,
    title: '4x4',
    key:"4x4"
  },
  {
    id:6,
    title: 'Light trucks ( < 20 MT)',
    key: "lightTrucks"
  },
  {
    id:8,
    title: 'Medium trucks ( > 20 MT)',
    key: "mediumTrucks"
  }
]


const VehiclePracticability = ({form, setForm, goNext, layer, config, t}) => {
  const [index, setIndex] = useState(-1);
  
  useEffect(()=>{
    if (!layer) return;
    const layerField = layer.fields.filter((field) => field.name === "currvehtype")[0];
    if (!layerField || layerField.length === 0) {
      console.warn("warning: Report must refer a valid layer field. Layer: " + layer.title + " Field: currvehtype")
      return
    }
    const vehicleTypes = layerField.domain.codedValues;
  },[])
  
  const onSelect = (value, index) => {
    setForm(form=>({...form, currvehtype: value }))
    if (goNext && index !== 1) {
      goNext()
    }
    
    if (value === 1 || value === 10) {
      setIndex(index)
    }
  }
  
  return (
    <>
      {
        RoadConditions.map((condition, i)=>(
          <CategoriesCard
            key={condition.key}
            selected={index === i}
            onClick={() => onSelect(condition.id, i)}
            bgColor={index === i ? condition.selectedColor : condition.bgColor}
            color={condition.color}
          >
            <span>
              {t('report.pac.pages.vehiclePracticability.currvehtype.' + condition.key)}
            </span>
          </CategoriesCard>
        ))
      }
      <div style={{
        width:'100%',
        flexDirection:'row',
        flexWrap:'wrap',
        gap:'8px',
        display:'flex',
        opacity: index === 1 ? 1 : 0,
        pointerEvents: index === 1 ? 'auto' : 'none',
        transition: 'all 0.5s'
      }}>
        <StyledSectionDescription style={{width:'100%'}}>{t('report.pac.pages.vehiclePracticability.subtitle')}</StyledSectionDescription>
        {
          optionalVehicleTypes.map(vehicle=>(
            <CategoriesCard
              key={vehicle.id}
              selected={form.currvehtype === vehicle.id}
              className={form.currvehtype === vehicle.id ? "blink_me" : ""}
              onClick={()=>onSelect(vehicle.id)}
              borderColor={config.opsColor}
              bgColor={form.currvehtype === vehicle.id && config.opsColor ? `${config.opsColor}1a` : ''}
              
            >
              <span>
                {t('report.pac.pages.vehiclePracticability.currvehtype.' + vehicle.key)}
              </span>
            </CategoriesCard>
          ))
        }
      </div>
  </>
)
};

export default VehiclePracticability;