import styled from "styled-components";
import {dangerColor} from "../../../../utils/Theme";

export const GeometryContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: .5em;
    width: 100%;
	height: 100%;
	padding: 0;
`
export const Label = styled.label`
	font-weight: 400;
	font-size: .675em;
	line-height: 20px;
	color: #000000;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 1em;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`
export const Input = styled.input`
	width: 100%;
	display: block;
	border-radius: 2px;
	font-size: .875em;
	padding: 0;
    cursor: auto;
    color: #8C9BA5;
	border: 0;
	border-bottom: 1px solid #C5CDD2;
	&:active, 
	&:focus {
		color: #000000;
        border: 0;
        border-bottom: 2px solid ${dangerColor};
        box-shadow: 0 0 0 0;
        outline: 0;
	}
`