import React, {useContext} from "react";
import {LeftArrowIcon, RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import {StyledArrowButton, StyledSubmitButton, StyledFooter, StyledFooterButtonWrapper} from "./Footer-styled";
import {ConfigContext} from "../../../../utils/ConfigContext";

const translationPath = 'screen.popup.actions.'

const Footer = ({onPrevClick, onNextClick, showNext, onSubmit, isValid, isNextActive, showSubmit, nextTitle, isFinal, t}) => {
  const {config} = useContext(ConfigContext)
  return (
    <StyledFooter>
        <StyledFooterButtonWrapper>
          <div style={{borderRadius:'4px', overflow:'hidden', display:'flex'}}>
            {!isFinal && (
              <>
                <StyledArrowButton bg={config.opsColor ?? '#b21b0c'} disable={!onPrevClick} onClick={onPrevClick ? onPrevClick : undefined}>
                  <RightArrowIcon style={{transform: 'rotate(90deg)'}} width={18} color='#FFFFFF'/>
                </StyledArrowButton>
                <div style={{width:'1px', height:'100%', background: '#F3F3F3'}} />
                <StyledArrowButton bg={config.opsColor ?? '#b21b0c'} disable={!isNextActive} onClick={onNextClick}>
                  <LeftArrowIcon style={{transform: 'rotate(90deg)'}} width={18} color='#FFFFFF'/>
                </StyledArrowButton>
              </>
            )}
          </div>
          {
            (isFinal || showNext) && (
              <StyledSubmitButton
                bg={config.opsColor}
                disable={!isNextActive}
                onClick={onNextClick}
              >
                {nextTitle}
              </StyledSubmitButton>
            )
          }
          {
            showSubmit && (
              <StyledSubmitButton bg={config.opsColor} disable={!isValid} onClick={onSubmit}>
                {t(translationPath + 'submit')}
              </StyledSubmitButton>
            )
          }
        </StyledFooterButtonWrapper>
    </StyledFooter>
  );
};

export default Footer;