import React from "react";
import TextArea from "../../../TextArea/TextArea";

const Comment = ({onChange, value, onClick, title, ...rest}) => {
  return (
    <TextArea
      label={title}
      value={value}
      onChange={onChange}
      wrap='hard'
      maxLength={1000}
      {...rest}
    />
  );
};

export default Comment;