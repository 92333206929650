import React from "react";
import {Content, FooterContainer} from "./PanelFooter-styled";
import {RenderContent} from "../../helpers";
import Pagination from "../Pagination/Pagination";

const PanelFooter = ({data, openScreenId, panelPath, t, config, formProp, hasPagination = false}) => {
    const {onSubmit} = formProp
    return (
        <FooterContainer id={"panel-footer"}>
            <Content>
                {(data && data.content && (Object.keys(data.content) ?? []).length > 1) &&
                    <RenderContent
                        content={data}
                        openScreenId={openScreenId}
                        path={`${panelPath}.footer`}
                        t={t}
                        config={config}
                    />
                }
                {hasPagination && <Pagination onSubmit={onSubmit}/>}
            </Content>
        </FooterContainer>
    )
}
export default PanelFooter