import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import FilterToggle from "./FilterToggle";
import FilterDropdown from "./FilterDropdown";
import {filter16} from "@esri/calcite-ui-icons";
import {MapFilterButton} from "../ManualWidget-styled";
import useFilters from "../../../hooks/useFilters";
import {view} from "../../../utils/API";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";

const MapFilter = ({config, t, field}) => {
  const {activeModule} = useSelector((store) => store)
  
  // triggering filter only on small screens,
  // as we have listener for larger screens in Filter.js file
  const {setFilters, filters} = useFilters({
    isActive: window.isSmall,
    config: config
  });
  
  const onFilterChange = (fieldName, value) => {
    let _filters = JSON.parse(JSON.stringify(filters))
    _filters[activeModule][fieldName] = value;
    setFilters(_filters)
  }
  
  const renderFilter = (visibleField) => {
    switch (visibleField.type) {
      case 'toggle':{
        const {field, options, layer, showSymbol} = visibleField;
        const selectedValue = filters[activeModule][field.name];
        return <FilterToggle
          key={field.name}
          field={field}
          options={options}
          selectedValue={selectedValue.length === 1 ? selectedValue[0] : undefined}
          onFilterChange={onFilterChange}
          t={t}
          config={config}
          view={view}
          layer={layer}
          showSymbol={showSymbol}
        />
      }
      case 'dropdown':{
        const {field, options} = visibleField;
        const selectedValue = filters[activeModule][field.name];
        return <FilterDropdown
          key={field.name}
          field={field}
          options={options}
          selectedValue={selectedValue ?? []}
          onFilterChange={onFilterChange}
          t={t}
          config={config}
        />
      }
      case 'all':{
        return !window.isSmall && isWidgetDisplayed(config, 'Filter') ? (
          <MapFilterButton
            onClick={handleToggleFilter}
          >
            <span>All filters</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path d={filter16} />
            </svg>
          </MapFilterButton>
        ) : null
      }
      default:
        return null;
  
    }
  }
  
  const handleToggleFilter = useCallback(() => {
    const filterWidget = view.ui.find('Filter')
    if (filterWidget) {
      filterWidget.expanded = !filterWidget.expanded
    }
  },[view])
  
  return (
    renderFilter(field)
  );
};

export default MapFilter;