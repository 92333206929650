import React, {useCallback, useEffect, useRef, useState} from "react";
import {handleMapViewClick, view} from "../../../../utils/API";
import Input from "../Input/Input";
import {loadModules} from "esri-loader";
import {
  LocationExpandButton,
  LocationInputContainer,
  LocationMap,
  LocationMapWrapper,
} from "../../components/Location/Location-styled";
import {fullScreen16, fullScreenExit16} from "@esri/calcite-ui-icons";
import {createRoot} from "react-dom/client";
import {composeLayerQuery, searchWidget} from "../../../../esri/widgets/search";
import {getFeatureTitle, getLayerTitle} from "../../../../esri/custom-popup-content";
import {useSelector} from "react-redux";
import {countryCodes} from "../../../../data/countryCodes";

const initMap = async (t, config) => {
  const [MapView] = await loadModules(["esri/views/MapView"]);
  
  const locationView = new MapView({
    map: view.map,
    container:'locationMap',
    center: view.center,
    extent: view.extent,
    zoom:view.zoom,
    ui: {
      components: []
    },
    constraints: view.constraints
  });
  
  
  locationView.when(() => {
    locationView.popup = null;
  })
  createWidgets(locationView, config, t)
  return locationView;
}

const createWidgets = async (locationView, config, t) =>{
  const [Search, Zoom, Locate, LayerSearchSource, Query, Graphic] = await loadModules(["esri/widgets/Search", 'esri/widgets/Zoom', "esri/widgets/Locate", "esri/widgets/Search/LayerSearchSource", "esri/rest/support/Query", "esri/Graphic"]);
  const locateBtn = new Locate({
    view: locationView
  });
  
  const sw = new Search({
    view: locationView,
    locationEnabled: false,
    includeDefaultSources: false,
    minSuggestCharacters: 2,
    sources: searchWidget?.sources || []
  });
  
  const promises = []
  
  locationView.map.layers.forEach(layer=>{
    promises.push(layer.load());
  })
  let initialExtent = locationView.extent;
  if (!initialExtent)
    initialExtent = view.extent
  
  
  Promise.all(promises).then(layerRes=>{
    layerRes.forEach(layer=>{
      if (layer.type !== "feature")
        return null
      const searchConfig = layer.layerConfig?.search
      if (searchConfig && searchConfig.enabled === false)
        return
    
      //exclude not feature layers and the global country layer
      if (!layer.fields || layer.title === "World Countries (Generalized)")
        return
    
      let searchFields = layer.fields.filter(field => field.type === "string").map((field) => field.name)
      if (searchFields.length === 0)
        return
    
      //Filter fields
      if (searchConfig && searchConfig.searchFields){
        const fields = searchConfig.searchFields
        if (!Array.isArray(fields) || fields.length === 0) {
          console.warn("Layer configuration error found. Layer: " + layer.title +
            " Error: searchField attribute must be an array of strings with at least one value")
        } else {
          searchFields = searchFields.filter((layerField) => fields.includes(layerField))
        }
      }
    
      //Is layer already loaded?
      if (sw.sources.filter((locator) => locator.layer && locator.layer === layer).length > 0)
        return
      
      let layerSearchSource = new LayerSearchSource({
        layer: layer,
        name: getLayerTitle(layer, t),
        popupEnabled: false,
        enableSuggestions: true,
        getSuggestions: (params) => {
          if (!layer.visible)
            return
        
          const query = composeLayerQuery(params.suggestTerm, Query, config, layer, searchFields)
          return layer.queryFeatures(query).then((results) => {
            return results.features.map((feature) => {
              let suggestion = getFeatureTitle(feature, t, config)
              if (!suggestion)
                suggestion = t("screen.widget.Search.noTitle", "No title")
            
              return {
                key: feature.attributes[layer.objectIdField] ? feature.attributes[layer.objectIdField] : "key",
                text: suggestion,
                sourceIndex: params.sourceIndex,
                feature: feature
              }
            })
          })
        },
        getResults: (params) => {
          if (!params.suggestResult || !params.suggestResult.feature)
            return null
        
          return new Promise( (resolve) => {
            const feature = params.suggestResult.feature
            const graphic = new Graphic({
              geometry: feature.geometry,
              attributes: feature.attributes
            })
          
            //Mark the result, that is coming from the layer search source
            return resolve ( [{
              feature: feature,
              target: graphic,
              isLayerSearchSourceResult: true
            }])
          })
        },
        filter: layer.definitionExpression ? {where: layer.definitionExpression} : {},
        exactMatch: false,
        outFields: ["*"]
      })
    
      if (initialExtent)
        layerSearchSource.zoomScale=  initialExtent.width / 3
    
      sw.sources.push(layerSearchSource)
      sw.sources.sort((a, b) => {
        if (a.layer && !b.layer)
          return -1
        else if (!a.layer && b.layer)
          return 1
      
        return a.name.localeCompare(b.name)
      })
    })
  })
  
  const arcgisSource = {
    name: t("screen.widget.Search.geocoding", "ArcGIS World Geocoding Service"),
    url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
    zoomScale: initialExtent?.extent?.width / 3
  }
  const country = countryCodes.find((country) => Array.isArray(config.iso3) ? config.iso3.includes(country.ISO3) : country.ISO3 === config.iso3)
  
  sw.sources.push(arcgisSource)
  
  if (country) {
    arcgisSource.countryCode = country.ISO2;
  }
  
  sw.when(()=>{
    const input = sw.container.querySelector('input');
    input.placeholder = t("screen.widget.Search.placeholder", "Search on LogIE");
  })
  
  const zoom = new Zoom({
    view: locationView
  });
  
  // Add the search widget to the top right corner of the view
  locationView.ui.add(sw, {
    position: "top-right"
  });
  locationView.ui.add(locateBtn, {
    position: "bottom-right",
  });
  
  locationView.ui.add(zoom, {
    position: "bottom-right"
  });
}

const Location = ({form, setForm, config, t}) => {
  const [lat, setLat] = useState( form.geometry?.latitude.toString() ?? "");
  const [lng, setLng] = useState(form.geometry?.longitude.toString() ?? "");
  const {layersLoading} = useSelector(state=>state);
  const [expandMap, setExpandMap] = useState(false);
  const locationView = useRef();
  
  const mapRef = useRef();
  useEffect(() => {
    if (layersLoading) return;
    const getMap = async () => {
      const lv = await initMap(t, config);
      locationView.current = lv
  
      const toggleDiv = document.createElement("div")
      toggleDiv.setAttribute("id", 'mapExpand')
      const root = createRoot(toggleDiv)
      root.render(<LocationExpandButton className="esri-widget--button" onClick={toggleExpandMap}>
        <svg
          id="fullscreen"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path d={fullScreen16} />
        </svg>
        <svg
          style={{display:'none'}}
          id="fullscreenexit"
          xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
        >
          <path d={fullScreenExit16} />
        </svg>
      </LocationExpandButton>)
  
      lv.ui.add({component: toggleDiv, position: "bottom-left"})
    }
    getMap();
  },[layersLoading])
  
  useEffect(() => {
    let handler = locationView.current?.on("click", (event) => {
      let graphicsLayer;
      locationView.current?.map.allLayers.map(layer=>{
        if (layer.get('title') === 'Sketch') {
          graphicsLayer = layer
        }
      })
      handleMapViewClick({ event });
      
      setLat(event.mapPoint.latitude.toString());
      setLng(event.mapPoint.longitude.toString());
      setForm(form=>({...form, geometry:{
          latitude: event.mapPoint.latitude,
          longitude: event.mapPoint.longitude
      }}))
    });
    return () => handler && handler.remove();
  }, [locationView.current]);
  
  const toggleExpandMap = useCallback(()=>{
    
    setExpandMap(prev=>!prev)
    
    if (!mapRef.current) return;
    const fullScreenIcon = mapRef.current.querySelector('#fullscreen')
    const fullScreenExitIcon = mapRef.current.querySelector('#fullscreenexit')
    
    fullScreenExitIcon.style.display = fullScreenExitIcon.style.display === 'none' ? 'block' : 'none';
    fullScreenIcon.style.display = fullScreenExitIcon.style.display === 'none' ? 'block' : 'none'
  },[])
  
  const addAndShowCoordinates = useCallback(({latitude, longitude}) =>{
    const point = {
      type: "point",
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude)
    };
    
    const event = {
      mapPoint: point
    }
  
    setForm(form=>({...form, geometry:{
      latitude: point.latitude,
      longitude: point.longitude
    }}))
    
    handleMapViewClick({ event });
    locationView.current?.goTo({
      center:[point.longitude, point.latitude],
    })
  },[locationView])
  
  let timer
  const handleTextChange = useCallback(({ target }, cb) => {
    cb(target.value)
    
    const coordinates = {
      latitude: lat,
      longitude: lng
    }
    
    coordinates[target.name] = target.value;
    
    if (timer)
      return

    timer = setInterval(() => {
      clearInterval(timer)
      timer = null
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      coordinates[target.name] = target.value;

      if (coordinates.longitude && coordinates.latitude) {
        addAndShowCoordinates(coordinates)
      } else {
        setForm(form=>({...form, geometry: undefined}))
      }
    }, 300)
  },[timer, addAndShowCoordinates, lat, lng])
  
  return <>
    <LocationMapWrapper opsColor={config.opsColor} expand={expandMap}>
      <LocationMap id="locationMap" ref={mapRef} />
    </LocationMapWrapper>
    <LocationInputContainer>
      <Input
        name="latitude"
        value={lat}
        onChange={(ev) => handleTextChange(ev, setLat)}
        disabled={false}
        label={t("layer.fieldAlias.latitude")}
        placeholder="Please provide latitude"
        borderColor={config.opsColor}
      />
      <Input
        name="longitude"
        value={lng}
        onChange={(ev) => handleTextChange(ev, setLng)}
        disabled={false}
        label={t("layer.fieldAlias.longitude")}
        placeholder="Please provide longitude"
        borderColor={config.opsColor}
      />
    </LocationInputContainer>
  </>
};

export default Location;