import styled from "styled-components";

export const LegendWrapper = styled.div`
    max-width: max-content;
    height: auto;
    font-style: italic;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2B3674;
    overflow: hidden;
`;

export const LegendItem = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5px;
`;

export const LegendColor = styled.span`
    display: block;
    background-color: ${(props) => (props?.color)};
    width: 15px;
    height: 15px;
    border-radius: 4px;
`;