import React, {useEffect, useMemo, useState} from "react";

import {renderCell} from "../../../../utils/helper";
import {StyledTableCell, StyledTableRow, StyledContent} from "../../style/DetailRow/DetailRow-styled";
import {LOGIE_API_ENDPOINT} from "../../../../utils/API";

const DetailRow = ({field, row, t, i18n, config}) => {

    const [translation, setTranslation] = useState(null)
    const [isTranslating, setIsTranslating] = useState(false)

    useEffect(() => {
        const translatedFields = row?.layer?.layerConfig?.customPopupOps?.translatedFields
        if (!translatedFields || !Array.isArray(translatedFields) || !translatedFields.includes(field?.name))
            return

        const layer = row?.layer
        setIsTranslating(true)

        fetch(LOGIE_API_ENDPOINT + "/getTranslation?" + new URLSearchParams({
            layerId:  layer.originalId,
            objectId:  row.attributes[layer.objectIdField],
            fieldName:  field.name,
            value:  row.attributes[field.name],
            lang:  i18n.language
        })).then( (response) => {
            if (response.ok)
                return response.text()
        }).then( (data) => {
            setIsTranslating(false)
            const trans =JSON.parse(data)
            if (trans.statusCode === 200 && data.detectedSourceLang !== i18n.language)
                setTranslation(trans.body)

        }).catch ((error) => {
            setIsTranslating(false)
            console.error("error occurred fetching translations", error)
        })
    }, [])

    if (!field)
        return null

    const content = useMemo(() => {
        const stringCell = renderCell(field, row.attributes[field.name], t, config);
        return String(stringCell);
    }, [config, t, field, row])

    if (!content.length)
        return null


    return (
        <StyledTableRow withUnderline={false} key={field.name}>
            <StyledTableCell comment={true}>
                {t('layer.fieldAlias.' + field.name, field.alias) + ": "}
                <StyledContent dangerouslySetInnerHTML={{ __html: renderCell(field, row.attributes[field.name], t, config) }} />
                <div>
                    {isTranslating && <calcite-progress type="indeterminate">retrieving translation</calcite-progress>}

                    {translation && <span style={{color: "gray"}} ><calcite-icon icon="language-translate" scale="s" style={{padding: "0 10px 0 0"}}/>
                        {translation}</span>}
                </div>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default DetailRow;