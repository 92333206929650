import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: .8em 0 0 0;
`

export const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`
export const ArrowButtonsContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`
export const ArrowButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .5em .6em;
  gap: 8px;
  border: 1px solid #DFE6EB;
  border-radius: 8px;
  background: ${(props) => props?.disabled ? '#F0F3F6' : '#FFFFFF'};
  margin-left: 8px;
`
export const PressText = styled.p`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  flex-direction: row;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1120px) {
    display: none;
  }
`

export const PressEnterText = styled.span`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0 8px 0 4px;
`