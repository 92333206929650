import { setClusterFeature_actionType } from '../constants';

const initialState = null;
const clusterFeature = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case setClusterFeature_actionType:
      return (state = payload)
    default:
      return state
  }
}

export default clusterFeature
