import {useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {getLayerTitle} from "../../esri/custom-popup-content";

const LayerListWidget = ({view, LayerList, t, config, widgetId}) => {
  const {activeModule} = useSelector(state=>state);
  const ref = useRef();
  const widgetRef = useRef();
  const listeners = useRef([]);
  const module = useRef(activeModule);
  const configRef = useRef(config);
  const render = useRef(true);
  
  useEffect(()=>{
    render.current = true;
  },[activeModule, config])
  
  useEffect(() => {
    let layersMap = {};
    let count = 0;
    let arrCreatedId = [];
    let layersShown = [];
    
    if (widgetRef.current) {
      ref.current.innerHTML = '';
      layersMap = {};
      arrCreatedId = [];
      count = 0;
    }
    
    if (listeners.current.length > 0) {
      listeners.current.forEach(w=>{
        w.remove();
      })
      listeners.current = [];
    }
    
    widgetRef.current = new LayerList({
      id:widgetId,
      container: ref.current,
      view: view,
      title: t('screen.widget.LayerList.name'),
      listItemCreatedFunction: (event) => {
        let item = event.item;
        const layers = config.modules[activeModule]?.layers || [];
        const optionalLayers = config.modules[activeModule]?.optionalLayers || [];
        
        const moduleLayers = [...layers, ...optionalLayers];
        
        item.title = getLayerTitle(item.layer, t);

        item.hidden = item.layer.isLabel || !item.title;
        
        if (count === 0) {
          count++;
        }
        
        if (item.layer.layerConfig && item.layer.layerConfig.titleLabel) {
          const label = item.layer.layerConfig.titleLabel;
  
          if (!layersShown[label]) {
            layersShown[label] = false
          }
          
          if (!Array.isArray(layersMap[label])) {
            layersMap[label] = [];
          }
          
          // reseting everything, this is because listItemCreatedFunction executes twice on module change
          // some kind of bug from arcgis api, which will be fixed in 4.24 version
          if (arrCreatedId.includes(item.layer.id) && count === 1) {
            count++;
            for (const k in layersMap) {
              layersMap[k] = [];
            }
            
            const layer = item.layer;
            const label = layer.layerConfig.titleLabel;
          }
  
          const layer = item.layer;
          item.hidden = layersMap[label].length > 0;
          if (!item.hidden) {
            layersShown.push(label);
          }
          
          //hiding layer which is not in module
          if (layersMap[label].length > 0 && moduleLayers.includes(item.layer.layerConfig.alias)) {
            widgetRef.current.operationalItems.forEach(opItem=>{
              const layerConfig = opItem.layer.layerConfig;
              if (layerConfig && layerConfig.titleLabel === label && !opItem.hidden) {
                const isInModule = moduleLayers.includes(layerConfig.alias);
                if (!isInModule) {
                  opItem.hidden = true;
                  item.hidden = false;
                }
              }
            })
          }
          
          if (Array.isArray(layersMap[label])) {
            layersMap[label].push(layer.layerConfig.id);
          }
        }
  
        arrCreatedId.push(item.layer.id);
        if (!item.hidden) {
          const watchListener = item.watch("visible", function(visible){
            const layerConfig = item.layer.layerConfig;
            if (layerConfig && layerConfig.titleLabel){
              const label = layerConfig.titleLabel;
              let allIn = true;
              const ignore = [];
              
              widgetRef.current.operationalItems.forEach(opItem=>{
                const opLayerConfig = opItem.layer.layerConfig;
                if (opLayerConfig && opLayerConfig.titleLabel === label) {
                  if (allIn) {
                    allIn = moduleLayers.includes(opLayerConfig.alias);
                    if (!allIn) {
                      ignore.push(opLayerConfig.id);
                    }
                  }
                }
              })
              const layerConfigIdsArray = layersMap[label];
              
              view.map.layers.forEach(l=>{
                if (l.layerConfig && layerConfigIdsArray.includes(l.layerConfig.id) && !ignore.includes(l.layerConfig.id) && label === l.layerConfig?.titleLabel) {
                  l.visible = visible;
                }
              })
            }

            view.map.layers.forEach(l=>{
              if (!l.layerConfigId || !item.layer.layerConfig) return;
              if (l.layerConfigId === item.layer.layerConfig.id) {
                l.visible = visible;
              }
            })
          });
          listeners.current.push(watchListener);
        }
      }
    })
  },[activeModule, config]);
  
  return (
    <div ref={ref} />
  );
};

export default LayerListWidget;