import React, {useCallback, useMemo, useState} from "react";
import {FeatureIcon, FeatureIconContainer} from "../FeatureTable-styled";
import {DownloadIcon} from "../../Icons/Feature-TableIcons";
import Dropdown, {DropdownMenu, DropdownMenuItem} from "../../Dropdown";
import {ROLE_EDITOR} from "../../../utils/helper";

const DownloadButton = ({setDownload, config, layer, t}) => {
  const [show, setShow] = useState(false);
  
  const handleDownload = useCallback((downloadType)=>{
    setDownload(downloadType);
    setShow(false);
  },[setDownload])
  
  const allowedExports = useMemo(()=>{
    const {layerConfig} = layer || {};
    const allowed = [];
    if (!layerConfig) return allowed;
    
    const shpAllowed = config.role !== ROLE_EDITOR ? (layerConfig.featureTable.allowedExtractionSHPForPublic && layerConfig.featureTable.exportCoordinates) : layerConfig.featureTable.allowedExtractionCSVForEditors
    const csvAllowed = config.role !== ROLE_EDITOR ? layerConfig.featureTable.allowedExtractionCSVForPublic : layerConfig.featureTable.allowedExtractionCSVForEditors
    if (shpAllowed) {
      allowed.push('shapefile');
    }
    
    if (csvAllowed) {
      allowed.push('csv');
    }
    
    return allowed;
  },[layer, config])
  if (allowedExports.length === 0) return null;
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
    >
      <FeatureIconContainer>
        Download<FeatureIcon><DownloadIcon /></FeatureIcon>
      </FeatureIconContainer>
      <DropdownMenu style={{minWidth: 80}}>
        {allowedExports.includes("csv") && <DropdownMenuItem
          opsColor={config.opsColor}
          onClick={() => handleDownload("csv")}
        >
          <span
            style={{
              fontSize: 12
            }}
          >{t("screen.download.csv")}</span>
        </DropdownMenuItem>}
        {allowedExports.includes('shapefile') && <DropdownMenuItem
          opsColor={config.opsColor}
          onClick={() => handleDownload("shapefile")}
        >
          <span
            style={{
              fontSize:12
            }}
          >{t('screen.download.shapefile')}</span>
        </DropdownMenuItem>}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DownloadButton;