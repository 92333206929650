import {StyledDropdownMenu} from "./Dropdown-styled";

const DropdownMenu = ({children, ...props}) => {
  return (
    <StyledDropdownMenu {...props}>
      {children}
    </StyledDropdownMenu>
  );
};

export default DropdownMenu;