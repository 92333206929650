import React, {useCallback, useEffect, useState} from "react";

import {LegendBox, CanvasChart} from "./components"; 
import {AnalyticsPanel, AnalyticsTitle, ListContainer,
		TitleOfChart, ListOfChart, ItemOfChartList} from "./Analytics-styled"
import {colorScheme, borderColorScheme, hoverColorScheme} from "./helpers/chartOptions";
import {StyledLoader} from "../App/App-styled";
import {renderCell} from '../../utils/helper'

const selectedPieOffset = 20
const selectedPieBorder = '#00ffff'
/**
 * Analytics widget
 */
const Analytics = ({view, config, t, expand,  reactiveUtils}) => {
	let [charts, setCharts] = useState([])
	const [ready, setReady] = useState(false)
	const [_, setReload] = useState(0)

	const setChartLayers = useCallback(() => {
		if (!config.analytics)
			return

		let layerPromises = []
		view.map.layers.forEach((l) => {
			layerPromises.push(l.load())
		})

		Promise.all(layerPromises).then(() => {
			let queryPromises = []
			let chartData = []

			config.analytics.forEach((an, index) => {
				if (!an.groupField || !an.measureField || !an.layers)
					return

				view.map.layers.forEach((l) => {
					if (!l.fields || !l.layerConfig
						|| !(an.layers.includes(l.layerConfig.alias) || an.layers.includes(l.layerConfig.id)))
						return

					const grpFld = l.fields.filter((f) => f?.name === an?.groupField)[0]
					const meaFld = l.fields.filter((f) => f?.name === an?.measureField)[0]
					if (!grpFld || !meaFld)
						return

					let query = l.createQuery()
					query.outStatistics = [ { onStatisticField: meaFld.name,
						outStatisticFieldName: meaFld.name,
						statisticType: an.aggregation}
					]
					query.groupByFieldsForStatistics = [ an.groupField ]

					const p = l.queryFeatures(query)
					queryPromises.push(p)

					chartData.push({
						index: index,
						labels: [],
						values: [],
						datasets: [{
							label: an.title,
							data: [],
							backgroundColor: [],
							borderColor: [],
							borderWidth: 1,
							hoverRadius: 10,
							hoverBorderWidth: 2,
							hoverBackgroundColor: [],
							offset:[],
							type: an.type,
							index: index
						}],
						layer: l,
						groupField: grpFld,
						measureField: meaFld
					})
				})
			})

			Promise.all(queryPromises).then((qps) => {
				let tmpCharts = []
				qps.forEach((resp, index) => {
					let cd = chartData[index]
					resp.features?.forEach((f, idx) => {
						const colorIdx = (idx % colorScheme.length)

						cd.labels.push(renderCell(cd.groupField, f.attributes[cd.groupField.name], t))
						cd.values.push(f.attributes[cd.groupField.name])
						let ds = cd.datasets[0]
						ds.data.push(f.attributes[cd.measureField.name])
						ds.backgroundColor.push(colorScheme[colorIdx])
						ds.borderColor.push(borderColorScheme[colorIdx])
						ds.hoverBackgroundColor.push(hoverColorScheme[colorIdx])
						ds.offset.push(0)
					})
					tmpCharts.push(cd)
				})

				setReady(true)
				setCharts(tmpCharts)

				reactiveUtils.when(
					() => !expand.viewModel.expanded,
					() => {
						tmpCharts.forEach((chart) => {
							setOffset(chart.datasets[0], null, selectedPieOffset)
							setBorder(chart.datasets[0], null, selectedPieBorder)
							view.whenLayerView(chart.layer).then((lv) => {
								lv.featureEffect = null
							})
						})
						setCharts(tmpCharts)
						setReload(prev => prev + 1)
					}
				)
			})
		})
	}, [config, t, setCharts])

	useEffect(() => {
		let tmpReload = 0
		view?.map?.layers?.forEach((layer) => {
			layer.watch("visible", () => {
				tmpReload++
				setReload(tmpReload)
			})
		})
		view?.map?.layers?.on("change", (event) => {
			if (!event.added)
				return

			event.added.forEach((layer) => {
				layer.load().then((layer) => {
					layer.watch("visible", () => {
						setReady(false);
						setChartLayers()
					})
				})
			})
		})

		setChartLayers()
	},[])

	const getFieldCond = (fieldName, value) => {
		if (typeof value === 'number' && isFinite(value))
			return `${fieldName}=${value}`
		else if (typeof value === 'string' && value.trim().length === 0)
			return ''
		else if (value === null)
			return `${fieldName} is null`
		else
			return `${fieldName}='${value}'`
	}

	const clickEvent = (e, element) => {
		const el = element[0]
		const clickedDs = e.chart.config.data.datasets[el.datasetIndex]

		let tmpCharts = charts
		let layerConditions = {}
		tmpCharts.forEach((chart) => {
			let ds = chart.datasets[0]
			const isChartClicked = clickedDs.index === ds.index
			const clickedIndex = isChartClicked ? el.index : null
			const alreadySelected = (isChartClicked && clickedDs.offset[el.index]) !== 0

			if (isChartClicked && !alreadySelected){
				const clickedVal = chart.values[el.index]
				layerConditions[chart.layer] = `NOT (${getFieldCond(chart.groupField.name, clickedVal)})`
			}

			setOffset(ds, alreadySelected ? null : clickedIndex, selectedPieOffset)
			setBorder(ds, alreadySelected ? null : clickedIndex, selectedPieBorder)
		})

		//Apply filters
		tmpCharts.forEach((chart) => {
			const layerCond = layerConditions[chart.layer]
			view.whenLayerView(chart.layer).then((lv) => {
				lv.featureEffect = (layerCond) ? {
					filter: {where: layerCond},
					includedEffect: `sepia(70%) saturate(150%) opacity(25%)`
				} : null
			})
		})

		setCharts(tmpCharts)
		setReload(prev => prev + 1)
	}

	/**
	 * Set the offset value for the specified datasource, for the specified index
	 * if forIndex is null, reset all values to 0
	 */
	const setOffset = ((ds, forIndex, val) => {
		ds.offset.forEach((os, index) => {
			ds.offset[index] = (forIndex != null && forIndex === index) ? val : 0
		})
	})

	const setBorder = ((ds, forIndex, val) => {
		ds.offset.forEach((os, index) => {
			const colorIdx = (index % colorScheme.length)
			ds.borderColor[index] = (forIndex != null && forIndex === index) ? val : borderColorScheme[colorIdx]
		})
	})

	const visibleCharts = charts?.map((chart, index) => {
		if (!chart.layer.visible || !chart.labels || chart.labels <= 0)
			return null

		const dataset = chart.datasets[0]
		return (
			<ItemOfChartList key={`chart_${index}`}>
				<TitleOfChart>{dataset.label}</TitleOfChart>
				<CanvasChart type={dataset.type} chart={chart} onClickCallBack={clickEvent}/>
				<LegendBox labels={chart.labels} colors={dataset.backgroundColor} title={dataset.label}/>
			</ItemOfChartList>
		)
	}).filter((chart) => {return chart != null})

	if (ready) {
		expand.container.style.display = (visibleCharts.length === 0) ? "none" : "block"

		return (
			<AnalyticsPanel className="esri-layer-list esri-widget esri-widget--panel">
				<AnalyticsTitle>{t('screen.widget.Analytics.title')}</AnalyticsTitle>
				<ListContainer className="esri-item-list__list-item-container">
					<ListOfChart className="esri-layer-list__list esri-layer-list__list--root esri-layer-list__list--independent">
						{visibleCharts}
					</ListOfChart>
				</ListContainer>
			</AnalyticsPanel>
		)
	}	

	return (
		<AnalyticsPanel className="esri-layer-list esri-widget esri-widget--panel">
			<StyledLoader
				text="Loading..."
				id="app-loader"
				data-testid="app-loader"
			/>
		</AnalyticsPanel>
	)
}

export default Analytics