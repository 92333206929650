import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import {clearSourceAndLink} from "../../utils/clearSourceAndLink";
import {ButtonClose, HeaderContainer, HeaderButtonContainer, StyledSideBar,
	StyledHeader, Resizer, SidebarContainer, DLCAContainer} from "./SideBar-styled";
import {StyledLoader} from "../App/App-styled";
import {graphicsLayer, surveyPoint} from "../../utils/API";

/**
 *
 * @param sideBarIFrame:
 * behave differently based on the sideBarIFrame value:
 * open a link in an iframe, if sideBarIFrame is a link,
 * show the pleaseselect message when sideBarIFrame has the value: "pleaseselect",
 * open the embedded dlca page otherwise
 * @param setSideBarIFrame
 * @param setSideBarMobile
 * @constructor
 */
const SideBar = ({ sideBarIFrame, setSideBarIFrame, setSideBarMobile }) => {
	let [lcaLoadState, setLcaLoadState] = useState()

	useEffect(() => {
		setLcaLoadState(null)
	}, [sideBarIFrame])

	const isIFrame = sideBarIFrame && (typeof sideBarIFrame === "string") && sideBarIFrame.startsWith('http');
	const isReport = sideBarIFrame && (typeof sideBarIFrame === "string") && sideBarIFrame.startsWith('pleaseselect');
	const isReportsWidget = sideBarIFrame && (typeof sideBarIFrame === "string") && sideBarIFrame.startsWith('reportsactive');
	const isLCA= sideBarIFrame && !isReport && !isIFrame;
	const isActive = isLCA || isIFrame || isReport;
	
	if (isReportsWidget) return null;

	let mapHeight = 500;
	let docWidth = 500;
	let viewDiv = document.getElementById("viewDiv");
	if (viewDiv)
		mapHeight = viewDiv.offsetHeight - 48;

	const rootDiv = document.getElementById("root");
	if (rootDiv)
		docWidth = rootDiv.offsetWidth;

	if (!isActive){
		if (viewDiv)
			viewDiv.style.flexBasis= "auto";

		if (surveyPoint && surveyPoint.marker && graphicsLayer)
			graphicsLayer.remove(surveyPoint.marker);

		return null;
	}

	let sideBarDiv = null;

	const initDrag = () => {
		document.documentElement.addEventListener('mousemove', doDrag, false);
		document.documentElement.addEventListener('mouseup', stopDrag, false);
	};

	const doDrag = e => {
		if (!sideBarDiv)
			sideBarDiv = document.getElementById("sideBar");

		if (!viewDiv)
			viewDiv = document.getElementById("viewDiv");

		sideBarDiv.style.flexBasis = `${docWidth - e.x}px`;
		viewDiv.style.flexBasis = `${e.x}px`;
	};

	const stopDrag = () => {
		document.documentElement.removeEventListener('mousemove', doDrag, false);
		document.documentElement.removeEventListener('mouseup', stopDrag, false);
	};

	if (isReport)
		return (
			<StyledSideBar id="sideBar">
				<HeaderContainer>
					<StyledHeader>Make a Report</StyledHeader>
					<HeaderButtonContainer>
						<ButtonClose className='esri-popup__icon esri-icon-close' onClick={() => {setSideBarIFrame(null)}}/>
					</HeaderButtonContainer>
				</HeaderContainer>

				<SidebarContainer>
					<div>Select Point on the Map to Get Started.</div>
				</SidebarContainer>
			</StyledSideBar>
		)

	if (isIFrame)
		return (
			<StyledSideBar id="sideBar">
				<HeaderContainer>
					<StyledHeader></StyledHeader>
					<HeaderButtonContainer>
						<ButtonClose className='esri-icon-close' onClick={() => {setSideBarIFrame(null);}}>
						</ButtonClose>
					</HeaderButtonContainer>
				</HeaderContainer>

				<SidebarContainer>
					<iframe id="reportIframe" title="Make a Report" src={sideBarIFrame} style={{width: "100%"}}/>
				</SidebarContainer>
			</StyledSideBar>
		)

	const lcaPageId = sideBarIFrame
	//const dlcaHost = "https://dlca.logcluster.org"
	const makeRequest = () => {
		//This is where we can download the dlca content from. (localhost works only by using a proxy, because of the crossite xhr policy)

//		const lcaEmbedDownloadUrl = window.location.hostname === 'localhost' ?
//			"/rest/api/content/" + lcaPageId  :
//			dlcaHost + "/rest/api/content/" + lcaPageId

		let headers = new Headers()
		//headers.set('Authorization', 'Basic ' + btoa('gizra' + ":" + '1234'))
		let lcaEmbedDownloadUrl = window.location.hostname === 'localhost' ?
			"/rest/api/content/" + lcaPageId + "?expand=body.view" :
			"https://dlca.logcluster.org/rest/api/content/" + lcaPageId + "?expand=body.view";

		fetch(lcaEmbedDownloadUrl, {headers: headers}).then( (response) => {
			if (response.ok)
				return response.text()

			setLcaLoadState({"state": 2, "data":response})
		}).then( function (data){
			setLcaLoadState({"state": 1, "data":JSON.parse(data)})
		}).catch ((error) => {
			setLcaLoadState({"state": 2, "data":error})
		})
	}

	//The rest of this page is about loading the LCA page content
	if (!lcaLoadState) {
		makeRequest()
		return <StyledSideBar id="sideBar" ><StyledLoader text="Loading..." id="app-loader"/></StyledSideBar>
	} else if (lcaLoadState.state === 1) {
		const lcaData = lcaLoadState.data
		const lcaLinks = lcaData._links

		const content = clearSourceAndLink(lcaData.body.view.value, lcaLinks.base)
		return (
			<StyledSideBar id="sideBar" >

				<HeaderContainer key="head">
					<StyledHeader>
						<a href={lcaLinks.base + lcaLinks.webui} rel="noreferrer" target="_blank">
							{lcaData.title}	<span className='esri-icon-link-external'/>
						</a>
					</StyledHeader>

					<HeaderButtonContainer>
						<ButtonClose className='esri-icon-close' onClick={() => { setSideBarMobile(false); setSideBarIFrame(null)}}/>
					</HeaderButtonContainer>
				</HeaderContainer>

				<SidebarContainer  key="content">
					<Resizer id="resizer" onMouseDown={(e) => {initDrag(e)}} >
						<span className="esri-icon-menu"/>
					</Resizer>

					<DLCAContainer dangerouslySetInnerHTML={{__html: content, __base: lcaLinks.base}} className={"dlca-content"} mapHeight={mapHeight}/>
				</SidebarContainer>
			</StyledSideBar>
		)
	} else {
		return (
			<StyledSideBar id="sideBar" >
				<div>Couldn't retrieve LCA content</div>
				<button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
			</StyledSideBar>)
	}
}

SideBar.propTypes = {
	setSideBarIFrame: PropTypes.func,
	setSideBarMobile: PropTypes.func
}

export default SideBar;