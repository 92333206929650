import styled from "styled-components";

export const StyledSideBar = styled.div`
	flex-shrink: 3;
  	display: flex;
  	flex-direction: column;
  	width: 100%;
	height: 100%;
`;

export const HeaderContainer = styled.div`
	background-color: #b74f6f;
	display: flex;
	height: 3rem;
	justify-content: space-between;
  	align-items: center;
  	width: 100%;
	  @media (max-width: 769px) {
			height: max-content;
		}
`;

export const StyledHeader = styled.p`
  color: #fff;
  margin: 0 0.5rem;
  a {
	color: #fff;
	text-decoration: none;
	span {
		padding: 1em;
    }
  }
`;
export const SidebarContainer = styled.div`
	width: 100%;
	flex-grow: 1;
	display: flex;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
` ;

  	//DLCA styling
export const DLCAContainer = styled.div`
	color: #172b4d;
	padding: 0 0 0 0.5em;
	text-align: left;
	max-height: ${(props) => props.mapHeight+ "px"};
	overflow-y: scroll;
	overflow-x: hidden;
	@media (max-width: 769px) {
        	max-height: 100vh;
			padding: 0 8px 8px 15px;
			max-width: 100vw !important;
		}

	table.confluenceTable th.confluenceTh { 
		color: #172B4D;
		background-color: #f4f5f7;
	}

	.confluenceTh, .confluenceTd {
		border: 1px solid #c1c7d0;
		padding: 7px 10px;
		vertical-align: top;
		text-align: left;
		min-width: 8px;
	}

	h1, h2 {
		color: #c03a2b;
	}

	a, a:link, a:visited, a:focus, a:hover, a:active {
		color: #3b73af;
	}
	
`;

export const Resizer = styled.div`
	z-index: 2;
	cursor: col-resize;
	color: lightgrey;
  	display: flex;
  	align-items: center;
  	justify-content: center;
	  	@media (max-width: 769px) {
        	display: none;
		}
` ;

export const HeaderButtonContainer = styled.div`
  height: auto;
  width: auto;
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  outline: none;
  width: 3rem;
`;