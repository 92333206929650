import {useEffect} from "react";

const useInView = (observerTarget, handler, deps = []) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          handler();
        }
      },
      { threshold: 0 }
    );
    
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, ...deps]);
};

export default useInView;