import React, {useState, useEffect, useMemo} from "react";

import Select from 'react-select';

import {FilterFieldComboContainer} from "./Filter-styled";
import {renderCellInEditor} from "../../utils/helper";

const FilterFieldCombo = (props) => {
	const [selectedValues, setSelectedValues] = useState(
		props.defaultValue ? props.defaultValue :
			props.multi ? [] : ""
	)

	useEffect(() => {
		setSelectedValues(props.defaultValue ? props.defaultValue :
			props.multi ? [] : "")
	}, [props.defaultValue])

	let handleValueSelection = (value) => {
		let selected = props.multi ? value.map(val => val.value) : value.value
		setSelectedValues(value)
		props.onValuesChange(props.referenceKey, selected)
	}

	const handleTextEntry = (evt) => {
		if (evt.target.value) {
			setSelectedValues([evt.target.value]);
			props.onValuesChange(props.referenceKey, props.multi ? [evt.target.value] : evt.target.value)
		} else {
			setSelectedValues([])
			props.onValuesChange(props.referenceKey, [])
		}
	}

	const ReactSelectStyles = () => ({
		control: baseStyles => ({
			...baseStyles,
			fontSize: "12px",
			marginLeft: 0
		}),
		multiValueLabel: baseStyles => ({
			...baseStyles,
			whiteSpace: "normal",
		}),
	});

	const renderCellValue = useMemo(() => {
		if (props.field.length > 60 && Array.isArray(props.defaultValue)) {
			 return props.defaultValue.join('');
		}
		return ""
	},[props.defaultValue, props.field])
	
	return (
		<FilterFieldComboContainer>
			<label >{props.t('layer.fieldAlias.' + props.field.name, props.field.alias)}</label>

			{(() => {
				const field = props.field
				if (field.domain && field.domain.codedValues) {
					let fieldValues = []
					if (field.nullable)
						fieldValues.push({label: props.t('screen.domain.unspecified'), value: null})

					field.domain.codedValues.forEach((codedValue) => {
						fieldValues.push({label: codedValue.name, value: codedValue.code})
					})
					let defaultValues = null
					if (selectedValues) {
						if (Array.isArray(selectedValues)) {
							defaultValues = selectedValues.map((val) => {
								if (val?.label) {
									return val
								}
								return fieldValues.filter((fieldVal)=> fieldVal.value === val)[0]
							})
						} else {
							defaultValues = fieldValues[selectedValues]
						}
					}

					return <Select defaultValue={defaultValues} closeMenuOnSelect={!props.multi} menuPosition="fixed" isMulti={props.multi}
						onChange={handleValueSelection}	styles={ReactSelectStyles()} options={fieldValues} value={defaultValues} />
				} else {
					return renderCellInEditor(field, renderCellValue, handleTextEntry)
				}
			})()}

		</FilterFieldComboContainer>
	)
}

export default FilterFieldCombo
