import React from "react";

export const DownloadIcon =  () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g fill="#418fde" data-name="Layer 2">
            <path d="M7.879 33.121l14 14a2.999 2.999 0 0 0 4.242 0l14-14a3 3 0 1 0-4.242-4.242L27 37.758V12a3 3 0 0 0-6 0v25.758l-8.879-8.88a3 3 0 1 0-4.242 4.243z" style={{fill: '#6e6e6e'}}/>
            <path d="M45.799 0H2.2A2.373 2.373 0 0 0 0 2.514v8.972A2.373 2.373 0 0 0 2.201 14H18v-2a6 6 0 0 1 12 0v2h15.799A2.373 2.373 0 0 0 48 11.486V2.514A2.373 2.373 0 0 0 45.799 0zM36 9a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm7 0a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" style={{fill: '#6e6e6e'}}/>
        </g>
    </svg>
)


export const ExpandUp = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path fill="#418fde" d="M0 44a3.989 3.989 0 0 1 1.171-2.828l20-19a4 4 0 0 1 5.658 0l20 19a4 4 0 0 1-5.657 5.657L24 31 6.829 46.829A4 4 0 0 1 0 44zM1.171 25.83a4 4 0 0 0 5.658 0L24 10l17.172 15.829a4 4 0 0 0 5.657-5.657l-20-19a4 4 0 0 0-5.658 0l-20 19a4 4 0 0 0 0 5.657z" data-name="Layer 2" style={{fill: "#6e6e6e"}}/>
    </svg>
)

export const ExpandDown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path fill="#418fde" d="M48 4a3.989 3.989 0 0 1-1.171 2.829l-20 19a4 4 0 0 1-5.658 0l-20-19a4 4 0 0 1 5.657-5.657L24 17 41.171 1.172A4 4 0 0 1 48 4zm-1.171 18.172a4 4 0 0 0-5.658 0L24 38 6.828 22.172a4 4 0 0 0-5.657 5.657l20 19a4 4 0 0 0 5.658 0l20-19a4 4 0 0 0 0-5.657z" data-name="Layer 2" style={{fill: "#6e6e6e"}}/>
    </svg>

)

export const CloseTable = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
        <path fill="#418fde" d="M28.657 23L44.83 6.828a4 4 0 0 0-5.657-5.657L23 17.343 6.83 1.17a4 4 0 0 0-5.657 5.657L17.342 23 1.173 39.17a4 4 0 0 0 5.657 5.657L23 28.657l16.172 16.171a4 4 0 0 0 5.657-5.657z" data-name="Layer 2" style={{fill: "#6e6e6e"}}/>
    </svg>

)