import * as React from "react"

export const Cross = (props) => (
    <svg
        width={16}
        height={16}
        viewBox={"0 0 24 24"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m5.25 5.25 13.5 13.5M5.25 18.75l13.5-13.5"
            stroke="#5A6872"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </svg>
)