import {loadModules} from "esri-loader";
import {LOGIE_API_ENDPOINT, view} from "../../utils/API";
import {ROLE_EDITOR} from "../../utils/helper";

/**
 * Base class for the Conops and commenting layers
 * This class creates a Graphics layer. On the graphics layer we draw SVG images for features.
 * The SVG images can be dragged and dropped
 */
export class DragAndDropGraphics {
    map
    layer
    config
    t
    graphicsLayer

    /**
     * The graphic that we are dragging right now
     */
    currentlyDraggingGraphic

    /**
     * This offset map saves the relative position (in pixels) of the graphic boxes related to the original feature.
     * when zooming in or out, we will place the boxes using this position
     */
    offSet = {}

    localStorageName

    getLabelPosEndpoint
    setLabelPosEndpoint

    constructor(map, layer, config, t) {
        this.map = map
        this.layer = layer
        this.config = config
        this.t = t

        const viewerId = layer.layerConfig.viewer ? layer.layerConfig.viewer : layer.layerConfig.id
        this.localStorageName = viewerId + 'offset'
        this.getLabelPosEndpoint = `${LOGIE_API_ENDPOINT}/getLabelPositions?layerId=${viewerId}&opId=${config.id}`
        this.setLabelPosEndpoint = `${LOGIE_API_ENDPOINT}/setLabelPositions?layerId=${viewerId}&opId=${config.id}`

        try {
            fetch(this.getLabelPosEndpoint).then( (response) => {
                if (response.ok)
                    return response.text()
            }).then((data) => {
                try {
                    const respJson = JSON.parse(data)
                    const respBody = JSON.parse(respJson.body)
                    this.offSet = respBody.value
                } catch (e) {
                    this.offSet = localStorage[this.localStorageName] ? JSON.parse(localStorage[this.localStorageName]): {}
                }
            }).catch (() => {
                console.error("Error occurred retrieving label positions")
                this.offSet = localStorage[this.localStorageName] ? JSON.parse(localStorage[this.localStorageName]): {}
            })
        } catch (e) {this.offSet = {}}

        loadModules(["esri/layers/GraphicsLayer", 'esri/core/reactiveUtils'] ).then(([GraphicsLayer, reactiveUtils]) => {
            this.init(GraphicsLayer, reactiveUtils)
        })
    }

    /**
     * - create the graphics layer,
     * - redraw on zoom in/out and pan
     * - init drag&drop
     */
    init = (GraphicsLayer, reactiveUtils) => {
        this.graphicsLayer = new GraphicsLayer({
            title: this.layer.getLayerTitle(this.t) + " - Labels",
            opacity: 1,
            layerConfigId: this.layer.layerConfig?.id,
            layerConfigAlias: this.layer.layerConfig?.alias,
            isLabel: true
        })
        this.graphicsLayer.layerModules = this.layer.layerModules
        this.map.layers.add(this.graphicsLayer, view.map.layers.indexOf(this.layer))

        view.whenLayerView(this.layer).then((layerView) => {
            reactiveUtils.watch(() => layerView.updating, () => { this.updateLayerView(layerView)})
            this.updateLayerView(layerView)
        })

        view.on("drag", async (event) => {
            if (!this.graphicsLayer.visible)
                return

            if (event.action === "start"){
                //Drag starting, search for graphics around the mouse
                await view.hitTest(event, {include: [this.graphicsLayer]}).then((res) => {
                    const relevantRes = res?.results.filter((res) => res.graphic && res.graphic.sourceLayer && res.layer === this.graphicsLayer)
                    if (relevantRes.length) {
                        this.currentlyDraggingGraphic = relevantRes[0].graphic
                        event.stopPropagation()
                    } else
                        this.currentlyDraggingGraphic = null
                })

            }  else if (event.action === "update" && this.currentlyDraggingGraphic){
                //Dragging: adjust position
                this.currentlyDraggingGraphic.geometry = view.toMap({x: event.x, y: event.y})
                event.stopPropagation()

            }  else if (event.action === "end"  && this.currentlyDraggingGraphic){
                //Drop: redraw connecting line
                const oid = this.currentlyDraggingGraphic.attributes[this.layer.objectIdField]
                const orig = this.currentlyDraggingGraphic.originalGeometry
                const origXy = view.toScreen(orig)
                const to = view.toMap({x: event.x, y: event.y})

                this.graphicsLayer.graphics.filter((gr) => gr.parentOid === oid).forEach((gr) => {
                    gr.geometry = this.getConnectingLine(oid, orig, to).geometry
                    this.offSet[oid] = {x: event.x - origXy.x, y: event.y - origXy.y}
                    const offsetJson = JSON.stringify(this.offSet)
                    localStorage[this.localStorageName] = offsetJson

                    if (this.config.role === ROLE_EDITOR){
                        fetch(this.setLabelPosEndpoint, {
                            method: 'POST',
                            headers: new Headers({'Content-Type': 'application/json'}),
                            body: offsetJson.replaceAll("\"", "\'")
                        }).then(() => {
                        }).catch (() => {
                            console.error("Error occurred when setting label positions")
                        })
                    }
                })

                this.currentlyDraggingGraphic = null
                event.stopPropagation()
            }
        })
    }

    /**
     * Query the layer and redraw SVG's
     */
    updateLayerView = (layerView) => {
        if (layerView.updating)
            return

        const layer = layerView.layer

        const query = {
            geometry: view.extent,
            where: layer.definitionExpression,
            outFields: ["*"],
            returnGeometry: true
        }

        layerView.queryFeatures(query).then((result) => {
            this.graphicsLayer.removeAll()
            if (!result || !result.features)
                return
            
            result.features.forEach((ftr) => {
                const oid = ftr.attributes[layer.objectIdField]
                const symbol = this.createSvgSymbol( ftr)
                if (!symbol)
                    return

                const geometry = ftr.geometry
                const screenPoint = view.toScreen(geometry)

                if (!screenPoint) return;
                
                if (this.offSet && this.offSet[oid]){
                    screenPoint.y += this.offSet[oid].y
                    screenPoint.x += this.offSet[oid].x
                } else
                    screenPoint.y += symbol.height + 20

                const shiftedGeometry = view.toMap(screenPoint)

                this.graphicsLayer.add(this.getConnectingLine(oid, geometry, shiftedGeometry))
                this.graphicsLayer.add({
                    geometry: shiftedGeometry,
                    symbol: symbol,
                    attributes: ftr.attributes,
                    sourceLayer: layer,
                    popupTemplate: layer.popupTemplate,
                    originalGeometry: geometry
                })
            })
        })
    }

    /**
     * Connect the feature with the box with a line.
     * ParentId is the objectId of the feature, this will id will be used to associate the line with the feature
     */
    getConnectingLine = (parentOid, from, to) => {
        return {
            geometry: {
                type: "polyline",
                paths: [[from.longitude, from.latitude], [to.longitude, from.latitude], [to.longitude, to.latitude]]
            },
            symbol: {
                type: "simple-line",
                color: this.config.opsColor,
                width: 1
            },
            parentOid: parentOid
        }
    }

    /**
     * Override this method!!
     * @param feature
     */
    createSvgSymbol = (feature) => {
        console.log("Don't forget to override this method", feature)
        return null
    }

    createNsElement = (qName) => {
        return document.createElementNS("http://www.w3.org/2000/svg", qName)
    }
    /**
     * Firefox doesn't render SVGs on html5 canvas when the height and width parameters are not set
     * https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage
     */
    setElementWidthHeight = (svgContainer, width, height) => {
        svgContainer.setAttribute('width', `${width}px`)
        svgContainer.setAttribute('height', `${height}px`)
        svgContainer.setAttribute("viewBox", `0 0 ${width} ${height}`)
    }
}