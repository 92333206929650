import {CustomizationTitle, DrawingsButton, StyledBody} from "../SidebarMenu/SidebarMenu-styled";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ColorsRow from "./ColorsRow";

const tools = [
  {
    type: 'selection',
    icon: (
      <svg
        aria-hidden="true"
        focusable="false"
        viewBox="0 0 22 22"
        fill="none"
        strokeWidth="1.25"
      >
        <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
          <path
            className="stroke"
            d="M6 6l4.153 11.793a0.365 .365 0 0 0 .331 .207a0.366 .366 0 0 0 .332 -.207l2.184 -4.793l4.787 -1.994a0.355 .355 0 0 0 .213 -.323a0.355 .355 0 0 0 -.213 -.323l-11.787 -4.36z"></path>
          <path className="stroke" d="M13.5 13.5l4.5 4.5"></path>
        </g>
      </svg>
    )
  },
  {
    type: 'arrow',
    icon: (
      <svg aria-hidden="true"
           focusable="false"
           role="img" viewBox="0 0 24 24"
           className=""
           fill="none"
           strokeWidth="2"
           stroke="currentColor"
           strokeLinecap="round"
           strokeLinejoin="round"
      >
        <g strokeWidth="1.5">
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <line x1="15" y1="16" x2="19" y2="12"></line>
          <line x1="15" y1="8" x2="19" y2="12"></line>
        </g>
      </svg>
    )
  },
  {
    type: 'rectangle',
    icon:(
      <svg aria-hidden="true"
           focusable="false"
           role="img"
           viewBox="0 0 24 24"
           fill="none"
           strokeWidth="2"
           stroke="currentColor"
           strokeLinecap="round"
           strokeLinejoin="round"
      >
        <g strokeWidth="1.5">
          <rect x="4" y="4" width="16" height="16" rx="2"></rect>
        </g>
      </svg>
    ),
  },
  {
    type: 'diamond',
    icon: (
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        viewBox="0 0 24 24"
        className=""
        fill="none"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g strokeWidth="1.5">
          <path
            d="M10.5 20.4l-6.9 -6.9c-.781 -.781 -.781 -2.219 0 -3l6.9 -6.9c.781 -.781 2.219 -.781 3 0l6.9 6.9c.781 .781 .781 2.219 0 3l-6.9 6.9c-.781 .781 -2.219 .781 -3 0z"></path>
        </g>
      </svg>
    )
  },
  {
    type: 'ellipse',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none"
           strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.5">
          <circle cx="12" cy="12" r="9"></circle>
        </g>
      </svg>
    )
  },
  {
    type: 'line',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.167 10h11.666" strokeWidth="1.5"></path>
      </svg>
    )
  },
  {
    type: 'freedraw',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.25">
          <path clipRule="evenodd"
                d="m7.643 15.69 7.774-7.773a2.357 2.357 0 1 0-3.334-3.334L4.31 12.357a3.333 3.333 0 0 0-.977 2.357v1.953h1.953c.884 0 1.732-.352 2.357-.977Z"></path>
          <path d="m11.25 5.417 3.333 3.333"></path>
        </g>
      </svg>
    )
  },
  {
    type: 'text',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none"
           strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.5">
          <line x1="4" y1="20" x2="7" y2="20"></line>
          <line x1="14" y1="20" x2="21" y2="20"></line>
          <line x1="6.9" y1="15" x2="13.8" y2="15"></line>
          <line x1="10.2" y1="6.3" x2="16" y2="20"></line>
          <polyline points="5 20 11 4 13 4 20 20"></polyline>
        </g>
      </svg>
    )
  },
  {
    type: 'eraser',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none"
           strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.5">
          <path
            d="M19 20h-10.5l-4.21 -4.3a1 1 0 0 1 0 -1.41l10 -10a1 1 0 0 1 1.41 0l5 5a1 1 0 0 1 0 1.41l-9.2 9.3"></path>
          <path d="M18 13.3l-6.3 -6.3"></path>
        </g>
      </svg>
    )
  },
  {
    type: "image",
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.25">
          <path d="M12.5 6.667h.01"></path>
          <path
            d="M4.91 2.625h10.18a2.284 2.284 0 0 1 2.285 2.284v10.182a2.284 2.284 0 0 1-2.284 2.284H4.909a2.284 2.284 0 0 1-2.284-2.284V4.909a2.284 2.284 0 0 1 2.284-2.284Z"></path>
          <path d="m3.333 12.5 3.334-3.333c.773-.745 1.726-.745 2.5 0l4.166 4.166"></path>
          <path d="m11.667 11.667.833-.834c.774-.744 1.726-.744 2.5 0l1.667 1.667"></path>
        </g>
      </svg>
    )
  }
];

const strokeWidth = [
  {
    type:1,
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.167 10h11.666" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round"
              strokeLinejoin="round"></path>
      </svg>
    )
  },
  {
    type: 2,
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.167 10h11.666" stroke="currentColor" strokeWidth="2.25" strokeLinecap="round"
              strokeLinejoin="round"></path>
      </svg>
    )
  },
  {
    type:4,
    icon:(
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
       stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
    <path d="M4.167 10h11.666" stroke="currentColor" strokeWidth="3.75" strokeLinecap="round"
          strokeLinejoin="round"></path>
  </svg>
)
  }
]
const strokeColors = ["#1e1e1e", "#228be6", "#fab005", "#7ED321", "#50E3C2"];
const bgColors = ['transparent','#b2f2bb', '#a5d8ff', '#ffec99'];
const strokeStyle = [
  {
    type: 'solid',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.167 10h11.666" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round"
              strokeLinejoin="round"></path>
      </svg>
    )
  },
  {
    type:'dashed',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none" strokeWidth="2"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="2">
          <path d="M5 12h2"></path>
          <path d="M17 12h2"></path>
          <path d="M11 12h2"></path>
        </g>
      </svg>
    )
  },
  {
    type:'dotted',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none" strokeWidth="2"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="2">
          <path d="M4 12v.01"></path>
          <path d="M8 12v.01"></path>
          <path d="M12 12v.01"></path>
          <path d="M16 12v.01"></path>
          <path d="M20 12v.01"></path>
        </g>
      </svg>
    )
  }
  ];
const edges = [
  {
    type: 'sharp',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
            strokeLinecap="round" strokeLinejoin="round">
        <svg strokeWidth="1.5">
          <path
            d="M3.33334 9.99998V6.66665C3.33334 6.04326 3.33403 4.9332 3.33539 3.33646C4.95233 3.33436 6.06276 3.33331 6.66668 3.33331H10"></path>
          <path d="M13.3333 3.33331V3.34331"></path>
          <path d="M16.6667 3.33331V3.34331"></path>
          <path d="M16.6667 6.66669V6.67669"></path>
          <path d="M16.6667 10V10.01"></path>
          <path d="M3.33334 13.3333V13.3433"></path>
          <path d="M16.6667 13.3333V13.3433"></path>
          <path d="M3.33334 16.6667V16.6767"></path>
          <path d="M6.66666 16.6667V16.6767"></path>
          <path d="M10 16.6667V16.6767"></path>
          <path d="M13.3333 16.6667V16.6767"></path>
          <path d="M16.6667 16.6667V16.6767"></path>
        </svg>
      </svg>
    )
  },
  {
    type: 'round',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" className="" fill="none" strokeWidth="2"
           strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <path d="M4 12v-4a4 4 0 0 1 4 -4h4"></path>
          <line x1="16" y1="4" x2="16" y2="4.01"></line>
          <line x1="20" y1="4" x2="20" y2="4.01"></line>
          <line x1="20" y1="8" x2="20" y2="8.01"></line>
          <line x1="20" y1="12" x2="20" y2="12.01"></line>
          <line x1="4" y1="16" x2="4" y2="16.01"></line>
          <line x1="20" y1="16" x2="20" y2="16.01"></line>
          <line x1="4" y1="20" x2="4" y2="20.01"></line>
          <line x1="8" y1="20" x2="8" y2="20.01"></line>
          <line x1="12" y1="20" x2="12" y2="20.01"></line>
          <line x1="16" y1="20" x2="16" y2="20.01"></line>
          <line x1="20" y1="20" x2="20" y2="20.01"></line>
        </g>
      </svg>
    )
  
  }]
const bgFill = [
  {
    type:'hachure',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
          stroke="currentColor" strokeWidth="1.25"></path>
        <mask id="FillHachureIcon" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16"
              style={{
                maskType: "alpha"
              }}>
          <path
            d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
            fill="currentColor" stroke="currentColor" strokeWidth="1.25"></path>
        </mask>
        <g mask="url(#FillHachureIcon)">
          <path
            d="M2.258 15.156 15.156 2.258M7.324 20.222 20.222 7.325m-20.444 5.35L12.675-.222m-8.157 18.34L17.416 5.22"
            stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
      </svg>
    )
  },
  {
    type: 'cross-hatch',
    icon:(
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none"
           strokeLinecap="round" strokeLinejoin="round">
        <g clipPath="url(#a)">
          <path
            d="M5.879 2.625h8.242a3.254 3.254 0 0 1 3.254 3.254v8.242a3.254 3.254 0 0 1-3.254 3.254H5.88a3.254 3.254 0 0 1-3.254-3.254V5.88a3.254 3.254 0 0 1 3.254-3.254Z"
            strokeWidth="1.25"></path>
          <mask id="FillCrossHatchIcon" maskUnits="userSpaceOnUse" x="-1" y="-1" width="22" height="22"
                style={{
                  maskType: "alpha"
                }}>
            <path
              d="M2.426 15.044 15.044 2.426M7.383 20 20 7.383M0 12.617 12.617 0m-7.98 17.941L17.256 5.324m-2.211 12.25L2.426 4.956M20 12.617 7.383 0m5.234 20L0 7.383m17.941 7.98L5.324 2.745"
              strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
          </mask>
          <g mask="url(#FillCrossHatchIcon)">
            <path
              d="M14.121 2H5.88A3.879 3.879 0 0 0 2 5.879v8.242A3.879 3.879 0 0 0 5.879 18h8.242A3.879 3.879 0 0 0 18 14.121V5.88A3.879 3.879 0 0 0 14.121 2Z"
              fill="currentColor"></path>
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h20v20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  },
  {
    type: 'solid',
    icon: (
      <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="currentColor"
           strokeLinecap="round" strokeLinejoin="round">
        <g clipPath="url(#a)">
          <path
            d="M4.91 2.625h10.18a2.284 2.284 0 0 1 2.285 2.284v10.182a2.284 2.284 0 0 1-2.284 2.284H4.909a2.284 2.284 0 0 1-2.284-2.284V4.909a2.284 2.284 0 0 1 2.284-2.284Z"
            strokeWidth="1.25"></path>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h20v20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    ),
  }
]

const compareKeys = (a, b) => {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

const Drawings = ({drawings, config, t}) => {
  const [count, setCount] = useState(0);
  const selectedTool = useRef('');
  const selectedIds = useRef({});
  
  useEffect(()=>{
    if (drawings.api) {
      drawings.api.onChange((elements, appState)=>{
        const { activeTool, selectedElementIds } = appState;

        if (selectedTool.current !== activeTool.type) {
          selectedTool.current = activeTool.type;
          setCount(prev => prev+1);
        }
        
        const areEqual = compareKeys(selectedElementIds, selectedIds.current)

        if (!areEqual) {
          selectedIds.current = selectedElementIds
          setCount(prev => prev+1);
        }
      })
    }
  },[drawings.api])
  
  const onToolSelect = (tool) =>{
    drawings.api.setActiveTool({
      type: tool,
    })
  }
  
  const configurations = useMemo(()=>{
    if (!drawings.api) return;
    
    if (selectedTool.current !== 'selection') {
      return {
        strokeColor: [],
        strokeWidth: [],
        strokeStyle:[],
        backgroundColor: [],
        fillStyle:[],
        roundness:[],
      }
    }
    
    const {getSceneElements} = drawings.api;
    const selectedElements = getSceneElements().filter(el=>selectedIds.current[el.id])
    
    const configs = {
      strokeColor: [],
      strokeWidth: [],
      strokeStyle:[],
      backgroundColor: [],
      fillStyle:[],
      roundness:[],
    }
    
    selectedElements.forEach(el=>{
      if (el.type === 'image') return;
      
      if (el.strokeColor && !configs.strokeColor.includes(el.strokeColor)) {
        configs.strokeColor.push(el.strokeColor)
      }
  
      if (el.type === 'text') return;
      
      if (el.strokeStyle && !configs.strokeStyle.includes(el.strokeStyle)){
        configs.strokeStyle.push(el.strokeStyle)
      }
      
      if (el.backgroundColor && el.type !== 'arrow' && !configs.backgroundColor.includes(el.backgroundColor)) {
        configs.backgroundColor.push(el.backgroundColor)
      }
  
      if (el.strokeWidth && !configs.strokeWidth.includes(el.strokeWidth)) {
        configs.strokeWidth.push(el.strokeWidth)
      }
  
      if (el.fillStyle && el.type !== 'arrow' && !configs.fillStyle.includes(el.fillStyle)) {
        configs.fillStyle.push(el.fillStyle)
      }
      
      if ('roundness' in el && !configs.roundness.includes(el.roundness?.type ? 'round' : 'sharp')) {
        const value = el.roundness?.type ? 'round' : 'sharp';
        configs.roundness.push(value)
      }
    })
    
    Object.keys(configs).forEach(k=>{
      if (configs[k].length > 1) {
        configs[k] = '';
      } else if (configs[k].length === 1) {
        configs[k] = configs[k][0];
      }
    })
    
    return configs;
  },[count, drawings.api])
  
  const updateElements = useCallback((key, v)=>{
    const {getSceneElements, getAppState} = drawings.api;
    const {selectedElementIds} = getAppState();
    
    const newElements = getSceneElements().map(el=>{
      if (selectedElementIds[el.id]){
        let value = v;
        if (key === 'roundness') {
          value = v === 'sharp' ? null : {
            type: 2
          }
        }
        
        return {
          ...el,
          [key]:value
        }
      }
      
      return el
    });
    
    drawings.api.updateScene({
      elements: newElements
    })
    setCount(prev=>prev+1);
    
  },[drawings.api])
  
  if (!configurations) return;
  
  return (
      <StyledBody opsColor={config.opsColor}>
        <div style={{
          display:'flex',
          flexWrap:'wrap',
          gap:8
        }}>
          <p><span>1. </span>{t("print.drawings.questions.1.title")}</p>
          {
            tools.map((tool=>(
              <DrawingsButton
                key={tool.type}
                selected={selectedTool.current === tool.type}
                onClick={()=>onToolSelect(tool.type)}
              >
                {tool.icon}
              </DrawingsButton>
            )))
          }
        </div>
        {
          (!Array.isArray(configurations.strokeColor) || !Array.isArray(configurations.backgroundColor)) && <div style={{display:'flex', flexDirection:'column', gap:14, justifyContent:'space-between'}}>
            {
              !Array.isArray(configurations.strokeColor) && (
                <ColorsRow
                  title={t("print.drawings.questions.1.options.strokeColor")}
                  selectedColor={configurations.strokeColor}
                  colors={[...strokeColors, config.opsColor]}
                  onClick={(color)=>updateElements('strokeColor', color)}
                />
              )
            }
            {!Array.isArray(configurations.backgroundColor) && (
              <ColorsRow
                title={t("print.drawings.questions.1.options.backgroundColor")}
                selectedColor={configurations.backgroundColor}
                colors={[...bgColors, config.opsColor]}
                onClick={(color)=>updateElements('backgroundColor', color)}
              />
            )}
          </div>
        }
        {(!Array.isArray(configurations.strokeWidth) || !Array.isArray(configurations.fillStyle)) &&
          <div style={{display: "flex", justifyContent: "space-between"}}>
            {
              !Array.isArray(configurations.strokeWidth) && (
                <div>
                  <CustomizationTitle>{t("print.drawings.questions.1.options.strokeWidth")}</CustomizationTitle>
                  <div style={{display: "flex", gap: 4}}>
                    {
                      strokeWidth.map(strokeWidth => (
                        <DrawingsButton
                          size="s"
                          onClick={() => updateElements("strokeWidth", strokeWidth.type)}
                          key={strokeWidth.type}
                          selected={strokeWidth.type === configurations.strokeWidth}
                        >
                          {strokeWidth.icon}
                        </DrawingsButton>
                      ))
                    }
                  </div>
                </div>
              )
            }
            {
              !Array.isArray(configurations.fillStyle) && (
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                  <CustomizationTitle>{t("print.drawings.questions.1.options.fill")}</CustomizationTitle>
                  <div style={{display: "flex", gap: 4}}>
                    {
                      bgFill.map(fill => (
                        <DrawingsButton
                          size="s"
                          onClick={() => updateElements("fillStyle", fill.type)}
                          key={fill.type}
                          selected={fill.type === configurations.fillStyle}
                        >
                          {fill.icon}
                        </DrawingsButton>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </div>}
        {(!Array.isArray(configurations.strokeStyle) && !Array.isArray(configurations.roundness)) &&
          <div style={{display: "flex", justifyContent: "space-between"}}>
            {
              !Array.isArray(configurations.strokeStyle) && <div>
                <CustomizationTitle>{t("print.drawings.questions.1.options.strokeStyle")}</CustomizationTitle>
                <div style={{display: "flex", gap: 4}}>
                  {
                    strokeStyle.map(style => (
                      <DrawingsButton
                        size="s"
                        onClick={() => updateElements("strokeStyle", style.type)}
                        key={style.type}
                        selected={style.type === configurations.strokeStyle}
                      >
                        {style.icon}
                      </DrawingsButton>
                    ))
                  }
                </div>
              </div>
            }
            {!Array.isArray(configurations.roundness) && <div>
              <CustomizationTitle>{t("print.drawings.questions.1.options.edges")}</CustomizationTitle>
              <div style={{display: "flex", gap: 4}}>
                {
                  edges.map(edge => (
                    <DrawingsButton
                      size="s"
                      onClick={() => updateElements("roundness", edge.type)}
                      key={edge.type}
                      selected={edge.type === configurations.roundness}
                    >
                      {edge.icon}
                    </DrawingsButton>
                  ))
                }
              </div>
            </div>}
          </div>}
      </StyledBody>
  );
};

export default Drawings;