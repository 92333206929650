import {HelpDropdownListItem, HelpDropdownListItemIcon, SectionItem} from "../../Header-styled";
import Dropdown, {DropdownMenu, DropdownMenuItem} from "../../../Dropdown";
import React, {useCallback, useState} from "react";
import {isWidgetDisplayed} from "../../../../esri/widgets/expandUtils";
import {useTranslation} from "react-i18next";

const InfoIcon = ({...props}) =>(
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path><path d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z"></path>
  </svg>
)

const ShowMeAroundIcon = () =>{
  return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"></path></svg>
}

const QuestionIcon = () =>{
  return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10.97 8.265a1.45 1.45 0 0 0-.487.57.75.75 0 0 1-1.341-.67c.2-.402.513-.826.997-1.148C10.627 6.69 11.244 6.5 12 6.5c.658 0 1.369.195 1.934.619a2.45 2.45 0 0 1 1.004 2.006c0 1.033-.513 1.72-1.027 2.215-.19.183-.399.358-.579.508l-.147.123a4.329 4.329 0 0 0-.435.409v1.37a.75.75 0 1 1-1.5 0v-1.473c0-.237.067-.504.247-.736.22-.28.486-.517.718-.714l.183-.153.001-.001c.172-.143.324-.27.47-.412.368-.355.569-.676.569-1.136a.953.953 0 0 0-.404-.806C12.766 8.118 12.384 8 12 8c-.494 0-.814.121-1.03.265ZM13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path><path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1ZM2.5 12a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12 2.5 9.5 9.5 0 0 0 2.5 12Z"></path></svg>
}

const EmailIcon = () =>{
  return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg>
}

const HelpDropdown = ({tour, config, setMenuVisible}) => {
  const [show, setShow] = useState(false);
  const {t} = useTranslation('common');
  
  const handleClick = useCallback((href)=>{
    window.open(href, '_blank');
    setShow(false);
  },[])
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
      leftOffset={0.5}
      styles={{
        zIndex:5
      }}
    >
      <SectionItem>
        <QuestionIcon />
      </SectionItem>
      <DropdownMenu>
        {
          isWidgetDisplayed(config, 'Onboarding') && (
            <DropdownMenuItem
              style={{
                padding: '4px 8px'
              }}
              opsColor={config.opsColor}
              onClick={()=>{
                setShow(false);
                tour.start();
                setMenuVisible(false);
              }}
            >
              <HelpDropdownListItem>
                <HelpDropdownListItemIcon style={{position:'relative', top:-1}}>
                  <ShowMeAroundIcon />
                </HelpDropdownListItemIcon>
                <span>{t('help.onboarding')}</span>
              </HelpDropdownListItem>
            </DropdownMenuItem>
          )
        }
        {/*<DropdownMenuItem*/}
        {/*  style={{*/}
        {/*    padding: '4px 8px'*/}
        {/*  }}*/}
        {/*  opsColor="#525252"*/}
        {/*  onClick={()=>handleClick('mailto:global.logie@wpf.org')}*/}
        {/*>*/}
        {/*  <HelpDropdownListItem*/}
        {/*    style={{height:12}}*/}
        {/*  >*/}
        {/*    <HelpDropdownListItemIcon>*/}
        {/*      <EmailIcon />*/}
        {/*    </HelpDropdownListItemIcon>*/}
        {/*    <span>Contact</span>*/}
        {/*  </HelpDropdownListItem>*/}
        {/*</DropdownMenuItem>*/}
        <DropdownMenuItem
          style={{
            padding: '4px 8px'
          }}
          opsColor={config.opsColor}
          onClick={()=>handleClick('https://logcluster.org/en/logie/about')}
        >
          <HelpDropdownListItem>
            <HelpDropdownListItemIcon>
              <InfoIcon style={{position:'relative', left:-1, top:-1}} />
            </HelpDropdownListItemIcon>
            <span>{t('help.about')}</span>
          </HelpDropdownListItem>
        </DropdownMenuItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default HelpDropdown;
