import {StyledBody, StyledHeader, StyledSwiperSlide} from "../SidebarMenu/SidebarMenu-styled";
import {StyledSectionTitle} from "../../Report/new/ScrollSection/ScrollSection-styled";
import StyledSelect from "../../StyledSelect/StyledSelect";
import PageOrientation from "../PageOrientation/PageOrientation";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";

const pageOptions = [
  {
    label: 'A4',
    value: 'A4'
  },
  {
    label: 'A3',
    value: 'A3'
  },
  {
    label: 'A2',
    value: 'A2'
  }
]

const DocumentSettings = ({
    config,
    page,
    dpi,
    setPage,
    setDpi,
    t
}) => {
  const onPageSizeChange = useCallback(({_label, value})=>{
    setPage(prev=>({
      ...prev,
      size: value
    }))
  },[setPage])
  
  const onOrientationChange = useCallback((e)=>{
    setPage(prev=>({
      ...prev,
      orientation: e.target.value
    }))
  },[setPage])
  
  const onDpiChange = useCallback(({_label, value}) =>{
    setDpi(value)
  },[setDpi])
  
  const dpiOptions = useMemo(()=>{
    return [
      {
        label: t('print.documentSettings.questions.3.options.print'),
        value: 300
      },
      {
        label: t('print.documentSettings.questions.3.options.screen'),
        value: 72
      }
    ]
  },[t])
  
  const selectedDpi = useMemo(()=>{
    return dpiOptions.find(item=>item.value == dpi);
  },[dpiOptions])
  
  return (
      <StyledBody opsColor={config.opsColor}>
        <div>
          <p><span>1. </span>{t("print.documentSettings.questions.1.title")}</p>
          <StyledSelect
            onChange={onPageSizeChange}
            opsColor={config.opsColor}
            isSearchable={false}
            defaultValue={{
              label: page.size,
              value: page.size
            }}
            options={pageOptions}
          />
        </div>
        <div>
          <p><span>2. </span>{t("print.documentSettings.questions.2.title")}</p>
          <PageOrientation
            selected={page.orientation}
            onInputChange={onOrientationChange}
            opsColor={config.opsColor}
            t={t}
          />
        </div>
        <div>
          <p><span>3. </span>{t("print.documentSettings.questions.3.title")}</p>
          <StyledSelect
            onChange={onDpiChange}
            opsColor={config.opsColor}
            defaultValue={selectedDpi}
            options={dpiOptions}
          />
        </div>
      </StyledBody>
  );
};

export default DocumentSettings;