import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {ROLE_EDITOR} from "../../../../utils/helper";
import {
  StyledCard,
  StyledContentWrapper,
} from "../Insights-styled";
import InsightsCard from "../InsightsCard/InsightsCard";
import CardHeader from "../InsightsCard/CardHeader";
import CustomPieChart from "../Charts/CustomPieChart";
import {ResponsiveContainer} from "recharts";
import CustomBarChart from "../Charts/CustomBarChart";
import {COLORS} from "../Insights";

const InsightsSCP = ({expand, onFilterChange, statsData}) => {
  const [barData, setBarData] = useState([]);
  const [pieData, setPiedData] = useState([]);
  const [piedData2, setPiedData2] = useState([]);
  const [totals, setTotals] = useState([]);
  
  const {t} = useTranslation('common');
  const selectedFilters = useRef([]);
  
  const {activeModule, filters:mapFilters} = useSelector(state=>state);
  const {config} = useContext(ConfigContext);
  
  const handleCloseSidebar = useCallback(()=>{
    setBarData([]);
    setTotals([]);
    setPiedData([]);
    setPiedData2([]);
    
    const filter = {}
    selectedFilters.current.forEach((key)=>{
      const filterField = config.filterFields.find(item=>item.name === key);
      filter[key] = filterField?.defaultValue
    })
    
    onFilterChange(filter);
    
    selectedFilters.current = [];
  },[onFilterChange])
  
  useEffect(()=>{
    if (!expand){
      handleCloseSidebar();
    }
    
    return ()=>{
      handleCloseSidebar();
    }
  },[expand])
  
  useEffect(()=>{
    handleData(statsData);
  },[statsData])
  
  const handleData = useCallback((newData)=>{
    if (newData.length === 0) {
      setBarData([]);
      setTotals([]);
      setPiedData([]);
      setPiedData2([]);
      return
    }
    const {storagecapm2_sum, currutilizationperc_avg, storagecapm2_count, communityshare_sum_1, storageclimatecontrol_sum_1, numcommunityshare_sum, numpartners_sum, numwh_sum, numwhccs_sum ,...restData} = newData[0].attributes || {};
    const attributes = newData[0].attributes;

    if (config.role !== ROLE_EDITOR) {
      setTotals([
        {
          title: t('layer.fieldAlias.' + 'storagecapm2'),
          value: formatNumber(storagecapm2_sum ?? 0),
          unit: ' m²'
        },
        {
          title: t('layer.fieldAlias.' + 'numpartners'),
          value: formatNumber(numpartners_sum ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numwh'),
          value: formatNumber(numwh_sum ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numwhccs'),
          value: formatNumber(numwhccs_sum ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numcommunityshare'),
          value: formatNumber(numcommunityshare_sum ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'currutilizationperc'),
          value: formatNumber((currutilizationperc_avg ?? 0)),
          unit: '%'
        }
      ])
    } else {
      setTotals([
        {
          title: t('layer.fieldAlias.' + 'storagecapm2'),
          value: formatNumber(storagecapm2_sum ?? 0),
          unit: ' m²'
        },
        {
          title: t('layer.fieldAlias.' + 'numpartners'),
          // value: formatNumber(numpartners ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numwh'),
          value: formatNumber(storagecapm2_count ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numwhccs'),
          value: formatNumber(storageclimatecontrol_sum_1 ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'numcommunityshare'),
          value: formatNumber(communityshare_sum_1 ?? 0)
        },
        {
          title: t('layer.fieldAlias.' + 'currutilizationperc'),
          value: formatNumber((currutilizationperc_avg ?? 0)),
          unit: '%'
        }
      ])
      
      if (!selectedFilters.current.includes('communityshare')){
        setPiedData([
          {
            name: t('layer.domain.communityshare.1'),
            value: communityshare_sum_1 ?? 0,
            key: '1',
            filterKey:'communityshare'
          },
          {
            name: t('layer.domain.communityshare.2'),
            value: attributes.communityshare_sum_2 ?? 0,
            key: '2',
            filterKey:'communityshare'
          },
          {
            name: t('layer.domain.communityshare.0'),
            value: attributes.communityshare_sum_0 ?? 0,
            key: '0',
            filterKey:'communityshare'
          }
        ])
      }
      
      if (!selectedFilters.current.includes('storageclimatecontrol')){
        setPiedData2([
          {
            name: t('layer.domain.storageclimatecontrol.1'),
            value: attributes.storageclimatecontrol_sum_1 ?? 0,
            filterKey: 'storageclimatecontrol',
            key:'1'
          },
          {
            name: t('layer.domain.storageclimatecontrol.2'),
            value: attributes.storageclimatecontrol_sum_2 ?? 0,
            filterKey: 'storageclimatecontrol',
            key:'2'
          },
          {
            name: t('layer.domain.storageclimatecontrol.0'),
            value: attributes.storageclimatecontrol_sum_0 ?? 0,
            filterKey: 'storageclimatecontrol',
            key:'0'
          }
        ])
      }
    }
    
    const ccsKeys = ['ccstoragecapminus15m2', 'ccstoragecap2to8m2', 'ccstoragecap8to15m2', 'ccstoragecap15to25m2'];
    
    const array = selectedFilters.current.filter(key=>ccsKeys.includes(key));
    if (array.length === 0){
      setBarData([
        {
          name:'Below -15°C (㎡)',
          value:restData.ccstoragecapminus15m2_sum ?? 0,
          key:'ccstoragecapminus15m2'
        },
        {
          name: '2°C to 8°C (㎡)',
          value:restData.ccstoragecap2to8m2_sum ?? 0,
          key: 'ccstoragecap2to8m2'
        },
        {
          name: '8°C to 15°C (㎡)',
          value:restData.ccstoragecap8to15m2_sum ?? 0,
          key:'ccstoragecap8to15m2'
        },
        {
          name:'15°C to 25°C (㎡)',
          value: restData.ccstoragecap15to25m2_sum ?? 0,
          key:'ccstoragecap15to25m2'
        },
      ])
    }
  },[config, mapFilters, activeModule, piedData2, pieData, barData])
  
  const formatNumber = (value, options = {}) =>{
    if (!Number.isFinite(value)) return value
    const result = Intl.NumberFormat("en-GB", {
      maximumFractionDigits: 2,
    }).format(value);
    
    return result;
  }
  
  const handlePieChartSelection = useCallback((key, value)=>{
    if (value) {
      selectedFilters.current.push(key)
      onFilterChange({[key]: [value]})
    } else {
      const index = selectedFilters.current.indexOf(key);
      if (index > - 1) {
        selectedFilters.current.splice(index,1);
      }
      
      onFilterChange({[key]: []})
    }
  },[onFilterChange])
  
  const handleBarSelect = useCallback((params)=>{
    const index = params.activeTooltipIndex;
    if (index === undefined) return;
    
    const filter = {};
    selectedFilters.current = selectedFilters.current.filter(key=>!barData.find(item=>item.key === key));
    barData.forEach((item,i)=>{
      if (i === index && !mapFilters[activeModule][item.key]) {
        selectedFilters.current.push(item.key)
        filter[item.key] = ['> 0'];
      } else {
        filter[item.key] = undefined;
      }
    })
    
    onFilterChange(filter)
  },[barData, mapFilters, activeModule, onFilterChange])
  
  return (
    <div style={{
      display:'flex',
      flexDirection:"column",
      flex:1,
      gap:10
    }}>
      <StyledContentWrapper>
        {totals.filter(item=>item.value !== null || item.value !== undefined).map(item=>(
          <InsightsCard
            key={item.title}
            insight={item}
          />
        ))}
      </StyledContentWrapper>
      <div style={{display:'flex', flexDirection:'column', flex:1, gap:10}}>
        {
          (piedData2.filter(item => item.value !== null).length > 0  || pieData.filter(item => item.value !== null).length > 0) && (
            <div style={{display:'flex', gap:10, flex:1, flexWrap:'wrap'}}>
              <StyledCard style={{ display:'flex', flexDirection:'column', minHeight:170, boxSizing:'border-box', position:'relative'}}>
                <CardHeader
                  title={t("layer.fieldAlias." + "numwhccs")}
                  description="Description of the chart"
                />
                <div style={{position: "relative", flex:1}}>
                  <CustomPieChart
                    onClick={handlePieChartSelection}
                    data={piedData2}
                    mapFilters={mapFilters[activeModule]}
                  />
                </div>
              </StyledCard>
              <StyledCard style={{ display:'flex', flexDirection:'column', minHeight:170, boxSizing:'border-box', position:'relative'}}>
                <CardHeader
                  title={t("layer.fieldAlias." + "numcommunityshare")}
                  description="Description of the chart"
                />
                <div style={{position: "relative", flex:1}}>
                  <CustomPieChart
                    onClick={handlePieChartSelection}
                    data={pieData}
                    mapFilters={mapFilters[activeModule]}
                  />
                </div>
              </StyledCard>
            </div>
          )
        }
        {barData.filter(item => item.value !== null).length > 0 && (<div style={{flex:1}}>
            <StyledCard style={{width: "100%", height:'100%',boxSizing: "border-box", display:'flex', flexDirection:'column', minHeight:170}}>
              <CardHeader
                title={t("layer.fieldAlias." + "storagecapm2")}
                description="Chart description"
              />
              <div
                style={{position: "relative", flex:1}}>
                <ResponsiveContainer style={{position:'absolute'}}>
                  <CustomBarChart
                    t={t}
                    config={config}
                    data={barData}
                    onClick={handleBarSelect}
                    mapFilters={mapFilters[activeModule]}
                    barValues={['value']}
                    colors={{
                      fill:{
                        value: COLORS.blue
                      }
                    }}
                  />
                </ResponsiveContainer>
              </div>
            </StyledCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsSCP;