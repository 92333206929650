import styled from "styled-components";

export const StyledReportButton = styled.div`
    ${({color}) => {if (color) { return { backgroundColor: color }}}};
    color: white;
    cursor: pointer;
    padding: 9px 7px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 8px;
    outline: none;
    display: flex;
    align-items: center;
    gap: 6px;
    
    @media (max-width: 769px) {
        width: 48px;
        height: 38px;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 0 0 2px;
        span {
            display: none;
            font-size: 12px;
        }
    }
`