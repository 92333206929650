export const timezones = {
    AND: ["Europe/Andorra"],
    ARE: ["Asia/Dubai"],
    AFG: ["Asia/Kabul"],
    ATG: ["America/Antigua"],
    AIA: ["America/Anguilla"],
    ALB: ["Europe/Tirane"],
    ARM: ["Asia/Yerevan"],
    AGO: ["Africa/Luanda"],
    ATA: [
        "Antarctica/McMurdo",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Mawson",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
    ],
    ARG: [
        "America/Buenos_Aires",
        "America/Argentina/ComodRivadavia",
        "America/Catamarca",
        "America/Cordoba",
        "America/Rosario",
        "America/Jujuy",
        "America/Mendoza",
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
    ],
    ASM: ["Pacific/Samoa", "US/Samoa", "Pacific/Pago_Pago"],
    AUT: ["Europe/Vienna"],
    AUS: [
        "Australia/South",
        "Australia/Queensland",
        "Australia/Yancowinna",
        "Australia/North",
        "Australia/Tasmania",
        "Australia/LHI",
        "Australia/Victoria",
        "Australia/West",
        "Australia/ACT",
        "Australia/Canberra",
        "Australia/NSW",
        "Antarctica/Macquarie",
        "Australia/Sydney",
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Broken_Hill",
        "Australia/Currie",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/Lord_Howe",
        "Australia/Lindeman",
        "Australia/Melbourne",
        "Australia/Perth",
    ],
    ABW: ["America/Aruba"],
    ALA: ["Europe/Mariehamn"],
    AZE: ["Asia/Baku"],
    BIH: ["Europe/Sarajevo"],
    BRB: ["America/Barbados"],
    BGD: ["Asia/Dacca", "Asia/Dhaka"],
    BEL: ["Europe/Brussels"],
    BFA: ["Africa/Ouagadougou"],
    BGR: ["Europe/Sofia"],
    BHR: ["Asia/Bahrain"],
    BDI: ["Africa/Bujumbura"],
    BEN: ["Africa/Porto-Novo"],
    BLM: ["America/St_Barthelemy"],
    BMU: ["Atlantic/Bermuda"],
    BRN: ["Asia/Brunei"],
    BOL: ["America/La_Paz"],
    BES: ["America/Kralendijk"],
    BRA: [
        "Brazil/West",
        "Brazil/DeNoronha",
        "America/Porto_Acre",
        "Brazil/Acre",
        "Brazil/East",
        "America/Araguaina",
        "America/Bahia",
        "America/Belem",
        "America/Boa_Vista",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Eirunepe",
        "America/Fortaleza",
        "America/Maceio",
        "America/Manaus",
        "America/Noronha",
        "America/Rio_Branco",
        "America/Porto_Velho",
        "America/Recife",
        "America/Santarem",
        "America/Sao_Paulo",
    ],
    BHS: ["America/Nassau"],
    BTN: ["Asia/Thimbu", "Asia/Thimphu"],
    BVT: [],
    BWA: ["Africa/Gaborone"],
    BLR: ["Europe/Minsk"],
    BLZ: ["America/Belize"],
    CAN: [
        "America/Coral_Harbour",
        "Canada/Mountain",
        "Canada/Atlantic",
        "Canada/Saskatchewan",
        "Canada/Newfoundland",
        "America/Montreal",
        "Canada/Eastern",
        "Canada/Pacific",
        "Canada/Yukon",
        "Canada/Central",
        "America/Atikokan",
        "America/Blanc-Sablon",
        "America/Cambridge_Bay",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Dawson",
        "America/Edmonton",
        "America/Fort_Nelson",
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Inuvik",
        "America/Iqaluit",
        "America/Moncton",
        "America/Toronto",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Regina",
        "America/Resolute",
        "America/St_Johns",
        "America/Swift_Current",
        "America/Thunder_Bay",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Winnipeg",
        "America/Yellowknife",
    ],
    CCK: ["Indian/Cocos"],
    COD: ["Africa/Kinshasa", "Africa/Lubumbashi"],
    CAF: ["Africa/Bangui"],
    COG: ["Africa/Brazzaville"],
    CHE: ["Europe/Zurich"],
    CIV: ["Africa/Timbuktu", "Africa/Abidjan"],
    COK: ["Pacific/Rarotonga"],
    CHL: [
        "Chile/Continental",
        "Chile/EasterIsland",
        "America/Punta_Arenas",
        "America/Santiago",
        "Pacific/Easter",
    ],
    CMR: ["Africa/Douala"],
    CHN: [
        "Asia/Chongqing",
        "Asia/Chungking",
        "Asia/Harbin",
        "PRC",
        "Asia/Kashgar",
        "Asia/Shanghai",
        "Asia/Urumqi",
    ],
    COL: ["America/Bogota"],
    CRI: ["America/Costa_Rica"],
    CUB: ["Cuba", "America/Havana"],
    CPV: ["Atlantic/Cape_Verde"],
    CUW: ["America/Curacao"],
    CXR: ["Etc/GMT-7", "Indian/Christmas"],
    CYP: ["Europe/Nicosia", "Asia/Famagusta", "Asia/Nicosia"],
    CZE: ["Europe/Prague"],
    DEU: ["Europe/Busingen", "Europe/Berlin"],
    DJI: ["Africa/Djibouti"],
    DNK: ["Europe/Copenhagen"],
    DMA: ["America/Dominica"],
    DOM: ["America/Santo_Domingo"],
    DZA: ["Africa/Algiers"],
    ECU: ["America/Guayaquil", "Pacific/Galapagos"],
    EST: ["Europe/Tallinn"],
    EGY: ["Egypt", "Africa/Cairo"],
    ESH: ["Africa/El_Aaiun"],
    ERI: ["Africa/Asmara"],
    ESP: ["Africa/Ceuta", "Atlantic/Canary", "Europe/Madrid"],
    ETH: ["Africa/Addis_Ababa"],
    FIN: ["Europe/Helsinki"],
    FJI: ["Pacific/Fiji"],
    FLK: ["Atlantic/Stanley"],
    FSM: [
        "Pacific/Truk",
        "Pacific/Yap",
        "Pacific/Ponape",
        "Pacific/Chuuk",
        "Pacific/Kosrae",
        "Pacific/Pohnpei",
    ],
    FRO: ["Atlantic/Faeroe", "Atlantic/Faroe"],
    FRA: ["Europe/Paris"],
    GAB: ["Africa/Libreville"],
    GBR: ["Europe/Belfast", "GB", "GB-Eire", "Europe/London"],
    GRD: ["America/Grenada"],
    GEO: ["Asia/Tbilisi"],
    GUF: ["America/Cayenne"],
    GGY: ["Europe/Guernsey"],
    GHA: ["Africa/Accra"],
    GIB: ["Europe/Gibraltar"],
    GRL: [
        "America/Nuuk",
        "America/Danmarkshavn",
        "America/Godthab",
        "America/Scoresbysund",
        "America/Thule",
    ],
    GMB: ["Africa/Banjul"],
    GIN: ["Africa/Conakry"],
    GLP: ["America/Guadeloupe"],
    GNQ: ["Africa/Malabo"],
    GRC: ["Europe/Athens"],
    SGS: ["Etc/GMT+2", "Atlantic/South_Georgia"],
    GTM: ["America/Guatemala"],
    GUM: ["Pacific/Guam"],
    GNB: ["Africa/Bissau"],
    GUY: ["America/Guyana"],
    HKG: ["Hongkong", "Asia/Hong_Kong"],
    HMD: [],
    HND: ["America/Tegucigalpa"],
    HRV: ["Europe/Zagreb"],
    HTI: ["America/Port-au-Prince"],
    HUN: ["Europe/Budapest"],
    IDN: [
        "Asia/Ujung_Pandang",
        "Asia/Jakarta",
        "Asia/Jayapura",
        "Asia/Makassar",
        "Asia/Pontianak",
    ],
    IRL: ["Eire", "Europe/Dublin"],
    ISR: ["Asia/Tel_Aviv", "Israel", "Asia/Jerusalem"],
    IMN: ["Europe/Isle_of_Man"],
    IND: ["Asia/Calcutta", "Asia/Kolkata"],
    IOT: ["Indian/Chagos"],
    IRQ: ["Asia/Baghdad"],
    IRN: ["Iran", "Asia/Tehran"],
    ISL: ["Iceland", "Atlantic/Reykjavik"],
    ITA: ["Europe/Rome"],
    JEY: ["Europe/Jersey"],
    JAM: ["Jamaica", "America/Jamaica"],
    JOR: ["Asia/Amman"],
    JPN: ["Japan", "Asia/Tokyo"],
    KEN: ["Africa/Asmera", "Africa/Nairobi"],
    KGZ: ["Asia/Bishkek"],
    KHM: ["Asia/Phnom_Penh"],
    KIR: [
        "Etc/GMT-12",
        "Pacific/Tarawa",
        "Pacific/Enderbury",
        "Pacific/Kiritimati",
    ],
    COM: ["Indian/Comoro"],
    KNA: ["America/St_Kitts"],
    PRK: ["Asia/Pyongyang"],
    KOR: ["ROK", "Asia/Seoul"],
    KWT: ["Asia/Kuwait"],
    CYM: ["America/Cayman"],
    KAZ: [
        "Asia/Almaty",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Atyrau",
        "Asia/Oral",
        "Asia/Qostanay",
        "Asia/Qyzylorda",
    ],
    LAO: ["Asia/Vientiane"],
    LBN: ["Asia/Beirut"],
    LCA: ["America/St_Lucia"],
    LIE: ["Europe/Vaduz"],
    LKA: ["Asia/Colombo"],
    LBR: ["Africa/Monrovia"],
    LSO: ["Africa/Maseru"],
    LTU: ["Europe/Vilnius"],
    LUX: ["Europe/Luxembourg"],
    LVA: ["Europe/Riga"],
    LBY: ["Libya", "Africa/Tripoli"],
    MAR: ["Africa/Casablanca"],
    MCO: ["Europe/Monaco"],
    MDA: ["Europe/Tiraspol", "Europe/Chisinau"],
    MNE: ["Europe/Podgorica"],
    MAF: ["America/Marigot"],
    MDG: ["Indian/Antananarivo"],
    MHL: ["Kwajalein", "Pacific/Kwajalein", "Pacific/Majuro"],
    MKD: ["Europe/Skopje"],
    MLI: ["Africa/Bamako"],
    MMR: ["Asia/Yangon", "Asia/Rangoon"],
    MNG: [
        "Asia/Ulan_Bator",
        "Asia/Choibalsan",
        "Asia/Hovd",
        "Asia/Ulaanbaatar",
    ],
    MAC: ["Asia/Macao", "Asia/Macau"],
    MNP: ["Pacific/Saipan"],
    MTQ: ["America/Martinique"],
    MRT: ["Africa/Nouakchott"],
    MSR: ["America/Montserrat"],
    MLT: ["Europe/Malta"],
    MUS: ["Indian/Mauritius"],
    MDV: ["Indian/Maldives"],
    MWI: ["Africa/Blantyre"],
    MEX: [
        "Mexico/BajaSur",
        "Mexico/General",
        "America/Ensenada",
        "America/Santa_Isabel",
        "Mexico/BajaNorte",
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Chihuahua",
        "America/Tijuana",
        "America/Hermosillo",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey",
        "America/Ojinaga",
    ],
    MYS: ["Singapore", "Asia/Kuala_Lumpur", "Asia/Kuching"],
    MOZ: ["Africa/Maputo"],
    NAM: ["Africa/Windhoek"],
    NCL: ["Pacific/Noumea"],
    NER: ["Africa/Niamey"],
    NFK: ["Pacific/Norfolk"],
    NGA: ["Africa/Lagos"],
    NIC: ["America/Managua"],
    NLD: ["Europe/Amsterdam"],
    NOR: ["Atlantic/Jan_Mayen", "Europe/Oslo"],
    NPL: ["Asia/Katmandu", "Asia/Kathmandu"],
    NRU: ["Pacific/Nauru"],
    NIU: ["Pacific/Niue"],
    NZL: [
        "Antarctica/South_Pole",
        "NZ",
        "NZ-CHAT",
        "Pacific/Auckland",
        "Pacific/Chatham",
    ],
    OMN: ["Asia/Muscat"],
    PAN: ["America/Panama"],
    PER: ["America/Lima"],
    PYF: ["Pacific/Gambier", "Pacific/Marquesas", "Pacific/Tahiti"],
    PNG: ["Etc/GMT-10", "Pacific/Port_Moresby", "Pacific/Bougainville"],
    PHL: ["Asia/Manila"],
    PAK: ["Asia/Karachi"],
    POL: ["Poland", "Europe/Warsaw"],
    SPM: ["America/Miquelon"],
    PCN: ["Pacific/Pitcairn"],
    PRI: ["America/Puerto_Rico"],
    PSE: ["Asia/Gaza", "Asia/Hebron"],
    PRT: ["Portugal", "Atlantic/Azores", "Atlantic/Madeira", "Europe/Lisbon"],
    PLW: ["Etc/GMT-9", "Pacific/Palau"],
    PRY: ["America/Asuncion"],
    QAT: ["Asia/Qatar"],
    REU: ["Indian/Reunion"],
    ROU: ["Europe/Bucharest"],
    SRB: ["Europe/Belgrade"],
    RUS: [
        "W-SU",
        "Asia/Anadyr",
        "Asia/Barnaul",
        "Asia/Chita",
        "Asia/Irkutsk",
        "Asia/Kamchatka",
        "Asia/Khandyga",
        "Asia/Krasnoyarsk",
        "Asia/Magadan",
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk",
        "Asia/Sakhalin",
        "Asia/Srednekolymsk",
        "Asia/Tomsk",
        "Asia/Ust-Nera",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yekaterinburg",
        "Europe/Astrakhan",
        "Europe/Kaliningrad",
        "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Samara",
        "Europe/Saratov",
        "Europe/Ulyanovsk",
        "Europe/Volgograd",
    ],
    RWA: ["Africa/Kigali"],
    SAU: ["Asia/Riyadh"],
    SLB: ["Pacific/Guadalcanal"],
    SYC: ["Indian/Mahe"],
    SDN: ["Africa/Khartoum"],
    SWE: ["Europe/Stockholm"],
    SGP: ["Asia/Singapore"],
    SHN: ["Atlantic/St_Helena"],
    SVN: ["Europe/Ljubljana"],
    SJM: ["Arctic/Longyearbyen"],
    SVK: ["Europe/Bratislava"],
    SLE: ["Africa/Freetown"],
    SMR: ["Europe/San_Marino"],
    SEN: ["Africa/Dakar"],
    SOM: ["Africa/Mogadishu"],
    SUR: ["America/Paramaribo"],
    SSD: ["Africa/Juba"],
    STP: ["Africa/Sao_Tome"],
    SLV: ["America/El_Salvador"],
    SXM: ["America/Lower_Princes"],
    SYR: ["Asia/Damascus"],
    SWZ: ["Africa/Mbabane"],
    TCA: ["America/Grand_Turk"],
    TCD: ["Africa/Ndjamena"],
    ATF: ["Indian/Kerguelen"],
    TGO: ["Africa/Lome"],
    THA: ["Asia/Bangkok"],
    TJK: ["Asia/Dushanbe"],
    TKL: ["Pacific/Fakaofo"],
    TLS: ["Asia/Dili"],
    TKM: ["Asia/Ashkhabad", "Asia/Ashgabat"],
    TUN: ["Africa/Tunis"],
    TON: ["Pacific/Tongatapu"],
    TUR: ["Asia/Istanbul", "Turkey", "Europe/Istanbul"],
    TTO: ["America/Virgin", "America/Port_of_Spain"],
    TUV: ["Pacific/Funafuti"],
    TWN: ["ROC", "Asia/Taipei"],
    TZA: ["Africa/Dar_es_Salaam"],
    UKR: [
        "Europe/Kiev",
        "Europe/Simferopol",
        "Europe/Uzhgorod",
        "Europe/Zaporozhye",
    ],
    UGA: ["Africa/Kampala"],
    UMI: ["Pacific/Midway", "Pacific/Wake"],
    USA: [
        "America/Atka",
        "US/Aleutian",
        "US/Alaska",
        "US/Central",
        "America/Shiprock",
        "Navajo",
        "US/Mountain",
        "US/Michigan",
        "America/Indiana/Indianapolis",
        "America/Indianapolis",
        "US/East-Indiana",
        "America/Knox_IN",
        "US/Indiana-Starke",
        "America/Louisville",
        "US/Pacific",
        "US/Pacific-New",
        "US/Eastern",
        "US/Arizona",
        "Pacific/Johnston",
        "US/Hawaii",
        "America/Adak",
        "America/Anchorage",
        "America/Boise",
        "America/Chicago",
        "America/Denver",
        "America/Detroit",
        "America/Fort_Wayne",
        "America/Indiana/Knox",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Tell_City",
        "America/Indiana/Vevay",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Juneau",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Los_Angeles",
        "America/Menominee",
        "America/Metlakatla",
        "America/New_York",
        "America/Nome",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Phoenix",
        "America/Sitka",
        "America/Yakutat",
        "Pacific/Honolulu",
    ],
    URY: ["America/Montevideo"],
    UZB: ["Asia/Samarkand", "Asia/Tashkent"],
    VAT: ["Europe/Vatican"],
    VCT: ["America/St_Vincent"],
    VEN: ["America/Caracas"],
    VGB: ["America/Tortola"],
    VIR: ["America/St_Thomas"],
    VNM: ["Asia/Saigon", "Asia/Ho_Chi_Minh"],
    VUT: ["Pacific/Efate"],
    WLF: ["Pacific/Wallis"],
    WSM: ["Pacific/Apia"],
    YEM: ["Asia/Aden"],
    MYT: ["Indian/Mayotte"],
    ZAF: ["Africa/Johannesburg"],
    ZMB: ["Africa/Lusaka"],
    ZWE: ["Africa/Harare"],
};
