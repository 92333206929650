import React from "react";

import {StyledTable} from "../CustomPopupWrapper-styled";
import {DetailRow} from "./index"

/**
 * Details tab
 */
const DetailsTab = ({fields, row, t, i18n, config}) => {
    if (!fields.length)
        return null

    return (
        <StyledTable striped className="col m-1">
            {fields.map((field, index) => <DetailRow key={index} field={field} row={row} t={t} i18n={i18n} config={config} />)}
        </StyledTable>
    )
}

export default DetailsTab