import React, {useState, useEffect, useRef} from "react";
import CryptoJS from "crypto-js";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "yup-phone";
import {ReportHeader, ReportProgress} from "../index";
import FormInput from "../../new/ReportContacts/FormInput";
import {MainQuestion, ReportContent, MainHint} from "../../styles/ReportContent/ReportContent-styled";
import {MessageContainer} from "../../styles/Message/Message-styled";
import {StyledLoader} from "../../../App/App-styled";

// TODO: move to the .env file
const SECRET_KEY = "LogIeSecretKey";

const schema = yup.object().shape({
	firstName: yup.string().required("First name is required"),
	surName: yup.string(),
	contactNumber: yup.string(),
	email: yup.string().email().required("Please enter a valid email"),
	organization: yup.string().required("Organization is required"),
});

const Contacts = ({ onFormSubmit, currentScreen, title, mainQuestion, hint, screenEnum, openSnackbar, message, setMessage, closeAndResetReportArea }) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { isValid, errors },
		trigger,
	} = useForm({ resolver: yupResolver(schema), mode: "onChange" })

	const [sendEmail, setSendEmail] = useState(false)
	const [saveContacts, setSaveContacts] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const mountedRef = useRef(true)

	useEffect(() => {
		const fetchSavedContacts = () => {
			try {
				const savedContacts = localStorage.getItem("contacts")
				if (savedContacts && savedContacts?.length) {
					const parsedSavedContacts = JSON.parse(
						CryptoJS.AES.decrypt(savedContacts, SECRET_KEY).toString(CryptoJS.enc.Utf8)
					)
					
					Object.entries(parsedSavedContacts).forEach(([key, value]) => {
						setValue(key, value)
						trigger(key)
					})
					setSaveContacts(true)
					return parsedSavedContacts
				}
			} catch (err) {
				console.log(err)
			}
		}

		fetchSavedContacts()

	}, [setValue])

	useEffect(() => {
		if (message) {
			openSnackbar(message, 15000)
			setMessage("")
			setIsLoading(false)
			closeAndResetReportArea()
		}
	}, [message, openSnackbar])

	useEffect(() => {
		return () => {
			mountedRef.current = false
		}
	}, [mountedRef])

	const onSubmit = (ContactInfo) => {
		setIsLoading(true)
		if (saveContacts) {
			const encryptedString = CryptoJS.AES.encrypt(
				JSON.stringify({
					...ContactInfo,
					report: undefined,
					sendEmail: undefined,
				}),
				SECRET_KEY
			).toString();
			localStorage.setItem("contacts", encryptedString)
		}

		const task = onFormSubmit({ ...ContactInfo, sendEmail });

		task.then(() => {
			if (!mountedRef.current) return null
		}).catch(() => {
			if (!mountedRef.current) return null
		})
	}

	if (isLoading) {
		return (
			<MessageContainer>
				<StyledLoader text="Loading..."/>
			</MessageContainer>
		)
	}

	return (
		<>
			<ReportHeader title={title}/>
			<ReportProgress disableNext={true} screenEnum={screenEnum}/>
			<ReportContent>
				<MainQuestion>{mainQuestion}</MainQuestion>
				{hint && <MainHint>{hint}</MainHint>}
			</ReportContent>
			<FormInput
				onSubmit={handleSubmit(onSubmit)}
				register={register}
				errors={errors}
				setSaveContacts={setSaveContacts}
				saveContacts={saveContacts}
				isValid={isValid}
				currentScreen={currentScreen}
			/>
		</>
	)
}

export default Contacts;