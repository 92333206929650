import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: .3em;
    border: 1px solid #E0E0E0;
    font-family: 'Open Sans', sans-serif !important;
    transition: 0.5s;
`
export const InnerContainer = styled.div`
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`
export const ProgressBarContainer = styled.div`
    padding: 0 0.6em 0.6em 0.6em;
    margin: 0;
    align-items: center;
    z-index: 1;
`
export const Content = styled.div`
    padding: 0 1em .8em 1em;
    overflow-y: auto;
    flex-grow: 2;
    flex-shrink: 1;
    z-index: 1;
    ${(props) => props?.panelPosition === 'modal' ?
    `height: ${props?.parentHeight};  
           overflow: auto;
           ` : `
                min-height: 180px;
             `};
    `

export const Paragraph = styled.p`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-size: 16px;
`