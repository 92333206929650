import { setFilters_actionType } from '../constants';

const initialState = {};
const filters = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case setFilters_actionType:
            return (state = payload);
        default:
            return state;
    }
};

export default filters;
