import styled from "styled-components";
import {CalciteLoader, CalciteProgress, CalciteSwitch} from "@esri/calcite-components-react";

export const StyledApp = styled.div`
	display: flex;
	flex-direction: column;
  	height: 100%
`

export const StyledLoader = styled(CalciteLoader)`
	margin: 10rem 0 0;
`

export const StyledProgress = styled(CalciteProgress)`
  --calcite-color-brand: ${({opsColor})=>opsColor};
`

export const CustomLoader = styled(CalciteLoader)`
  padding: 0;
  stroke: ${({opsColor})=> opsColor ? `${opsColor} !important` : ''};
  --calcite-loader-size: ${({fontSize})=> fontSize ?? '1.5rem'};
`

export const CustomSwitch = styled(CalciteSwitch)`
  --calcite-color-brand: ${({opsColor})=>opsColor};
  --calcite-color-brand-hover: ${({opsColor})=>opsColor};
  --calcite-ui-focus-color: ${({opsColor})=>opsColor};
`

export const StyledMapSurveyContainer = styled.div`
	display: flex;
	justify-content: center;
  flex-grow: 2;
  text-align: center;
  position: relative;
`