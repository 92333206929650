import { loadModules, setDefaultOptions } from "esri-loader";
import { setAsyncError} from "../utils/helper";
setDefaultOptions({ css: true });

export const createMap = async (config, setError) => {
	try {
		if (!config.webmap) {
			const [ArcGISMap, Basemap] = await loadModules(["esri/Map", "esri/Basemap"])
			const basemap = (config.basemapId) ? new Basemap({	portalItem: { id: config.basemapId }})
				: Basemap.fromId(config.basemap)

			return new ArcGISMap({ basemap: basemap })
		} else {
			const [WebMap] = await loadModules(["esri/WebMap"]);
			return new WebMap({
				portalItem: {
					id: config.webmap
				}
			});
		}
	} catch (err) {
		setAsyncError(err, setError);
	}
};

export const createView = async ({
									 container,
									 map,
									 config,
									 countryGeometry,
									 setError
								 }) => {
	try {
		const [MapView] = await loadModules(["esri/views/MapView"]);

		let countryCenter = countryGeometry && countryGeometry.centroid ? countryGeometry.centroid : null;
		let view;
		if (!config.webmap) {
			view = new MapView({
				map,
				container,
				zoom: config.zoom,
				center: config.center ? config.center : (countryCenter ? countryCenter : [0,0]),
				extent: countryGeometry && countryGeometry.extent ? countryGeometry.extent : null,
				popup: { defaultPopupTemplateEnabled: true }
			})
		} else {
			view = new MapView({
				map,
				container,
				extent: countryGeometry && countryGeometry.extent ? countryGeometry.extent : null,
				popup: { defaultPopupTemplateEnabled: true }
			});

			if (countryCenter !== undefined) {
				view.center = countryCenter;
			}

			if (config.zoom !== null) {
				view.zoom = config.zoom;
			}

			if (config.center !== null) {
				view.center = config.center;
			}
		}

		if (config.minZoom)
			view.constraints.minZoom = config.minZoom

		if (config.maxZoom)
			view.constraints.maxZoom = config.maxZoom

		view.ui.components = []
		view.constraints.snapToZoom = false;
		return view;
	} catch (err) {
		setAsyncError(err, setError);
	}
};