import styled from "styled-components";

export const StyledPrintBar = styled.div`
  background: ${({opsColor})=>opsColor};
  position:absolute;
  //z-index:1;
  pointer-events: all;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  top: 0;
  left: 0;
  color: ${({textColor})=>textColor};
  
  ${({isPortrait})=> isPortrait ? `
    width: 100%;
    height: 55px;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0px 12px 0px 6px;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
  
  ` : `
     width: 55px;
     height: 100%;
     left: -55px;
     top:0;
     flex-direction: column;
     padding: 12px 0px 6px 0px;
     svg {
       transform: rotate(-90deg) translate(-3px, 0px);
  
     path {
         fill: #FFFFFF;
       }
     }
  `}
  
   //width: 56px;
   //height: 100%;
   //left: -56px;
   //top:0;
   //flex-direction: column;
   //
   //svg {
   //  transform: rotate(-90deg) translate(-3px, 0px);
   //
   //path {
   //    fill: #FFFFFF;
   //  }
   //}
`

export const StyledTextWrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 6px;


  transform: ${({isPortrait})=> !isPortrait ? 'rotate(180deg)' : ''};
  writing-mode: ${({isPortrait})=> !isPortrait ? 'vertical-rl' : ''};
  justify-content: ${({isPortrait})=> !isPortrait ? 'center' : ''};

  span {
    color: inherit;
    line-height: 1;
    font-weight: 400;
  }

  span:first-child {
    font-size: 18px;
  }

  span {
    font-size: 10px;
  }
  
`