import styled from "styled-components";

export const SubCategoriesCardsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 8px;
	padding: 2px;
`;

export const SubCategoriesCard = styled.div`
	max-width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 8px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 8px;
	background: ${({selected}) => (selected ? "#b21b0c" : "#fff")};
	box-shadow: 0px 2px 8px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.06)" : "rgba(0, 0, 0, 0.06)")}, 0px 0px 4px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.1)" : "rgba(0, 0, 0, 0.1)")};
		&:active,
		&:focus {
			box-shadow: 0px 0px 2px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.06)" : "rgba(0, 0, 0, 0.06)")}, 0px 0px 3px ${({selected}) => (selected ? "rgba(178, 27, 12, 0.1)" : "rgba(0, 0, 0, 0.1)")};
		}
`;

export const SubCategoriesCardTitle = styled.p`
	color: ${({selected}) => (selected ? "#fff" : "#323232")};
	text-align: center;
	padding: 0;
	margin: 0;
	font-weight: 600;
    font-size: 12px;
`;
