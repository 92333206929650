import styled from "styled-components";

export const TextAreaLabel = styled.label`
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	cursor: pointer;
	text-align: left;
	padding: 0 0 32px 0;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 160px;
  resize: none;
  border: none;
  border-radius: 10px;
  padding: 8px 14px;
  color: #25282b;
  font-size: 14px;
  outline-color: ${({borderColor})=> borderColor ?? '#b21b0c'};
  background: #f5f5f5;

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #535353;
  }
`;
