import {
	CardContent,
	CardIcon,
	CardImage,
	CardImageContainer, CardThumbnailIcon,
	CardTitle,
	StyledCard
} from "./ReportManager-styled";
import {appendParamToURL, formatDateTime, renderCell} from "../../utils/helper";
import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactDOMServer from 'react-dom/server'
import {generateTitle, getFeatureTitle, getLayerTitle} from "../../esri/custom-popup-content";
import {LineSymbol} from "./symbols";


const FeatureCard = ({ feature, config, t, commentFieldName, dateLabel}) => {
	const [iconUrl, setIconUrl] = useState()
	const [symbolSvg, setSymbolSvg] = useState()
	//Is the component still mounted? avoid updating state of an unmounted component
	const isMounted = useRef(false)

	useEffect(() => {
		isMounted.current = true;
		return () => { isMounted.current = false }
	}, [])

	const image = feature.attachments?.filter((att) => att.contentType.startsWith("image"))[0]

	useEffect(() => {
		feature.layer?.renderer?.getSymbolAsync(feature).then((symbol) => {
			if (!isMounted.current)
				return

			if (symbol.url)
				setIconUrl(symbol.url)
			else if (symbol.type === "simple-line" && symbol.color){
				const color = symbol.color ? symbol.color.toHex() : "grey"
				setSymbolSvg(<LineSymbol stroke={color}/>)
				if (image)
					setIconUrl("data:image/svg+xml;base64," +
						btoa(ReactDOMServer.renderToStaticMarkup((<LineSymbol stroke={color}/>))))
			}
		})
	}, [])

	const editDateFieldName = feature.layer.editFieldsInfo.editDateField
	const commentField = feature.layer.fields.filter((field) => field.name === commentFieldName)[0]
	const label = dateLabel ? dateLabel : t('screen.widget.ReportManager.reportedOn')
	const title = feature.layer.getLayerTitle(t)

	const getImage = () => {
		if (image) {
			//return with the attachment image, with a thumbnail if icon is specified
			let background = <CardImage key="ci" alt={image.name} src={appendParamToURL(image.url, "h", 67)}
							className="esri-attachments__image--resizable esri-attachments__image" />
			if (iconUrl)
				return [background, <CardThumbnailIcon key="tn" alt={title} src={iconUrl} />]
			else
				return background
		} else {
			if (iconUrl){
				return <CardIcon alt={title} src={iconUrl}/>
			} else {
				if (symbolSvg)
					return <div>{symbolSvg}</div>
				else
					return <calcite-icon icon="no-image" scale="l"/>
			}
		}
	}
	
	const template = useMemo(()=>{
		return feature?.layer?.layerConfig?.latestUpdates?.title
	},[feature])
	
	const cardContent = useMemo(()=>{
		const content = renderCell(commentField, feature.attributes[commentFieldName], t, config);
		return String(content).trim();
	},[commentField, feature, commentFieldName, t, config])
	
	return (
		<StyledCard className="esri-layer-list__item-container esri-layer-list__item-label" title={title}>
			<CardImageContainer>
				{getImage()}
			</CardImageContainer>

			<span className="esri-layer-list__item-title">
				<CardTitle key="title">
					<b>{generateTitle(template, feature, feature.layer, t)}</b>
				</CardTitle>

				{feature.attributes[editDateFieldName] &&
					<CardContent key="date">
						{label}: {formatDateTime(config, feature.attributes[editDateFieldName])}
					</CardContent>
				}

				<CardContent key="ltitle">
					{getLayerTitle(feature.layer, t)}
				</CardContent>
				
				{cardContent && <CardContent>
					{renderCell(commentField, feature.attributes[commentFieldName], t, config)}
				</CardContent>}
			</span>
		</StyledCard>
	)
}

export default FeatureCard