import React, {useEffect, useState} from "react";
import {InputLabel, InputContainer} from "./Input-styled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getFormDataVal} from "../../helpers";
import {borderColor, dangerColor, mutedColor} from "../../../../utils/Theme";

const Input = ({type, specs, path, formProps }) => {
    let {isSurvey, value, onChange} = formProps
    const { panelFormData } = useSelector((store) => store)
    let newValue = value ?? ((getFormDataVal(panelFormData, path)).value)
    newValue = newValue ? newValue.toString() : ""
    const [inputVal, setInputVal] = useState(newValue)
    const [isFocus, setIsFocus] = useState(false)
    const {t} = useTranslation('common')
    const label = specs.label ?? t(`screen.panel.${path}.label`, "")
    const placeholder = specs.placeholder ?? t(`screen.panel.${path}.placeholder`, t("screen.component.input.placeholder") ?? "")

    useEffect( () => {
        if (!isSurvey)
            return
        onChange(inputVal, path)
    }, [inputVal])

    useEffect(() => {
        if (!formProps && !formProps.value)
            return
        setInputVal(formProps.value)
    }, [formProps])

    const inputStyles = {
        width: "100%",
        display: "block",
        padding: 0,
        cursor: "auto",
        color:  isFocus ? "#000000" :mutedColor,
        border: 0,
        borderBottom: isFocus ? `2px solid ${specs.opsColor ?? dangerColor}` :`1px solid ${borderColor}`,
        boxShadow: "0 0 0 0",
        outline: 0,
        fontSize: ".6em",
        lineHeight: "2em",
        textOverflow: "ellipsis",
    }

    return (
        <InputContainer>
            {(label && label.length > 0) && <InputLabel>{label}</InputLabel>}
            <input
                style={inputStyles}
                type={type ?? "text"}
                value={inputVal}
                placeholder={placeholder}
                onChange={(e) => setInputVal(e.target.value)}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
            />
        </InputContainer>
    )
}

export default Input