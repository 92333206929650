import {setPanelPosition_actionType} from "../constants"

const initialState = "modal"
const panelPosition = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case setPanelPosition_actionType:
            return state = payload
        default:
            return state
    }
}

export default panelPosition