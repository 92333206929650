import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import React, {useMemo, useState} from "react";
import {StyledChartTooltip} from "../Insights-styled";

const CustomTooltip = ({ active, payload, label, ...rest }) => {
  if (active && payload && payload.length) {
    return (
      <StyledChartTooltip>
        <span style={{fontSize:12, fontWeight:500}}>{payload[0].payload.name}: <span style={{fontSize:18, fontWeight:500}}>{payload[0].value}</span></span>
      </StyledChartTooltip>
    );
  }
  
  return null;
};


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  
  return (
    <>
      <text fontSize={10} x={sx} y={sy-5} fill="#6F7072" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {name}
      </text>
      <text fontSize={12} x={sx+1} y={sy+7} fontWeight="bold" fill="#6F7072" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value}
      </text>
    </>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      {/*<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>*/}
      {/*  {payload.name}*/}
      {/*</text>*/}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke={fill}
      />
    </g>
  );
};

const COLORS = {
  'yes': '#00C49F',
  'no':"#0088FE",
  'unknown':'#FF8042',
  'unselected': '#6F70724D'
}

const CustomPieChart = ({data, onClick, mapFilters}) => {
  const [activeIndex, setActiveIndex] = useState([]);
  const pieData = useMemo(()=>data.filter(item=>item.value > 0),[data])
  
  const selectedIndexes = useMemo(()=>{
    if (!pieData.length) return []
    const key = pieData[0].filterKey;
    const filters = mapFilters[key] ?? [];
    
    const indexes = pieData.filter((item)=>filters.includes(item.key)).map((item, i)=>pieData.findIndex(data=>data.key === item.key));
    
    return indexes;
    
  },[mapFilters, pieData])
  
  return (
    <ResponsiveContainer style={{position:'absolute'}}>
      <PieChart width="100%">
        <Pie
          activeIndex={selectedIndexes}
          paddingAngle={2}
          activeShape={renderActiveShape}
          dataKey="value"
          isAnimationActive={false}
          data={pieData}
          outerRadius="60%"
          label={renderCustomizedLabel}
          labelLine={false}
          onClick={(params, index)=>{
            setActiveIndex([index]);
            if (selectedIndexes.includes(index)) {
              if (onClick) {
                onClick(params.filterKey)
              }
            } else {
              if (onClick) {
                onClick(params.filterKey, params.key)
              }
            }
          }}
        >
          {pieData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={(selectedIndexes.length > 0 && !selectedIndexes.includes(index)) ? COLORS.unselected : COLORS[entry.name.toLowerCase()]}
            />
          ))}
        </Pie>
        <Tooltip
          cursor={{fill:"#000" + '1a'}}
          content={CustomTooltip}
        />
      </PieChart>
    
    </ResponsiveContainer>
  );
};

export default CustomPieChart;