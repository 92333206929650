import styled from "styled-components";
import { CalciteSwitch } from "@esri/calcite-components-react";
import Filter from "../Filter";
import {CalciteTabTitle} from "@esri/calcite-components-react"

export const FeatureTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({isExpanded})=>isExpanded ? '3px 0' : '0px 0px'};
  position: relative;
  flex-basis: ${({ isExpanded, isDisclaimerShown }) => isExpanded ? `${window.innerHeight / 2.8}px` : isDisclaimerShown ? '0px' : '13px'};
  z-index: ${({ isExpanded, isDisclaimerShown }) => (isExpanded && isDisclaimerShown) ? 0 : 2};
  width: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? 'calc(100% - 22px)' : '100%'};
  //show disclaimer when table is expanded
  &+ #disclaimer {
    z-index: ${({ isExpanded, isDisclaimerShown }) => (isExpanded && isDisclaimerShown)? 0 : 1};
    padding-left: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? '91px' : '0px'};
    width: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? 'calc(100% - 91px)' : '100%'};
    transition: all 0.5s;
  }
  
  #tabs {
    transition: opacity 0.5s linear;
    transition-delay: 0.4s;
    transition-property: opacity;
    opacity: ${({ isExpanded }) => isExpanded ? 1 : 0};
  }

  @media screen and (max-width: 863px) {
    flex-basis: ${({ isExpanded }) => isExpanded ? `${window.innerHeight / 2.8}px` : '20px'};
    overflow: ${({ isExpanded }) => isExpanded ? 'auto' : 'hidden'};
    &+ #disclaimer {
      width: 100%;
      padding-left: 0;
    }
  }
}
`

export const FeatureIconContainer = styled.div`
    display: flex;
    cursor: pointer;
    fontSize: 12px;
    fontFamily: 'Open Sans' sans-serif !important;
    transition: all 0.3s;
    opacity: ${({isTableFullScreen})=> isTableFullScreen ? 0 : 1};
    pointer-events: ${({isTableFullScreen})=> isTableFullScreen ? 'none' : 'auto'};
`

export const FeatureIcon = styled.div`
    margin: 0.1em 1em 0 0.4em;
    height: 1em;
    width: 1em;
    cursor: pointer;
`

export const StyledSwitch = styled(CalciteSwitch)`
    margin: 0.2em 1em 0 0.4em;
    border: 0 !important;
    &.container {
        border: 0 !important;
    }
`

export const CustomTabTitle = styled(CalciteTabTitle)`
    &.kzpobj {
      border-bottom-color: ${({opsColor}) => opsColor} !important;
    }
    &:hover {
      border-bottom-color: ${({opsColor}) => `${opsColor}99`};
    }

    &:focus {
      border-bottom-color: ${({opsColor}) => opsColor};
    }
`

export const StyledFilter = styled(Filter)``

export const StyledTableTopBar = styled.div`
  cursor:row-resize;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding: 2px 0.6rem;
  box-shadow: ${({ isExpanded }) => isExpanded ? `0 -0.04px 0px #a9a9a9, 0px -1px 1px #ccc` : ''};
  background: ${({isExpanded})=>isExpanded ? '#dcdbdb80' : ''};
  :hover {
    box-shadow: 0 -0.04rem 0px #a9a9a9, 0px -3px 3px #ccc
  }
  
  span {
    cursor: default;
  }
`

export const StyledButton = styled.button`
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  
  svg {
    transition: all 0.5s;
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
    path{
      stroke-width: 2px;
    }
  }
`

export const StyledTableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  --calcite-color-brand:${({opsColor})=>opsColor};
  --calcite-color-brand-hover: transparent;

  calcite-tab .content {
    padding-bottom: 0 !important;
  }
  
  .esri-grid.esri-widget {
    padding-bottom: 0;
  }
`