import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
  ResizerIcon,
  Sidebar,
  SidebarBody,
  StyledResizer,
  UpdateIconWrapper
} from "../LatestUpdates/new/LatestUpdates-styled";
import Insights from "./Insights/Insights";
import useDragSidebar from "../../hooks/useDragSidebar";
import {chevronRight24, graphBar16} from "@esri/calcite-ui-icons";
import {ConfigContext} from "../../utils/ConfigContext";
import SharedInformation from "../SharedInformation/SharedInformation";
import {CalciteTabNav, CalciteTabs, CalciteTabTitle} from "@esri/calcite-components-react";
import {StyledTab} from "./Dashboard-styled";
import {view} from "../../utils/API";
import {ROLE_EDITOR} from "../../utils/helper";
import {useSelector} from "react-redux";
import {CustomLoader} from "../App/App-styled";
import {StyledLoaderWrapper} from "./Insights/Insights-styled";
import LatestUpdates, {LatestUpdatesIcon} from "../LatestUpdates/new/LatestUpdates";
import {isWidgetDisplayed} from "../../esri/widgets/expandUtils";
import {useTranslation} from "react-i18next";

const sharedInformationWidth = 300;
const insightsWidth = 470;

const ShareInformationIcon = ({color, width, height}) =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 47.97761 48" fill={color}>
      <g>
        <g>
          <path
            d="M26.56,28.69l-6.18,1.72a2.02035,2.02035,0,0,1-.6.08,2.245,2.245,0,0,1-1.79-.89,2.04577,2.04577,0,0,1-.32-.6H6a1,1,0,0,1,0-2H17.79l1.11-4H6a1,1,0,0,1,0-2H19.51a2.80674,2.80674,0,0,1,.59-.86L23.24,17H6a1,1,0,0,1,0-2H25.24L30,10.24V7a.97228.97228,0,0,0-.94-1H.94A.97228.97228,0,0,0,0,7V47a.97228.97228,0,0,0,.94,1H21V42a3.00879,3.00879,0,0,1,3-3h6V25.8l-2.12,2.12A2.99629,2.99629,0,0,1,26.56,28.69ZM13,35H6a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z"/>
          <path d="M24,41a1.003,1.003,0,0,0-1,1v6l7-7H24Z"/>
          <path d="M47.13,1.16A3.511,3.511,0,0,0,44.52,0a3.45971,3.45971,0,0,0-2.47,1.03L30,13.07l-3.01,3.01-.91.91-4.57,4.57a.92293.92293,0,0,0-.25.44l-1.49,5.36-.23.82a.24359.24359,0,0,0,.24005.31.19482.19482,0,0,0,.06995-.01l.03-.01,6.15-1.71a.941.941,0,0,0,.43-.25L30,22.97,46.86,6.11A3.638,3.638,0,0,0,47.13,1.16Z"/>
        </g>
      </g>
    </svg>
  )
}

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [showShared, setShowShared] = useState(false);
  const {config} = useContext(ConfigContext);
  const [_dashboardCount, setDashboardCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState('')
  
  const sidebarRef = useRef();
  const dashboardTabs = useRef(new Set());
  const dashboardCount = useRef(0);
  const userSelectedTab = useRef('');
  
  const {t} = useTranslation('common');
  const {layersLoading, activeModule, showLatestUpdates} = useSelector(state=>state);
  
  const {expand, setExpand, initDrag, onArrowClick, isMobile, mobileOffset} = useDragSidebar({
    defaultWidth: showShared ? sharedInformationWidth : insightsWidth,
    ref: sidebarRef
  })
  
  const visibleLayers = useRef({
    shared:{},
    insights:{},
    latestUpdates:{}
  });
  
  useEffect(()=>{
    if (!isMobile) return;
    const viewDiv = document.querySelector('#viewDiv');
    if (show){
      viewDiv.style.height = `calc(100% - ${mobileOffset}px)`;
    } else {
      viewDiv.style.height = ``;
      if (sidebarRef.current) {
        sidebarRef.current.style.flexBasis = '';
      }
    }
  },[show])
  
  const handleShowSidebar = useCallback(()=>{
    setShow(true);
  },[]);
  
  const handleCloseSidebar = useCallback(()=>{
    setShow(false);
  },[])
  
  const processTab = ({tab, visible})=> {
    if (visible) {
      dashboardTabs.current.add(tab)
      if (!isMobile){
        setExpand(true);
      }
    } else {
      dashboardTabs.current.delete(tab)
    }
    dashboardCount.current+=1;
    setDashboardCount(dashboardCount.current);
  }
  
  const handleInsightsLayer = (layer, visible) =>{
    const insights = config.insights || {};
    const insightsLayer = config.role === ROLE_EDITOR ? insights.editorLayer : insights.publicLayer;
    
    if (!insightsLayer || !isWidgetDisplayed(config, 'Insights') || window.isSmall) return;
    const layers = [insightsLayer];
    
    if ((layers.includes(layer.layerConfig?.alias) || layers.includes(layer.layerConfig?.extends)) && (layer.layerConfig?.alias === insightsLayer || layer.layerConfig?.extends === insightsLayer)) {
      const tab = 'insights';
      visibleLayers.current.insights[layer.id] = visible;
      const arrayVisibleLayers = Object.values(visibleLayers.current.insights).filter(visible=>visible);
      processTab({tab, visible: arrayVisibleLayers.length > 0});
    }
  }
  
  const watchHandlers = useRef([]);
  
  useEffect(()=>{
    userSelectedTab.current = '';
    setSelectedTab('');
    dashboardCount.current++;
    setDashboardCount(dashboardCount.current);
  },[activeModule])
  
  let expandTimer;
  useEffect(()=>{
    clearTimeout(expandTimer)
    expandTimer = setTimeout(()=>{
      if (dashboardTabs.current.size === 0) {
        setExpand(false);
      }
    }, 250)
  },[_dashboardCount])
  
  const processLayer = (layer, visible) => {
    handleInsightsLayer(layer, visible);
    if (layer.layerConfig?.isShownInReportManager && isWidgetDisplayed(config, 'ReportManager')) {
      const tab = 'sharedInformation';
      visibleLayers.current.shared[layer.id] = visible;
      const arrayVisibleLayers = Object.values(visibleLayers.current.shared).filter(visible=>visible);
      processTab({tab, visible: arrayVisibleLayers.length > 0})
    }
    
    if (layer.layerConfig?.isShownInLatestUpdates && isWidgetDisplayed(config, 'LatestUpdatesNew')) {
      visibleLayers.current.latestUpdates[layer.id] = visible;
      const arrayVisibleLayers = Object.values(visibleLayers.current.latestUpdates).filter(visible=>visible);
      const tab = 'latestUpdates';
      processTab({tab, visible: arrayVisibleLayers.length > 0})
    }
  }
  
  useEffect(() => {
    // if (!layersLoading) return;
    
    if (watchHandlers.current.length > 0) {
      watchHandlers.current.forEach(h=>h.remove());
      watchHandlers.current = [];
    }
    
    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {
          processLayer(layer, layer.visible);
          const visibleHandler = layer.watch("visible", (visible) => {
            processLayer(layer, visible);
          })
          watchHandlers.current.push(visibleHandler)
        })
    })
    watchHandlers.current.push(layersChangeHandler)
    
    view.map.layers.forEach((layer) => {
      processLayer(layer, layer.visible);
      const visibleHandler = layer.watch("visible", (visible) => {
        processLayer(layer, visible);
      })
      watchHandlers.current.push(visibleHandler)
    })
    
    return () => {
      watchHandlers.current.forEach(h=>h.remove());
    }
  }, [layersLoading]);
  
  let timer;
  useEffect(()=>{
    clearTimeout(timer)
    setTimeout(()=>{
      if (!userSelectedTab.current && dashboardTabs.current.size > 0) {
        if (dashboardTabs.current.has('sharedInformation')) {
          setSelectedTab('sharedInformation')
        } else if (dashboardTabs.current.has('latestUpdates')) {
          setSelectedTab('latestUpdates')
        } else {
          setSelectedTab('insights')
        }
      }
    },250)
    return ()=>{
      clearTimeout(timer);
    }
  },[_dashboardCount])
  
  const handleTabSelection = useCallback((tab)=>{
    setSelectedTab(tab);
    userSelectedTab.current = tab;
  },[])
  
  const tabs = Array.from(dashboardTabs.current);
  return (
    <Sidebar
      ref={sidebarRef}
      show={tabs.length > 0}
      expand={expand}
      defaultWidth={tabs.includes('insights') ? (config.id === 'vanuatu' ? window.innerWidth / 2 : insightsWidth) : sharedInformationWidth}
      className="right-sidebar"
    >
        <StyledResizer
          onMouseDown={initDrag}
          onClick={isMobile ? onArrowClick : undefined}
          expand={expand}
          className={window.isSmall && tabs.includes('latestUpdates') ? 'onboarding-latest-updates' : ''}
        >
          <div role="button" className="expandButton esri-icon esri-icon-up"></div>
          {!expand && <UpdateIconWrapper expand={expand}>
            {selectedTab === "sharedInformation" && (
              <>
                <span>{t("screen.widget.ReportManager.title")}</span>
                <ShareInformationIcon
                  style={{
                    position: "relative",
                    left: "1px"
                  }}
                  height={16}
                  width={16}
                  color={config.opsColor}
                />
              </>
            )}
            {
              selectedTab === "latestUpdates" && <>
                <span>{t("screen.widget.LatestUpdates.title")}</span>
                <LatestUpdatesIcon color={config.opsColor}/>
              </>
            }
            {tabs.includes("insights") && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill={config.opsColor}
              >
                <path d={graphBar16}/>
              </svg>
            )}
          </UpdateIconWrapper>}
          <ResizerIcon
            onClick={onArrowClick}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            expand={expand}
          >
            <path d={chevronRight24} />
          </ResizerIcon>
        </StyledResizer>
        <SidebarBody
          style={{
            overflow:'hidden',
          }}
          opsColor={config.opsColor}
          expand={expand}>
          {selectedTab === '' && <div
            style={{
              position: 'absolute',
              width: '100%',
              height: `calc(100% - ${isMobile ? '34px' : '44px'})`,
              top: isMobile ? '34px' : '44px',
            }}
          >
            <StyledLoaderWrapper>
              <CustomLoader fontSize="3rem" opsColor={config.opsColor}/>
            </StyledLoaderWrapper>
          </div>}
          <CalciteTabs
            scale={window.isSmall ? 'm' : 'l'} style={{width:'100%', height:'100%', position:'absolute'}}>
            <CalciteTabNav
              opsColor={config.opsColor}
              slot="title-group"
            >
              <CalciteTabTitle
                tab="sharedInformation"
                onCalciteTabsActivate={()=>{
                  handleTabSelection('sharedInformation');
                }}
                selected={selectedTab === 'sharedInformation' || undefined}
                closed={tabs.includes('sharedInformation') ? undefined : true}
                key="shared-information"
              >
              <span style={{fontSize:18, fontWeight:500, color: '#393738', padding:'0px 5px', display:'flex', gap:6, alignItems:'center'}}>
                {t("screen.widget.ReportManager.title")}
                <div style={{
                  height:16,
                  width:16
                }}>
                  <ShareInformationIcon height={16} width={16} color={config.opsColor} />
                </div>
              </span>
              </CalciteTabTitle>
              <CalciteTabTitle
                tab="lu"
                onCalciteTabsActivate={()=>{
                  handleTabSelection('latestUpdates');
                }}
                selected={selectedTab === 'latestUpdates' || undefined}
                closed={tabs.includes('latestUpdates') && showLatestUpdates ? undefined : true}
                key="latestUpdates"
              >
              <span style={{fontSize:18, fontWeight:500, color: '#393738', padding:'0px 5px', display:'flex', gap:6, alignItems:'center'}}>
                {t("screen.widget.LatestUpdates.title")}
                <div style={{height:16, width:16}}
                >
                  <LatestUpdatesIcon color={config.opsColor} />
                </div>
              </span>
              </CalciteTabTitle>
              <CalciteTabTitle
                tab="insights"
                onCalciteTabsActivate={()=>{
                  handleTabSelection('insights');
                }}
                selected={selectedTab === 'insights' || undefined}
                closed={tabs.includes('insights') ? undefined : true} key="insights">
              <span style={{fontSize:18, color:'#393738' ,fontWeight:500, padding:'0px 5px', display:'flex', gap:6}}>
                Insights
                <div
                  style={{
                    height:16,
                    width:16
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       viewBox="0 0 16 16"
                       fill={config.opsColor}
                  >
                    <path d={graphBar16} />
                  </svg>
                </div>
              </span>
              </CalciteTabTitle>
            </CalciteTabNav>
            <StyledTab
              selected={selectedTab === 'sharedInformation' || undefined}
                       tab="sharedInformation" style={{width:'100%', height:'100%'}}>
              {selectedTab === "sharedInformation" ? <SharedInformation
                expand={expand}
                setExpand={setExpand}
                setShow={setShowShared}
                selectedTab={selectedTab}
              /> : <StyledLoaderWrapper>
                <CustomLoader fontSize="3rem" opsColor={config.opsColor}/>
              </StyledLoaderWrapper>}
            </StyledTab>
            <StyledTab
              selected={selectedTab === 'latestUpdates' || undefined}
              tab="lu" style={{width:'100%', height:'100%'}}>
              {showLatestUpdates ? <LatestUpdates /> : <StyledLoaderWrapper>
                <CustomLoader fontSize="3rem" opsColor={config.opsColor}/>
              </StyledLoaderWrapper>}
            </StyledTab>
            <StyledTab
              selected={selectedTab === 'insights' || undefined} tab="insights" style={{width:'100%', height:'100%'}}>
              {selectedTab === "insights" ? <Insights
                expand={expand}
                showSidebar={handleShowSidebar}
                closeSidebar={handleCloseSidebar}
              /> : <StyledLoaderWrapper>
                <CustomLoader fontSize="3rem" opsColor={config.opsColor}/>
              </StyledLoaderWrapper>}
            </StyledTab>
          </CalciteTabs>
        </SidebarBody>
      {/*</div>*/}
    </Sidebar>
  );
};

export default Dashboard;