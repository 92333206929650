import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useCallback, useMemo, useState} from "react";
import {scroller} from "react-scroll";

const useReport = ({pages, containerId, t}) => {
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  //contacts schema
  const schema = useMemo(()=>{
    return yup.object().shape({
      firstName: yup.string().required(t('screen.component.input.required')),
      surName: yup.string(),
      contactNumber: yup.string(),
      email: yup.string().email().required(t('screen.component.input.required')),
      organization: yup.string().required(t('screen.component.input.required')),
    });
  },[t])
  
  const methods = useForm({ resolver: yupResolver(schema), mode: "onChange" })
  
  //prevent user to click on next multiple times and change pages
  let nextTimer;
  const goNext = useCallback(() => {
    if (nextTimer) return;
    
    setPage(page=>{
      if (page < pages.length) {
        const next = page + 1;
        scroller.scrollTo(`scrollSection-${next}`, {
          duration: 700,
          smooth: true,
          containerId: containerId,
          delay: 150,
        });
        return next
      }
      return page
    })
  
    nextTimer = setTimeout(()=>{
      nextTimer = null
      clearTimeout(nextTimer)
    },700)
  },[pages, containerId])
  
  const goBack = useCallback(() => {
    setPage(page=>{
      if (page > 0) {
        const prev = page - 1;
        scroller.scrollTo(`scrollSection-${prev}`, {
          duration: 700,
          smooth: true,
          containerId: containerId,
        });
        return prev;
      }
      return page
    })
  },[containerId])
  
  let timer
  const scrollNextDelay = useCallback(() => {
    if (timer) return
    timer = setTimeout(() => {
      timer = null
      goNext()
      clearTimeout(timer)
    }, 500)
  },[page, goNext, timer]);
  
  const resetReport = useCallback(() => {
    setPage(0);
    setForm({});
  },[containerId])
  
  return {
    contactsMethods: methods,
    page,
    setPage,
    goNext,
    goBack,
    scrollNextDelay,
    resetReport,
    form,
    setForm
  }
};

export default useReport;