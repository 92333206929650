import {StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import {StyledBody} from "../SidebarMenu/SidebarMenu-styled";
import React from "react";

const FinaliseExport = ({
  onDownload,
  config,
  loading,
  t
}) => {
  return (
    <StyledBody style={{justifyContent:'center'}}>
      <div style={{
        display:'flex',
        flexDirection:'column',
      }}>
        <p style={{textAlign:'justify'}}>{t("print.final.questions.1.title")}</p>
        <StyledSubmitButton
          onClick={onDownload}
          bg={config.opsColor}
          disable={loading}
        >
          <span>{t("print.final.questions.1.options.export")}</span>
        </StyledSubmitButton>
      </div>
    </StyledBody>
  );
};

export default FinaliseExport;