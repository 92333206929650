import styled from "styled-components";

export const ButtonFilled = styled.button`
	background-color: #b21b0c;
	border-radius: 2px;
	min-height: 40px;
	text-decoration: none;
	color: #fff;
	border-width: 0px;
	font-size: 16px;
	cursor: pointer;
	width: 100%;
	opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
`;
