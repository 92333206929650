import React, {useEffect, useState} from "react";
import {GroupContainer} from "./Group-styled";
import {getFormDataVal, RenderContent, getScreenId, getDataList} from "../../helpers";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {view} from "../../../../utils/API";
import {setPanelId_actionType} from "../../../../redux/constants";
import {CalciteLoader} from "@esri/calcite-components-react";

const Group = ({specs, path, formProps, config}) => {
    const dispatch = useDispatch()
    const {isSurvey, value, onChange} = formProps
    const { panelFormData } = useSelector((store) => store)
    const {t} = useTranslation("common")
    const newValue = value ?? (getFormDataVal(panelFormData, path)).value ?? ""
    const [groupVal, setGroupVal] = useState(newValue)
    const [content, setContent] = useState(specs.content)
    const [loading, setLoading] = useState(true)

    useEffect( () => {
        if (!view || !specs || !specs.data)
            return
        const fetchData = async () => {
            setLoading(true)
            await getDataList(specs.data, view).then((dataList) => {
                dataList = dataList ?? []
                let cont = {}
                dataList.map((item) => {
                    cont = {
                        ...cont, [item.layerConfig?.id ?? item.id]: {
                            "type": "card",
                            "size": "small",
                            "description": item.getLayerTitle(t)
                        }
                    }
                })
                return cont
            })
        }
        fetchData().then((data) => {
            setContent({content: data})
            setLoading(false)
        })
    }, [])

    const updateValue = (newValue, path) => {
        const pathArr = path.split(".")
        pathArr.pop()
        if (specs && specs.data) {
            dispatch({ type: setPanelId_actionType, payload: {type: specs.data.type ?? "", content: newValue} })
        }
        onChange(newValue, pathArr.join("."))
        setGroupVal(newValue)
    }

    if (loading)
        return (
            <div style={{display: "flex", paddingBottom: ".5em"}}>
                <CalciteLoader key="l" scale="s" style={{color: specs.opsColor, padding: 0}} type={"indeterminate"} inline={true} />
                <p style={{margin: 0, fontSize: ".575em", lineHeight: "1.2em"}}>{t("screen.component.loader.fetchingData")}</p>
            </div>
        )

    return (
        <GroupContainer id={"group-container"}>
            <RenderContent
                data={content}
                openScreenId={getScreenId(path)}
                path={path}
                config={config}
                formProps={{isSurvey, isGroup: true, onChange: updateValue, value: groupVal}}
            />
        </GroupContainer>
    )
}

export default Group