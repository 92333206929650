import React from "react";
import {InputLabel, InputContainer, WarningMessage} from "./Input-styled";

const Input = ({label, register = {}, name ,errorMessage, borderColor ,...rest }) => {
  return (
    <InputContainer borderColor={borderColor}>
      <input name={name} {...register} {...rest} />
      {(label && label.length > 0) && <InputLabel>{label}</InputLabel>}
      <WarningMessage isError={!!errorMessage}>{errorMessage}</WarningMessage>
    </InputContainer>
  )
}

export default Input