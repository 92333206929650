import {useEffect, useRef, useState} from "react";
import {AnimatedLine, BannerContainer, BannerInner, BannerText} from "./Banner-styled";
import {loadModules} from "esri-loader";
import {bannerTextFieldName, isShowInBannerFieldName} from "../../utils/API";

const Banner = ({view, config}) => {

    let [layersLoaded, setLayersLoaded] = useState(0)
    let [features, setFeatures] = useState([])

    const animatedLineRef = useRef(null)
    const bannerRef = useRef(null)

    useEffect(() => {
        const animatedLine = animatedLineRef?.current
        const banner = bannerRef?.current
        if (!banner || !animatedLine)
            return

        const speed = 30
        const time = (banner.clientWidth + animatedLine.scrollWidth)/speed
        console.log(animatedLine.scrollWidth, time)
        //animatedLine.style.display = "flex"
        animatedLine.style.animation = "banner-anim " + time + "s infinite linear"

    }, [features])

    useEffect(() => {
        view.map.layers.on("change", (event) => {
            event.added?.forEach((layer) => {
                addLayer(layer)
            })
        })

        view.map.layers.forEach((layer) => {
            addLayer(layer)
        })
    },[])

    const addLayer = (layer) => {
        layer.load().then((l) => {
            if (!l.fields)
                return

            const bannerTextFields = l.fields.filter((f) => f.name === bannerTextFieldName)
            const showninbannerFields = l.fields.filter((f) => f.name === isShowInBannerFieldName)
            if (!bannerTextFields.length || !showninbannerFields.length)
                return

            l.on("edits", () => {
                queryLayer(l)
            })

            l.watch("visible", () => {
                setLayersLoaded(layersLoaded++)
            })
            queryLayer(l)
        })
    }

    const queryLayer = (layer) => {
        console.log("Querying layer", layer.title)
        loadModules(["esri/rest/support/Query"]).then(([Query]) => {
            const query = new Query()
            query.outFields = ["*"]
            query.returnGeometry = true
            const editDateFieldName = layer.editFieldsInfo?.editDateField
            if (editDateFieldName)
                query.orderByFields = [editDateFieldName + " DESC"]

            query.where = isShowInBannerFieldName + " = 1 and " + bannerTextFieldName + " is not null"
            if (layer.definitionExpression)
                query.where += " and (" + layer.definitionExpression + ")"

            layer.queryFeatures(query).then((res) => {
                setFeatures(features.concat(res.features))
            })
        })
    }

    const onFeatureClick = (f) =>{
        view.openPopup({features: [f], location: f.geometry,})
    }

    const displayFeatures = features.filter((f) =>f.layer.visible)
    if (!displayFeatures.length)
        return null

    var sum = displayFeatures.reduce((sum, f) => {
        return sum + f.attributes[bannerTextFieldName].length
    }, 0)



    console.log(sum)
    return <BannerContainer color={config.opsColor} ref={bannerRef}>
        <BannerInner>
            <AnimatedLine duration={18} ref={animatedLineRef}>
                {displayFeatures.map((f, idx) => {
                    const text = f.attributes[bannerTextFieldName]
                    return <BannerText key={idx} onClick={() => onFeatureClick(f) }  style={{textWrap: "nowrap"}}>{text} |</BannerText>
                })}
            </AnimatedLine>
        </BannerInner>
    </BannerContainer>
}

export default Banner