import styled from "styled-components";

export const StyledMessage = styled.p`
	margin-top: 24px;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: #000000;
`;

export const MessageContainer = styled.div`
	width: 100%;
	padding: 32px 16px 200px 16px;
`;
