import styled from "styled-components";

export const OnBoardingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 14px 8px 14px;
  position: relative;
  p {
    margin: 0;
  }
`

export const StyledTitle = styled.p`
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 500;
`

export const StyledDescription = styled.p`
  font-size: 14px;
  color: #626060;
`

export const OnBoardingFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 0px 8px 8px 8px;
  
  button {
    height: 38px;
  }
`

export const CloseIconWrapper = styled.div`
  position:absolute;
  right: 4px;
  top:4px;
  cursor: pointer;
`