import styled from "styled-components";

export const StyledSidebarWrapper = styled.div`
  z-index:12;
  position:absolute;
  top:10px;
  right:0;
  pointer-events:all;
  font-size:10px;
  height:calc(100% - 20px);
  width:250px;
  background:#FFFFFF;
  box-sizing:border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius:10px;
  padding: 0px 0 10px;
  display:flex;
  flex-direction:column;
  overflow: hidden;

  swiper-container {
    width:100%;
    height:100%;
    overflow:hidden;
  }
  
  swiper-slide {
    display:flex;
    flex-direction:column;
    height: 100%;
  }
`

export const StyledLoaderWrapper = styled.div`
  position:absolute;
  top:-50px;
  left:0;
  width:100%;
  height: calc(100% + 50px);
  background:#f5f5f533;
  z-index:${({loading})=>loading ? '1' : '0'};
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  pointer-events: ${({loading})=>loading ? 'all' : 'none'};
  opacity: ${({loading})=>loading ? 1 : 0};

  p {
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 10px;
  }
  
  calcite-loader {
    stroke: ${({opsColor})=> opsColor ? `${opsColor} !important` : ''};
  }
  
  calcite-progress .bar, calcite-progress .track {
    block-size: 20px !important;
    border-radius: 10px !important;
  }
`
export const StyledHeader = styled.div`
  padding:10px 10px;
  display:flex;
  align-items:center;
  position: relative;
  
  p {
    margin: 0;
    line-height: 1.3rem;
    width: 95%;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    z-index: 1;
  }
  
  svg {
    stroke: #FFFFFF !important;
    path {
      stroke: #FFFFFF !important;
    }
  }
`

export const StyledBody = styled.div`
  display:flex;
  flex-direction:column;
  gap: 20px;
  overflow: auto;
  height: 100%;
  padding:10px 10px;
  box-sizing: border-box;
  text-align: left;
  
  > div:not(:last-child) {
    border-bottom: 1px solid #e9e6e6;
    padding-bottom: 20px;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    
    > span {
      font-size: inherit;
      font-weight: 600;
      color: ${({opsColor})=>opsColor};
    }
  }
  
  input[type='range'] {
    width: 100%;
    accent-color:${({opsColor})=>opsColor ? `${opsColor}B3` : ''}
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 2em;
    height: 2em;
    border-radius: 1em;
    border: none;
    margin-top: -5px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 8px;
    border: 1px solid ${({opsColor})=>opsColor};
    border-radius: 0.5em;
    box-shadow: none;
  }

  input[type='range']:hover::-webkit-slider-runnable-track {
    border-color: ${({opsColor})=>opsColor};
  }

  input[type='range']:active::-webkit-slider-runnable-track {
    border-color: ${({opsColor})=>opsColor};
  }

  /*mozilla*/
  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    width: 2em;
    height: 2em;
    border-radius: 1em;
    border: none;
    margin-top: -5px;
  }

  input[type='range']::-moz-range-track {
    height: 8px;
    border: 1px solid ${({opsColor})=>opsColor};
    border-radius: 0.5em;
    box-shadow: none;
  }

  input[type='range']:hover::-moz-range-track {
    border-color: ${({opsColor})=>opsColor};
  }

  input[type='range']:active::-moz-range-track {
    border-color: ${({opsColor})=>opsColor};
  }

  /*ms*/
  input[type='range']::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }
  
  //input[type='range'].styled-slider::-ms-thumb {
  //  width: 2em;
  //  height: 2em;
  //  border-radius: 1em;
  //  background: #007cf8;
  //  border: none;
  //  box-shadow: 0 0 2px black;
  //  margin-top: 0;
  //  box-sizing: border-box;
  //}

  input[type='range']::-ms-track {
    height: 8px;
    border-radius: 0.5em;
    border: 1px solid ${({opsColor})=>opsColor};
    box-shadow: none;
    box-sizing: border-box;
  }
  
  input[type='range']:hover::-ms-track {
    border-color: ${({opsColor})=>opsColor};
  }

  input[type='range']:active::-ms-track {
    border-color: ${({opsColor})=>opsColor};
  }

  input[type='range']::-ms-fill-lower {
    height: 8px;
    border-radius: 0.5em 0 0 0.5em;
    margin: -1px 0 -1px -1px;
    border: 1px solid ${({opsColor})=>opsColor};
    border-right-width: 0;
  }

  input[type='range']:hover::-ms-fill-lower {
    border-color: ${({opsColor})=>opsColor};
  }

  input[type='range']:active::-ms-fill-lower {
    border-color: ${({opsColor})=>opsColor};
  }
`

export const StyledLabel = styled.label`
  display:flex;
  gap:6px;
  cursor:pointer;
  font-size:11px;
  line-height:normal;
  text-align:left;
  align-items: center;
  margin-bottom:20px
`

export const SidebarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  background:#FFFFFF;
  border: 1px solid #575757;
  transition:all 0.3s;
  min-width: 70px;

  :hover {
    background: #5757571A;
  }
  padding: 10px;
  border-radius: 100px;
  
  span {
    font-size: 12px;
    color: #575757;
    font-weight: 500;
  }
  
  svg {
    fill: ${({fill}) => fill}
    width: 12px;
    height: 12px;
  }
`

export const DrawingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  background:${({selected})=>selected ? '#575757DB' : '#FFFFFF'};
  border: 1px solid #57575766;
  transition:all 0.3s;
  padding: ${({size})=> size === 's' ? '6px' : '8px' };
  border-radius: 0.5rem;

  :hover {
    background: ${({selected})=>selected ? '' : '#57575740' };
  }
  
  span {
    font-size: 12px;
    color: #575757;
    font-weight: 500;
  }

  svg {
    stroke: ${({selected})=> selected ? '#FFFFFF' : '#575757'};
    width: 16px;
    height: 16px;

    path {
      stroke: ${({selected})=> selected ? '#FFFFFF' : '#575757'};
    }
  }
`

export const ColorsRowWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`

export const ColorButtonWrapper = styled.div`
  position: relative;
  
  .twitter-picker {
    width: 240px !important;
    span > div {
    
    }
  }
`

export const ColorButton = styled.div`
  width: ${({width})=>width ?? 21}px;
  height: ${({height})=>height ?? 21}px;
  background: ${({color})=> color};
  border-radius:4px;
  cursor: pointer;
  transition:all 0.3s;
  box-shadow: ${({selected})=> selected ? 'rgba(3, 102, 214, 0.5) 0px 0px 0px 3px' : ''};
  border: 1px solid #57575726;
  overflow: hidden;
  
  img {
    width: 100%;
  }
  
  :hover {
    box-shadow: rgba(3, 102, 214, 0.2) 0px 0px 0px 3px;
  }
`

export const CustomizationTitle = styled.span`
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
`