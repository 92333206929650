import React from "react";
import {
    BurgerMenuWrapper,
    IconWrapper,
    BurgerMenuList,
    BurgerMenuListItem,
    BurgerMenuHeader
} from "../../style/BurgerMenu/BurgerMenu-styled";
import {CloseBurgerIcon, LogIEIcon} from "../../../Icons/Header-Icons";

const HumbuggerMenu = ({showMenu, setMenuVisible, items, setLocalModule, opsColor, module, isEditorMode, isEmbedMode, children}) => {
    return (
        <BurgerMenuWrapper
          isEmbed={isEmbedMode}
          isEditorMode={isEditorMode}
          showMenu={showMenu}
        >
            <BurgerMenuHeader
                isEmbed={isEmbedMode}
            >
                <a href="/" style={{display: "flex"}}>
                    <LogIEIcon/>
                </a>
                <IconWrapper
                  isEmbed={isEmbedMode}
                  isEditorMode={isEditorMode}
                  onClick={() => setMenuVisible(!showMenu)}
                >
                    <CloseBurgerIcon/>
                </IconWrapper>
            </BurgerMenuHeader>

            <BurgerMenuList isEmbed={isEmbedMode} isEditorMode={isEditorMode}>
                {items && <li>Choose module</li>}
                {items?.map((item, index) => (
                    <BurgerMenuListItem isEmbed={isEmbedMode} key={index} onClick={() => {
                        setLocalModule(item?.module);
                        setMenuVisible(false)
                    }}
                                        active={item?.module === module} opsColor={opsColor}>
                        {item.moduleName}
                    </BurgerMenuListItem>
                ))}
            </BurgerMenuList>
            <div style={{display:'flex', flexDirection:'row', justifyContent:"center", marginTop:14}}>
                {children}
            </div>
        </BurgerMenuWrapper>
    )
}


export default HumbuggerMenu;