import styled from "styled-components";
export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    margin-top: 1em;
`

export const CheckboxInput = styled.input`
    margin: 0.2em 0.4em 0 0;
`
export const CheckboxLabel = styled.div`
    width: 100%;
	font-weight: 400;
	font-size: .575em;
	line-height: 1.5em;
	color: #000000;
	cursor: pointer;
	text-align: left;
	padding: 0;
`