import styled from "styled-components";

export const PanelHeaderContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    overflow-y: visible;
    justify-content: space-between;
    align-items: baseline;
    max-width: 100%;
    z-index: 998;
`
export const TextContainer = styled.div`
    padding: 0 0.6em;
    margin: 0;
    flex: 2;
    text-align: left;
    overflow: hidden;
`
export const PanelTitle = styled.p`
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: .75em;
    line-height: 1.5em;
    max-width: 100%;
    text-align: left;
    margin: 0;
`
export const PanelSubtitle = styled.p`
    font-family: 'Open Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
`
export const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    overflow-y: visible;
`
export const CloseIconContainer = styled.button`
    padding: 0 0.2em;
    border: 0;
    margin-left: 10px;
    background-color: #FFFFFF;
    cursor: pointer;
`