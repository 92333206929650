import React, {useMemo} from "react";
import {StyledMapDisclaimer} from "./MapDisclaimer-styled";

const MapDisclaimer = ({
  config
}) => {
  const isEmbedMode = useMemo(()=> !!config.embed,[config]);
  
  return (
    <StyledMapDisclaimer isEmbed={isEmbedMode} id="disclaimer" className="esri-widget--disclaimer"	>
      <div id="disclaimerText"/>
      <div id="disclaimerExpand" className="expandButton esri-icon esri-icon-up" style={{display: "none"}}/>
    </StyledMapDisclaimer>
  );
};

export default MapDisclaimer;