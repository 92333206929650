import {useSelector} from "react-redux";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import React, {useEffect, useState} from "react";
import PrintPreview from "../PrintPreview/PrintPreview";
import {calculateAspectRatioFit, ZOOM_DIMENSIONS, ZOOM_OUT} from "../helpers";
import {exportToCanvas} from "@excalidraw/excalidraw";
import ExcalidrawWrapper from "../ExcalidrawWrapper/ExcalidrawWrapper";
import {getDisclaimerText} from "../../../utils/helper";
import {toSvg} from "html-to-image";

const convertImageToDataURL = (imgElement) =>{
  let canvas = document.createElement("canvas");
  
  canvas.width = imgElement.naturalWidth * 4;
  canvas.height = imgElement.naturalHeight * 1.6;
  
  let ctx = canvas.getContext("2d");
  ctx.drawImage(imgElement, 0, -440, canvas.width, imgElement.naturalHeight * 4);
  let pngUrl = canvas.toDataURL("image/png");
  return pngUrl;
}

const PrintScreen = ({
                       config,
                       parent,
                       setPage,
                       page,
                       setDpi,
                       dpi,
                       masks,
                       t,
                     }) => {
  const [loading, setLoading] = useState(false);
  const {activeModule} = useSelector(state=>state);
  const [drawings, setDrawings] = useState({
    active: false,
    api: null,
    elements: [],
    viewModeEnabled: false
  });
  
  const [zoomMap, setZoomMap] = useState({
      selected: false,
      active: false,
      x: 2,
      y: 2,
      size: 'Medium',
      orientation: 'horizontal',
      width: ZOOM_DIMENSIONS.Medium[0],
      height: ZOOM_DIMENSIONS.Medium[1]
    });
  
  const [legendPos, setLegendPos] = useState({
    x: 2,
    y: 2,
  })
  
  const [zoomOut, setZoomOut] = useState({
    x: 2,
    y: 2,
    loading: false,
    active: false,
    width: ZOOM_OUT.width,
    height: ZOOM_OUT.height
  });
  
  const [legendDims, setLegendDims] = useState(null);
  const [addPublicCustomizations, setAddPublicCustomizations] = useState(false);
  const [mapData, setMapData] = useState({
    title: '',
    subtitle: '',
    mapAuthor: '',
    mapEmail: '',
    mapUrl: '',
    date: '',
    color: ''
  });
  
  useEffect(()=>{
    const moduleTranslationPath = "screen.widget.ModuleSwitcher." + activeModule + ".name";
    const moduleTitleTranslation = t(moduleTranslationPath)
    
    const subtitle = moduleTranslationPath !== moduleTitleTranslation ? moduleTitleTranslation : `${config.modules[activeModule]?.title ? `${config.modules[activeModule]?.title}` : ''}`;
    const date = new Date();
    const disclaimer = config.printMap?.mapDisclaimer ?? config.opDefaults.printMap?.mapDisclaimer ?? getDisclaimerText(config, activeModule)
    const mapData = {
      mapAuthor: config.printMap?.mapAuthor ?? config.opDefaults.printMap?.mapAuthor,
      mapEmail: config.printMap?.mapEmail ?? config.opDefaults.printMap?.mapEmail,
      mapUrl: config.printMap?.mapUrl ?? config.opDefaults.printMap?.mapUrl,
      dataSources: config.printMap?.mapSources ?? config.opDefaults.printMap?.mapSources,
    }
    
    if (!addPublicCustomizations) {
      setMapData({
        title: config.title,
        subtitle: subtitle,
        date: date.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        disclaimer: disclaimer,
        backgroundColor: config.opsColor,
        textColor: '#ffffff',
        ...mapData
      })
    } else {
      setMapData(prev=>({
        ...prev,
        title: '',
        subtitle: '',
        backgroundColor: '#FFFFFF',
        textColor: '#393738',
        mapAuthor:'',
        mapEmail: '',
        mapUrl: '',
        disclaimer: config.printMap?.mapPublicCustomisationDisclaimer ?? config.opDefaults.printMap?.mapPublicCustomisationDisclaimer ?? ''
      }))
    }
  },[activeModule, config, addPublicCustomizations])
  
  const onMapDataChange = (key, value) =>{
    setMapData(prev=>({
      ...prev,
      [key]: value
    }))
  }
  
  const getPrintableData = async () => {
    const previewMap = document.querySelector('#pdfMap');
    const legendBox = previewMap?.querySelector('#legendBox');
    let legendUri = '';
    if (legendBox){
      const bodyNodes = legendBox.childNodes.length > 1 ? legendBox.childNodes[1].childNodes : []
      if (bodyNodes.length > 0) {
        const legendSVG = await toSvg(legendBox);
        const img = new Image();
        img.src = legendSVG;
    
        const legendOnload = new Promise((resolve, reject) => {
          img.onload = function (){
            let canvas = document.createElement("canvas");
        
            canvas.width = legendBox.offsetWidth * 10;
            canvas.height = legendBox.offsetHeight * 10 ;
        
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            let pngUrl = canvas.toDataURL("image/png");
            legendUri = pngUrl;
            resolve();
            img.onerror = reject;
          }
        })
        await legendOnload
      }
    }
    
    let orgLogoAttachment = '';
    if (!addPublicCustomizations || (addPublicCustomizations && mapData.attachment)) {
      const img = document.getElementById('preview-attachment-image');
      let canvas = document.createElement("canvas");
      if (img){
        const {width, height} = calculateAspectRatioFit(img.naturalWidth, img.naturalHeight, 2000, 2000);
  
        canvas.width = width //img.naturalWidth //* 10;
        canvas.height = height //img.naturalHeight //* 10;
  
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        let pngUrl = canvas.toDataURL("image/png");
  
        orgLogoAttachment = pngUrl;
      }
    }
    
    const disclaimerLogoImageElement = document.getElementById('disclaimer-logo');
    let disclaimerLogo;
    if (disclaimerLogoImageElement) {
      disclaimerLogo = convertImageToDataURL(disclaimerLogoImageElement);
    }
    
    const zoomOutArea = document.querySelector('#zoomOutArea')
    const images = [];
    
    if (drawings.active) {
      const elements = drawings.api.getSceneElements();
      const promises = [];
      elements.forEach(el=>{
        promises.push(exportToCanvas({
          elements:[el],
          appState: drawings.api.getAppState(),
          files: drawings.api.getFiles(),
          getDimensions: (width, height) => { return {width: width * 10, height: height * 10, scale:10}},
          exportPadding: 10
        }))
      })
      
      const res = await Promise.all(promises);
  
      res.forEach((c, index) => {
        const element = elements[index];
        const pos = {
          x: element.x,
          y: element.y
        }
        if (element.points && Array.isArray(element.points)) {
          const min = {
            x: 0,
            y: 0
          }
  
          element.points.forEach(coordinates=>{
            const [x, y] = coordinates;
            if (x < min.x) {
              min.x = x;
            }
      
            if (y < min.y) {
              min.y = y;
            }
          })
          pos.x = pos.x + min.x;
          pos.y = pos.y + min.y;
        }
        
        images.push({
          x: pos.x - 10,
          y: pos.y - 10,
          width: c.width / 10,
          height: c.height / 10,
          url: c.toDataURL(),
          points: element.points
        });
      })
    }

    return {
      legend: {
        uri: legendUri,
        x: legendPos.x,
        y: legendPos.y,
        width: legendDims.width,
        height: legendDims.height,
      },
      zoom: {
        url: undefined,
        ...zoomMap
      },
      zoomOut:{
        url: undefined,
        ...zoomOut,
        area: {
          width: zoomOutArea?.offsetWidth - 2,
          height: zoomOutArea?.offsetHeight - 2
        }
      },
      page: page,
      mapData: {
        ...mapData,
        attachment: orgLogoAttachment,
        disclaimerLogo
      },
      config: config,
      activeModule,
      drawings: {
        active: drawings.active,
        images: images
      },
      addPublicCustomizations,
      borderColor: addPublicCustomizations ? '#393738' : config.opsColor
    }
  }
  
  return (
    <>
      <SidebarMenu
        setPage={setPage}
        page={page}
        mapData={mapData}
        onMapDataChange={onMapDataChange}
        config={config}
        setDpi={setDpi}
        dpi={dpi}
        setZoomMap={setZoomMap}
        getPrintableData={getPrintableData}
        zoomMap={zoomMap}
        zoomOut={zoomOut}
        setZoomOut={setZoomOut}
        setDrawings={setDrawings}
        drawings={drawings}
        loading={loading}
        setLoading={setLoading}
        addPublicCustomizations={addPublicCustomizations}
        setAddPublicCustomizations={setAddPublicCustomizations}
      />
      <div
        id="printPreview"
        data-page={`${page.size}_${page.orientation}`}
        data-dpi={dpi}
        style={{
          position: 'absolute',
          width: masks.type === 'horizontal' ? masks.width : '100%',
          height: masks.height ?? 'calc(100% - 20px)',
          left: masks.type === 'horizontal' ? masks.style.width + 'px' : 0,
          top: masks.type === 'horizontal' ? '10px' : masks.style.height + 'px'
        }}>
        <PrintPreview
          config={config}
          mapData={mapData}
          parent={parent}
          dimensions={legendDims}
          setDimensions={setLegendDims}
          setPosition={setLegendPos}
          position={legendPos}
          page={page}
          setZoomMap={setZoomMap}
          zoomMap={zoomMap}
          zoomOut={zoomOut}
          setZoomOut={setZoomOut}
          loading={loading}
          addPublicCustomizations={addPublicCustomizations}
        >
          {drawings.active && <div style={{position:"absolute", width:'100%', height:'100%'}}>
            <ExcalidrawWrapper drawings={drawings} setDrawings={setDrawings} />
          </div>}
        </PrintPreview>
      </div>
    </>
  );
};

export default PrintScreen;