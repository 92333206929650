import {setLegendWidgetUpdate_actionType} from "../constants"

const initialState = {
  updateCount: 0
};
const legendWidget = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case setLegendWidgetUpdate_actionType:
      return {
        ...state,
        updateCount: state.updateCount + 1
      }
  }
  return state
}

export default legendWidget