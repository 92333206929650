import React from "react";


const AttachmentRemovalDialog = ({layer, feature, refreshAttachments, attachmentIdToRemove, setAttachmentIdToRemove}) => {
    const deleteAttachment = () => {
        console.log(feature, attachmentIdToRemove)
        layer.deleteAttachments(feature, [attachmentIdToRemove]).then((res) => {
            if (res.error)
                console.log("Unable to remove attachments, details", res)

            refreshAttachments()
            setAttachmentIdToRemove(null)
        })
    }
    return (attachmentIdToRemove !== null &&
        <div id="attachmentRemovalContainer" className="esri-editor__prompt--warning" >
            <div className="esri-editor__prompt__header">
                <calcite-icon icon="exclamation-mark-triangle" aria-hidden="true" scale="m"/>
                <h4 className="esri-widget__heading esri-editor__prompt__header__heading" role="heading"
                    aria-level="4">Are you sure you want to remove this attachment?</h4></div>

            <div id="attachmentErrorMessage" className="esri-editor__prompt__message"/>
            <div className="esri-editor__prompt__divider"/>
            <div className="esri-editor__prompt__actions">
                <calcite-button id="attachmentErrorButton" appearance="solid" color="white" width="half" alignment="center" scale="m" onClick={() => {setAttachmentIdToRemove(null)}}>
                    Cancel
                </calcite-button>

                <calcite-button id="attachmentErrorButton" appearance="solid" color="blue" width="half" alignment="center" scale="m" onClick={() => { deleteAttachment()}}>Remove</calcite-button>
            </div>
        </div>
    )
}

export default AttachmentRemovalDialog;