import React, {useMemo} from "react";
import {FormProvider} from "react-hook-form";
import ScrollSection from "../ScrollSection/ScrollSection";
import ProgressBar from "../ProgressBar/ProgressBar";
import {StyledReportsBody} from "../Reports-styled";
import Footer from "../Footer/Footer";
import useReport from "../../../../hooks/useReport";
import ReportFinal from "../ReportFinal";
import ReportsHeader from "../ReportsHeader/ReportsHeader";

const containerId = 'pac-report'

const PacReport = ({config, closeModal, feature, layer, setFeature, onSubmit, openReport, title, t}) => {
  const pages = useMemo(()=>{
    return [
      {
        id:1,
        title: t("report.pac.pages.pacType.title"),
        component: 'pactype',
        required: ['pactype'],
      },
      {
        id:2,
        title: t("report.pac.pages.location.title"),
        description: t("report.pac.pages.location.description"),
        component: 'location',
        required: ['geometry'],
      },
      {
        id:3,
        title: t("report.pac.pages.vehiclePracticability.title"),
        component: 'vehiclePracticability',
        required: ['currvehtype'],
      },
      {
        id:4,
        component: 'describe',
      },
      {
        id: 5,
        component: 'contacts',
        title:t("report.contacts.title"),
        description:t("report.contacts.description"),
      },
    ]
  },[t])
  
  
  const pacPages = useMemo(()=>{
    return pages.slice(feature ? 3 : 0).map(item=>{
      if (feature && item.component === 'describe') {
        return {
          ...item,
          required: ['reportedremarks']
        }
      }
      return item
    })
  },[feature, pages])
  
  const {
    contactsMethods,
    scrollNextDelay,
    goNext,
    goBack,
    page,
    form,
    setForm,
    resetReport
  } = useReport({pages: pacPages, containerId, t});
  
  const canGoNext = useMemo(() => {
    if (page === pacPages.length) return;
    const currentPage = pacPages[page]
    const requiredFields = {};
    
    currentPage.required?.map(requiredField=>{
      requiredFields[requiredField] = form[requiredField]
    })
    return !(Object.values(requiredFields).filter(value => value === undefined).length > 0)
  },[pacPages, form, page])
  
  const handleFormSubmit = async (formData) => {
    const geometry = {
      longitude: feature?.geometry?.longitude ?? feature?.geometry?.centroid?.longitude,
      latitude: feature?.geometry?.latitude ?? feature?.geometry?.centroid?.latitude,
    }

    const pacData = {
      reportingtype: feature ? 2 : 1,
      reporton: 1,
      ...form,
      geometry: form?.geometry ?? geometry
    }

    if (feature) {
      const layer = feature.sourceLayer || feature.layer;
      
      pacData.reportedlayerid = layer.layerConfig?.id;
      pacData.reportedlayername = layer.get("title");
      pacData.reportedfeatureid = feature.attributes[layer.objectIdField];
    }
    
    try {
      await onSubmit(formData, pacData);
      if (feature) {
        closeModal()
      } else {
        goNext();
      }
    } catch (err) {
      console.error(err);
    }
  }
  
  const onReset = () => {
    setFeature(null)
    resetReport();
  }
  
  const currentPage = useMemo(()=>{
    return pacPages[page]
  },[page])
  
  const isFinalPage = useMemo(()=>{
    return page === pacPages.length
  },[page, pacPages])
  
  return (
    <>
      <ReportsHeader
        isCustom={true}
        close={closeModal}
        onArrowClick={!feature && openReport}
        title={feature ? t("report.pac.update.title") : title}
        showArrow={!feature}
      >
        <ProgressBar total={pacPages.length - 1} currentPosition={page} color={config.opsColor} />
      </ReportsHeader>
      <FormProvider {...contactsMethods}>
        <StyledReportsBody
          isFinal={isFinalPage}
          id={containerId}>
          {
            !isFinalPage && pacPages.map((item, index)=>(
              <ScrollSection
                key={item.id}
                index={index}
                item={item}
                goNext={scrollNextDelay}
                form={form}
                setForm={setForm}
                resetReport={resetReport}
                layer={layer}
                t={t}
              />
            ))
          }
          {
            (!feature && isFinalPage) && <ScrollSection
              index={pacPages.length}
            >
              <ReportFinal
                closeModal={closeModal}
                startCounter={isFinalPage}
                t={t}
              />
            </ScrollSection>
          }
        </StyledReportsBody>
        <Footer
          isFinal={isFinalPage}
          isNextActive={(canGoNext || isFinalPage) && currentPage?.component !== 'contacts'}
          nextTitle={page === pages.length ? t("report.final.button") : t("screen.popup.actions.next")}
          showSubmit={currentPage?.component === 'contacts'}
          showNext={currentPage?.component !== 'contacts'}
          isValid={contactsMethods.formState.isValid}
          onSubmit={contactsMethods.handleSubmit(handleFormSubmit)}
          onNextClick={()=>{
            if (isFinalPage) {
              onReset();
            } else {
              goNext();
            }
          }}
          onPrevClick={page > 0 && goBack}
          t={t}
        />
      </FormProvider>
    </>
  );
};

export default PacReport;