import {isWidgetDisplayed, removeWidget} from "./expandUtils";
import {createAutoCollapseExpand} from "./expandUtils";
import {Provider} from "react-redux";
import store from "../../redux/store";
import React from "react";
import {layoutDimensions} from "../../components/PrintMask/helpers";
import PrintButton from "../../components/PrintMask/PrintButton/PrintButton";
import {createRoot} from "react-dom/client";

export const createPrint = (Print, reactiveUtils, Expand, view, t, config, pos) => {
	const widgetId = "Print"
	if (!isWidgetDisplayed(config, widgetId))
		return

	const defaultCustomTextElement = [{"url": config.mapUrl},	{"email": config.mapEmail}]
	const templateCustomTextElements = {}
	Object.keys(layoutDimensions).forEach((layout) => {
		templateCustomTextElements[layout] = defaultCustomTextElement
	})
	
	const print = new Print({
		view: view,
		printServiceUrl: config.printServiceUrl,
		title:  t('screen.widget.Print.name'),
		templateOptions: {
			"title": config.mapTitle,
			"author": config.mapAuthor,
			"copyright": config.mapCpr,
			"scaleEnabled": false,
			"dpi": "300"
		},
		templateCustomTextElements: templateCustomTextElements,
	})

	/** This code can override the default layout options, but have error with the default layout
	print.viewModel.load().then((vm) => {
		let customTextElements = {}
		Array.from(vm.templatesInfo.layout.choiceList).forEach((choice) => {
			customTextElements[choice] = defaultCustomTextElement
		})

		vm.templateCustomTextElements = customTextElements
	})
	 */
	const expand = createAutoCollapseExpand(Expand, view, config, t, widgetId, print, "esri-icon-printer")
	
	const toggleDiv = document.createElement("div")
	toggleDiv.setAttribute("id", widgetId)
	removeWidget(widgetId)

	const root = createRoot(toggleDiv)
	root.render(<Provider store={store}><PrintButton /></Provider>)
	view.ui.add({component: toggleDiv, position: "top-right", index: pos})
}


export const removeMask = ()=> {
	const mapDiv = document.querySelector('#mapContainer');
	
	if (mapDiv) {
		mapDiv.classList.remove('print-enabled');
	}
}