import React, {useEffect, useState} from "react";
import {Container, Content, InnerContainer} from "./PanelContainer-styled";
import PanelHeader from "./components/PanelHeader/PanelHeader";
import {
    fetchSavedContacts,
    formValidation, getLayer,
    getScreenId,
    PanelDimensions,
    RenderContent,
    routeLink, saveContacts,
    setFormDataValues,
    submitData
} from "./helpers";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import PanelFooter from "./components/PanelFooter/PanelFooter";
import {useDispatch, useSelector} from "react-redux";
import {
    resetPanel,
    setPanelFormData_actionType,
    setPanelId_actionType,
    setPanelSurveyIsValid_actionType
} from "../../redux/constants";
import {removePoint} from "../../utils/API";
import {CalciteLoader} from "@esri/calcite-components-react";
import {view} from "../../utils/API";

const PanelContainer = ({config, t, openSnackbar}) => {
    const {
        panelFormData,
        panelPosition,
        panelPath,
        panelPageIndex,
        panelId
	} = useSelector((store) => store)
    const dispatch = useDispatch()
    const panelDimensions = PanelDimensions()
    const mapDiv = document.getElementById("mapContainer")
    const [screenInfo, setScreenInfo] = useState(routeLink(panelPath))
    const [message, setMessage] = useState("")
    const [contactFields, setContactFields] = useState([])
    const [savedFieldValues, setSavedFieldValues] = useState({})
    const [loading, setLoading] = useState(false)

    const [width, setWidth] = useState(panelDimensions[panelPosition].width)
    const [height, setHeight] = useState(panelDimensions[panelPosition].height)
    const [left, setLeft] = useState(panelDimensions[panelPosition].left)
    const [top, setTop] = useState(panelDimensions[panelPosition].top)
    const KEY = "LogIeSecretKey!00116"
    view.popup.autoOpenEnabled = false

    useEffect( () => {
        let viewportHeight = window.innerHeight
        let viewportWidth = window.innerWidth
        setWidth(panelDimensions[panelPosition].width)
        setHeight(panelDimensions[panelPosition].height)
        setLeft(panelDimensions[panelPosition].left)
        setTop(panelDimensions[panelPosition].top)
        mapDiv.style.position = "relative"
        mapDiv.style.width = viewportWidth < 600 ? "0" : panelPosition === "bottom" || panelPosition === "modal" ? "100%" : "70%"
        mapDiv.style.height = viewportHeight < 600 ? "0" : panelPosition === "bottom" ? "60%" : "100%"
        mapDiv.style.left = panelPosition === "left" ? "30%" : "0"
        //mapDiv.style.bottom = panelPosition === "bottom" ? `${(viewportHeight - headerHeight) * 40 / viewportHeight}%` : "0"
    },[panelPosition])

    useEffect( () => {
        let sInfo = routeLink(panelPath)
        setScreenInfo(sInfo)
        if (!sInfo.onSubmit || !sInfo.onSubmit.configAttr)
            return
        const {type, configAttr} = sInfo.onSubmit
        const id = config[configAttr]
        if (!id)
            return
        const setLayer = async () => {
            await getLayer(id, view).then(async (layer) => {
                if (!layer)
                    return false
                dispatch({type: setPanelId_actionType, payload: {id: id, type: type ?? "", content: layer}})
            })
        }
        setLayer()
        let depth = 1
        for(let i = 0; i < depth; i++) {
            sInfo = Object.assign({}, ...Object.values(sInfo))
            depth += (sInfo && sInfo.rememberContacts) ? 0 : 1
        }
        if (!sInfo || !sInfo.hasOwnProperty("rememberContacts"))
            return
        const fields = Object.entries(sInfo.rememberContacts?.fields ?? {})
        if(fields.length < 1)
            return
        setContactFields(fields)
    }, [panelPath])

    useEffect(() => {
        if (!contactFields || contactFields.length < 1)
            return
        const savedContacts = fetchSavedContacts(KEY)
        if (savedContacts.length < 1)
            return
        let contacts = {}
        contactFields.map(([key, name]) => {
            const value = savedContacts[key]
            if(!value || value.length < 1)
                return
            contacts[name] = value
        })
        if (Object.keys(contacts).length < 1)
            return

        setSavedFieldValues(contacts)
    }, [contactFields])

    useEffect( () => {
        if (message === "")
            return
        openSnackbar(message, 15000)
        setMessage("")
    }, [message])

    const updateFormData = (newValue, path) => {
        //console.log(newValue, path)
        const newFormData = setFormDataValues("", path, panelFormData, newValue)
        setFormData(newFormData)
        //console.log(newValue, path, newFormData)
    }
    
    const setFormData = (newFormData) => {
        dispatch({ type: setPanelFormData_actionType, payload: newFormData })
        const formData = Object.values(panelFormData[panelPath])[panelPageIndex]
        const setFormValidation = formValidation(formData)
        dispatch({ type: setPanelSurveyIsValid_actionType, payload: setFormValidation })
    }

    const onSubmit = async () => {
        if (!screenInfo.isSurvey)
            return
        if (!panelId || panelId.id.length < 1){
            setMessage(t("screen.message.error"))
            return
        }
        setLoading(true)
        const formData = Object.assign({}, ...Object.values(panelFormData[panelPath]))
        await submitData(formData, panelId)
            .catch(async (error) => {
                console.error(error)
                setMessage(t("screen.message.error"))
                await removePoint()
            })
            .then(async () => {
                setMessage(t("screen.message.reportSuccess"))
                await removePoint()
            })
        if (!formData.hasOwnProperty("rememberContacts"))
            return
        if(contactFields.length < 1)
            return
        let contacts = {}
        const shouldSave = (formData["rememberContacts"].value).length > 0
        contactFields.map(([key, name]) => {
            const value = shouldSave ? formData[name].value: ""
            if(!value || value.length < 1) return
            contacts[key] = value
        })
        if (Object.keys(contacts).length > 0)
            saveContacts(KEY, contacts)
        setLoading(false)
        closePanel()
    }
    const closePanel = () => {
        dispatch({type: resetPanel})
        setScreenInfo({})
        mapDiv.style.width = `${window.innerWidth}px`
        mapDiv.style.height = `${window.innerHeight}px`
        mapDiv.style.left = "0"
        dispatch({ type: setPanelFormData_actionType, payload: {[panelPath]: {}} })
        view.popup.autoOpenEnabled = true
        const gl = view.map.layers.find(layer => layer.title === "Geometry Sketch")
        gl.removeAll()
        view.map.remove(gl)
    }

    return  (screenInfo) ? (
        <Container id={"panel-container"} style={{ width: `${width}%`, height: `${height}%`, left: `${left}%`, top: `${top}%`, padding: panelPosition ==="modal" ? ".3em .3em 0 .3em" : "0" }}>
            <InnerContainer>
                <PanelHeader panelPath={panelPath} t={t} closePanel={closePanel} />
                {(screenInfo && screenInfo.hasPagination) &&
                    <ProgressBar color={config.opsColor} total={(Object.keys(screenInfo.content ?? {})).length} pos={panelPageIndex + 1}/>
                }
                {loading ?
                    <CalciteLoader key="l" scale="s" style={{color: config.opsColor}} type={"indeterminate"} text={t('screen.component.loader.submittingData')} />
                    :
                    <Content id={"panel-body"} panelPosition={panelPosition}>
                        <RenderContent
                            data={screenInfo}
                            openScreenId={getScreenId(panelPath)}
                            path={panelPath}
                            config={config}
                            t={t}
                            currPageIndex={panelPageIndex}
                            formProps={{ isSurvey: screenInfo.isSurvey, formData: panelFormData, setFormData, onChange: updateFormData }}
                            defaultFieldValues={savedFieldValues}
                        />
                    </Content>
                }
                {(screenInfo && (screenInfo.footer || screenInfo.hasPagination)) &&
                    <PanelFooter
                        data={screenInfo.footer ? screenInfo.footer : {}} panelPath={panelPath} config={config}
                        openScreenId={getScreenId(panelPath)} hasPagination={screenInfo.hasPagination} t={t}
                        formProp={{onSubmit: onSubmit, panelFormData: panelFormData, setFormData: setFormData}}
                    />
                }
                </InnerContainer>
        </Container>
    ) : null
}
export default PanelContainer