import {setGeometry_actionType, removeData} from "../constants";

const initialState = null;
const geometry = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setGeometry_actionType:
			return state = payload;
		case removeData: 
			return state = initialState
		default:
			return state;
	}
};

export default geometry 