import React from "react";
import {DisclaimerSection, DisclaimerWrapper} from "./Disclaimer-styled";

const fontSizes = {
  'A4':{
    'Landscape': '4px',
    'Portrait': '3px'
  },
  'A3':{
    'Landscape': '3px',
    'Portrait': '2px'
  },
  'A2':{
    'Landscape': '2px',
    'Portrait': '1px'
  }
}

const elementWidth = {
  'Landscape':{
    'disclaimer': '45%',
    'rest': '25%',
  },
  'Portrait':{
    'disclaimer': '35%',
    'rest': '35%'
  }
}

const logoSizes = {
  'A4':{
    'Landscape':{
      width:28,
      height:18
    },
    'Portrait':{
      width:22,
      height: 14
    }
  },
  'A3':{
    'Landscape':{
      width:20,
      height:16
    },
    'Portrait':{
      width:14,
      height:10
    }
  },
  'A2':{
    'Landscape':{
      width:14,
      height:10
    },
    'Portrait':{
      width:10,
      height:6
    }
  }
}

const Disclaimer = ({mapData, page, addPublicCustomizations, config}) => {
  return (
    <DisclaimerWrapper fontSize={fontSizes[page.size][page.orientation]}>
      <div style={{
        width: page.size === 'A4' ? 110 : 55,
        marginRight: 5,
        height:'100%',
        display: 'flex',
        alignItems: 'center',
      }}>
        <img style={{width:'100%'}} src={process.env.PUBLIC_URL + '/assets/northArrow_Scale.png'} alt="disclaimer_arrow_scale" />
      </div>
      <div style={{display:'flex', flex:1, gap:4, height:'100%', alignItems:'flex-start'}}>
        <DisclaimerSection
          style={{width:elementWidth[page.orientation].rest}}
        >
          <span><span>{mapData.date ? 'Date created: ': ""} </span>{mapData.date ? `- ${mapData.date}` : ''}<span>{mapData.mapEmail ? "Contact:" : ""} </span>{mapData.mapEmail}</span>
            {mapData.mapUrl && <span><span>Website url: </span>{mapData.mapUrl}</span>}
          {mapData.mapAuthor && <span><span>Prepared by: </span>{mapData.mapAuthor}</span>}
        </DisclaimerSection>
        <DisclaimerSection
          style={{width:elementWidth[page.orientation].rest}}
        >
          {mapData.dataSources && <span><span>Data sources: </span>{mapData.dataSources}</span>}
        </DisclaimerSection>
        <DisclaimerSection
          style={{width:elementWidth[page.orientation].disclaimer}}
        >
          {mapData.disclaimer && <span>{mapData.disclaimer}</span>}
        </DisclaimerSection>
      </div>
      <DisclaimerSection
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: "2px 0px",
        }}>
          <div style={{
            width: '74px',
            height: '28px',
            position:'relative',
            display:'flex',
            flexDirection:'column',
            border: `1px dashed ${config.opsColor}`,
          }}>
            <img
              id="disclaimer-logo"
              style={{
                position: 'absolute',
                top: '-14px',
                left: '4px',
                width: "90%",
              }}
              alt="app_logo"
              src={process.env.PUBLIC_URL + '/assets/LogIE_poweredby.svg'}
            />
          </div>
           <span>
              @ World Food Programme {mapData.date.slice(-4)}
           </span>
      </DisclaimerSection>
    </DisclaimerWrapper>
  );
};

export default Disclaimer;