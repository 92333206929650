import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {applyCurrentFiltersOnLayer} from "../Filter/ApplyFilters";
import {loadModules} from "esri-loader";
import shpwrite from '@mapbox/shp-write';
import {CalciteTab, CalciteTabNav, CalciteTabs, CalciteTabTitle} from "@esri/calcite-components-react"
import {
	FeatureIconContainer,
	FeatureTableContainer,
	StyledButton,
	StyledTableTopBar,
	StyledFilter,
	StyledSwitch,
	StyledTableContentWrapper
} from "./FeatureTable-styled";
import {view} from "../../utils/API";
import {getDisclaimerText, getFieldsByPattern, ROLE_EDITOR} from "../../utils/helper";
import {ConfigContext} from "../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {CalciteLoader} from "@esri/calcite-components-react";
import {getLayerTitle} from "../../esri/custom-popup-content";
import DownloadButton from "./DownloadButton/DownloadButton";
import { chevronDown24 } from "@esri/calcite-ui-icons";
import {arcgisToGeoJSON} from "@terraformer/arcgis";

export const geometryTypes = new Set(["point", "line", "polygon", "polyline"])

const FeatureTable = ({ openSnackbar }) => {
	let [watchHandles, setWatchHandles] = useState([])
	let [activeTab, setActiveTab] = useState(null)
	let [tabs, setTabs] = useState({})
	let [tableInitialized, setTableInitialized] = useState(false); // Used for turning on FeatureTable filter
	let [featureTableFullScreenActive, setFeatureTableFullScreenActive] = useState(false)
	let [download, setDownload] = useState('');
	let [checked, setChecked] = useState(true) // MapView filter FeatureTable records to map extent checked prop
	let [featureTable, setFeatureTable] = useState() // Access to the FeatureTable
	let [mapView, setMapView] = useState() // Access to the MapView
	let [featureTableState, setFeatureTableState] = useState(null) // variable to track featureTable.state
	let [isInteractingWithView, setIsInteractingWithView] = useState(false)
	let [currentLayer, setCurrentLayer] = useState(null)
	let [isLoading, setIsLoading] = useState(true)
	const switchRef = useRef(null)
	const featureTableRef = useRef(null);
	let filters = useSelector((store) => store.filters)
	let activeModule = useSelector((store) => store.activeModule)
	let [currFilters, setCurrFilters] = useState(filters[activeModule])
	const [featureTableExpanded, setFeatureTableExpanded] = useState(false);
	
	const {config} = useContext(ConfigContext)
	const { t } = useTranslation('common')

	const initDrag = (e) => {
		if (e.target.id !== 'resizer') return;
		document.documentElement.addEventListener('mousemove', doDrag, false)
		document.documentElement.addEventListener('mouseup', stopDrag, false)
	}
	
	let mapDiv = document.getElementById("mapContainer")

	/** Drag event, move the height of the featureTable */
	const doDrag = e => {
		e.preventDefault();
		if (!featureTableRef.current) return;
		
		const appHeight = window.innerHeight
		const headerHeight = document.getElementById("header").offsetHeight || 0;
		const disclaimerHeight = document.getElementById('disclaimer').offsetHeight || 0;
		if (!mapDiv)
			mapDiv = document.getElementById("mapContainer")
		mapDiv.style.cursor = "n-resize"
		featureTableRef.current.style.transition = "none"
		const tableFlexBasis = appHeight - e.clientY - disclaimerHeight
		const isFullScreen = tableFlexBasis >= appHeight - headerHeight - disclaimerHeight - 5
		if (tableFlexBasis > 13 && !featureTableRef.current.style.flexBasis){
			setFeatureTableExpanded(true);
			switchRef.current.checked = true;
			setChecked(true);
		}
		//when table height is near the bottom
		if (tableFlexBasis <= 13) {
			featureTableRef.current.style.removeProperty('flex-basis');
			setFeatureTableExpanded(false);
			setFeatureTableFullScreenActive(false)
			return;
		}
		
		featureTableRef.current.style.flexBasis = `${isFullScreen ? appHeight : tableFlexBasis}px`
		setFeatureTableFullScreenActive(isFullScreen)
		if (isFullScreen) {
			featureTable.filterGeometry = undefined;
		}
	}

	const stopDrag = () => {
		if (mapDiv) mapDiv.style.cursor = "default"
		if (featureTableRef.current) featureTableRef.current.style.cursor = "default"
		document.documentElement.removeEventListener('mousemove', doDrag, false)
		document.documentElement.removeEventListener('mouseup', stopDrag, false)
	}

	const convertToCSV = (objArray, fields) => {
    const csvData =
      typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let x = 0; x < csvData.length; x++) {
      let line = "";
      const attr = fields.length > 0 ? fields : Object.keys(csvData[x]);
      for (let y of attr) {
        if (line !== "") {
          line += ",";
        }
        const text =
          csvData[x][y] !== null
            ? t(`layer.domain.${y}.${csvData[x][y]}`, csvData[x][y])
            : "";
        csvData[x][y] =
          text.search(/layer.domain/) === -1 ? text : csvData[x][y];
        csvData[x][y] =
          typeof csvData[x][y] == "string"
            ? csvData[x][y]
            : csvData[x][y] == null
            ? ""
            : String(csvData[x][y]);
        line += `"${csvData[x][y].replace(/"/g, '""')}"`;
      }

      str += line + "\r\n";
    }

    return str;
  };

	const exportCSVFile = (headers, csvData, fileName, fields) => {
		if (headers) {
			csvData.unshift(headers);
		}

		let jsonCsvData = JSON.stringify(csvData);
		const csv = convertToCSV(jsonCsvData, fields);

		const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(csvBlob, fileName);
		}
		else {
			const link = document.createElement("a");
			if (link.download !== undefined) {
				// feature detection
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(csvBlob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	const setVisibleLayers = useCallback(() => {
		const visibleLayers = view.map.layers.filter((layer) => {
			return layer.layerConfig && layer.layerConfig.editable
				&& layer.visible && layer.layerConfig.featureTable?.isShownInFeatureTable
				&& layer.geometryType && geometryTypes.has(layer.geometryType)
		}).sort((a, b) => {
			if (!a || !a.title || !b || !b.title)
				return 0
			else
				return a.title.localeCompare(b.title)
		})

		let navs = visibleLayers.map((layer) => {
				return <CalciteTabTitle key={"tit" + layer.id} opsColor={config.opsColor} onClick={(evt) => {
					const tab = evt.target
					const idx = [...tab.parentNode.children].indexOf(tab)
					setActiveTab(idx)
				}}>
					{getLayerTitle(layer, t)}
				</CalciteTabTitle>
			})

		let tabContents = visibleLayers.map((layer) => {
			return <CalciteTab key={"sec" + layer.id}>
				<div id={"tab_" + layer.id} />
			</CalciteTab>
		})

		setTabs({ layers: visibleLayers.toArray(), navs: navs, contents: tabContents })
		setActiveTab(0)
	}, [t])

	// When clicking switch, set checked.
	const handleSwitchToggle = (e) => {
		if (e.target.checked) {
			featureTable.filterGeometry = view.extent
		} else {
			featureTable.filterGeometry = undefined
		}
		// we are using uncontrolled component that utilizes useRef
		// by adding setTimeout, we can make the switch more "responsive"/"smooth" as requested
		// and rerender before the rest of the table rerenders

		// this setChecked is simply to trigger a rerender for the rest of the table
		// since useRef changes do not trigger rerenders
		setTimeout(() => {
			setChecked(e.target.checked);
		}, 100)
	};

	useEffect(() => {
		if (switchRef && switchRef.current && featureTableFullScreenActive) {
			switchRef.current.disabled = true;
			if (featureTable) {
				featureTable.filterGeometry = undefined;
			}
		}
		if (switchRef && switchRef.current && !featureTableFullScreenActive) {
			switchRef.current.disabled = false;
		}
	}, [featureTableFullScreenActive])
	
	// track the changes for featureTable.state
	// useEffect alone cant seem to track featureTable.state changes
	// `featureTable` state is used to set default checked = true.
	useEffect(() => {
		loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
			reactiveUtils.watch(
				() => featureTable?.state,
				(state) => {
					setFeatureTableState(state)
				});
		})
	}, [featureTable])
	
	useEffect(()=>{
		if (!featureTable) return;
		let handler;
		
		loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
			handler = reactiveUtils.when(() => !!view?.stationary,
				() => {
					// Get the new extent of view/map whenever map is updated.
					if (view.extent && featureTable) {
						// Filter out and show only the visible features in the feature table.
						featureTable.filterGeometry = !checked || featureTableFullScreenActive ? undefined : view.extent;
					}
				}, { initial: false });

			if ((!featureTableExpanded || !checked || featureTableFullScreenActive) && handler) {
				handler.remove();
			}
		})

		return () => {
			handler?.remove();
		}
	},[featureTableFullScreenActive, checked, featureTableExpanded, featureTable])
	
	let timer
	const setTableFilterGeometry = useCallback((extent) => {
		//Prevent creating multiple queries while user is still typing
		if (timer)
			return
		
		timer = setInterval(() => {
			clearInterval(timer);
			timer = null
			featureTable.filterGeometry = extent;
		}, 200)
	}, [featureTable])

	useEffect(() => {
		loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
			reactiveUtils.watch(
				() => view?.interacting,
				(state) => {
					setIsInteractingWithView(state);
				})
			})
		}, [view])

	// when feature state is "loaded",
	// run this once. Set the switch checked = true as default.
	// We are doing this because if table is not "loaded" state and
	// filter is set, the app breaks
	useEffect(() => {
		if (featureTableState === 'loaded' && tableInitialized === false) {
			setTableInitialized(true);
			setIsLoading(false);
			switchRef.current.checked = true;
		}
	}, [featureTableState, switchRef])

	// useEffect for the checked prop
	useEffect(() => {
		// set FeatureTable to map extent filterGeometry
		// https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-FeatureTable.html#filterGeometry
		try {
			const currLayer = tabs.layers[activeTab]
			setCurrentLayer(currLayer);
		} catch { }
	}, [switchRef?.current?.checked, featureTableExpanded, featureTable, mapView, isInteractingWithView, filters, config, activeModule, tabs, activeTab])
	
	useEffect(() => {
		/** Activate/inactivate */
		if (!featureTableExpanded || !view || !view.map) {
			setActiveTab(null)
			watchHandles.forEach(wh => {
				wh.remove()
			})
		} else {
			// set the mapView property
			setMapView(view)
			let whs = []
			view.map.layers.forEach((layer) => {
				whs.push(layer.watch("visible", () => {
					setActiveTab(null)
					setVisibleLayers()
				}))
			})

			view.map.layers.on("change", (event) => {
				if (event.added)
					event.added.forEach((layer) => {
						layer.load().then((layer) => {
							whs.push(layer.watch("visible"))
							setActiveTab(null)
							setVisibleLayers()
						})
					})
			})

			setWatchHandles(whs)
			setVisibleLayers()
		}
	}, [featureTableExpanded, setVisibleLayers])

	useEffect(() => {
		if (!tabs || !tabs.layers)
		return

		const currLayer = tabs.layers[activeTab]
		setCurrentLayer(currLayer);
		if (!currLayer || !currLayer.layerConfig.featureTable?.isShownInFeatureTable)
			return

		const tableDiv = document.getElementById("tab_" + currLayer.id)
		/*if (!tableDiv || tableDiv.getAttribute("isLoaded") === "true")
			return*/
		tableDiv.innerHTML = "";
		loadModules(["esri/widgets/FeatureTable"]).then(([FeatureTable]) => {
			tableDiv.setAttribute("isLoaded", "true")
			const layerConfig = currLayer.layerConfig;
			
			const visibilityFields = getFieldsByPattern(currLayer, layerConfig.visibilityFields, config.role !== ROLE_EDITOR);
			const situationalFields = getFieldsByPattern(currLayer, layerConfig.situationalFields, config.role !== ROLE_EDITOR);
			const baselineFields = getFieldsByPattern(currLayer, layerConfig.baselineFields, config.role !== ROLE_EDITOR);

			const efi = currLayer.editFieldsInfo
			const editFields = efi ? getFieldsByPattern(currLayer, [efi.creationDateField,
				efi.creatorField,	efi.editDateField, efi.editorField], config.role !== ROLE_EDITOR) : []
			
			const tableTemplate = {
				columnTemplates: [
					getColumnTemplate(t("screen.widget.Editor.visibilityFields.label"), visibilityFields, t),
					getColumnTemplate(t("screen.widget.Editor.baselineFields.label"), baselineFields, t),
					getColumnTemplate(t("screen.widget.Editor.situationalFields.label"), situationalFields, t),
					getColumnTemplate(t("screen.widget.Editor.editFields.label"), editFields, t)
				]
			}

			let isEditable = false
			if (config.role === ROLE_EDITOR && layerConfig && layerConfig.editable)
				isEditable = true

			const geometry = (checked && !featureTableFullScreenActive && view?.extent) ? view.extent : undefined
			
			setIsLoading(false)
			setFeatureTable(new FeatureTable({
				view: view,
				editingEnabled: isEditable,
				layer: currLayer,
				container: tableDiv,
				filterGeometry: geometry,
				tableTemplate: tableTemplate
			}))
		})
	}, [activeTab, config, filters, activeModule, featureTableExpanded, tabs])

	useEffect( () => {
		if (!tabs || !tabs.layers)
			return
		let filtrs = []
		const currLayer = activeTab ? tabs.layers[activeTab] : tabs.layers[0]
		if (filters && filters[activeModule]){
			const filterNames = Object.keys(filters[activeModule])
			for (const filterName of filterNames) {
				const field = currLayer?.fields?.filter((field) => field.name === filterName)[0]
				if (field) filtrs.push(filterName)
			}
		}
		setCurrFilters(filtrs)
	},[activeTab, filters, activeModule])

	const getColumnTemplate = (label, fieldSet, t) =>{

		const ret = { // Autocasts to new GroupColumnTemplate
			type: "group",
				label: label,
			columnTemplates: []}

		ret.columnTemplates = fieldSet.map((field) => {
			return {
				type: "field",
				fieldName: field.name,
				label: t('layer.fieldAlias.' + field.name, field.alias)
			}
		})

		return ret
	}
	
	const exportShapeFile = useCallback((currLayer, resultFeatures, fileName, fields)=>{
		const geoJSON = {
			type: 'FeatureCollection',
			features : resultFeatures.map(item=>{
				const geoJSONFeature = arcgisToGeoJSON(item, currLayer.objectIdField);
				geoJSONFeature.geometry.coordinates = [item.geometry.longitude, item.geometry.latitude];
				const newProperties = {};
				
				Object.keys(geoJSONFeature.properties).forEach(k=>{
					if (fields.includes(k)) {
						
						let field = t(`layer.fieldAlias.${k}`, k)
						if (field.search(/layer.fieldAlias/) === 0) {
							field = k;
						}
						
						const attributeValue = geoJSONFeature.properties[k];
						let propertiesValue = !isDateAttribute(k, attributeValue)
							? t(`layer.domain.${k}.${attributeValue}`, attributeValue)
							: convertTimestampToDateString(attributeValue)
						
						if (propertiesValue.search(/layer.domain/) === 0) {
							propertiesValue = geoJSONFeature.properties[k];
						}
						
						newProperties[field] = propertiesValue;
					}
				})
				geoJSONFeature.properties = newProperties;
				return geoJSONFeature;
			})
		}
		
		const options = {
			folder: "Downloads",
			filename: fileName,
			outputType: "blob",
			compression: "DEFLATE",
		};
		shpwrite.download(geoJSON, options);
	},[t])

	useEffect(() => {
    if (!((tabs || tabs.layers) && download)) {
      return;
    }
    const currLayer = tabs.layers[activeTab];
    setCurrentLayer(currLayer);
    applyCurrentFiltersOnLayer(currLayer, filters[activeModule], config);
    const { layerConfig } = currLayer;
    const efi = currLayer.editFieldsInfo;
		
		const visibilityFields = getFieldsByPattern(currLayer, layerConfig.visibilityFields, config.role !== ROLE_EDITOR);
		const situationalFields = getFieldsByPattern(currLayer, layerConfig.situationalFields, config.role !== ROLE_EDITOR);
		const baselineFields = getFieldsByPattern(currLayer, layerConfig.baselineFields, config.role !== ROLE_EDITOR);
		const editFields = efi ? getFieldsByPattern(currLayer, [efi.creationDateField,
			efi.creatorField,	efi.editDateField, efi.editorField], config.role !== ROLE_EDITOR) : []
		
		const fields = [...visibilityFields, ...baselineFields, ...situationalFields, ...editFields].map(field=>field.name);

		if (layerConfig.featureTable.exportCoordinates) {
			fields.push('latitude', 'longitude');
		}
		
		let query;
		if (checked && !featureTableFullScreenActive){
			query = {
				outFields:['*'],
				geometry: view.extent,
				spatialRelationship: "intersects",
				where: currLayer.definitionExpression,
				returnGeometry: layerConfig.featureTable.exportCoordinates
			}
		}
		
		//when passing query as undefined gets all features satisfying the layer's configuration/filters
    currLayer.queryFeatures(query).then((results) => {
      const resultFeatures = results.features;
      let message;
			const layerName = getLayerTitle(currLayer, t);
      if (resultFeatures.length) {
				const fileTitle = `${config.alias ? `${config.alias}_` : ''}${layerName}`;
				let date = (new Date()).toISOString().split('T')[0].replace(/-/g,"")
				const exportedFilename = fileTitle !== "" ? `${fileTitle}_${String(date)}` : `logie_data_${String(date)}`;
				if (download === 'shapefile') {
					//export shapefile
					exportShapeFile(currLayer, resultFeatures, exportedFilename, fields);
				} else {
					// export to csv
					const data = getDataFromResults(resultFeatures);
					const headers = {};
					const entry = data[0];
					for (let key in entry) {
						if (entry.hasOwnProperty(key)) {
							headers[key] = t("layer.fieldAlias." + key, key);
						}
					}
					
					exportCSVFile(headers, data, exportedFilename, fields);
				}
				setDownload('');
				message = t("screen.message.downloadSuccess", { title: layerName });
			} else {
				message = t("screen.message.noData", { title: layerName });
			}
      setDownload('');
      openSnackbar(message, 15000);
    });
  }, [download, tabs]);
	
	const onArrowClick = useCallback(() => {
			if (!featureTableRef.current) return;
			featureTableRef.current.style.transition = 'all 0.5s';
			if (!featureTableExpanded) {
				setFeatureTableExpanded(true)
				switchRef.current.checked = true;
				setChecked(true);
			} else {
				featureTableRef.current.style.removeProperty('flex-basis');
				setFeatureTableExpanded(false);
				setFeatureTableFullScreenActive(false);
			}
		}
		,[featureTableRef, featureTableExpanded])
	
	const isDisclaimerShown = useMemo(()=> {
		return getDisclaimerText(config, activeModule);
	},[config, activeModule])

	if (!view || !view.map)
		return null

	return (
		<FeatureTableContainer
			isExpanded={featureTableExpanded}
			isDisclaimerShown={isDisclaimerShown}
			ref={featureTableRef}
			id="ftc"
		>
			<StyledTableContentWrapper opsColor={config.opsColor} isExpanded={featureTableExpanded}>
				<StyledTableTopBar isExpanded={featureTableExpanded} key="resizer" id="resizer" onMouseDown={(e) => { initDrag(e) }}>
					<div style={{display:'flex', padding:'0px 2px'}} className="onboarding-feature-table" >
						<StyledButton isExpanded={featureTableExpanded} id="btn" onClick={onArrowClick}>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
								<path d={chevronDown24}/>
							</svg>
						</StyledButton>
						<span style={{display:'flex', alignItems:'center', gap: 5}}>
						Data
						<svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 40 40" fill={config.opsColor ?? "#418fde"}>
							<g>
								<g >
									<path d="M38,0H2A2.00587,2.00587,0,0,0,0,2V38a2.00591,2.00591,0,0,0,2,2H38a2.00591,2.00591,0,0,0,2-2V2A2.00587,2.00587,0,0,0,38,0ZM37,13v6H13V13Zm0,15H13V22H37ZM3,22h7v6H3Zm0-9h7v6H3ZM3,31h7v6H3Zm34,6H13V31H37Z"/>
								</g>
							</g>
						</svg>
					</span>
					</div>
					<div id="tab_icons"
							 key="tab_icons"
							 style={{
								 opacity: featureTableExpanded ? 1 : 0,
								 pointerEvents: featureTableExpanded ? 'auto' : 'none',
								 display: "flex",
								 justifyContent: "flex-end",
								 marginLeft: "1em",
								 fontSize: "12px",
								 fontFamily: "'Open Sans',sans-serif !important"
					}}>
						<FeatureIconContainer isTableFullScreen={featureTableFullScreenActive}>
							Filter by Map Extent <StyledSwitch opsColor={config.opsColor} ref={switchRef} onCalciteSwitchChange={handleSwitchToggle}></StyledSwitch>
						</FeatureIconContainer>
						<DownloadButton
							t={t}
							config={config}
							setDownload={setDownload}
							layer={tabs && tabs.layers ? tabs.layers[activeTab] : null}
						/>
					</div>
				</StyledTableTopBar>
				{featureTableFullScreenActive &&
					<StyledFilter config={config} t={t} featureTableStyling={true} filterFields={currFilters}/>}
				{/*{isLoading && <div style={{width: "100%",  height:'100%', display:'block'}}>*/}
				{/*	<CalciteLoader key="l" scale="s" style={{color: config.opsColor}} type={"indeterminate"}*/}
				{/*								 text={t("screen.widget.FeatureTable.loading")}/>*/}
				{/*</div>}*/}
				{
					tabs?.contents?.length > 0 && <CalciteTabs id="tabs" key="tabs" style={{width: "100%",  height:'100%'}} activeTabIndex={activeTab}>
						<CalciteTabNav slot="title-group">{tabs.navs}</CalciteTabNav>
						{tabs.contents}
					</CalciteTabs>
				}
				{isLoading && <div style={{width: "100%",  height:'100%', display:'block'}}>
					<CalciteLoader key="l" scale="s" style={{color: config.opsColor}} type={"indeterminate"}
												 text={t("screen.widget.FeatureTable.loading")}/>
				</div>}
			</StyledTableContentWrapper>
			
		</FeatureTableContainer>
	)
	
}

function convertTimestampToDateString(timestamp) {
if (typeof timestamp == "number") {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  // Format the components into the "yyyy-mm-dd hh:mm" string
  return `${day}-${month}-${year} ${hour}:${minute}`;
}
return timestamp;
}

const isDateAttribute = (key, value) => {
	const regex = /.*date.*/i
	return typeof value === "number" && key.match(regex)
}

function getDataFromResults(resultFeatures) {
	return resultFeatures.map((row) => {
		const { attributes, geometry } = row
		for (const [key, value] of Object.entries(attributes)) {
			if (isDateAttribute(key, value))
				attributes[key] = convertTimestampToDateString(value)
		}
		
		if (geometry && geometry.latitude && geometry.longitude){
			attributes.latitude = geometry.latitude;
			attributes.longitude = geometry.longitude;
		}
		
		return attributes
	})
}

export default FeatureTable
