import styled from "styled-components";

export const StyledDocumentLink = styled.a`
	padding: 8px 0 8px 14px;
	background-color: #D9D9D9;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	gap: 13px;
	font-family: 'Open Sans';
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	color: #031C2D !important;
`;

export const StyledDocumentsListItem = styled.li`
	width: 100%;
	height: 56px;
	margin-bottom: 0 !important;
`;

export const DocumentLinkContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 2px;	
`;

export const DocumentLinkDocumentType = styled.span`
	font-weight: 400;
	font-size: 13px;
	color: #3C474F;
`;
