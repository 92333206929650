import {
	setNextPage_actionType,
	setPrevPage_actionType,
	removeData
} from "../constants";

const initialState = 1;
const currentScreen = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setNextPage_actionType:
			return state = payload + 1;
		case setPrevPage_actionType:
			return state = payload - 1;
		case removeData: 
			return state = initialState;
		default:
			return state;
	}
};

export default currentScreen;