import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {
  FileDelete,
  FilePreview,
  FilePreviewContainer,
  FileUpload,
  FileUploadText,
} from "./UploadFile-styled";
import {CircleClose} from "../../../Icons";
import Button from "../Button/Button";
import { download24 } from "@esri/calcite-ui-icons";
import {StyledSectionDescription, StyledSectionTitle} from "../ScrollSection/ScrollSection-styled";


const UploadFile = ({form, setForm, name, config, title, description, buttonTitle, fileDescription, assignUrl = false}) => {
  const file = useMemo(()=>form[name],[form,name])
  const [preview, setPreview] = useState();
  const objectUrl = useRef();
  
  useEffect(() => {
    // create the preview
    // let objectUrl
    // if (file) {
    //   objectUrl = URL.createObjectURL(file)
    //   setPreview(objectUrl)
    // }
    
    // free memory when ever this component is unmounted
    return () => {
      objectUrl.current && URL.revokeObjectURL(objectUrl.current);
      objectUrl.current = null;
      setPreview(null);
    }
  }, []);
  
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      
      // setForm(form=>({...form, [name]: acceptedFiles[0]}))
      if (file){
        objectUrl.current = URL.createObjectURL(file)
        setPreview(objectUrl.current);
        
        if (assignUrl){
          setForm(name, objectUrl.current )
        } else {
          setForm(name, file)
        }
      }
    },
    [setForm, assignUrl]
  );
  
  const handleDelete = (e) => {
    e.stopPropagation()
    
    setForm(name, null)
    URL.revokeObjectURL(objectUrl.current);
    objectUrl.current = null;
    // setForm(form=>({...form, [name]: null}))
  };
  
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg,image/jpg,image/png,application/pdf"
  });
  
  return (
    <>
      {title &&
        <StyledSectionTitle style={{margin: "5px 0px 0px 0px"}}>{title}</StyledSectionTitle>}
      {
        description && <StyledSectionDescription style={{margin:0}}>{description}</StyledSectionDescription>
      }
      <FileUpload color={config.opsColor} isDragActive={isDragActive} {...getRootProps()}>
        <input accept='image/*' {...getInputProps()} />
        {!file ? <div>
          <Button onClick={open}>
            <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d={download24} fill="#393838" />
            </svg>
            {!file && <span>{buttonTitle}</span>}
          </Button>
            <FileUploadText isDragActive={isDragActive}>
              {fileDescription}
            </FileUploadText>
        </div>
        : (
          <FilePreviewContainer>
            <FilePreview src={preview} alt={file.name} />
            <FileDelete onClick={handleDelete}>
              <CircleClose color={config.opsColor} />
            </FileDelete>
          </FilePreviewContainer>
        )}
      </FileUpload>
    </>
  );
};

export default UploadFile;