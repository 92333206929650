import React from "react";
import {CategoriesCard} from "../Reports-styled";
import {
  PACAccident,
  PACBlockingObject, PACConstruction,
  PACFire,
  PACFlood, PACGathering,
  PACLandslide, PACOther,
  PACRoadDamage, PACStuckVehicle,
  SVGIcon
} from "../../../Icons/ReportTypes-icons";

const ReportsCategories = [
  {
    id: 8,
    title: "Road Damage",
    key: 'roadDamage',
    icon: PACRoadDamage,
  },
  {
    id: 1,
    title: "Landslide",
    key:'landslide',
    icon: PACLandslide,
  },
  {
    id: 2,
    title: "Flood",
    key:"flood",
    icon: PACFlood,
  },
  {
    id: 3,
    title: "Fire",
    key:'fire',
    icon: PACFire,
  },
  {
    id: 4,
    title: "Blocking Object",
    key:'blockingObjects',
    icon: PACBlockingObject,
  },
  {
    id: 5,
    title: "Accident",
    key:'accident',
    icon: PACAccident,
  },
  {
    id: 6,
    title: "Stuck Vehicle",
    key:'stuckVehicle',
    icon: PACStuckVehicle,
  },
  {
    id: 9,
    title: "Gathering",
    key:'gathering',
    icon: PACGathering,
  },
  {
    id: 7,
    title: "Construction",
    key:'construction',
    icon: PACConstruction,
  },
  {
    id: 10,
    title: "Other",
    key:'other',
    icon: PACOther,
  },
];

const PacType = ({form, setForm, config, goNext, t}) => {
  const onSelect = (value) => {
    setForm(form=>({...form, pactype: value}))
    if (goNext) {
      goNext();
    }
  }
  
  return (
    ReportsCategories.map(category=>(
      <CategoriesCard
        key={category.id}
        selected={form.pactype === category.id}
        className={form.pactype === category.id ? "blink_me" : ""}
        onClick={()=>onSelect(category.id)}
        borderColor={config.opsColor}
        bgColor={form.pactype === category.id && config.opsColor ? `${config.opsColor}1a` : ''}
      >
        <SVGIcon color={config.opsColor} iconName={category.title} />
        <span>
          {t('report.pac.pages.pacType.typeOfAccessConstraint.' + category.key)}
        </span>
      </CategoriesCard>
    ))
  );
};

export default PacType;