import React, {useCallback, useEffect, useState} from "react";
import {
    FileDelete,
    FilePreview,
    FilePreviewContainer, FileUpload, FileUploadText, FileUploadTitle
} from "./Dropbox-styled";
import {CircleClose, DragAndDrop} from "../../../Icons";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {getFormDataVal} from "../../helpers";

const Dropbox = ({path, formProps}) => {
    const {isSurvey, onChange} = formProps
    const { panelFormData } = useSelector((store) => store)
    const value = (getFormDataVal(panelFormData, path) ?? {value: null}).value
    const [file, setFile] = useState(value ?? null)
    const [preview, setPreview] = useState()
    const [blur, setBlur] = useState(false)
    const onDrop = useCallback(
        (acceptedFiles) => {
            setFile(acceptedFiles[0])
            console.log(Object.keys(acceptedFiles[0]), acceptedFiles[0] instanceof File)
            if (isSurvey)
                onChange(acceptedFiles[0], path)
        },
        [setFile]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const handleDelete = (e) => {
        e.stopPropagation()
        setFile(null)
        setBlur(false)
    }

    useEffect(() => {
        // create the preview
        let objectUrl
        if (file) {
            objectUrl = URL.createObjectURL(file)
            setPreview(objectUrl)
        }
        // free memory when ever this component is unmounted
        return () => objectUrl && URL.revokeObjectURL(objectUrl)
    }, [file])
    return (
        <FileUpload {...getRootProps()} id={"file-upload"}>
            <input accept='image/*' {...getInputProps()} />
            {file ? (
                <FilePreviewContainer>
                    <FilePreview src={preview} alt={file.name}  style={{filter: blur ? "blur(3px)" : "initial"}} />
                            <FileDelete
                        id={"file-delete"}
                                onMouseEnter={() => setBlur(true)}
                                onMouseLeave={() => setBlur(false)}
                        onClick={(e) => handleDelete(e)}>
                                <CircleClose />
                            </FileDelete>
                </FilePreviewContainer>
            ) : (
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <DragAndDrop/>
                    <FileUploadTitle isDragActive={isDragActive}>Drag and drop your files here or Browse</FileUploadTitle>
                    <FileUploadText isDragActive={isDragActive}>
                        System supports PNG, PDF, and JPG files only. Max size of each file is 150MB
                    </FileUploadText>
                </div>
            )}
        </FileUpload>
    )
}

export default Dropbox