import React from "react";
import DependencyWrapper from "../DependencyWrapper/DependencyWrapper";
import {contentComponent, getFormDataVal, getScreenId, RenderContent} from "../../helpers";
import {useSelector} from "react-redux";
import Organisations from "../../../../data/organisation.json";

const ComponentWrapper = ({componentParams, style}) => {
    let {t, config, specs, path, formProps} = componentParams
    const {onChange, value} = formProps
    const { panelFormData } = useSelector((store) => store)
    const newValue = value ?? (getFormDataVal(panelFormData, path)).value ?? ""
    const dependencies = specs.dependencies
    const [showDependencies, setShowDependencies] = React.useState(dependencies ? dependencies.value === newValue :  false)
    const pathArr = path.split(".")
    const currentNode = pathArr.pop()

    if (currentNode === "managerorg") {
        componentParams = {...componentParams, specs: {...specs, content: Organisations}}
    }

    const setNewValue = (newValue, path) => {
        if (dependencies)
            setShowDependencies(dependencies.value == newValue)
        onChange(newValue, path)
    }
    return (
        <div style={style}>
            {contentComponent({...componentParams, formProps: {...formProps, onChange: setNewValue}})}
            {dependencies &&
                <DependencyWrapper show={showDependencies}>
                    <RenderContent
                        data={dependencies}
                        openScreenId={getScreenId(path)}
                        path={pathArr.join(".")}
                        config={config}
                        t={t}
                        formProps={formProps}
                    />
                </DependencyWrapper>
            }
        </div>

    )
}
export default ComponentWrapper