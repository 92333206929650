import styled from "styled-components";

export const FileUpload = styled.div`
	width: 100%;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: ${({isDragActive})=> `10px ${isDragActive ? '4px' : '0px'}`};
  transition: all 0.5s;
  border: ${({isDragActive})=> `1px dashed ${isDragActive ? '#dedede' : '#fff'}`};
  min-height: 96px;

	span {
    	font-size: 18px;
    	text-align: center;
    	color: #303030;
  	}
  
  button {
    min-width: 112px;
    height: 100%;
    background: #fff;
    border: ${({color})=>`1px solid ${color ?? '#b21b0c'}`};
    transition: all 0.5s;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 20px;
    width: auto;
    margin-bottom: 8px;
    
    span {
      font-size: 14px;
      color: ${({color})=> color ?? '#b21b0c'};
    }
    
    svg {
      path {
        fill: ${({color})=> color ?? '#b21b0c'};
      }
    }
    
    
    &:hover {
      background: ${({color})=> `${color ?? '#b21b0c'}14`};
    }
  }
`;
export const FileDelete = styled.div`
	position: absolute;
	cursor: pointer;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	top: 5px;
	right: 10px;
	opacity: 0;
  transition: all 0.5s;
  background: #FFFFFF;
  
  svg {
    width: 26px;
    height: 26px;
  }
`;

export const FilePreviewContainer = styled.div`
  width: 112px;
  height: 96px;
	position: relative;
	left: 0;
  background: #f3f3f3;
  padding: 8px;
	&:hover {
        div {
          opacity: 1;
        }
    }
`;

export const FilePreview = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 8px;
	margin: 0 auto;
  object-fit: contain;
`;

export const FileUploadTitle = styled.h4`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	color: ${({isDragActive}) => (isDragActive ? "#999999" : "#000")};
`;

export const FileUploadText = styled.p`
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: ${({isDragActive}) => (isDragActive ? "#cccccc" : "#A7ADB2")};
	max-width: max-content;
	margin: 0;
`;
