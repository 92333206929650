import React from "react";
import {StyledArrowButton} from "../Footer/Footer-styled";
import {RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import {CloseIconContainer, Header} from "../Reports-styled";
import {Cross} from "../../../Panel/components/PanelHeader/helpers";

const ReportsHeader = ({close, onArrowClick, showArrow, isCustom = false, title, children}) => {
  return (
    <Header
      isCustom={isCustom}
    >
      {children}
      {
        showArrow && (
          <StyledArrowButton
            onClick={onArrowClick ? onArrowClick : undefined}
            radius={4}
            disable={!onArrowClick}
          >
            <RightArrowIcon width={12} height={12} />
          </StyledArrowButton>
        )
      }
      <span style={{marginLeft: !showArrow ? 16 : 0}}>{title}</span>
      <CloseIconContainer onClick={close}>
        <Cross/>
      </CloseIconContainer>
    </Header>
  );
};

export default ReportsHeader;