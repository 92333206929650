import styled from "styled-components";

export const FileUpload = styled.div`
    display: flex;
	width: 100%;
	height: 200px;
	background: #ffffff;
	align-items: center !important;
	justify-content: center;
	border: 1px dashed #a7adb2;
	box-sizing: border-box;
	padding: 15px 20px;
	border-radius: 2px;
	margin-bottom: 16px;
	cursor: pointer;
	span {
    	font-size: 18px;
    	text-align: center;
    	color: #303030;
  	}
`
export const FilePreviewContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	left: 0px;
	margin-bottom: 16px;
	&:hover {
        div {
            display: block;
        }
    }
`
export const FilePreview = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 8px;
	margin: 0 auto;
	max-height: 100%;
`
export const FileDelete = styled.div`
	position: absolute;
	cursor: pointer;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	top: 5px;
	right: 10px;
	display: none;
`
export const FileUploadTitle = styled.h4`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	color: ${({isDragActive}) => (isDragActive ? "#999999" : "#000")};
`
export const FileUploadText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: ${({isDragActive}) => (isDragActive ? "#cccccc" : "#A7ADB2")};
	max-width: max-content;
	margin: 0;
`