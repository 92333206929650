import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux";
import {setPanelPath_actionType} from "../../../../redux/constants";
import {StyledReportButton} from "./ReportButton-styled";
import {getModulesWithReports, getOperationsWithReports, getReports, replaceURLParams} from "../helpers";
import {EMBED_VARIANTS} from "../../../../utils/helper";

export const ReportButton = ({config, t})=>{
    const dispatch = useDispatch()
    const {activeModule, panelPath} = useSelector((store) => store)
    
    useEffect(() => {
        if (config.embed === EMBED_VARIANTS.APP) {
            return;
        }
        
        if (config.datacollection === 'open') {
            let reports = [];
            const urlParams = new URLSearchParams(window.location.search);
            const urlModule = urlParams.get('module');
            const urlDataCollection = urlParams.get('datacollection');
            const urlOp = urlParams.get('op');
    
            if (urlDataCollection) {
                if (urlModule) {
                    reports = getReports(config, activeModule)
                } else if (urlOp) {
                    reports = getModulesWithReports(config);
                } else {
                    reports = getOperationsWithReports(config);
                }
            } else if (urlOp) {
                reports = getReports(config, activeModule)
            }

            if (reports.length > 0) {
                setPanelPath('reportAll')
            }
        } else {
            setPanelPath('');
        }
    },[])
    
    const setPanelPath = (newPath) => {
        dispatch({type: setPanelPath_actionType, payload: newPath})
    }
    
    const reportType = useMemo(()=>{
        const reports = config.modules[activeModule]?.reports ?? config.opDefaults.modules[activeModule]?.reports ?? [];
        const urlParams = new URLSearchParams(window.location.search);

        if (!urlParams.has('op') || reports.length > 0) {
            return 'reportAll';
        }
    
    },[config, activeModule])
    
    const openReports = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('op')) {
            replaceURLParams([{key:'datacollection', value:'open'}])
        } else {
            const op = urlParams.get('op')
            const params = [
                {
                    key: "op",
                    value: op
                },
                {
                    key: "module",
                    value: activeModule.toLowerCase()
                },
                {
                    key: "datacollection",
                    value: "open"
                }
            ]
            replaceURLParams(params)
        }

        setPanelPath(reportType)
    }

    if (!reportType || config.embed === EMBED_VARIANTS.APP) return <></>
    
    return (
      <StyledReportButton className="onboarding-report" color={config.opsColor} onClick= {openReports}>
          <span>{t("screen.popup.actions.report")}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 47.97761 48" fill="white">
              <g>
                  <g>
                      <path
                        d="M26.56,28.69l-6.18,1.72a2.02035,2.02035,0,0,1-.6.08,2.245,2.245,0,0,1-1.79-.89,2.04577,2.04577,0,0,1-.32-.6H6a1,1,0,0,1,0-2H17.79l1.11-4H6a1,1,0,0,1,0-2H19.51a2.80674,2.80674,0,0,1,.59-.86L23.24,17H6a1,1,0,0,1,0-2H25.24L30,10.24V7a.97228.97228,0,0,0-.94-1H.94A.97228.97228,0,0,0,0,7V47a.97228.97228,0,0,0,.94,1H21V42a3.00879,3.00879,0,0,1,3-3h6V25.8l-2.12,2.12A2.99629,2.99629,0,0,1,26.56,28.69ZM13,35H6a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z"/>
                      <path d="M24,41a1.003,1.003,0,0,0-1,1v6l7-7H24Z"/>
                      <path d="M47.13,1.16A3.511,3.511,0,0,0,44.52,0a3.45971,3.45971,0,0,0-2.47,1.03L30,13.07l-3.01,3.01-.91.91-4.57,4.57a.92293.92293,0,0,0-.25.44l-1.49,5.36-.23.82a.24359.24359,0,0,0,.24005.31.19482.19482,0,0,0,.06995-.01l.03-.01,6.15-1.71a.941.941,0,0,0,.43-.25L30,22.97,46.86,6.11A3.638,3.638,0,0,0,47.13,1.16Z"/>
                  </g>
              </g>
          </svg>
      </StyledReportButton>
    )
}

export default ReportButton
