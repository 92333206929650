import styled from "styled-components";

export const CardContainer = styled.div`
    border-radius: 8px;
    border: 0;
`
export const CardTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const IconTitleContainer = styled.div`
  display: flex;
  justify-content: centre;
  align-items: center;
`
export const CardTitle = styled.p`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 24px;
  max-width: 100%;
  @media screen and (max-width: 1120px) {
    max-width: 100%;
    margin: 0 8px 0 8px;
  }
`
export const CardDescription = styled.p`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-size: .75em;
  line-height: 1.2em;
  height: auto;
`