import styled, {keyframes} from "styled-components";

const fadeInModal = keyframes`
  from {
    opacity:0.9;
    scale: 0.9
  }
  to {
    opacity:1;
    scale: 1
  }
`

const fadeInBody = keyframes`
  from {
    opacity:0.3;
  }
  to {
    opacity:1;
  }
`

export const StyledReportsWrapper = styled.div`
  position: absolute;
  top: 0;
  background: #031C2D80;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`

export const StyledReportsModal = styled.div`
  background: #fff;
  width: 470px;
  height: 85%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 12px;
  animation: ${fadeInModal} 0.3s linear;
  position: relative;
  
  iframe {
    border: none;
  }
  
`

export const StyledReportsBody = styled.div`
  overflow: hidden;
  animation: ${fadeInBody} 0.3s linear;
  height: ${({isFinal})=> isFinal && '100%'};
  //scroll-snap-type: y mandatory;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  

  > p {
    font-weight: 600;
    font-size: 20px;
  }
  
  > div {
  
  }
`

export const CategoriesCard = styled.div`
  width: ${({width}) => width ? width : "calc(33% - 8px)"};
  min-height: 40px;
  max-width: 100%;
	background: #ffffff;
	border-radius: 8px;
	font-weight: 600;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	gap: 8px;
  padding: 8px 8px;
  border:  #b21b0c;
  box-sizing: border-box;
	background: ${({selected, bgColor}) => (selected ? (bgColor ? bgColor : "#b21b0c1A" ): bgColor ?? "#fff")};
  box-shadow: ${({selected, bgColor, borderColor}) => `${selected ? "0 0 0 2px" : "0 0 0 1px"} ${borderColor ? borderColor : bgColor ? bgColor : '#b21b0c'}`};
  font-size: 12px;
  transition: all 0.5s;
  pointer-events: ${({disable}) => disable ? "none" :"auto"};
  color: ${({color}) => color };

  @media screen and (max-width: 643px) {
    width: 100%;
  }
  
  
  svg {
    width: 28px;
    height: 28px;
  }
  
  span {
    margin: 0;
    line-height: normal;
    color: ${({color}) => color };
    transition: all 0.5s;
    font-size: 13px;
  }
`

export const CloseIconContainer = styled.button`
    border: 0;
    margin-left: auto;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.1), 0 1px 4px 2px rgba(60,64,67,.1);
  min-height: 26px;
  margin-bottom: 6px;
  z-index: 1;
  color:${({isCustom})=> isCustom ? '#FFFFFF' : '#393738'} ;
  
  svg {
    path {
      stroke: ${({isCustom})=> isCustom && '#FFFFFF'}
    }
  }
  
  button {
    min-height: 26px;
    z-index: 1;
  }
  
  span {
    text-align: left;
    margin: 0;
    z-index: 1;
    line-height: 1;
    font-weight: 400;
    font-size: .75em;
    max-width: max-content;
    padding: 0;
  }
`