import React, { useContext, useEffect, useRef } from "react";
import { StyledMapContainer } from "./MapContainer-styled";
import Error from "../Error";
import PropTypes from "prop-types";
import useEsriMap from "../../hooks/useEsriMap";
import { handleMapViewClick, view } from "../../utils/API";
import { ConfigContext } from "../../utils/ConfigContext";
import ContextMenu from "../ContextMenu";
import PrintMask from "../PrintMask/PrintMask";
import {useSelector} from "react-redux";

const MapContainer = ({ setSideBarIFrame,
	sideBarIFrame,
	activeModule,
	activeLanguage, setActiveLanguage,
	setFeatureTableActive,
	setSideBarMobile,
}) => {
	const elementRef = useRef();
	const {config} = useContext(ConfigContext)
	const {printWidget} = useSelector(state=>state);
	const { errors } = useEsriMap({
		elementRef,
		setSideBarIFrame,
		activeModule,
		activeLanguage,
		setActiveLanguage,
		setFeatureTableActive,
		setSideBarMobile
	});

	/*
	useEffect(() => {
		if (!view)
			return;

		view.on("click", () => {
			view.ui._components.forEach((comp) => {
				if (comp.widget && typeof comp.widget.collapse === 'function')
					comp.widget.collapse()
			})

		})
	});
	*/

	useEffect(() => {
		if (!view)
			return;

		if (sideBarIFrame === "pleaseselect") {
			let handler = view.on("click", (event) => {
				handleMapViewClick({ event, config, setSideBarIFrame, activeLanguage }).then(() => {
					handler.remove();
				});
			});
		}
	}, [sideBarIFrame, config, setSideBarIFrame])

	return (
		<>
			<ContextMenu />
			<StyledMapContainer
				id="viewDiv"
				className="onboarding-map"
				ref={elementRef}
				data-testid="map-container-viewDiv">
				{errors.length && (
					<Error messages={errors} data-testid="map-container-error" />
				)}
				{printWidget && elementRef.current && <PrintMask parent={elementRef.current} config={config}/>}
			</StyledMapContainer>
		</>
	);
};

MapContainer.propTypes = {
	config: PropTypes.object,
	AppErrors: PropTypes.array,
	opLayersVisible: PropTypes.bool
};

export default MapContainer;
