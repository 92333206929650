import styled from "styled-components";

export const Input = styled.input`
	background: #ffffff;
	border: 1px solid #c5cdd2;
	box-sizing: border-box;
	height: 40px;
	width: 100%;
	padding: 8px 14px;
`;

export const CoordinatesContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 0 24px 0;
`;

export const InputTitle = styled.label`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	font-family: 'Open Sans', sans-serif;
	margin: 0 0 24px 0;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;
