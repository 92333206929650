import {removeData, setTypeOfVehicle_actionType} from "../constants";
const initialState = null;

const typeOfVehicle = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case setTypeOfVehicle_actionType:
			return payload;
		case removeData:
			return state = initialState;
		default:
			return state;
	}
};

export default typeOfVehicle;