import Input from "../../Report/new/Input/Input";
import {CustomizationTitle, StyledBody} from "../SidebarMenu/SidebarMenu-styled";
import React, {useCallback, useMemo} from "react";
import TextArea from "../../TextArea/TextArea";
import ColorsRow from "./ColorsRow";
import UploadFile from "../../Report/new/UploadFile/UploadFile";
import DisclaimerConfigurations from "./DisclaimerConfigurations";

const CustomizeMapDetails = ({
  onMapDataChange,
  config,
  isEditor,
  mapData,
  t,
  addCustomizations
}) => {
  
  const onInputChange = useCallback((e)=>{
    const {name, value} = e.target;
    onMapDataChange(name, value)
  },[onMapDataChange])
  
  const colors = ["#000000", "#228be6", "#fab005", "#7ED321", "#50E3C2"];
  const startIndex = useMemo(()=> !isEditor && addCustomizations ? 1 : 0,[isEditor, addCustomizations])
  
  return (
    <StyledBody opsColor={config.opsColor}>
      {addCustomizations && <div style={{paddingBottom: 20, display: "flex", flexDirection: "column"}}>
        <p style={{marginBottom: 10}}><span>{startIndex}. </span>{t("print.mapDetails.questions.0.title")}</p>
        <CustomizationTitle style={{marginBottom:0}}>{t("print.mapDetails.questions.0.options.logo.title")}</CustomizationTitle>
        <UploadFile
          buttonTitle={t("print.mapDetails.questions.0.options.logo.buttonTitle")}
          fileDescription={t("print.mapDetails.questions.0.options.logo.description")}
          config={config}
          form={mapData}
          setForm={(name, file) => {
            onMapDataChange(name, file);
          }}
          name="attachment"
          assignUrl
        />
        <ColorsRow
          title={t("print.mapDetails.questions.0.options.backgroundColor")}
          selectedColor={mapData.backgroundColor}
          colors={[...colors, config.opsColor]}
          onClick={(color) => onMapDataChange("backgroundColor", color)}
        />
      </div>}
      <div style={{paddingBottom:0}}>
        <p style={{marginBottom:addCustomizations ? 10 : 20}}><span>{startIndex+1}. </span>{t("print.mapDetails.questions.1.title")}</p>
        <div style={{
          display:'flex',
          flexDirection:'column',
          gap:20
        }}>
          {addCustomizations && <ColorsRow
            title={t("print.mapDetails.questions.0.options.textColor")}
            selectedColor={mapData.textColor}
            colors={[...colors, "#ffffff"]}
            onClick={(color) => onMapDataChange("textColor", color)}
          />}
          <div>
            <Input onChange={onInputChange} value={mapData.title} label={t("print.mapDetails.questions.1.options.mapTitle")} placeholder="" name="title" borderColor={config.opsColor}  />
            <Input onChange={onInputChange} value={mapData.subtitle} label={t("print.mapDetails.questions.1.options.mapSubtitle")} placeholder="" name="subtitle" borderColor={config.opsColor}  />
          </div>
        </div>
      </div>
      {!addCustomizations && (
        <DisclaimerConfigurations
          index={startIndex + 2}
          config={config}
          t={t}
          onMapDataChange={onMapDataChange}
          mapData={mapData} isEditor={isEditor}
        />
      )}
    </StyledBody>
  );
};

export default CustomizeMapDetails;