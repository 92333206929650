import styled from "styled-components";

export const BasemapWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  position:relative;
  z-index: 2;
  
  #basemapGallery {
    transition: all 0.5s;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    background: #efefef;
    backdrop-filter: blur(2px);
    width: auto !important;
    min-width: 91px;
    outline: none;
    bottom: -4px;
    position: absolute;
    right: calc(100% + 10px);
  }
  
  #basemapGallery ul {
    display: flex !important;
    flex-direction: row !important;
    gap: 4px !important;
  }

  #basemapGallery ul li {
    width: 91px !important;
    height: 60px !important;
  }

  @media (max-width: 769px) {
    #basemapGallery {
      width: 102px !important;
    }
    
    #basemapGallery ul {
      flex-direction: column !important;
    }
  }
  
  .esri-basemap-gallery__item {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .esri-basemap-gallery__item-thumbnail {
    border:none;
  }
  
  .esri-basemap-gallery__item-content {
    display: none;
  }
  
  .esri-basemap-gallery__item-container {
    padding:6px !important;
    background: none;
  }
`

export const BasemapPreview = styled.div`
  border: ${({opsColor, show})=> `2px solid ${show && opsColor ? opsColor : '#efefef'}`};
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background:#FEFEFE;
  width:91px;
  height:60px;
  cursor:pointer;
  transition: all 0.3s;
  
  img {
    width: 100%;
  }
`