import styled from "styled-components";
import {mutedColor} from "../../../../utils/Theme";
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: .8em;
`
export const InputLabel = styled.label`
    font-family: 'Open Sans', sans-serif !important;
    width: 100%;
	font-weight: 400;
	font-size: .575em;
	line-height: 1.5em;
    color: ${mutedColor};
	cursor: pointer;
	text-align: left;
	padding: 0;
`