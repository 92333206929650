import React, {useEffect, useState} from "react";
import {GetCardIcon} from "./helpers";
import {CardContainer, CardDescription, CardTitle, CardTitleRow, IconTitleContainer} from "./Card-styled";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
    setPanelPageIndex_actionType,
    setPanelPath_actionType
} from "../../../../redux/constants";

const Card = ({specs, path, formProps}) => {
    const {isSurvey, onChange, value} = formProps
    let {size, icon, link, disabled, title, description} = specs
    const dispatch = useDispatch()
    const {t} = useTranslation("common")
    title = t(`screen.panel.${path}.title`, title ?? "")
    description = t(`screen.panel.${path}.description`, description ?? "")
    const hasLink = link && link !== ""
    const pathArr = path.split(".")
    const cardValue = pathArr[pathArr.length - 1]
    pathArr.pop()
    const [selected, setSelected] = useState(value ? value === cardValue : false)

    useEffect(() => {
        setSelected(value ? value === cardValue : false)
    }, [value])

    const onBtnPress = () => {
        if (hasLink) {
            const linkArr = link.split(".")
            if (linkArr.length < 1) return
            dispatch({ type: setPanelPageIndex_actionType, payload: 0 })
            dispatch({ type: setPanelPath_actionType, payload: link })
        }
        if (isSurvey) {
            onChange(cardValue, path)
        }
    }
    //24, 16 margin-top: 24px;
    const containerStyles = {
        marginTop: size === "small" ? ".5em" : "1.5em",
        padding: size === "large" ? "1.5em" : ".7em",
        cursor: (hasLink || isSurvey) ? "pointer" : "default",
        display: "flex",
        flexDirection: "column",
        justifyContent: size === "large" ? "space-between" : "center",
        alignItems: size === "large" ? "flex-start" : "center",
        background: selected ? "#b21b0c" : "#fff",
        color: selected ? "#fff" : "#323232",
        boxShadow: `0px 2px 8px ${selected ? "rgba(178, 27, 12, 0.06)" : "rgba(0, 0, 0, 0.06)"}, 0px 0px 4px ${selected ? "rgba(178, 27, 12, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
        opacity: disabled ? 0.5 : 1,
        maxHeight: size === "small" ? "30%" : size === "medium" ? "50%" : "auto",
    }
    const RowStyles = size === "medium" ? {} :
        {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        }
    const descriptionStyles = {
        fontWeight: size === "small" ? "500" : "400",
        textAlign: size === "large" ? "left" : "center"
    }
    return (
        <CardContainer id={"card-container"} style={containerStyles}
            onClick={() => onBtnPress()}>
            <CardTitleRow id={"card-title-row"} style={RowStyles}>
                <IconTitleContainer id={"icon-container"}>
                    {icon &&
                        <GetCardIcon description={{
                            ...icon,
                            color: (selected) ? "#FFFFFF" : icon.color
                        }}/>
                    }
                    <CardTitle>{title}</CardTitle>
                </IconTitleContainer>
            </CardTitleRow>
            {(path && description) && <CardDescription style={descriptionStyles}>{description}</CardDescription>}
        </CardContainer>
    )
}
export default Card