import styled from "styled-components";

export const ReportProgressStyled = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin: 0;
`;

export const ProgressBarBtn = styled.div`
	background-color: #fff;
	width: 24px;
	padding-top: 2px;
	height: 24px;
	border-radius: 4px;
	cursor: pointer;
    visibility: ${({disablePrev, disableNext}) => (disableNext || disablePrev ? "hidden" : "visible")};
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 3px rgba(0, 0, 0, 0.1);
		&:last-child{
			margin-left: auto;
		}
`;