import styled from "styled-components";

export const LocationMapWrapper = styled.div`
  margin-bottom: 14px !important;
  width: 100%;
  min-height: 350px;
  border: ${({opsColor})=>`1px solid ${opsColor ?? '#b21b0c'}e6`};
  border-radius: 4px;
  overflow: hidden;
  ${props => props.expand && `
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    background: #FFFFFF;
    z-index: 10;
    border: 5px solid #fff;
    box-sizing: border-box;
    border-radius: 10px;
  `}
  
  
`



export const LocationMap = styled.div`
  width: 100%;
  height: 100%;
  
  & .esri-ui-bottom-right.esri-ui-corner {
    flex-direction: column;
    gap: 8px;
  }

  & .esri-search--multiple-sources.esri-search__container {
    height: 32px;
  }
  
  & .esri-search__form input {
    height: 26px;
  }

  & .esri-widget--button {
    width: 32px !important;
    height: 32px !important;
  }
  
  & .esri-search__form input {
    font-size: 12px;
  }

  & .esri-search__clear-button {
    width: 12px !important;
  }
  
  & .esri-search__clear-button span {
    font-size: 12px;
  }
`
export const LocationInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    gap: 5px;
    
    input {
      max-width: 120px;
    }
  }
  
`

export const LocationExpandButton = styled.button`
  border: none;
  svg {
    fill:#6e6e6e;
  }
`
