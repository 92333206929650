import React, {useCallback, useMemo, useState} from "react";
import {
  FilterDropdownFooter,
  FilterDropdownMenu,
  FilterDropdownMenuOption, FilterDropdownWrapper,
  MapFilterButton
} from "../ManualWidget-styled";
import {chevronDown16} from "@esri/calcite-ui-icons";
import {getMapFilterOptions} from "../../Filter/helpers";
import Dropdown from "../../Dropdown";

const FilterDropdown = ({field, t, onFilterChange, selectedValue, config, options}) => {
  const [show, setShow] = useState(false);
  
  const handleClose = useCallback(()=>{
    setShow(false)
  },[])
  
  const handleSelection = useCallback((e, value)=>{
    if (selectedValue.includes(value)) {
      onFilterChange(field.name, selectedValue.filter(item => item !== value));
    } else {
      onFilterChange(field.name, [...selectedValue, value]);
    }
  },[onFilterChange, selectedValue])
  
  const clearAll = useCallback(() =>{
    onFilterChange(field.name, []);
  },[onFilterChange, field])
  
  const selectedCount = useMemo(()=>{
    if (!!selectedValue.length) {
      return ` (${selectedValue.length})`
    }
    return '';
  },[selectedValue])
  
  const dropdownOptions = useMemo(()=> getMapFilterOptions({options, field, t}),[field, t, options])
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
    >
      <FilterDropdownWrapper>
        <MapFilterButton>
        <span>
          {t('layer.fieldAlias.' + field.name, field.alias)}{selectedCount}</span>
          <svg
            style={{
              transform: show ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: "all 0.3s"
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16" height="16"
            viewBox="0 0 16 16"
          >
            <path d={chevronDown16} />
          </svg>
        </MapFilterButton>
      </FilterDropdownWrapper>
      <FilterDropdownMenu
        show={show}
      >
        {
          dropdownOptions.map((option)=>(
            <FilterDropdownMenuOption
              key={`${option.label}-${option.value}`}
              opsColor={config.opsColor}
            >
              <label>
                <input
                  checked={selectedValue.includes(option.value)}
                  onChange={(e)=>handleSelection(e, option.value)}
                  style={{scale:1.2}} type="checkbox"
                />
                <span>{option.label}</span>
              </label>
            </FilterDropdownMenuOption>
          ))
        }
        <FilterDropdownFooter>
          <button onClick={clearAll}>Clear</button>
          <button onClick={handleClose}>Done</button>
        </FilterDropdownFooter>
      </FilterDropdownMenu>
    </Dropdown>
  );
};

export default FilterDropdown;