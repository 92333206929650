export const getMapFilterOptions = ({options, field, t}) => {
  let fieldValues = []

  if (Array.isArray(options)) {
    options.forEach(option=> {
      const codedValue = field.domain.codedValues.find(codedValue => codedValue.code === option)
      if (codedValue) {
        fieldValues.push({label: codedValue.name, value: codedValue.code})
      }
    })
    
  } else {
    if (field.domain && field.domain.codedValues) {
      if (field.nullable)
        fieldValues.push({label: t('screen.domain.unspecified'), value: null})
      
      field.domain.codedValues.forEach((codedValue) => {
        fieldValues.push({label: codedValue.name, value: codedValue.code})
      })
    }
  }
  return fieldValues;
}