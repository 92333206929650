import React from "react";
import {Chart as ChartJS, ArcElement} from  'chart.js';
import {Pie} from "react-chartjs-2";

ChartJS.register(
    ArcElement
)

const PieChart = ({data, options}) => {
    return <Pie data={data} options={options}/>
}

export default PieChart;