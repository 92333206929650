import {ProgressBarContainer} from "../../PanelContainer-styled";
import React from "react";
import {borderColor} from "../../../../utils/Theme";

const ProgressBar = ({color, total, pos}) => {
    const styles = {
        backgroundColor: color,
        marginBottom: "10px",
        height: ".3em",
        borderRadius: ".1em"
    }
    return (
        <ProgressBarContainer>
            <div style={{...styles, width: "100%", backgroundColor: borderColor}}>
                <div style={{...styles, width: `${100 / total * pos}%`, backgroundColor: color}}></div>
            </div>
        </ProgressBarContainer>
    )
}
export default ProgressBar