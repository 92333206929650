import React, {useEffect, useState} from "react";
import {DomainContainer} from "./Domain-styled";
import {getDataList, getLayer, getScreenId, RenderContent} from "../../helpers";
import {useTranslation} from "react-i18next";
import {view} from "../../../../utils/API";
import {useSelector} from "react-redux";
import {CalciteLoader} from "@esri/calcite-components-react";

const Domain = ({specs, path, formProps, config}) => {
    const {panelId} = useSelector((store) => store)
    const {t} = useTranslation("common")
    const [content, setContent] = useState({})
    const [loading, setLoading] = useState(true)
    //b68ff89fda174defab271d7ee203e283 - Wld trs bridges b w
    const pathArr = path.split(".")
    const currentNode = pathArr.pop()

    useEffect(() => {
        if (!view)
            return
        const getContent = async () => {
            setLoading(true)
            if (!panelId)
                return []
            const layerId = panelId.id
            const layer = panelId.content ?? layerId ? await getLayer(layerId, view) : null
            if (!layer)
                return []
            const data = {
                ...specs.data,
                name: currentNode,
                layerId, layer
            }
            const dataList = await getDataList(data, view) ?? []
            let content = {}
            dataList.map((item) => {
                const domain = item.domain
                const type = domain ? "select" : !domain && item.type === "string" ? "textInput" : ""
                const label = t('layer.fieldAlias.' + item.name, item.alias)
                const itemContent = domain ? (domain.codedValues).map(({name, code}) => ({
                    value: code,
                    label: name
                })) : []
                content = {
                    ...content, [item.name]: {
                        type,
                        label,
                        content: itemContent,
                        isRequired: true,
                        size: "small",
                    }
                }
            })
            return content
        }

        getContent().then((data) => {
            setContent({content: data})
            setLoading(false)
        })
    }, [])

    if (loading)
        return (
            <div style={{display: "flex", paddingBottom: ".5em"}}>
                <CalciteLoader key="l" scale="s" style={{color: specs.opsColor, padding: 0}} type={"indeterminate"} inline={true} />
                <p style={{margin: 0, fontSize: ".575em", lineHeight: "1.2em"}}>{t("screen.component.loader.fetchingData")}</p>
            </div>
        )

    return (
        <DomainContainer id={"domain-container"}>
            <RenderContent
                data={content}
                openScreenId={getScreenId(path)}
                path={pathArr.join(".")}
                config={config}
                t={t}
                formProps={formProps}
            />
        </DomainContainer>
    )
}

export default Domain