/**
 * Create a chart based on a group filed (this must be a domain), a sum field (numeric) and an aggregation type
 * (SUM, AVG, MAX, MIN, etc)
 */
export const getChartMediaExpression = (chartType, groupField, aggregation, sumField, title) => {
	return `
		Expects($aggregatedFeatures, "${groupField}", "${sumField}")
		var attributes = {};
		var countFieldNames = [];
		var types = Distinct($aggregatedFeatures, "${groupField}");

		for(var t in types){
			var type = t.${groupField};
			var countFieldName;
			var filtered;
			if (type != null){
				filtered = Filter($aggregatedFeatures, "${groupField} = @type");
				countFieldName = DomainName($aggregatedFeatures, "${groupField}", type);
			} else {
				filtered = Filter($aggregatedFeatures, "${groupField} is null");
				countFieldName = "Undefined";
			}

			attributes[countFieldName] = COUNT(filtered);
			Push(countFieldNames, countFieldName);
		}
	  
		return {
			type: "media",
			attributes: attributes,
			title: "${title}",
			mediaInfos: [{
				type: "${chartType}",
				value: {
					fields: countFieldNames
				}
			}]
		}
	`
}

export const getTextMediaExpression = (groupField, aggregation, sumField, title) => {
	return `
		Expects($aggregatedFeatures, "${groupField}", "${sumField}");
		var types = Distinct($aggregatedFeatures, "${groupField}");

		var out = "<table>";
		for(var t in types){
			var type = t.${groupField};
			var countFieldName;
			var filtered;
			if (type != null){
				filtered = Filter($aggregatedFeatures, "${groupField} = @type");
				countFieldName = DomainName($aggregatedFeatures, "${groupField}", type);
			} else {
				filtered = Filter($aggregatedFeatures, "${groupField} is null");
				countFieldName = "Undefined";
			}

			out += "<tr><td>" + countFieldName + "</td><td>" + Count(filtered) + "</td></tr>";
		}
		out += "</table>";
	
		return {
			type: "text",
			title: "${title}",
			text: out
		}
	`
}

/**
 * Get a list of features and show a domain value for them
 */
export const getDomainValueListExpression = (domainField) => {
	return `
		Expects($aggregatedFeatures, '*')
		
		// create an HTML ordered list as a string and return in a rich text element
		var list = "<nav class='esri-popup__feature-menu-viewport' data-node-ref='_featureMenuViewportNode'><ol class='esri-popup__feature-menu-list' role='menu'>";
		for (var feat in $aggregatedFeatures){
			list += "<li role='menuitem' tabindex='-1' class='esri-popup__feature-menu-item no_link'>";
			list += \`<span class='esri-popup__feature-menu-title'><span> \${DomainName(feat, '${domainField}')}</span></span></li>\`
		}
		list += "</ol></nav>";

		return {
			type: "text",
			text: list
		}
	`
}