const getClusterAttributeFields = (cluster, fields, t) => {
  let clFields = {
    field1: undefined,
    fieldTitle1: undefined,
    field2: undefined,
    fieldTitle2: undefined
  }
  
  if (fields.length < 1) return clFields;
  
  clFields.field1 = fields.find(field => field.name === cluster?.clusterAttribute1)
  clFields.field2 = fields.find(field => field.name === cluster?.clusterAttribute2)
  clFields.fieldTitle1 = clFields?.field1 ? t('layer.fieldAlias.' + clFields.field1.name, clFields.field1.alias) : '';
  clFields.fieldTitle2 = clFields?.field2 ? t('layer.fieldAlias.' + clFields.field2.name, clFields.field2.alias) : '';
  
  return clFields;
}
export default getClusterAttributeFields;