import {useCallback, useEffect, useRef, useState} from "react";
import {BasemapPreview, BasemapWrapper} from "./Basemap-styled";
import useClickOutside from "../../hooks/useClickOutside";
import {useSelector} from "react-redux";

const Basemap = ({view, config, t, BasemapGallery, PortalBasemapsSource }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const{layersLoading} = useSelector(state=>state);
  const ref = useRef();
  const widgetRef = useRef();
  
  const watchListener = useRef();
  
  useClickOutside(ref, ()=>{
    setShowGallery(false);
  })
  
  useEffect(()=>{
    if (!view || widgetRef.current) return
    const basemapSource = new PortalBasemapsSource({query: `title:"${config.basemapGroup}"`})
  
    widgetRef.current = new BasemapGallery({
      view: view,
      source: basemapSource,
      container: 'basemapGallery',
    })
  
    if (!watchListener.current){
      watchListener.current?.remove();
    }
  
    watchListener.current = view.map.watch("basemap", (newBasemap) => {
      setImageUrl(newBasemap.thumbnailUrl)
    });
  
    widgetRef.current.when(()=>{
      setImageUrl(widgetRef.current?.activeBasemap?.thumbnailUrl)
    })
  },[layersLoading])
  
  const toggleBasemapGallery = useCallback(()=>{
    setShowGallery(prev=> !prev)
  },[])
  
  return (
    <BasemapWrapper
      ref={ref}
      opsColor={config.opsColor}
      className="onboarding-basemap"
    >
      <div
        style={{
          opacity: showGallery ? 1 : 0,
          pointerEvents: showGallery ? 'auto' : 'none',
        }}
        id="basemapGallery"
      />
      <BasemapPreview
        opsColor={config.opsColor}
        show={showGallery}
        className="basemap-preview"
        onClick={toggleBasemapGallery}
      >
        {imageUrl && <img alt="basemap-selected" src={imageUrl}/>}
      </BasemapPreview>
    </BasemapWrapper>
  );
};

export default Basemap;