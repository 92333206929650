import React from "react";
import {StyledCard, StyledCardBody} from "../Insights-styled";
import CardHeader from "./CardHeader";

const InsightsCard = ({
  insight,
}) => {
  return (
    <StyledCard>
      <CardHeader
        title={insight.title}
        description="Description"
      />
      <StyledCardBody>
        <span>{insight.value}{insight.unit && <span style={{color:'#6F7072'}}>{insight.unit}</span>}</span>
      </StyledCardBody>
    </StyledCard>
  );
};

export default InsightsCard;